.ecom_two {
  .Music-events {
    margin-bottom: 50px;




          @media (max-width: 1199px) {
            margin-left: auto;
          
          }

          @media (max-width: 991px) {
            margin-left: auto;
            

          @media (max-width: 767px) {
            margin-left: auto;

          }

          @media (max-width: 575px) {
            margin-left: auto;
            
          }

          @media (max-width: 360px) {
            margin-left: 0;
           
          }


        }
      }
    

    .event-container {
      .event-card {
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .image-container {
          position: relative;

          .event-image {
            width: 100%;
            height: auto;
            aspect-ratio: 5 / 2.5;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
          }

          p {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: var(--white-color);
            padding: 5px 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;

            @media (max-width: 1199px) {
              font-size: 15px;
            }

            @media (max-width: 991px) {
              font-size: 14px;
            }

            @media (max-width: 767px) {
              font-size: 13px;
            }

            @media (max-width: 575px) {
              font-size: 12px;
            }

            @media (max-width: 360px) {
              font-size: 11px;
            }

            @media (max-width: 320px) {
              font-size: 10px;
            }
          }

          .badge {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: red;
            color: white;
            padding: 5px 10px;
            border-radius: 5px;

            @media (max-width: 1199px) {
              padding: 4px 8px;
            }

            @media (max-width: 991px) {
              padding: 4px 8px;
            }

            @media (max-width: 767px) {
              padding: 3px 6px;
            }

            @media (max-width: 575px) {
              padding: 2px 5px;
            }

            @media (max-width: 360px) {
              padding: 1px 4px;
            }

            @media (max-width: 320px) {
              padding: 1px 3px;
            }
          }
        }

        .event-details {
          padding: 10px;
          display: grid;
          gap: 10px;

          @media (max-width: 1199px) {
            padding: 8px;
          }

          @media (max-width: 991px) {
            padding: 7px;
          }

          @media (max-width: 767px) {
            padding: 6px;
          }

          @media (max-width: 575px) {
            padding: 5px;
          }

          @media (max-width: 360px) {
            padding: 4px;
          }

          @media (max-width: 320px) {
            padding: 3px;
          }

          .text {
            display: flex;
            align-items: center;
            gap: 10px;

            h6 {
              font-size: 13px;
              color: #45474d;
              font-weight: 600;

              @media (max-width: 1199px) {
                font-size: 12px;
              }

              @media (max-width: 991px) {
                font-size: 11px;
              }

              @media (max-width: 767px) {
                font-size: 10px;
              }

              @media (max-width: 575px) {
                font-size: 9px;
              }

              @media (max-width: 360px) {
                font-size: 8px;
              }

              @media (max-width: 320px) {
                font-size: 7px;
              }
            }

            span {
              font-size: 20px;
              color: grey;

              @media (max-width: 1199px) {
                font-size: 18px;
              }

              @media (max-width: 991px) {
                font-size: 16px;
              }

              @media (max-width: 767px) {
                font-size: 14px;
              }

              @media (max-width: 575px) {
                font-size: 12px;
              }

              @media (max-width: 360px) {
                font-size: 10px;
              }

              @media (max-width: 320px) {
                font-size: 8px;
              }
            }
          }
        }

        .buy-now {
          background: #ffeaee;
          color: black;
          font-weight: 700;
          margin: 10px;
          margin-top: 0;
          border-radius: 8px;
          padding: 5px;
          display: flex;
          gap: 5px;
          justify-content: space-between;

          @media (max-width: 1199px) {
            padding: 4px;
          }

          @media (max-width: 991px) {
            padding: 4px;
          }

          @media (max-width: 767px) {
            padding: 3px;
          }

          @media (max-width: 575px) {
            padding: 2px;
          }

          @media (max-width: 360px) {
            padding: 1px;
          }

          @media (max-width: 320px) {
            padding: 1px;
          }

          h6 {
            font-size: 16px;
            font-weight: 700;

            &.buy_now {
              text-transform: uppercase;
              padding-left: 8px;
              border-left: 1px solid red;

              @media (max-width: 1199px) {
                font-size: 14px;
              }

              @media (max-width: 991px) {
                font-size: 13px;
              }

              @media (max-width: 767px) {
                font-size: 12px;
              }

              @media (max-width: 575px) {
                font-size: 11px;
              }

              @media (max-width: 360px) {
                font-size: 10px;
              }

              @media (max-width: 320px) {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  

  @media (max-width: 1199px) {
    .Music-events {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 991px) {
    .Music-events {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    .Music-events {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 575px) {
    .Music-events {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 360px) {
    .Music-events {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 320px) {
    .Music-events {
      margin-bottom: 5px;
    }
  }
}

