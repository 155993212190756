// // Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.user-details {
  min-height: 100vh;
  background-color: var(--page-bg);

  .user-details-container {
    padding: 20px;
    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }

    .user-details-layout {
      display: flex;
      gap: 25px;

      @media (max-width: $breakpoint-lg) {
        flex-direction: column;
      }

      .full-details-left-profile-card {
        width: 23%;
        height: 80vh;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        gap: 20px;
        position: fixed;

        @media (max-width: $breakpoint-lg) {
          position: relative;
          width: 100%;
          height: auto;
          padding: 20px;
        }

        .profile-photo {
          border-radius: 100%;
          width: 150px;
          height: 150px;
          background-color: #d8d3d3;
          position: relative;

          @media (max-width: $breakpoint-sm) {
            width: 120px;
            height: 120px;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            padding: 8px;
            background-color: rgb(164, 161, 161);
          }

          .online-indicator {
            position: absolute;
            bottom: 10px;
            right: 15px;
            width: 20px;
            height: 20px;
            border-radius: 50%;

            .status-indicator {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              margin: 1px;
            }

            .online {
              background-color: #22c55e;
            }

            .offline {
              background-color: #e74747;
            }
          }
        }

        .profile-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          h2 {
            font-size: 1.375rem;
            margin-bottom: 0.9375rem;

            @media (max-width: $breakpoint-sm) {
              font-size: 1.125rem;
            }
          }

          .contact-info {
            font-size: 1rem;
            margin-top: 4px;
            color: #6b7280;

            @media (max-width: $breakpoint-sm) {
              font-size: 0.875rem;
            }
          }

          .contact-methods {
            display: flex;
            gap: 20px;
            margin-top: 20px;
            flex-wrap: wrap;
            justify-content: center;

            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      .profile-detail {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-left: 25%;
        padding-bottom: 20px;

        @media (max-width: $breakpoint-lg) {
          width: 100%;
          margin-left: 0;
        }

        .pd-cards {
          width: 100%;
          padding: 20px;
          align-items: center;
          border-radius: 10px;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          background: white;
          overflow: visible;

          @media (max-width: $breakpoint-sm) {
            padding: 15px;
          }

          .pd-info {
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap: 20px;
            overflow: visible;

            .info-heading {
              display: flex;
              justify-content: space-between;
              gap: 80px;
              width: 60%;
              padding-left: 5px;

              @media (max-width: $breakpoint-md) {
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;
              }

              div {
                text-align: left;

                label {
                  font-size: 0.75rem;
                  font-weight: bold;
                  color: #868686;
                }

                p {
                  font-size: 0.9375rem;
                  font-weight: 400;
                }
              }
            }

            .info-detail {
              display: flex;
              justify-content: space-between;
              border-radius: 8px;
              padding: 20px;

              @media (max-width: $breakpoint-md) {
                flex-direction: column;
                gap: 15px;
              }

              .current-status {
                display: flex;
                gap: 2rem;
                .current-role,
                .current-mode {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 6px;
                  background-color: #f0f5ff;
                  padding: 15px;
                  border-radius: 10px;
                  @media (max-width: $breakpoint-sm) {
                    padding: 10px;
                    width: 100%;
                    gap: 0;
                  }

                  h6 {
                    font-size: 0.875rem;
                    font-weight: bold;
                  }

                  p {
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          .pd-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 7px 18px;
            border-bottom: 0.5px solid #a2a2a2;

            @media (max-width: $breakpoint-sm) {
              gap: 10px;
              align-items: flex-start;
            }

            h2 {
              font-size: 1.375rem;
              color: #3f5e98;

              @media (max-width: $breakpoint-sm) {
                font-size: 1.125rem;
              }
            }
            .pd-edit-state {
              display: flex;
              align-items: center;
              gap: 2px;
              cursor: pointer;
              color: #0091ff;
              padding: 4px 15px 4px 15px;
              span {
                font-size: 1.125rem;
              }
              &:hover {
                border: 1px solid;
                background-color: #4e63cf;
                color: #fff;
                padding: 4px 15px 4px 15px;
                border-radius: 6px;
              }
            }
          }

          .pd-roles {
            // display: flex;
            // flex-wrap: wrap;
            padding: 15px 0 0 8px;
            gap: 30px;
            margin-top: 15px;
            // @media (max-width: $breakpoint-sm) {
            //   // grid-template-columns: 1fr 1fr;
            //   padding: 10px 0 0 0;
            // }
          }

          .pd-data {
            padding: 15px 0 0 8px;
            gap: 30px;
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

            @media (max-width: $breakpoint-sm) {
              // grid-template-columns: 1fr 1fr;
              padding: 10px 0 0 0;
              width: 100%;
            }

            .pd-data-element {
              display: flex;
              align-items: center;
              gap: 10px;

              .pd-icon {
                img,
                .material-symbols-outlined {
                  height: 32px;
                  width: auto;
                }
              }

              .pd-data-value {
                display: flex;
                flex-direction: column;
                gap: 2px;

                h6 {
                  font-size: 0.75rem;
                  font-weight: bold;
                  line-height: normal;
                  margin-top: 0;
                  text-transform: capitalize;
                  color: #888;
                }

                p {
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background-color: #f0f5ff;
        padding: 15px;
        border-radius: 10px;

        @media (max-width: $breakpoint-sm) {
          padding: 10px;
          flex-direction: column;
        }

        h6 {
          font-size: 0.875rem;
          font-weight: bold;
        }

        p {
          font-size: 1rem;
        }
      }
    }
  }
}

// Rest of the styles remain the same but with added responsiveness
.theme_radio_container {
  // border: 1px solid var(--theme-grey);
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0 4px 0;

  @media (max-width: $breakpoint-sm) {
    gap: 5px;
  }

  .radio_single {
    .label_inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3px;
      width: 100%;
      .info_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: visible;

        .material-symbols-outlined {
          font-size: 1rem;
          cursor: pointer;
        }

        .info_icon_inner {
          position: absolute;
          line-height: normal;
          background: #e6e6e6;
          z-index: 1000;
          font-size: 0.8125rem;
          padding: 7px;
          border-radius: 10px;
          width: 180px;
          right: 0;
          transition: 0.3s;
          opacity: 0;
          top: 18px;
          color: var(--light-black);
          color: black;
          font-weight: 500;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          .text_red {
            color: #fa6262;
          }
          .text_green {
            color: #00a300;
          }
          b {
            font-weight: 500;
          }
        }

        &:hover {
          .info_icon_inner {
            transition: 0.3s;
            opacity: 1;
          }
        }
      }
    }
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    padding: 6px 10px;
    min-width: 100px;

    @media (max-width: $breakpoint-sm) {
      min-width: 80px;
      padding: 4px 8px;
      font-size: 0.75rem;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: flex;
    gap: 6px;
    cursor: pointer;
    padding: 6px 10px;
    border: 1px solid #498fff;
    color: var(--text-black) !important;
    border-radius: 30px;
    font-size: 0.875rem;
    min-width: 100px;
    text-align: center;
    background-color: white;
    justify-content: center;
  }

  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {
    background-color: #3f5e98;
    color: var(--white-color) !important;
    display: flex;
    justify-content: center;
  }
  input[type="checkbox"] + label::before,
  input[type="radio"] + label::before {
    content: "\002B";
    display: inline-block;
    margin-right: 5px;
    font-size: 0.875rem;
  }
  input[type="checkbox"]:checked + label::before,
  input[type="radio"]:checked + label::before {
    content: "\2713"; /* Check mark icon */
    display: inline-block;
    margin-right: 5px;
    font-size: 0.875rem;
  }
}

.pd-edit-option {
  display: flex;
  gap: 18px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    gap: 10px;
  }

  .pd-option-btn {
    padding: 8px 35px 8px 35px;
    border-radius: 5px;
    border: none;
    font-size: 0.875rem;
    text-align: center;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      padding: 8px 20px;
    }
  }
  .edit-msg_area {
    border: 2px solid;
    padding: 3px 20px 3px 20px;
    border-radius: 5px;
  }
  .success_msg {
    background-color: #e2ffe7;
    color: #00a300;
  }
  .error_msg {
    background-color: #ffefe5;
    color: #fa6304;
  }
  .option-btn-cancel {
    background-color: transparent;
    border: 1px solid #de5b5b;
    color: #de5b5b;
    font-weight: bolder !important;
    &:hover {
      background-color: #de5b5b;
      color: white;
      font-weight: bolder !important;
    }
  }
  .option-btn-save {
    background-color: #4991c8;
    color: white;
    &:hover {
      background-color: #1a6ba9;
    }
  }
}

.row_gap {
  row-gap: 30px;
  width: 100%;

  .form-field {
    position: relative;

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 15px;
    }
    .form-input {
      transition: all 0.3s ease;
      background-color: #f8fafc;
      border: 1px solid #658bc4;
      border-radius: 8px;
      padding: 12px 16px;
      width: 100%;
      font-size: 0.875rem;

      &:focus {
        background-color: white;
        border: 1px solid #658bc4;
      }
      textarea {
        border: 1px solid #658bc4;
      }
    }
    .input-label {
      position: absolute;
      left: 12px;
      top: -12px;
      pointer-events: none;
      color: #184db9;
      background: white;
      padding: 0 4px;
      font-size: 0.75rem;
      font-weight: bold !important;
      z-index: 100;
      border-radius: 10px;
    }

    // Required field indicator
    .required-field::after {
      content: "*";
      color: #ef4444;
      margin-left: 2px;
    }
    .react-select-container {
      width: 100%;

      .react-select__control {
        background-color: #f8fafc;
        border: 1px solid #658bc4;
        border-radius: 8px;
        padding: 6px;
        transition: all 0.3s ease;
        &--is-focused {
          border: 1px solid #4f76a6;
          box-shadow: none;
        }
      }

      .react-select__placeholder {
        color: #6b7280;
        font-size: 0.875rem;
      }

      .react-select__single-value {
        color: #374151;
        font-weight: 500;
        font-size: 0.875rem;
      }

      .react-select__menu {
        background-color: white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
      }

      .react-select__option {
        padding: 10px 20px;
        transition: background-color 0.2s ease;
        font-size: 0.875rem;
        z-index: 10000;

        &:hover {
          background-color: #f5f5f5; // Optional: Add a hover effect
        }
      }
    }
  }
}

.phone-input-container {
  position: relative;
  width: 100%;
  .react-tel-input {
    @media (max-width: $breakpoint-sm) {
      .phone-input {
        transition: all 0.3s ease-in-out;
        background-color: #f8fafc;
        border: 1px solid #658bc4 !important;
        border-radius: 8px;
        padding: 22px;
        width: 100%;
        font-size: 0.875rem;
        padding-left: 50px !important; // Space for country flag
        &:focus {
          background-color: white;
          box-shadow: 0 0 4px rgba(101, 139, 196, 0.5);
        }
      }
    }
  }
  .flag-dropdown {
    background: transparent;
    border: none;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  // Country selection dropdown styling
  .country-list {
    // border: 1px solid #658bc4;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .country {
      padding: 8px 12px;
      font-size: 0.875rem;
      transition: background 0.2s ease-in-out;
      border-bottom: 1px solid #e5e7eb;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #f3f4f6;
      }

      &.highlight {
        background: #e0e7ff;
      }
    }
  }
}

.upload-modal {
  .modal-content {
    @media (max-width: $breakpoint-sm) {
      padding: 15px;
    }

    .upload-modal-body {
      @media (max-width: $breakpoint-sm) {
        padding: 40px 20px;
      }
    }
  }
}

.parent-information-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;

  .info-section {
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    .info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h5 {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .parent-data {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
      gap: 20px;
      padding-top: 20px;
      .parent-data-element {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-basis: calc(20% - 20px); /* Ensures 5 items per row */
        min-width: 180px;
        min-width: 150px;

        .parent-icon img {
          height: 32px;
          width: auto;
          @media (max-width: $breakpoint-md) {
            height: 25px;
            width: auto;
          }
        }

        .parent-data-value {
          display: flex;
          flex-direction: column;
          gap: 2px;

          h6 {
            font-size: 0.75rem;
            font-weight: bold;
            color: #888;
            text-transform: capitalize;
          }

          p {
            font-size: 0.875rem;
            font-weight: 400;
            text-transform: capitalize;
            color: #333;
          }
        }
        @media (max-width: $breakpoint-md) {
          gap: 6px;
        }
      }
      @media (max-width: $breakpoint-md) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      @media (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr 1fr;
        padding: 0;
      }
    }
    @media (max-width: $breakpoint-md) {
      // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding: 1rem;
    }
  }
}

.animate-slide-in {
  animation: UserSlideIn 1s ease-out forwards;
}

@keyframes UserSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.parent-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  gap: 20px;
  padding-top: 20px;
  .parent-data-element {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-basis: calc(20% - 20px); /* Ensures 5 items per row */
    min-width: 180px;
    min-width: 150px;

    .parent-icon img {
      height: 32px;
      width: auto;
    }

    .parent-data-value {
      display: flex;
      flex-direction: column;
      gap: 2px;

      h6 {
        font-size: 0.75rem;
        font-weight: bold;
        color: #888;
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        text-transform: capitalize;
        color: #333;
      }
    }
  }
}
