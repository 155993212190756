.nav-setting-role {
    .nav-setting-menu-popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .nav-setting-menu-popup-container {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
      
      .nav-setting-menu-popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h6{
            font-weight: 700;
        }
      }
      
      .nav-setting-menu-popup-close {
        cursor: pointer;
        font-size: 20px;
        background-color: var(--grey-light);
        border-radius: 50%;
        padding: 2px;
      }
      
      .nav-setting-menu-popup-body {
        margin-top: 10px;
      }
      
      .nav-setting-menu-popup-field {
        margin-bottom: 15px;
      }
      
      .nav-setting-menu-popup-input {
        width: 100%;
        padding: 5px 7px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      .nav-setting-menu-popup-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      
        .menu-and-submenu-table-container {
            margin-top: 20px;
            padding: 10px;
            border: 4px solid #ddd;
            border-radius: 8px;
            background-color: #f9f9f9;
          
            .menu-and-submenu-table {
              width: 100%;
              border-collapse: collapse;
              table-layout: fixed;
            }
          
            .menu-and-submenu-table th,
            .menu-and-submenu-table td {
              padding: 12px 15px;
              text-align: left;
              border: 1px solid #ddd;
              vertical-align: top;
            }
          
            .menu-and-submenu-table th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
          
            .nav-setting-submenu-row {
              display: flex;
              flex-wrap: wrap;
              gap: 0; /* No space between items */
            }
          
            .nav-setting-submenu-cell {
              flex: 1 1 auto;
              /* border: 1px solid #ddd;
              padding: 10px; */
              text-align: left;
              min-width: 100px; 
              display: flex;
              align-items: center;
            }
          }
           
        .nav-setting-radio-container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            label {
                &.radio-container {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    border-radius: 8px;
                    transition: all 0.3s;

                    &.radio_btn_checked {
                        color: var(--white-color);
                        background-color: var(--click-color);
                    }
                }

                .radio-button {
                    margin-right: 10px;
                }
            }
        }

        .nav-setting-menu-edit-container {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            align-items: center;

            .nav-setting-menu-edit-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--card-bg);
                padding: 10px 15px;
                border-radius: 8px;
                color: var(--black-color);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                }

                .nav-setting-edit-input {
                    flex: 1;
                    padding: 0px 12px;
                    border: 1px solid var(--border-color);
                    border-radius: 4px;
                    font-size: 16px;
                    outline: none;
                    transition: box-shadow 0.3s ease;

                    &:focus {
                        box-shadow: 0 0 8px var(--primary-color);
                    }
                }

                .nav-setting-edit-icon:hover,
                .nav-setting-save-icon:hover {
                    color: var(--hover-color);
                }
            }
        }

        .nav-setting-add-nav-icon {
            cursor: pointer;
            background-color: var(--grey-light);
            height: 40px;
            width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nav-setting-active-inactive-text {
            color: red;
        }

        .nav-setting-products-status-switch {
            margin: 0 10px;
        }

        @media (max-width: 600px) {
            .nav-setting-menu-edit-container {
                max-width: 100%;
                padding: 0 10px;

                .nav-setting-menu-edit-item {
                    align-items: center;

                    .nav-setting-edit-input {
                        width: 100%;
                    }
                }
            }
        }
}