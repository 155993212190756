.app-store-one-video-container {
  background-color: var(--white-color);
  padding: 60px 0;

  .app-store-one-video-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .app-store-one-video-main-video {
      width: 100%;
      max-width: 960px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  .app-store-one-video-features {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    padding: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .app-store-one-video-feature {
      display: flex;
      gap: 15px;
      border-radius: 10px;

      .app-store-one-video-single-service {
        position: relative;
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 30px;
        overflow: hidden;
        border: 3px solid var(--grey-light);
      }

      .app-store-one-video-single-service .app-store-one-video-content {
        position: relative;
        z-index: 20;
      }

      .app-store-one-video-single-service .app-store-one-video-circle-before {
        position: absolute;
        top: 0;
        right: 0px;
        transform: translate(40%, -40%);
        width: 150px;
        height: 150px;
        background-color: var(--logo-primary-color);
        border: 6px solid var(--click-color);
        border-radius: 50%;
        opacity: 0.5;
        z-index: 10;
        transition: all .6s;
      }

      .app-store-one-video-single-service:hover .app-store-one-video-circle-before {
        width: 100%;
        height: 100%;
        transform: none;
        border: 0;
        border-radius: 0;
        opacity: 1;
      }

      .app-store-one-video-single-service .app-store-one-video-icon svg {
        display: inline-block;
        margin-bottom: 26px;
        width: 50px;
        height: 50px;
        padding: 10px;
        background-color: var(--logo-primary-color);
        border-radius: 5px;
        line-height: 70px;
        text-align: center;
        color: var(--white-color);
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .app-store-one-video-single-service:hover .app-store-one-video-icon svg {
        background-color: var(--white-color);
        color: red;
      }

      .app-store-one-video-single-service .app-store-one-video-icon {
        font-size: 45px;
      }

      .app-store-one-video-single-service h3 {
        margin-bottom: 8px !important;
        font-weight: 500;
        font-size: 20px;
        color: #303030;
        font-family: "Nunito", Sans-serif;
        transition: color .3s;
      }

      .app-store-one-video-single-service:hover .app-store-one-video-title {
        color: var(--black-color);
      }

      .app-store-one-video-single-service .app-store-one-video-description {
        margin-bottom: 20px !important;
        font-size: 14px;
        transition: color .3s;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--grey-dark);
      }

      .app-store-one-video-single-service:hover .app-store-one-video-description {
        color: #fff;
      }
      .app-store-one-video-single-service:hover .app-store-one-video-title {
        color: #fff;
      }
    }
  }
}