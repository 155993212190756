.ntf_icon {
    /* background: var(--click-color);
    transition: 0.3s;
    width: 52px !important;
    height: 52px !important;
    display: inline-block;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: fixed;
    right: 35px;
    bottom: 100px;
    z-index: 9999; */
}

.ntf_icon .material-symbols-outlined {
    color: white !important;
    font-size: 30px;
    font-weight: 500;
}

.ntf_icon:hover {
    background: var(--pink);
    transition: 0.3s;
}

.ntf_icon:hover>.material-symbols-outlined {
    color: var(--white-color) !important;
    transition: 0.3s;

}

@media only screen and (max-width: 991px) {
    .ntf_icon {
        display: none;
    }
}