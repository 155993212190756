.business_one_route_parent_div {
  --theme-white: #ffffff;
  --theme-black: #000000;
  --theme-darkgrey: #383632;
  --theme-lightgrey: #8d8987;
  --theme-yellow: #d39121;
  --background-black: #282725;
  --theme-red: #d51f0f;
  --theme-yellowbg: #faf6f3;
}
