.business-three-testimonials-section-parent {
  .business-three-testimonials-section {
    .business-three-testimonials-section-row {
      .col-6:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: end;
      }

      .col-6:nth-of-type(2) {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }

    .business-three-testimonials-testimonial-card {
      background-color: rgb(255 255 255 / 83%);
      border-radius: 10px;
      margin-bottom: 30px;
      border: 1px solid rgb(208, 208, 208);
      padding: 20px;
      width: 90%;
      text-align: left;
      position: relative;
      z-index: 1;
      overflow: hidden; // Ensure the pseudo-element stays within the card boundaries
      transition: color 0.3s;

      img {
        border-radius: 50%;
        border: 4px solid var(--click-color);
        transition: all 0.3s;
      }

      .business-three-testimonials-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 0.9rem;
        color: var(--black-color);
        font-weight: 700;
        margin-bottom: 10px;
        transition: color 0.3s;
      }

      p {
        font-size: 0.8rem;
        color: var(--black-color);
        transition: color 0.3s;
      }

      // Pseudo-element for border animation
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--click-color);
        border-radius: 10px;
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.5s;
        z-index: -1; // Ensure this is behind the card content
      }

      &:hover {
        color: white;

        img {
          border-color: white;
        }

        h3,
        p {
          color: white;
        }

        &::before {
          transform: scale(1);
        }
      }

      &:not(:hover)::before {
        transform: scale(0);
      }
    }

    .business-three-testimonials-circle-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    .business-three-testimonials-background-circle {
      width: 22vw;
      height: 22vw;
      // background-color: rgb(198 157 255 / 20%);
      border-radius: 50%;
      position: absolute;

      // Animation
      // animation: pulse 1.5s infinite;
    }

    .business-three-testimonials-background-circle-1 {
      width: 22vw;
      height: 22vw;
      background-color: rgb(198 157 255 / 20%);
      border-radius: 50%;
      position: absolute;
      z-index: 0;

      // Animation
      animation: pulse 1.5s infinite;
    }

    .business-three-testimonials-background-circle-2 {
      width: 25vw;
      height: 25vw;
      background-color: rgb(198 157 255 / 20%);
      border-radius: 50%;
      position: absolute;
      z-index: 0;

      // Animation
      animation: pulse 2s infinite;
    }

    .business-three-testimonials-center-circle {
      min-width: 16vw;
      min-height: 16vw;
      background-color: #6200ea;
      border-radius: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: bold;
      position: relative;
    }

    @media (max-width: 768px) {
      .business-three-testimonials-circle-wrapper {
        display: none;
      }

      .business-three-testimonials-section-row-phone {
        margin-top: -100px;
      }

    }
  }

}

// Keyframes for the pulse animation
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}