.faq_parent_div {
    width: 92%;
    border-radius: 10px;
    background-color: var(--card-bg);
    margin: auto;
}

.faq-img-div img {
    border-radius: 40% 40% 5% 5%;
    border: 20px solid rgb(240, 238, 238);
    box-shadow: 0px 20px 30px -20px;
    max-width: 90%;
    min-height: 38rem;
    object-fit: cover;
}


/* Style for the tooltip */
.drag-button-container-only-faq {
    position: absolute;
    top: 7px;
    /* Adjust as needed */
    right: 3%;
    /* Position in the middle */
    transform: translateX(-50%);
    z-index: 1;
}

.drag-button-container-only-faq .tooltip {
    visibility: hidden;
    background-color: var(--black-color);
    color: var(--white-color);
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    width: 100px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.drag-button-container-only-faq .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.drag-button-container-only-faq:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.faq_services_heading p {
    font-size: 25px;
    font-weight: 600;
    color: var(--heading-color);
}

.faq_services_heading h1:after {
    /* position: absolute; */
    content: "";
    display: flex;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
    margin: 10px 0px 10px 0px;
}

#faq_top_heading {
    font-size: 40px;
    font-weight: 700;
    color: var(--subheading-color);
}

.collaps-main-child {
    box-shadow: 0px 0px 41px -25px;
    border-radius: 10px;
    border: 1px solid rgb(235, 235, 235);
    margin-top: 20px;
}

.collaps-icon {
    background-color: #dee4ec;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 20px;
}

.collaps_trigger {
    cursor: pointer;
    /* display: grid; */
    /* position: relative; */
    margin-left: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--grey-dark);
}

/* for small devices */
@media screen and (max-width: 576px) {
    .drag-button-container-only-faq {
        display: none;
    }

    .right_sec {
        margin-top: 1rem;
        margin-bottom: 0px;
    }

    .faq_services_heading p {
        font-size: 18px;
    }

    .faq-img-div img {
        display: none;
    }

    #faq_top_heading {
        font-size: 18px;
    }
}