.education_two {
    .et_coursed {
      background: #f2f2f2;
  
      .line_img {
        position: absolute;
        top: 0;
        right: 0;
  
        @media (max-width: 767px) {
          display: none; // Hide on screens up to 767px
        }
      }
  
      .hat_img {
        img {
          position: absolute;
          top: 0;
          left: 0;
          animation: updown 0.8s infinite alternate;
  
          @media (max-width: 767px) {
            display: none; // Hide on screens up to 767px
          }
        }
      }
  
      .sect_header {
        text-align: center;
  
        .sect_badge {
          margin-left: auto;
          margin-right: auto;
        }
  
        .sect_title {
          max-width: 572px;
          margin: auto;
        }
      }
  
      .courses_card {
        display: grid;
        gap: 25px;
        margin-top: 50px;
  
        @media (max-width: 400px) {
          display: flex;
          overflow-x: scroll;
          gap: 10px;
          scroll-snap-type: x mandatory;
          -webkit-overflow-scrolling: touch;
          padding-bottom: 15px;
  
          .courses_card_single {
            min-width: 70%;
            max-width: 70%;
            scroll-snap-align: start;
            flex: 0 0 auto;
            margin-right: 10px;
          }
        }
  
        @media (min-width: 320px) and (max-width: 667px) {
          display: flex;
          overflow-x: scroll;
          gap: 15px;
          scroll-snap-type: x mandatory;
          -webkit-overflow-scrolling: touch;
          padding-bottom: 15px;
  
          .courses_card_single {
            min-width: 80%;
            max-width: 80%;
            scroll-snap-align: start;
            flex: 0 0 auto;
            margin-right: 10px;
            padding: 15px; // Reduced padding
            
            .img_area {
              span {
                font-size: 13px; // Reduced text size
                padding: 4px 8px; // Smaller padding
              }
  
              img {
                aspect-ratio: 4/3; // Adjust aspect ratio for smaller screens
              }
            }
  
            .content_area {
              .ca_top {
                padding: 15px 0px;
                row-gap: 8px;
                
                .title {
                  font-size: 16px; // Smaller font size for title
                }
  
                .other_info {
                  .oi_single {
                    .text {
                      font-size: 10px; // Smaller info text
                    }
                  }
                }
              }
  
              .ca_bottom {
                padding-top: 15px;
  
                .teacher_info {
                  span {
                    font-size: 12px; // Smaller teacher info text
                  }
  
                  img {
                    width: 30px; // Smaller teacher image
                    height: 30px;
                  }
                }
  
                .price_purchase {
                  .price {
                    font-size: 16px; // Adjusted price text size
                  }
  
                  h4 {
                    font-size: 12px; // Smaller purchase text
                  }
                }
              }
            }
          }
        }
  
        @media (min-width: 668px) and (max-width: 767px) {
          grid-template-columns: 1fr;
        }
  
        @media (min-width: 768px) {
          grid-template-columns: repeat(3, 1fr);
        }
  
        .courses_card_single {
          background: white;
          border-radius: 10px;
          padding: 20px;
  
          .img_area {
            position: relative;
  
            span {
              position: absolute;
              top: 15px;
              left: 15px;
              padding: 5px 10px;
              font-size: 15px;
              font-weight: 400;
              border-radius: 5px;
              color: white;
              background: var(--click-color);
            }
  
            img {
              width: 100%;
              border-radius: 10px;
              aspect-ratio: 3/2;
            }
          }
  
          .content_area {
            .ca_top {
              padding: 20px 0px;
              border-bottom: 1px dashed #4d5756;
              display: grid;
              row-gap: 10px;
  
              .stars {
                .material-symbols-outlined {
                  color: var(--click-color);
                  font-size: 18px;
                }
              }
  
              .title {
                font-family: regular;
                font-size: 18px;
                line-height: 1.4;
                color: var(--heading-color);
                font-weight: 600;
                text-transform: capitalize;
                letter-spacing: 0.5px;
              }
  
              .other_info {
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;
                justify-content: space-between;
  
                .oi_single {
                  color: #4d5756;
                  display: flex;
                  align-items: center;
                  gap: 3px;
  
                  .material-symbols-outlined {
                    font-size: 12px;
                  }
  
                  .text {
                    font-size: 12px;
                    font-family: regular;
                  }
                }
              }
            }
  
            .ca_bottom {
              padding-top: 20px;
              display: grid;
              row-gap: 10px;
  
              .teacher_info {
                display: flex;
                align-items: center;
                gap: 12px;
  
                span {
                  color: #4d5756;
                  font-family: regular;
                  font-size: 14px;
                }
  
                img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                }
              }
  
              .price_purchase {
                display: flex;
                align-items: center;
                gap: 15px;
                justify-content: space-between;
  
                .price {
                  font-size: 18px;
                  color: var(--heading-color);
                  font-family: regular;
                  font-weight: 700;
                }
  
                h4 {
                  font-size: 14px;
                  color: var(--click-color);
                  font-family: regular;
                  font-weight: 600;
                  border-bottom: 1px solid;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
  
      @keyframes updown {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-20px);
        }
      }
    }
  }
  