.happyerp-tesimonial {
  .happyerp-band {
    background: var(--theme-midnight-blue);
    padding: 50px 0px;
  }
  .happyerp-testimonial_inner {
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: var(--white-color);
    padding: 50px;
    margin-bottom: -30px;
    position: relative;
    .happyerp-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .happyerp-star {
        display: flex;
        align-items: center;
        gap: 6px;
        span {
          color: var(--theme-orange);
          font-variation-settings: "FILL" 1;
          font-size: 35px;
        }
      }
    }
    .happyerp-name_pic {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: end;
      .happyerp-left {
        display: flex;
        flex-direction: column;
        text-align: end;
        gap: 8px;
      }
    }
    .happyerp-right {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
        border: 3px solid var(--theme-orange);
      }
    }
    .happyerp-say {
      margin-top: 12px !important;
      line-height: 32px;
      text-align: center;
    }
  }
}
