.happyerp-react_table {
  // margin: 0 20px;
  overflow-x: auto;
  tr:nth-child(even) {
    background-color: #eff5ff;
  }
  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }

  table {
    width: 100%;
    min-width: 750px;
  }
  th {
    font-size: 14px;
    font-family: medium;
    font-weight: 400;
    background: var(--theme-white);
    color: var(--theme-black);
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 135px;
  }

  td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    color: var(--light-black);
    text-align: left;
    .happyerp-contact_btn {
      margin: -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
      }
    }
    .happyerp-contact_btn:after {
      content: "";
      height: 100%;
      width: 1px;
      background: #ddd;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }
  }

  tr:hover {
    background-color: #f5f5f5;
  }
  tr {
    background-color: var(--white-color);
  }
}
.happyerp-react_pagination {
  border: 2px solid #ddd;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background: var(--white-color);
  .disabled {
    opacity: 0.6;
  }
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 200, "opsz" 48;
  }
  .happyerp-items_on_page {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 15px;
    color: var(--light-black);
    select {
      border: 1px solid var(--theme-grey);
      padding: 2px 5px;
    }
    select:focus,
    select:focus-visible {
      outline: none;
      border-color: var(--theme-blue);
    }
  }

  .happyerp-right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
  .happyerp-previous_previous_first,
  .happyerp-next_next_last {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    color: var(--theme-teal);
    font-family: medium;
  }
  .happyerp-previous_first,
  .happyerp-next_last {
    font-size: 30px;
  }

  .happyerp-previous_click,
  .happyerp-next_click {
    display: flex;
    align-items: center;
    .happyerp-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .material-symbols-outlined {
      font-size: 12px;
    }
  }
  .happyerp-next_click {
    .happyerp-arrow {
      margin-left: 2px;
    }
  }
  .happyerp-total_page {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    .happyerp-active_page {
      font-size: 16px;
      color: var(--light-black);
      font-weight: 500;
      .happyerp-tp_number {
        color: var(--theme-grey);
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .happyerp-react_table {
    .happyerp-mobile_min_width {
      min-width: 120px;
    }
    td {
      font-size: 12px;
    }
    .happyerp-filters .happyerp-search_field .happyerp-field_icon span {
      font-size: 16px;
    }
    .happyerp-filters .happyerp-search_field input {
      font-size: 14px;
      padding: 4px 8px;
      padding-right: 30px;
    }
  }
}
.happyerp-table_global_filter {
  // margin: 0 20px;
  background: var(--theme-white);
  padding: 8px;

  border: 1px solid #ddd;
}
.happyerp-react_table::-webkit-scrollbar {
  width: 1px; /* Scrollbar width */
  height: 8px;
}

.happyerp-react_table::-webkit-scrollbar-thumb {
  background: var(--theme-lightmidnight-blue); /* Scrollbar thumb color */
  border-radius: 5px; /* Rounded corners for thumb */
}

.happyerp-react_table::-webkit-scrollbar-thumb:hover {
  background: var(--theme-lightmidnight-blue); /* Thumb color on hover */
}

.happyerp-react_table::-webkit-scrollbar-track {
  background: var(--theme-pg-bg); /* Scrollbar track color */
}

.happyerp-center {
  display: block;
  width: 300px;
  margin: 0;
  padding: 0;
}
