.customer_dashboard_parent_div {
    padding: 0px 0px 100px 0px;
    background-color: #eeeeee;
}

.customer_dash_welcome_heading {
    margin-bottom: 20px;
}

.customer_dash_welcome_heading h4 {
    font-size: 25px;
    font-weight: 600;
    margin-left: 10px;
}

.trophy_div_top_heading h4 {
    font-weight: 700;
}

.trophy_div_top_heading span {
    font-weight: 100;
    color: var(--pink);
    font-size: 30px;
}

.trophy_div_top_heading p {
    font-size: 20px;
    color: #888;
}

.customer_dash_wallet_div h4 {
    font-weight: 700;
}

.customer_dash_trophy_img {
    width: 190px;
}

.customer_dash_left_sec {
    box-shadow: 0 0.3125rem 0.3125rem 0 #523f690d;
    background-color: white;
    padding: 20px 20px 0px 20px;
    border-radius: 10px;
}

.customer_booking_offer_carosel {
    margin-top: 20px;
}

.customer_booking_offer_carosel img {
    height: 350px;
    border-radius: 10px;
}

.customer_dash_right_sec {
    box-shadow: 0 0.3125rem 0.3125rem 0 #523f690d;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}


.customer_icon_with_percent {
    margin-top: 25px;
}

.customer_icon_with_percent h4 {
    font-weight: 700;
    font-size: 17px;
}

.customer_icon_with_percent span {
    background-color: #eeeeee;
    border-radius: 50%;
    padding: 10px;
}

.booking_type_icons {
    padding: 12px;
    background-color: white;
    border-radius: 10%;
}

.booking_type_heading p {
    font-size: 20px;
}

.coming-soon-container{
    min-height: 50vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.coming-soon-container-heading{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coming-soon-container-heading h1{
    color: var(--black-color)
}

.coming-soon-container-heading h5{
    color: var(--black-color);
}

.progress-animation{
    height: 350px;
    width: auto;
}

.goto-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: var(--click-color);
    color: white;
    padding: 5px 10px;
    border-radius: 30px;
}

.goto-profile a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    font-weight: 800;
}

.goto-profile a .profile-image img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
/* Styles for small screens (phones) */
@media only screen and (max-width: 767px) {
    .customer_dashboard_parent_div {
        padding: 20px 0px 100px 0px;
    }

    .customer_dash_welcome_heading h4 {
        font-size: 25px;
    }

    .customer_dash_trophy_img {
        width: 130px;
    }

    .trophy_div_top_heading p {
        font-size: 16px;
    }

    .customer_dash_wallet_div h4 {
        font-size: 18px;
    }

    .customer_booking_offer_carosel {
        margin-bottom: 20px;
    }

    .customer_booking_offer_carosel img {
        height: 125px;
    }

    .customer_dash_right_sec {
        padding: 10px;
    }

    .booking_type_heading h5 {
        font-size: 17px;
    }

    .booking_type_heading p {
        font-size: 22px;
    }

    .booking_type_icons {
        padding: 5px;
    }
}

/* Styles for medium screens (tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {}

/* Styles for large screens (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /* Your styles for large screens go here */
}

/* Styles for extra-large screens (large desktops) */
@media only screen and (min-width: 1200px) {
    /* Your styles for extra-large screens go here */
}