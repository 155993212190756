.app-store-one-partners {
  padding: 15px 50px;
  width: 100%;
  overflow: hidden;

  .app-store-one-partners-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // Left Section
    .app-store-one-partners-left {
      h2 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        color: var(--black-color);
      }
    }

    // Right Section
    .app-store-one-partners-right {
      .app-store-one-row {
        display: flex;

        .app-store-one-item {
          padding: 10px;
          transition: transform 0.9s ease; // Smooth animation for scaling center item

          &.center {
            transform: scale(1.2); // Increase size for center card
          }

          .app-store-one-image-wrapper {
            width: 150px;
            height: 80px;
            // background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: all 0.5s ease; // Smooth animation for width/height

            .app-store-one-image {
              max-width: 100%;
              max-height: 100%;
              transition: filter 0.5s ease;

              &.grayscale {
                filter: grayscale(100%);
              }

              &.color {
                filter: grayscale(0%);
              }
            }
          }

          &.center .app-store-one-image-wrapper {
            width: 150px; // Increased width for center item
            height: 80px; // Increased height for center item
            // border: 1px dashed var(--logo-primary-color);
          }
        }
      }
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    padding: 50px 10px 0px 10px;

    .app-store-one-partners-content {
      flex-direction: column;
      align-items: center;

      .app-store-one-partners-left {
        max-width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }

      .app-store-one-partners-right {
        .app-store-one-row {
          .app-store-one-item {
            height: 7rem;

            .app-store-one-image-wrapper {
              width: 120px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}