.search-sort-paret-div {
    /* margin-top: 70px; */
    background-color: white;
    padding: 60px 0px 30px 0px;
}

.project-filter {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* margin-top: 25px; */
}

.four_services_filter_parent {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 6px;
}

.project-filter nav button {
    position: relative;
    width: 100%;
    height: 50px;
    transition: 0.5s;
    border: none !important;
    padding: 0px;
}

.project-filter button:nth-child(1) {
    background: var(--light-blue-bg);

}

.project-filter button:nth-child(2) {
    background: var(--light-gold);
}

.project-filter button:nth-child(3) {
    background: var(--rose);
}

.project-filter button:nth-child(4) {
    background: var(--grey-light);
}

.project-filter button.active:nth-child(1) {
    background: var(--dark-blue) !important;
}

.project-filter button.active:nth-child(2) {
    background: var(--gold);
}

.project-filter button.active:nth-child(3) {
    background: var(--pink);
}

.project-filter button.active:nth-child(4) {
    background: var(--grey-dark);
}

.project-filter nav button span {
    font-size: 16px;
    font-family: p_medium;

}

.project-filter button.active:nth-child(2) span {
    color: var(--black-color);
}

.project-filter nav button.active span {
    position: relative;
    top: 2px;
    color: var(--white-color);
}

.project-filter nav button small {
    position: absolute;
    top: -8px;
    right: 10%;
    width: auto;
    padding: 4px 5px 1px 5px;
    height: 15px;
    font-size: 0.6rem;
    background: var(--blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%;
}

/* SEARCH FILTER CSS */
/* search bar code   */
.search_bar {
    background: var(--light-blue-bg);
width: 70%;
    overflow: hidden;
}

.filter_search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* margin-top: 10px; */
}

.search_bar input {
    border: none;
    background: #edfbfb;
    padding: 10px 0px;
    position: relative;
    z-index: 10;
    width: 82%;
}

.filters-icon-user-list {
    display: none;
}

/* Add this CSS code to your component's stylesheet or a global stylesheet */
.overlay-stop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.no-scroll {
    overflow: hidden;
    /* Disable scrolling */
}

/* Add this CSS code to your component's stylesheet or a global stylesheet */
.animated-card {
    position: fixed;
    bottom: 500px;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    border-radius: 15px 15px 0px 0px;
}

.card_fields {
    /* margin-top: 44px; */
    margin-top: 0px;
}

.search_icon {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--click-color);
    height: 44px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.search_icon span {
    color: var(--white-color);
}

p.nobooking {
    text-align: center;
    margin: 40px 0px;
    font-size: 20px;
    color: var(--pink);
    font-family: 'p_semibold';
}

.filter_dropdown select {
    padding: 5px;
    border-radius: 30px;
}

@media only screen and (max-width: 767px) {
    .search-sort-paret-div {
        margin-top: -50px;
    }

    .four_services_filter_parent {
        display: none;
    }

    .project-filter nav button span {
        font-size: 12px;
    }

    .project-filter nav button {
        height: 35px;
    }

    .project-filter {
        margin-top: 0px;
    }

    /* SEARCH FILTER CSS */
    .search_y_year {
        display: none;
    }

    .filters-icon-user-list {
        display: contents;
    }

    .filter_dropdown select {
        padding: 3px 14px;
    }

    .search_y_year_mobile {
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        margin-left: 15px;
        background-color: white;
    }

    .filter-user-status-selected-button {
        background-color: var(--click-color);
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        margin-left: 15px;
        color: white;
        transition: background-color .1s ease, color 1s ease;
    }

    .filter-user-selected-button {
        background-color: var(--click-color);
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        color: white;
        transition: background-color .1s ease, color 1s ease;
    }

    .search_roles_mobile {
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        background-color: white;
        transition: background-color .5s ease, color 1s ease;
    }

    .cancel_filter_btn_border {
        padding: 6px 30px;
        border: 2px solid var(--pink);
        border-radius: 10px;
        color: var(--pink);
        font-weight: 700;
        font-size: 16px;
        background-color: white;
        letter-spacing: 1px;
        font-family: p_bold;
    }

    .apply_filter_btn_border {
        padding: 6px 30px;
        background: var(--click-color);
        border: none;
        border-radius: 6px;
        color: var(--white-color);
        padding: 10px 35px;
        font-family: 'p_bold';
        letter-spacing: 1px;
        text-transform: capitalize;
    }

    .pay_btn {
        bottom: 10px !important;
        right: 20px !important;
    }

    .delete_edit {
        position: unset;
        justify-content: left;
        display: flex;
        margin-top: 11px;
        gap: 14px;
    }

    .b_date_time .field_value {
        font-size: 18px;
    }
}