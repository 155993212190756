.diwali-theme {
    position: relative;

    .diwali-theme-lantern-gif-left {
        position: fixed;
        top: 50px;
        right: 0px;
        z-index: 100;
        width: 200px;
    }

    .diwali-theme-lantern-gif-right {
        position: fixed;
        top: 50px;
        left: 0px;
        z-index: 100;
        width: 200px;
    }

    .diwali-theme-h1-wish {
        position: fixed;
        top: 80px;
        left: 50%;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        width: 100vw;
        z-index: 10001;
        text-align: center;
        font-family: sans-serif;
        font-size: 3.5rem;
        color: #ffeb3b;
        // text-shadow: 2px 2px 10px rgba(255, 165, 0, 0.9),
        //     4px 4px 20px rgba(255, 140, 0, 0.8);
        animation: flicker 2s infinite alternate ease-in-out;
        pointer-events: none;
    }
    
    @keyframes flicker {
        0% {
            opacity: 1;
            text-shadow: 2px 2px 10px rgba(255, 165, 0, 0.9),
                4px 4px 20px rgba(255, 140, 0, 0.8);
        }
        100% {
            opacity: 0.40;
            text-shadow: 2px 2px 5px rgba(255, 140, 0, 0.7),
                4px 4px 10px rgba(255, 69, 0, 0.6);
        }
    }

    .diwali-theme-fire-crackers-gif {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none; /* Prevent interaction */
        overflow: hidden;

        img {
            width: 50%;
            height: auto;
        }
    }

    .diwali-theme-deepak-gif {
        width: 90px;
        position: fixed;
        bottom: 50px;
        left: 40px;
        z-index: 99999;
        cursor: pointer;
    }

    .diwali-theme-dark-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.95); /* Dark overlay */
        z-index: 10000;
        pointer-events: none; /* Prevent interaction */
        background: radial-gradient(circle at 50px 95vh, rgba(255, 165, 0, 0.8) 0%, rgba(0, 0, 0, 0.95) 40%); /* Light effect around deepak */
    }

    @media (max-width: 575px) {
        .diwali-theme-lantern-gif-left {
            width: 80px;
        }

        .diwali-theme-lantern-gif-right {
            width: 80px;
        }

        .diwali-theme-h1-wish {
            top: 80px;
            width: 100vw;
            font-size: 2rem;
        }
    
        .diwali-theme-deepak-gif {
            width: 50px;
            bottom: 60px;
            left: 20px;
        }

        .diwali-theme-fire-crackers-gif {
            img {
                position: absolute;
                width: 100%;
                height: auto;
            }
        }
    }
}
