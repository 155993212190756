.happyerp-card_container {
  position: relative;
  .happyerp-order_edit {
    margin-top: 5px;
    background: transparent;
    color: var(--theme-teal);
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    .happyerp-more_opt_card {
      position: absolute;
    }
  }
}

.happyerp-card_view {
  &.happyerp-col_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 22px;
    row-gap: 22px;
  }
  &.happyerp-col_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22px;
    row-gap: 22px;
    .happyerp-card_fields .happyerp-cf_single {
      h6 {
        font-size: 13px;
      }
      h5 {
        font-size: 15px;
      }
    }
    .happyerp-show_on_click {
      .happyerp-short_info .happyerp-si_single span {
        font-size: 14px;
      }
      .happyerp-desc h6 {
        font-size: 13px;
      }
    }
    .happyerp-card_btn.happyerp-theme_btn {
      padding: 4px 8px;
      font-size: 14px;
    }
  }
  .happyerp-card_btn.happyerp-theme_btn {
    padding: 2px 8px;
    font-size: 13px;
  }
  .happyerp-card_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #edf4ff;
    padding: 8px 12px;
    .card_fields {
      grid-template-columns: repeat(3, 1fr);
      width: 90%;
    }
    .happyerp-card_checkbox {
      margin-left: -2px;
      input {
        height: 20px;
        width: 15px;
        cursor: pointer;
      }
    }
  }
  .happyerp-card_middle {
    padding: 8px 12px;
    padding-right: 28px;
    .happyerp-expand_more_text {
      position: absolute;
      right: 0;
      top: 0;
      background: #fff7f6;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
      span {
        color: var(--theme-orange);
        font-size: 22px;
      }
    }
  }
  .happyerp-card_fields {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 8px;

    .happyerp-cf_single {
      &.happyerp-with_icon {
        display: flex;
        gap: 2px;
        .icon {
          display: flex;
          justify-content: center;
          span {
            font-size: 20px;
            color: var(--text-grey);
            font-variation-settings: "wght" 300;
          }
        }
      }
      h6 {
        font-size: 11px;
        color: var(--text-grey);
        font-family: "light";
        margin-bottom: 1px;
      }
      h5 {
        font-size: 13px;
        color: var(--text-black);
        font-family: regular;
      }
    }
    // .cf_single:nth-child(2n) {
    //   text-align: end;
    // }
  }
  .happyerp-show_on_click {
    margin-top: 10px;
    .happyerp-short_info {
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 8px;
      .happyerp-si_single {
        background: #edf4ff;
        border-radius: 4px;
        padding: 4px;
        display: flex;
        gap: 5px;
        align-items: center;
        span {
          font-size: 12px;
          color: var(--text-black);
          font-family: regular;
        }
      }
    }
    .happyerp-desc {
      margin-top: 6px;
      h6 {
        font-size: 12px;
        color: var(--text-grey);
        font-family: "light";
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .happyerp-card_bottom {
    background: #fff7f6;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-blue);
  }
  .happyerp-editable_text {
    display: flex;
    justify-content: center;
    .happyerp-pending {
      color: var(--theme-orange);
    }
  }
}
