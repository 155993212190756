.happyerp {
  section.happyerp-why_us {
    background: var(--theme-midnight-blue);
    h3 {
      margin-top: 10px;
    }
    .happyerp-inline_title_subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 70px;
      .happyerp-its_right {
        max-width: 340px;
        margin-right: auto;
        position: relative;
      }
      .happyerp-its_left {
        max-width: 480px;
        text-align: end;
        margin-left: auto;
        .happyerp-small_title_with_line {
          display: inline;
        }
      }
      .happyerp-its_right:after {
        content: "";
        background: var(--white-color);
        position: absolute;
        top: -12px;
        left: -35px;
        width: 1px;
        height: 130%;
      }
    }
    .happyerp-wu_inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin-top: 34px;
      .happyerp-wu_single {
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.7;
          -webkit-transition: 0.6s;
          transition: 0.6s;
        }
        .happyerp-div_first {
          width: 100%;
          aspect-ratio: 3/2;
          position: relative;
          -webkit-transition: 0.8s;
          transition: 0.8s;
          h4 {
            position: absolute;
            padding: 15px;
            bottom: 0;
            left: 0;
          }
        }
        .happyerp-div_second {
          padding: 15px;
          background: var(--white-color);
          width: 100%;
          aspect-ratio: 3 / 2;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          -webkit-transition: 0.8s;
          transition: 0.8s;
          opacity: 0;
          visibility: hidden;
        }
      }
      .happyerp-wu_single:hover {
        img {
          -webkit-transform: scale(1.3);
          transform: scale(1.3);
          -webkit-transition: 0.6s;
          transition: 0.6s;
        }
        .happyerp-div_first {
          -webkit-transition: 0.8s;
          transition: 0.8s;
          opacity: 0;
          visibility: hidden;
        }
        .happyerp-div_second {
          -webkit-transition: 0.8s;
          transition: 0.8s;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
