.home_two_page_parent{
    div{
        .clipPath_home_two_ellipse{
            position: absolute;
            clip-path: ellipse(50% 100% at 50% 0%);
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, var(--grey-light),var(--click-color));
        }
    }

    @media (max-width: 576px) {
         div{
        .clipPath_home_two_ellipse{
        clip-path: ellipse(100% 60% at 50% 0%);
        }
    }
    }
}