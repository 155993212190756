.app-store-one-privacy-parent {
    margin: 0px;
    padding-top: 40px;
    background-color: var(--white-color);
  
    .app-store-one-privacy-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
  
      /* Bars Section */
      .app-store-one-privacy-bars {
        display: flex;
        gap: 20px;
      }
    }
  
    .app-store-one-privacy-right {
      max-width: 500px;
  
      .app-store-one-privacy-header {
        margin-bottom: 15px;

        span{
            color: var(--logo-primary-color);
        }
      }
  
      .app-store-one-privacy-description {
        margin-bottom: 20px;
      }
  
      .app-store-one-privacy-features {
        list-style: none;
  
        .app-store-one-privacy-feature {
          margin-bottom: 15px;
          display: flex;
  
          strong {
            font-weight: bold;
            color: #333;
          }
  
        }
      }
  
      .app-store-one-privacy-buttons {
        display: flex;
        gap: 10px;
      }
    }
    @media (max-width: 576px) { 
      flex-direction: column-reverse; 
      margin: 0px;

      .app-store-one-privacy-left .app-store-one-privacy-bars {
        img {
          max-width: 100%;
        }
      }
    }
  }
  