.staff-assignments {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    margin-bottom: 20px;
    color: var(--text-color);
  }

  .no-assignments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #f5f5f5;
    border-radius: 8px;
    color: #666;

    .material-symbols-outlined {
      font-size: 48px;
      margin-bottom: 16px;
    }
  }

  .assignments-list {
    display: grid;
    gap: 20px;
  }

  .assignment-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .assignment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .role-badge {
        display: flex;
        align-items: center;
        gap: 8px;
        background: var(--click-color);
        color: white;
        padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;

        .material-symbols-outlined {
          font-size: 18px;
        }
      }

      .order-id {
        font-weight: bold;
        color: var(--text-color);
      }
    }

    .assignment-details {
      margin-bottom: 20px;

      .detail-item {
        margin-bottom: 12px;

        .label {
          font-weight: 500;
          color: #666;
          margin-right: 8px;
        }

        p {
          margin: 4px 0;
          color: var(--text-color);
        }
      }
    }

    .assignment-actions {
      display: flex;
      justify-content: flex-end;

      .complete-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        background: var(--click-color);
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background: var(--click-color);
        }

        .material-symbols-outlined {
          font-size: 18px;
        }
      }
    }
  }

  .tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 10;

    .tab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      border: none;
      background: none;
      cursor: pointer;
      color: #666;
      font-weight: 500;
      transition: all 0.3s ease;
      border-radius: 8px;
      white-space: nowrap;

      &:hover {
        background: rgba(0, 123, 255, 0.05);
        color: #007bff;
      }

      &.active {
        color: #007bff;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      }

      .material-symbols-outlined {
        font-size: 1.2rem;
      }
    }
  }

  .completion-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    color: #28a745;

    .material-symbols-outlined {
      font-size: 1.2rem;
    }
  }

  .order-details-accordion {
    width: 100%;
    margin-top: 1rem;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e0e0e0;

    .accordion-header {
      width: 100%;
      padding: 12px 16px;
      background: #f5f5f5;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 500;

      .material-symbols-outlined {
        transition: transform 0.3s ease;
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .accordion-content {
      padding: 16px;
      background: white;

      .products-list {
        margin-bottom: 1rem;

        h4 {
          margin-bottom: 0.5rem;
          color: #333;
        }

        .product-item {
          display: flex;
          padding: 8px 0;
          border-bottom: 1px solid #eee;

          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 4px;
            margin-right: 12px;
          }

          .product-info {
            flex: 1;

            .product-name {
              font-weight: 500;
              margin-bottom: 4px;
            }

            .product-quantity {
              color: #666;
              font-size: 0.9rem;
            }

            .product-price {
              color: #2196f3;
              font-weight: 500;
            }
          }
        }
      }

      .order-summary {
        padding-top: 1rem;
        border-top: 1px solid #eee;

        h4 {
          margin-bottom: 0.5rem;
          color: #333;
        }

        p {
          color: #666;
          margin: 4px 0;
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 768px) {
  .staff-assignments {
    padding: 12px;

    .tabs {
      padding: 0.25rem;
      gap: 0.5rem;

      .tab {
        padding: 0.5rem;
        font-size: 0.9rem;

        span.material-symbols-outlined {
          font-size: 1rem;
        }
      }
    }

    .assignment-card {
      padding: 15px;

      .assignment-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .role-badge {
          font-size: 12px;
          padding: 6px 12px;
        }
      }

      .assignment-details {
        .detail-item {
          .label {
            display: block;
            margin-bottom: 4px;
          }

          p {
            font-size: 0.9rem;
          }
        }
      }

      .assignment-actions {
        .complete-btn {
          width: 100%;
          justify-content: center;
          font-size: 0.9rem;
        }
      }
    }

    .order-details-accordion {
      .accordion-content {
        .products-list {
          .product-item {
            flex-direction: column;

            img {
              width: 100%;
              height: 120px;
              margin-right: 0;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .staff-assignments {
    .tabs {
      .tab {
        font-size: 0.8rem;
        padding: 0.5rem 0.25rem;

        span.material-symbols-outlined {
          font-size: 0.9rem;
        }
      }
    }

    .assignment-card {
      .assignment-header {
        .order-id {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .staff-assignments {
    .tabs {
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem;

      .tab {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        padding: 0.75rem;
        font-size: 0.9rem;

        span.material-symbols-outlined {
          font-size: 1.2rem;
          margin-right: 8px;
        }

        // Show only the icon and number for better space management
        &::after {
          content: attr(data-count);
          margin-left: auto;
          background: rgba(0, 123, 255, 0.1);
          padding: 2px 8px;
          border-radius: 12px;
          font-size: 0.8rem;
        }
      }
    }
  }
}
