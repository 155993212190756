.add-new-dynamic-page{
    .menu-and-submenu-table-container {
        margin-top: 20px;
        padding: 10px;
        border: 4px solid #ddd;
        border-radius: 8px;
        background-color: #f9f9f9;

        .all-dynamic-pages-list-div{
          display:ruby;
          .all-dynamic-pages-list{
            background-color: var(--card-bg);
            padding: 10px;
            margin: 10px;
            width: fit-content;
          }
        } 
    }
    
    .add-new-dynamic-page-popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .add-new-dynamic-page-popup-container {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
      
      .add-new-dynamic-page-popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        h5{
            font-weight: 700;
        }
      }
      
      .add-new-dynamic-page-popup-close {
        cursor: pointer;
        font-size: 24px;
        background-color: var(--grey-light);
        border-radius: 50%;
        padding: 2px;
      }
      
      .add-new-dynamic-page-popup-body {
        margin-top: 10px;
      }
      
      .add-new-dynamic-page-popup-field {
        margin-bottom: 15px;
      }
      
      .add-new-dynamic-page-popup-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      .add-new-dynamic-page-popup-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
}
