.success-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    background-color: #f7f7f7;
  
    .header-content {
      text-align: center;
      margin-bottom: 2rem;
  
      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 1rem;
      }
  
      .cta-button {
        background-color: #ff9900;
        color: white;
        padding: 0.8rem 2rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;
        
        &:hover {
          background-color: #e68a00;
        }
      }
    }
  
    .stats-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 900px;
  
      .stat {
        background-color: #fff;
        padding: 1.5rem 2rem;
        border-radius: 8px;
        text-align: center;
        width: 28%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 0.5s ease, transform 0.5s ease;
  
        h3 {
          font-size: 2.5rem;
          color: #ff9900;
        }
  
        p {
          font-size: 1.2rem;
          color: #666;
          margin-top: 0.5rem;
        }
      }
    }
  }
  