.happyerp-counter_section {
  .happyerp-counter_left {
    h2 {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }
  .happyerp-counter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 30px;
    max-width: 420px;
    .happyerp-counter_single {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      img {
        width: 38px;
      }
    }
  }
  .happyerp-counter_right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;

    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1.5;
    }
    .happyerp-img2 {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 0;
      visibility: hidden;
    }
    .happyerp-img4 {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 0;
      visibility: hidden;
    }
  }
  .happyerp-counter_right:hover {
    .happyerp-img1 {
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 0;
      visibility: hidden;
    }
    .happyerp-img3 {
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 0;
      visibility: hidden;
    }
    .happyerp-img2 {
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 1;
      visibility: visible;
    }
    .happyerp-img4 {
      -webkit-transition: 1.2s;
      transition: 1.2s;
      opacity: 1;
      visibility: visible;
    }
  }
}
