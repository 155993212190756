.happyerp-pg_order_report {
  margin-top: 5px;
  min-height: calc(100vh - 82px);
  .happyerp-filter_section {
    opacity: 1;
    width: 20%;
    position: fixed;
    top: 82px;
    left: 0;
    opacity: 1;
    height: calc(100vh - 82px);
    padding-bottom: 50px;
    transition: 0.5s;
    .happyerp-left_footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .happyerp-footer_btns {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--text-grey);
        // background: opacify($color: #000000, $amount: 0);
        background: white;

        .happyerp-arrow {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: end;
          cursor: pointer;
          span {
            color: var(--theme-green);
            font-size: 20px;
          }
        }
      }

      .happyerp-sr_btn {
        width: 100%;
        text-align: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .happyerp-theme_btn {
          padding: 12px 12px;
          width: 100%;
        }
      }
    }

    .happyerp-date_filter {
      padding: 10px;

      // padding-top: 0px;
      // margin-top: 5px;

      background: var(--theme-bg-grey);
      p.happyerp-error_message {
        margin-top: 10px;
        margin-bottom: -10px;
        padding: 10px;
        margin-left: -10px;
        margin-right: -10px;
        font-size: 14px;
        text-align: center;
        background: var(--error-bg);
        color: var(--theme-crimson-red);
      }

      .happyerp-df_inner {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      input {
        width: 100%;
        font-size: 14px;
        padding: 5px;
        border: 1px solid var(--theme-grey);
        &:focus {
          outline: none;
          border-color: var(--theme-lightmidnight-blue);
        }
      }
    }
    .happyerp-title {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--theme-bg-grey);
      h4 {
        font-size: 18px;
        font-family: medium;
        color: var(--theme-lightmidnight-blue);
      }
      h6 {
        color: var(--theme-crimson-red);
        font-size: 15px;
        font-family: regular;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
    .happyerp-left_accordions_section {
      .happyerp-parent_filter_accordion .accordion-body {
        padding: 0;
      }
      .happyerp-pivot_btn_dropdown .accordion-body {
        padding-left: 0;
        padding-right: 0;
      }
      .happyerp-select_columns .accordion-body {
        // border: 1px solid lightgray;

        padding: 0;
        // margin: var(--bs-accordion-body-padding-y);
        // var(--bs-accordion-body-padding-x);
        border-radius: 3px;

        .happyerp-cbox {
          border-bottom: 1px solid lightgray;
          padding: 5px;

          .happyerp-checkbox_item {
            // padding: 5px;
            display: flex;
            align-items: center;
            margin: 5px;
          }

          .happyerp-checkbox_item input {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &.happyerp-collapsed_yes {
    .happyerp-filter_section {
      width: 2%;
      overflow: hidden;
      transition: 0.5s;
      opacity: 1;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: white;
      }
      .happyerp-left_footer {
        .happyerp-arrow {
          padding: 10px;
          border-top: none;
          background: var(--theme-teal);
          position: relative;
          z-index: 1;
          cursor: pointer;
          span {
            color: var(--white-color);
          }
        }
        .happyerp-sr_btn {
          width: 100%;
          text-align: center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .happyerp-theme_btn {
            padding: 12px 12px;
            width: 100%;
          }
        }
      }
    }
    .happyerp-main_area {
      width: 98%;
      transition: 0.5s;
    }
  }
  .happyerp-main_area {
    width: 80%;
    margin-left: auto;
    transition: 0.5s;
    padding: 22px;
    gap: 22px;
    padding-top: 0px;
  }
  .happyerp-collapsible_card_inner {
    padding: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    .happyerp-radio_single {
      &.happyerp-with_more {
        label {
          display: flex;
          align-items: center;
        }
        .lhappyerp-abel_inner {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .happyerp-filter_more_icon {
          margin-left: 7px;
          .happyerp-more_icon {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .happyerp-more_popup {
            position: absolute;
            // top: 100%;
            // right: 20%;
            z-index: 1000;

            .happyerp-popup_container {
              background: white;
              padding: 0;
              border-radius: 4px;
              border: 1px solid #ccc;
              // border: 1px solid black;
              // position: relative;
              // width: 300px;

              .happyerp-popup_close_btn {
                float: right;
                // position: absolute;
                // top: 10px;
                // right: 10px;
                border: none;
                background: transparent;
                cursor: pointer;
                font-size: 14px;
              }
              .happyerp-popup_title {
                margin: 10px;
                padding: 5px;

                font-size: 15px;
                font-family: "medium";
                // color: var(--theme-black);
                color: black;
              }
              .happyerp-popup_options {
                list-style-type: none;
                padding: 0;
                // margin: 0;

                .happyerp-popup_option_item {
                  // margin: 5px;

                  border-top: 1px solid #c6c6c6;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  // padding: 10px;
                  padding-left: 0;
                  padding-right: 0;

                  h7 {
                    margin: 0;
                    color: black;
                    padding: 8px 15px;
                  }

                  img {
                    margin-left: auto;
                    height: 40px;
                    padding: 8px 15px;
                  }

                  &:hover {
                    background-color: #f5f5f5;
                  }
                }
              }
            }
          }
        }
      }
    }
    .happyerp-row_single {
      .title {
        font-size: 16px;
        color: #0d1820;
        font-family: "medium";
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
      }
      .happyerp-row_column {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 22px;
      }
      .happyerp-form_field {
        display: flex;
        gap: 5px;
        .happyerp-show_more_var {
          cursor: pointer;
        }
        .happyerp-smv_dropdown {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          gap: 10px;
        }
      }
    }
  }

  .happyerp-date_section {
    display: flex;
    align-items: center;
    gap: 5px;
    .happyerp-form_field {
      label {
        font-size: 12px;
      }
    }
  }
}
