.corporateTwo-home-clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 30px 0;

  .corporateTwo-home-clients-heading {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 16px;
      font-weight: 600;
      color: var(--subheading-color);
    }

    h2 {
      font-size: 36px;
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .business-three-partners-container {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .business-three-partners-marquee {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      width: 86%;
    }

    .business-three-partners-row {
      display: flex;
      animation: business-three-partners-scroll 23s linear infinite;

      .business-three-partners-image-wrapper {
        width: 210px;
        height: 120px;
        background-color: white;
        margin: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }
    }

    .business-three-partners-image {
      transition: transform 0.5s ease, box-shadow 0.3s ease;
      height: 100%;
      width: 100%;
      object-fit: scale-down;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    @keyframes business-three-partners-scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-home-clients {
    .corporateTwo-home-clients-heading {
      h2 {
        font-size: 24px;
      }
    }
  }
}
