.footer_headings_vertical_line {
    background-color: var(--black-color);
    height: 25px;
    padding: 1px;
    width: 2px;
    margin-right: 10px;
}

.footer_logo_design {
    width: 70%;
    margin-top: -30px;
}

.text-color {
    color: var(--black-color);
}

.footer_link_div p a {
    color: var(--black-color);
}

.border-bottom {
    background-color: var(--click-color);
    border-radius: 10px;
    position: absolute;
    top: -80px;
    width: 85%;
    z-index: 1;
    margin: auto;
    padding: 60px;
}

.footer_content_div {
    margin-top: 100px;
}

.footer-copyright-div {
    background-color: var(--page-bg);
    text-align: center;
    font-size: 14px;
    width: 100%;
}

@media (max-width: 575px) {
    .text-center {
        /* margin-top: 1rem; */
        padding: 10px;
    }

    .text-color {
        border-radius: 10px;
    }

    .footer-copyright-div {
        margin-bottom: 60px;
    }
    .footer_logo_design {
        width: 35%;
        /* margin-top: -30px; */
    }
}