.corporateTwo-policy-body {
  padding: 20px 40px; // Balanced padding for desktop and mobile
  color: var(--black-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px; // Increased gap for better spacing

  div {
    color: black;
  }

  p {
    ol {
      li {
        margin: 20px 0;
        font-size: 1rem;
      }
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }
}

// Media Queries for Mobile Responsiveness
@media (max-width: 768px) {
  .corporateTwo-policy-body {
    padding: 10px 15px; // Adjust padding for smaller screens
    gap: 30px; // Reduce gap on mobile

    .corporateTwo-policy-heading {
      width: 90%;
      h3 {
        font-size: 24px; // Adjust font size for smaller screens
      }
    }

    .corporateTwo-policy-content {
      width: 100%;
      .corporateTwo-policy-content-container {
        gap: 20px; // Reduce gap between policies
        li {
          margin-left: 10px; // Reduce margin for better fit
          h4 {
            font-size: 20px; // Adjust heading size
          }
          p {
            font-size: 15px; // Adjust paragraph size
          }
          ol {
            margin-left: 20px; // Reduce indentation for smaller screens
            gap: 10px; // Reduce gap between child policies
            li {
              h5 {
                font-size: 16px; // Adjust subheading size
              }
              p {
                font-size: 14px;
              }
            }
          }
          ul {
            margin-left: 20px; // Align unordered lists
            li {
              margin-left: 10px; // Reduce margin
            }
          }
        }
      }
    }
  }

  .corporateTwo-policy-update {
    h4 {
      font-size: 22px; // Adjust font size for update heading
      font-weight: 600;
    }
    p {
      font-size: 14px; // Adjust paragraph size
    }
  }
}

@media (max-width: 480px) {
  .corporateTwo-policy-body {
    padding: 10px 20px; // Further adjust padding for extra small screens

    .corporateTwo-policy-heading {
      h3 {
        font-size: 20px; // Smaller font for very small screens
      }
    }

    .corporateTwo-policy-content {
      .corporateTwo-policy-content-container {
        li {
          h4 {
            font-size: 18px; // Further reduce heading size
            font-weight: 600;
          }
          p {
            font-size: 14px;
          }
          ol {
            li {
              h5 {
                font-size: 14px; // Adjust subheading size
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .corporateTwo-policy-update {
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 13px;
      }
    }
  }
}
