.education_two {
  .highligt_text {
    position: absolute;
    top: 0;
    right: 0;
    margin: auto;
  }

  .theme_description {
    color: #333931;
    font-size: 16px;
    line-height: 30px;
    font-family: regular;
  }

  .theme_btn {
    padding: 14px 25px;
    border: 1px solid transparent;
    font-size: 17px;
    font-weight: 600;
    font-family: regular;
    letter-spacing: 1.4px;

    &.btn_fill {
      color: var(--white-color);
      background: var(--click-color);
    }

    &.with_icon {
      display: block;
      align-items: center;
      gap: 0;

      .material-symbols-outlined {
        font-variation-settings: "wght" 400;
        font-family: "Material Symbols Outlined";
        font-size: 12px;
        margin-bottom: 2px;
      }
    }
  }

  .click_link {
    color: var(--heading-color);
    font-size: 18px;
    padding-bottom: 1px;
    border-bottom: 2px solid;
    font-family: regular;

  }

  .sect_title {
    font-size: 45px;
  
    justify-content:space-between;
    color: var(--heading-color);
    font-family: regular;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .sect_padding {
    padding: 80px 0px;
  }

  .sect_badge {
    color: var(--click-color);
    font-size: 15px;
    font-weight: 600;
    padding: 7px 22px;
    display: inline-block;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: var(--logo-secondary-color);
    letter-spacing: 1px;
  }

  /* Max width: 320px media query */
  @media (min-width: 320px) {
    .theme_description {
      font-size: 14px;
      line-height: 25px;
    }

    .theme_btn {
      padding: 8px 10px;
      font-size: 10px;
      letter-spacing: 1px;
      display: block;
      margin-bottom:15px;
      margin-top:0px;
    

      &.with_icon .material-symbols-outlined {
        font-size: 10px;
      }
    }

    .click_link {
      font-size: 16px;
      text-align:left;
    }

    .sect_title {
      
      font-size: 29px;
      
      display: block; // Ensures the button is on a new line

    }

    .sect_padding {
      padding: 40px 0;
    }

    .sect_badge {
      font-size: 13px;
      padding: 5px 18px;
    }

    .highligt_text {
      top:0px;
      right: 0px;
      display:none;
    }
  }
}
