.news-n-blogs-three-parent {
  background-color: var(--white-color);

  .news-n-blogs-three-left-parent {
    height: 600px;
  }
  .news-n-blogs-three-left-parent,
  .news-n-blogs-three-right-parent {
    background: #fff;
    border-radius: 20px;
    border: 2px solid rgb(181 181 181);
    overflow: hidden;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 20px 20px 0px 0px;
    }

    .content {
      .date {
        font-size: 14px;
        color: #888;
        margin: 10px 0px 10px 0px;
      }

      h4 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
        color: #333;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
        color: #555;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .news-n-blogs-three-right-parent {
    flex-direction: row;
    height: 290px;

    img {
      width: 55%;
      object-fit: cover;
      height: auto;
      border-radius: 20px 0px 0px 20px;
    }

    .content {
      padding-left: 20px;
      .date {
        font-size: 14px;
        color: #888;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
        color: #555;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: 767px) {
    .news-n-blogs-three-left-parent,
    .news-n-blogs-three-right-parent 
    {
      padding: 12px;

    }
    .news-n-blogs-three-right-parent {
      flex-direction: row;
      height: 150px;
  
      img {
        width: 25%;
        object-fit: cover;
        height: auto;
        border-radius: 20px 0px 0px 20px;
      }
  
      .content {
        .date {
          margin: 0px 0px 5px 0px;
          font-size: 13px;
          margin-bottom: 0px;
        }
  
        h4 {
          font-size: 15px;
          margin-bottom: 5px;
         
        }
  
        p {
          font-size: 14px;
          margin-bottom: 5px;
          -webkit-line-clamp: 1;
         
        }
      }
    }
  }
}


