.happyerp-curve_divider {
  position: absolute;
  left: 0;
  z-index: 9;
}
.happyerp-curve_divider.happyerp-curve_bottom {
  bottom: 0;
}
.happyerp-curve_divider img {
  width: 100%;
  height: auto;
}
.happyerp-curve_divider.happyerp-curve_top {
  top: 0;
}
