.navbar-mobile-bottom {
display: none;
}

@media only screen and (max-width: 993px) {
    .navbar-mobile-bottom {
        display: block;
        position: fixed;
        bottom: -1px;
        width: 100%;
        z-index: 999;
        border-radius: 14px 14px 0px 0px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
    
    .navbar-mobile-bottom-menu {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }
    
    .navbar-mobile-bottom-menu .navbar-mobile-bottom-menu-a {
        color: var(--black-color);
        text-align: center;
        text-decoration: none;
        padding: 4px 0px;
    }
    
    .bm_single {
        width: 25%;
    }
    
    .bmp_single {
        width: 16%;
    }
    .bottom-nav-profil-pic{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid var(--logo-primary-color);
    }
    
    .navbar-mobile-bottom-menu-a.bm_single.active_bottom_menu {
        background: var(--click-color);
        color: var(--white-color);
    }
    
    .navbar-mobile-bottom-menu-a.bm_single.active_bottom_menu span {
        color: var(--white-color);
    }
    
    .navbar-mobile-bottom-menu-a span {
        font-size: 22px;
        color: var(--grey-dark);
    }
    
    .navbar-mobile-bottom-menu-a.active span {
        font-variation-settings:
            'FILL' 1
    }
    
    .navbar-mobile-bottom-menu-a small {
        font-size: 11px;
    }
    
    a.profile_icon {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: var(--logo-primary-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -26px;
    }
    
    a.profile_icon span {
        color: white;
    }
    
    .new-user:hover {
        text-decoration: none;
    }
    
    .new-user span {
        color: #fff;
    }
    
}

