/* theme tab css start  */
.happyerp-theme_tabs {
  .happyerp-progress_bar {
    position: absolute;
    width: 120%;
    height: 2px;
    border-radius: 15px;
    left: -10%;
    top: 21px;
    background: #cee1ff;
    .happyerp-fill {
      position: absolute;
      height: 2px;
      background: var(--theme-blue);
      border-radius: 15px;
      top: 0;
    }
  }
  .nav-tabs {
    .nav-link.active,
    .nav-link:hover {
      background: transparent;
    }
    justify-content: center;
    border: none;
    margin-bottom: 22px !important;
    gap: 65px;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    button {
      border: none;
      padding: 0px;
    }
    .happyerp-step_single {
      .happyerp-number {
        transition: 0.8s;
        opacity: 1;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--theme-blue);
        color: var(--white-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-family: medium;
        margin: auto;
        position: relative;
        z-index: 1;
      }
      h6 {
        transition: 0.8s;
        opacity: 1;
        font-size: 16px;
        color: var(--theme-blue);
        margin-top: 4px;
        font-family: regular;
      }
    }
  }
}
.accordion-button:focus {
  box-shadow: none !important;
}
// active in active css start
.happyerp-step1_active .nav-item:nth-child(2) .happyerp-number,
.happyerp-step1_active .nav-item:nth-child(2) h6 {
  transition: 0.8s;
  opacity: 0.4;
}
.happyerp-step2_active .nav-item:nth-child(1) .happyerp-number,
.happyerp-step2_active .nav-item:nth-child(1) h6 {
  transition: 0.8s;
  opacity: 0.4;
}
.happyerp-step2_active .happyerp-progress_bar .happyerp-fill {
  transition: 0.8s;
  right: 0 !important;
}
.happyerp-step1_active .happyerp-progress_bar .happyerp-fill {
  transition: 0.8s;
  left: 0 !important;
}
/* theme tab css end*/

// accordion filter css start
/* For Webkit-based browsers (Chrome, Edge, Safari) */
.accordion_filter::-webkit-scrollbar {
  width: 1px; /* Scrollbar width */
}

.accordion_filter::-webkit-scrollbar-thumb {
  background: var(--theme-lightmidnight-blue); /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
}

.accordion_filter::-webkit-scrollbar-thumb:hover {
  background: var(--theme-lightmidnight-blue); /* Thumb color on hover */
}

.accordion_filter::-webkit-scrollbar-track {
  background: var(--theme-pg-bg); /* Scrollbar track color */
}
.accordion_filter {
  height: 75%;
  overflow-y: auto;
  .accordion-button {
    padding: 10px;
    font-size: 14px;
    font-family: medium;
    color: var(--text-black);
  }
  .active_accordion_filter {
    .accordion-button {
      background: #caffca;
    }
  }
  .accordion-body {
    padding: 0px;
  }
  .happyerp-select_all_search {
    padding: 10px;
    display: flex;
    gap: 8px;
    align-items: center;
    .happyerp-search_input {
      border: none;
      font-size: 13px;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  .happyerp-checkbox_grid {
    .happyerp-c_single {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px;
      cursor: pointer;
      &:nth-child(odd) {
        background: var(--theme-bg-grey);
      }
      input {
        cursor: pointer;
      }
      label {
        font-size: 13px;
        width: 100%;
        cursor: pointer;
        color: var(--text-black);
      }
    }
  }
}
.happyerp-export_click {
  .happyerp-edit_status_popup {
    bottom: inherit;
    width: 180px;
    left: inherit;
    padding-bottom: 0px;
  }
  .happyerp-export_format {
    .happyerp-ef_single {
      border-top: 1px solid #c6c6c6;
      margin: 0px -15px;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      cursor: pointer;
      img {
        height: 20px;
      }
    }
  }
}
.happyerp-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 22px;
  justify-content: space-between;

  .happyerp-search_field {
    position: relative;

    input {
      background: var(--white-color);
      border: 1px solid var(--theme-grey);
      border-radius: 5px;
      color: var(--theme-black);
      font-size: 13px;
      font-family: regular;
      margin: 0;
      padding: 5px 10px;
      transition: 0.3s;
      width: 100%;
      padding-right: 40px;

      &:focus-visible {
        outline: none;
        transition: 0.3s;
        border-color: var(--theme-blue);
      }
    }

    .happyerp-field_icon {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      span {
        color: var(--theme-grey);
        font-size: 22px;
        line-height: 0px;
      }
    }
  }
}
