.et_work-flow {
  margin-bottom: 30px;

  .et_work_flow_inner_bottom {
    .et_work_flow_inner_bottom_inner {
      display: flex;
      gap: 25px;

      .et_work_circle {
        display: flex;
        position: relative;
        width: 25%;
        width: 250px;
        height: 250px;
        z-index: 0;
        margin: 20px;
        flex-direction: column;
        border-radius: 50%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .et_work_circle_img {
          position: absolute;
          top: 60px;
          left: 60px;
          z-index: 1;

          img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
          }
        }

        .et_work_circle_img_abso {
          position: absolute;
          top: 145px;
          right: 200px;
          z-index: 1;

          img {
            width: 66px;
            height: 66px;
          }

          /* Adding rotation animation */
          animation: rotate360 5s linear infinite;
        }

        .et_work_circle_content {
          margin-top: 280px;
          text-align: center;

          h2.et_work_circle_head {
            font-size: 18px;
            margin-bottom: 5px;
          }

          p.et_work_circle_para {
            font-size: 14px;
          }
        }
      }
    }
  }

  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 767px) {
    .et_work_flow_inner_bottom_inner {
      flex-direction: column; /* Stacking circles vertically */
    }

    .et_work_circle {
      width: 100%; /* Make the circles take full width */
      height: auto;
      margin: 20px auto; /* Center them */
      animation: none; /* Disable animations */
    }

    .et_work_circle_img_abso {
      animation: none; /* Disable the image animation */
    }
  }
}
