.business_one_route_parent_div {
  @font-face {
    font-family: title_regular;
    src: url(../../../../../public/assets/font/Bebas_Neue/BebasNeue-Regular.ttf);
  }
  .title_regular {
    font-family: title_regular;
  }
  /* font face for font Bebas_Neue end */

  /* font face for font Schibsted_Grotesk start */
  @font-face {
    font-family: regular;
    src: url(../../../../../public/assets/font/Schibsted_Grotesk/SchibstedGrotesk-Regular.ttf);
  }
  .regular {
    font-family: regular;
  }
  @font-face {
    font-family: medium;
    src: url(../../../../../public/assets/font/Schibsted_Grotesk/SchibstedGrotesk-Medium.ttf);
  }
  .medium {
    font-family: medium;
  }
  @font-face {
    font-family: semibold;
    src: url(../../../../../public/assets/font/Schibsted_Grotesk/SchibstedGrotesk-SemiBold.ttf);
  }
  .semibold {
    font-family: semibold;
  }
  @font-face {
    font-family: bold;
    src: url(../../../../../public/assets/font/Schibsted_Grotesk/SchibstedGrotesk-Bold.ttf);
  }
  .bold {
    font-family: bold;
  }
  @font-face {
    font-family: extrabold;
    src: url(../../../../../public/assets/font/Schibsted_Grotesk/SchibstedGrotesk-ExtraBold.ttf);
  }
  .extrabold {
    font-family: extrabold;
  }
  /* font face for font Schibsted_Grotesk end */

  body {
    font-family: regular !important;
  }
}
