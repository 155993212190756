.corporateTwo-main-banner {
  position: relative;
  height: 80vh;
  background-color: black;
  padding: 10px;
  overflow: hidden;
  
  // .business-three-all-banners-container {
  //   position: absolute;
  //   top: 10px;
  //   font-size: 50px;
  //   color: white;
  //   z-index: 20000;
  //   animation: fadeIn 1s forwards;
  
  //   &.hide {
  //     animation: fadeOut 1s forwards;
  //   }
  
  //   .row {
  //     div {
  //       display: flex;
  //       flex-wrap: wrap;
  //       margin-top: 20px;
  
  //       img {
  //         width: 100%;
  //         border: 4px solid white;
  //         border-radius: 5px;
  //         cursor: pointer;
  //         animation: zoomIn 1s forwards;
  //         opacity: 0; /* Start hidden */
  //       }
  //     }
  //   }
  // }
  
  // .note-stack-icon {
  //   position: absolute;
  //   top: 30px;
  //   right: 30px;
  //   font-size: 30px;
  //   color: white;
  //   background-color: var(--click-color);
  //   border-radius: 50%;
  //   padding: 7px;
  //   z-index: 9;
  // }

  .corporateTwo-main-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(31, 31, 29, 0.345),
        rgba(29, 3, 62, 0.7)
      );
    }

    .corporateTwo-main-slide-content {
      position: absolute;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 30px;
      max-width: 500px;
      height: 100%;

      .corporateTwo-main-banner-title {
        font-size: 4rem;
        font-weight: 800;
      }

      .corporateTwo-main-banner-button {
        margin-top: 10px;
        padding: 10px 20px;
        background-color: var(--click-color);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
    }
  }

  .visible {
    opacity: 1;
    z-index: 1;
  }

  .hidden {
    opacity: 0;
    z-index: 0;
  }

  .bubbles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 2;
  }

  .bubble {
    position: absolute;
    bottom: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: rise 5s ease-in infinite;
    
    // Main bubble structure
    background: radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.6) 20%,
      rgba(173, 216, 230, 0.4) 40%,
      rgba(173, 216, 230, 0.2) 60%,
      rgba(255, 255, 255, 0.1) 80%,
      transparent 100%
    );
    
    // Outer glow and subtle shadow
    box-shadow: 
      0 0 20px 10px rgba(255, 255, 255, 0.3),
      inset 0 0 20px rgba(255, 255, 255, 0.4);
    
    // Rainbow effect
    &::before {
      content: '';
      position: absolute;
      top: 5%;
      left: 5%;
      right: 5%;
      bottom: 5%;
      border-radius: 50%;
      background: linear-gradient(
        135deg,
        rgba(255, 0, 0, 0.1),
        rgba(255, 165, 0, 0.1),
        rgba(255, 255, 0, 0.1),
        rgba(0, 255, 0, 0.1),
        rgba(0, 0, 255, 0.1),
        rgba(75, 0, 130, 0.1),
        rgba(238, 130, 238, 0.1)
      );
      filter: blur(5px);
      opacity: 0.7;
    }
    
    // Main highlight
    &::after {
      content: '';
      position: absolute;
      top: 10%;
      left: 15%;
      width: 30%;
      height: 30%;
      border-radius: 50%;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.2)
      );
      filter: blur(2px);
    }
  }

  .currentSlideTracker {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 7px;
    z-index: 3;

    .slide-track {
      width: 7px;
      height: 7px;
      background-color: white;
      border-radius: 50px;
      transition: width 0.3s ease;
      cursor: pointer;

      &.wide-tracker {
        width: 50px;
        border-radius: 20px;
      }

      &.round-tracker {
        width: 7px;
        border-radius: 50px;
      }
    }
  }

  
}

@keyframes rise {
  0% {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-70vh) rotate(360deg);
    opacity: 0;
  }
}

@media (max-width: 1440px) {
  .corporateTwo-main-banner {
    height: 75vh;

    .corporateTwo-main-slide {
      .corporateTwo-main-slide-content {
        max-width: 450px;
        gap: 25px;

        .corporateTwo-main-banner-title {
          font-size: 3.5rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .corporateTwo-main-banner {
    height: 70vh;
    padding: 8px;

    .corporateTwo-main-slide {
      .corporateTwo-main-slide-content {
        max-width: 400px;
        gap: 20px;
        padding: 8px 16px;

        .corporateTwo-main-banner-title {
          font-size: 3rem;
        }

        .corporateTwo-main-banner-button {
          padding: 8px 16px;
        }
      }
    }

    .bubble {
      width: 80px;
      height: 80px;
    }

    .currentSlideTracker {
      bottom: 15px;

      .slide-track {
        &.wide-tracker {
          width: 40px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .corporateTwo-main-banner {
    height: 70vh;
    padding: 6px;

    .corporateTwo-main-slide {
      .corporateTwo-main-slide-content {
        max-width: 350px;
        gap: 15px;
        padding: 6px 12px;

        .corporateTwo-main-banner-title {
          font-size: 2.5rem;
        }

        .corporateTwo-main-banner-button {
          padding: 8px 14px;
        }
      }
    }

    .bubble {
      width: 60px;
      height: 60px;
    }

    .currentSlideTracker {
      bottom: 12px;
      gap: 5px;

      .slide-track {
        width: 6px;
        height: 6px;

        &.wide-tracker {
          width: 30px;
        }

        &.round-tracker {
          width: 6px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-main-banner {
    height: 60vh;
    padding: 5px;

    .corporateTwo-main-slide {
      .corporateTwo-main-slide-content {
        max-width: 300px;
        gap: 12px;
        padding: 5px 10px;

        .corporateTwo-main-banner-title {
          font-size: 2rem;
        }

        .corporateTwo-main-banner-button {
          padding: 7px 12px;
          font-size: 0.9rem;
        }
      }
    }

    .bubble {
      width: 50px;
      height: 50px;
    }

    .currentSlideTracker {
      bottom: 10px;
      gap: 4px;

      .slide-track {
        width: 5px;
        height: 5px;

        &.wide-tracker {
          width: 25px;
        }

        &.round-tracker {
          width: 5px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .corporateTwo-main-banner {
    height: 60vh;
    padding: 4px;

    .corporateTwo-main-slide {
      .corporateTwo-main-slide-content {
        max-width: 250px;
        gap: 10px;
        padding: 4px 8px;

        .corporateTwo-main-banner-title {
          font-size: 1.8rem;
        }

        .corporateTwo-main-banner-button {
          padding: 6px 10px;
          font-size: 0.8rem;
        }
      }
    }

    .bubble {
      width: 40px;
      height: 40px;
    }

    .currentSlideTracker {
      bottom: 8px;
      gap: 3px;

      .slide-track {
        width: 4px;
        height: 4px;

        &.wide-tracker {
          width: 20px;
        }

        &.round-tracker {
          width: 4px;
        }
      }
    }
  }
}

// Responsive background images
@media (max-width: 1024px) {
  .corporateTwo-main-slide {
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .corporateTwo-main-slide {
    background-position: center top;
  }
}

@media (max-width: 576px) {
  .corporateTwo-main-slide {
    background-size: contain;
    background-position: center;
  }
}

