.women-day-theme {
  position: relative;

  .diwali-theme-lantern-gif-left {
    position: fixed;
    top: 50px;
    right: 0px;
    z-index: 100;
    width: 350px;
    transform: scaleX(-1);
  }

  .diwali-theme-lantern-gif-right {
    position: fixed;
    top: 50px;
    left: 0px;
    z-index: 100;
    width: 350px;
  }

  @keyframes flicker {
    0% {
      opacity: 1;
      text-shadow: 2px 2px 10px rgba(255, 165, 0, 0.9),
        4px 4px 20px rgba(255, 140, 0, 0.8);
    }
    100% {
      opacity: 0.4;
      text-shadow: 2px 2px 5px rgba(255, 140, 0, 0.7),
        4px 4px 10px rgba(255, 69, 0, 0.6);
    }
  }

  .diwali-theme-fire-crackers-gif {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Prevent interaction */
    overflow: hidden;

    img {
      width: 50%;
      height: auto;
    }
  }

  .diwali-theme-deepak-gif {
    width: 90px;
    position: fixed;
    bottom: 50px;
    left: 40px;
    z-index: 99999;
    cursor: pointer;
  }

  .women-day-second {
    display: none;
  }

  .women-day-first {
    display: block;
  }

  @media (max-width: 575px) {
    .diwali-theme-lantern-gif-left {
      width: 270px;
    }

    .diwali-theme-lantern-gif-right {
      width: 270px;
    }
    .diwali-theme-deepak-gif {
      width: 70px;
      bottom: 80px;
      left: 20px;
    }

    .diwali-theme-fire-crackers-gif {
      img {
        position: absolute;
        min-width: 100vh;
        height: 100vh;
      }

      .women-day-first {
        display: none;
      }
      .women-day-second {
        display: block;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
