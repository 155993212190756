.education_one{
  background:white;

.Education_one_template{

   padding-bottom:50px ;

   
      
     .Education-One-Banner{
    
    background-repeat: no-repeat; 
    background-size:cover;
    background-position: center;
  
     
    .Education-One-Banner-inner{
       display:flex;
       background:white;
     
      
      
    
      .Education-One-Banner-right{
        width:50%;
        padding-left:70px;
        padding-top:60px;
        

       
     
        
         
        .Education-One-Banner-logo {
            width:100%; /* Adjust the logo size */
            border-radius:20px;
            padding-bottom:20px;
          }
          h1 {
            font-size: 30px;
            color: #003A60; /* Dark text */
            
          }
          .Education-One-Banner-subtitle {
            font-size: 20px;;
            color: black;
            padding-bottom:10px;
            padding-right:10px;
          }
      
          h2 {
            font-size: 28px;
            color: #003A60;
            padding-bottom:4px;
            
          }
      
          .Education-One-Banner-description {
            font-size: 18px;
            color: #333;
          
            padding-bottom:55px;
          
          }
      
          .Education-One-Banner-buttons {
            display: flex;
          
            gap:15px;
            
      
            .btn_fill, .btn_border, .btn_fill{
          
              border-radius: 10px;
              
              font-size: 12px;
              cursor: pointer;
              padding:10px;
             
            }
      
           
            }
          }
        }
      
      
        
      
    .Education-image-container-left{
        width:50%;
        background-color:var(--logo-primary-color);
        .Education-One-Banner-tutor-image{
            width:95%;

          
        }
    }
  }
      
}
  }
      


      
  
      

