.Business-Two-Offers-container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 300px;
  background-color: #fff;
}

.Business-Two-Offers-left-side {
  background: linear-gradient(
    rgba(255, 0, 150, 0.5) 0%,
    rgba(0, 119, 255, 0.5) 50%
  );
  color: white;
  flex: 1;
  z-index: 999;
  display: flex;
  align-items: center;
  position: relative;
  clip-path: polygon(0 0, 28% 0, 20% 100%, 0 100%);
}

.Business-Two-Offers-content {
  text-align: center;
  padding: 20px;
}

.Business-Two-Offers-right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 80%;
  z-index: 1;
  overflow: hidden;
}

.business-two-offer-item {
  margin: 0 10px; /* Add 10px gap between items */
}

.business-two-offer-item img {
  width: auto;
  height: 150px;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .Business-Two-Offers-content {
    padding: 2px;
  }
  .Business-Two-Offers-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
    background: linear-gradient(
      to top right,
      rgba(255, 0, 150, 0.5) 0%,
      rgba(0, 119, 255, 0.5) 50%,
      white 50%
    );
  }

  .Business-Two-Offers-left-side {
    clip-path: none;
    width: 100%;
    height: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0px;
  }

  .Business-Two-Offers-content h1 {
    font-size: 1.5em;
  }

  .Business-Two-Offers-right-side {
    position: relative;
    width: 100%;
    height: auto;
  }
}
