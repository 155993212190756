.corporateTwo-home-downloadApp {
  height: 70vh;
  background: url("https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fdownloadbanner.webp?alt=media&token=7a399011-2fc1-45ca-a4f8-03ab6d05d710");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }

  .corporateTwo-home-downloadApp-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;
    z-index: 2;
  }

  .corporateTwo-home-downloadApp-heading {
    text-align: center;
    z-index: 3;
    color: white;

    h1 {
      font-size: 36px;
      font-weight: 500;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .corporateTwo-home-downloadApp-source {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
      
      img {
        max-width: 230px;
        height:auto;
      }
    
  }

  // 1440px
  @media (max-width: 1440px) {
    height: 60vh;

    .corporateTwo-home-downloadApp-heading {
      h1 {
        font-size: 32px;
      }

      h2 {
        font-size: 22px;
      }
    }

    .corporateTwo-home-downloadApp-source {
      img {
        max-width: 200px;
        height: 70px;
      }
    }
  }

  // 1024px
  @media (max-width: 1024px) {
    height: 50vh;

    .corporateTwo-home-downloadApp-heading {
      h1 {
        font-size: 28px;
        font-weight: 500;
      }

      h2 {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .corporateTwo-home-downloadApp-source {
      img {
        max-width: 200px;
        height: 70px;
      }
    }
  }

  // 768px
  @media (max-width: 768px) {
    .corporateTwo-home-downloadApp-info {
      left: 40%;
      transform: translate(-40%, -50%);
    }

    .corporateTwo-home-downloadApp-heading {
      h1 {
        font-size: 24px;
        font-weight: 500;
      }

      h2 {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .corporateTwo-home-downloadApp-source {
      img {
        max-width: 200px;
        height: 70px;
      }
    }
  }

  // 576px
  @media (max-width: 576px) {
    background: url("./../../../../../../public/assets/img/corporate/corporatetwo/downloadbanner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .corporateTwo-home-downloadApp-info {
      height: 100%;
      top: 50%;
      padding: 10px 15px;
    }

    .corporateTwo-home-downloadApp-heading {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-size: 20px;
        font-weight: 400;
      }

      h2 {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .corporateTwo-home-downloadApp-source {
      align-items: center;

      img {
        max-width: 170px;
        height: 50px;
      }
    }
  }

  // 496px
  @media (max-width: 496px) {
    height: 40vh;

    .corporateTwo-home-downloadApp-heading {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 14px;
      }
    }

    .corporateTwo-home-downloadApp-source {
      img {
        max-width: 150px;
        height: 40px;
      }
    }
  }
}
