.services-details-parent-div {
  padding: 0px 0px 0px 0px;


  /* QR CODE POPUP START */
  .pg_contact_us_popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .pg_contact_us_popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 400px;
    width: 90%;
  }

  .pg_contact_us_close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .pg_contact_us_selected-image-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
  }

  .pg_contact_us_selected-image-preview {
    position: relative;
    display: inline-block;
  }

  .pg_contact_us_selected-image-preview img {
    max-width: 100%;
    max-height: 50vh;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .react-tel-input .country-list .country-name {
    color: var(--grey-dark);
  }

  .pg_contact_us_uploading-gif-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .contact-section-uploading-gif {
    width: 100%;
    /* Adjust the size for better visibility */
    height: 100%;
    object-fit: contain;
    /* Ensure the GIF scales properly */
    z-index: 9999;
  }
}

.prducts-details-top-suggestion-parent {
  border-bottom: 1px solid lightgray;
  padding: 5px 0px;
  background-color: #ebebeb;
}

.suggested-product-border-right {
  border-right: 1px solid var(--black-color);
  /* Adjust color and width as needed */
  padding-right: 15px;
  /* Adjust padding as needed */
}

.product-details-top-suggestion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.products-details-top-suggestion-img {
  width: 50px !important;
  height: 50px !important;
}

.products-details-top-suggestion-product-info {
  display: flex;
  width: calc(100% - 65px);
}

.products-details-top-suggestion-product-name {
  font-size: 13px;
  color: var(--black-color);
  width: 100%;
}

.products-details-top-suggestion-product-price,
.products-details-top-suggestion-product-rating,
.products-details-top-suggestion-discount {
  display: flex;
  align-items: center;
}

.products-details-top-suggestion-product-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.products-details-top-suggestion-discount {
  background-color: var(--red-color);
  color: var(--pure-white-color);
  padding: 1px 5px;
  font-weight: 700;
  font-size: 12px;
}

.services-images-slider-div-child {
  background-color: var(--card-bg);
  padding: 50px;
  box-shadow: 1px 1px 10px -5px;
  border-radius: 16px;
  margin-top: -40px;
}

.bigimage_container .image-gallery-content .image-gallery-slide .image-gallery-image,
.bigimage_container .image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 100%;
  aspect-ratio: 3/2;
  /* object-fit: cover; */
}

.bigimage_container .image-gallery-thumbnail.active,
.bigimage_container .image-gallery-thumbnail:focus {
  outline: none;
  border: 2px solid #161616;
}

.bigimage_container .image-gallery-thumbnail:hover {
  outline: none;
  border: 2px solid #161616;
}

.bigimage_container .image-gallery-thumbnails {
  padding: 0px;
  padding-top: 6px;
  border-radius: 0px 0px 16px 16px;
}

.bigimage_container .image-gallery-swipe {
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
}

.bigimage_container .image-gallery-left-nav .image-gallery-svg,
.bigimage_container .image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 40px;
  background: #00000057;
  border-radius: 50%;
}

.bigimage_container .image-gallery-icon {
  filter: none;
}

.bigimage_container .image-gallery-fullscreen-button,
.bigimage_container .image-gallery-play-button {
  padding: 8px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 40px;
  width: 40px;
  background: #00000057;
  padding: 7px;
  border-radius: 50%;
}

.product-details-pg-srvc-heading {
  line-height: 10px;
  text-align: center;
  margin-top: 30px;
  background-color: var(--logo-primary-color);
  height: 100px;
  border-radius: 20px;
  color: var(--pure-white-color);
}

.product-details-pg-srvc-heading h5 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 17px;
}

.gallery-img-delete-icon {
  align-items: center;
  background: #fff;
  border: 1px solid #666;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 35px;
  z-index: 999;
}

/* Define the CSS class for the red heart icon */
.heart-pink {
  color: red;
  /* Change the color to red */
  transition: color 0.3s;
  /* Add a smooth color transition */
}

.products-status-switch {
  transform: scale(0.7);
}


.namesd {
  justify-content: space-between;
}

.services_details_bottom_details_parent {
  padding-bottom: 0px;
  margin-top: 30px;
  background-color: white;
  box-shadow: 1px 1px 10px -5px;
  border-radius: 16px;

  .prodct-page-payment-qr-method {
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      width: 100px;
    }
  }

  .prodct-page-payment-qr-parent {
    .prodct-page-payment-qr {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 200px;
        height: 200px;
        border: 4px solid var(--logo-primary-color);
        padding: 8px;
        /* margin-bottom: 10px; */
      }
    }

    .prodct-page-payment-qr-details {
      /* display: flex;
      flex-wrap: wrap;
      justify-content: center; */
    }
  }
}


.more_carousel_services_heading {
  margin: 0px 0px 50px 0px;
}

.services_details_bottom_details_child {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  box-shadow: 1px 1px 10px -5px;
}

.product_edit_save_btn {
  padding: 3px 12px;
  background: var(--click-color);
  border: none;
  border-radius: 6px;
  color: var(--white-color);
  font-family: 'p_bold';
  letter-spacing: 1px;
  text-transform: capitalize;
  /* margin-top: 7px; */
}

.product-offer-carousel .slick-slide {
  transition: transform 0.3s ease;
}

.product-offer-carousel .slick-slide:hover {
  transform: scale(1.05);
}

.product-offer-carousel .product_offer_img_1 {
  width: 100%;
  border-radius: 10px;
  margin: 35px 0px;
  height: 250px;
}

/* radio button code start */

.service_details_radion_btn {
  background-color: var(--grey-light);
  border: 1px solid rgb(127, 127, 127);
  border-radius: 30px;
  padding: 3px 15px;
  /* display: inline; */
  margin: 5px 3px;
  color: var(--black-color);
  font-weight: 700;
  font-size: 16px;
  transition: background-color .1s ease, color 1s ease;
}

.radio_btn_checked {
  background-color: var(--click-color);
  border: 1px solid rgb(225, 225, 225);
  border-radius: 30px;
  padding: 3px 15px;
  /* display: inline; */
  margin: 5px 3px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  transition: background-color .1s ease, color 1s ease;
}

.radio-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  display: none;
}

.radio_btun_icon {
  font-size: 30px;
}

.prdct-dtils-removewishlist-btn {
  border: none;
  font-variation-settings: 'FILL' 1;
  transition: 0.5s;
}

.colors-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color-capsule {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.selected-color {
  background-color: var(--click-color);
  color: white;
}

.color-disc {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #ccc;
}

.color-name {
  font-size: 14px;
}

.delete-color-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
}

.add-color-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
}

.size-capsule {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f0f0f0;
  padding: 5px 20px;
  cursor: pointer;
}

.selected-size {
  background-color: var(--click-color);
  color: white;
}

.size-name {
  font-size: 1.15rem;
}

.variants-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.variant-capsule {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 8px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.variant_btn_checked {
  background-color: var(--click-color);
  color: white;
  border-radius: 30px;
  font-weight: 700;
}


.variant-name {
  font-size: 14px;
  font-weight: 500;
  /* color: #333; */
  margin-right: 8px;
}

.variant-price {
  font-size: 14px;
  font-weight: 600;
  color: #4CAF50;
}

.delete-variant-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 12px;
  color: #999;
  transition: color 0.3s ease;
}

.delete-variant-btn:hover {
  color: #f44336;
}

.add-variant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px dashed #999;
  border-radius: 30px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.add-variant-btn:hover {
  background-color: #e0e0e0;
  border-color: #666;
  color: #333;
}

.add-variant-btn .material-symbols-outlined {
  margin-right: 4px;
  font-size: 18px;
}

.product-details-add-to-cart-btn {
  background-color: var(--click-color);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.control-section-part:nth-child(1) {
  margin-bottom: 20px;
}

.quantity-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}



@media only screen and (max-width:575px) {
  .product-details-top-suggestion-item {
    align-items: flex-start;
  }

  .products-details-top-suggestion-product-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 65px);
    /* Adjusted width considering image size and gap */
    flex-shrink: 1;
    box-sizing: border-box;
    overflow: hidden;
    /* Prevents overflow */
  }

  .products-details-top-suggestion-product-name {
    font-size: 13px;
    color: var(--black-color);
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Hides the overflowing content */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) at the end of the text */
  }

  .products-details-top-suggestion-product-meta {
    display: flex;
    align-items: center;
  }

  .products-details-top-suggestion-product-price,
  .products-details-top-suggestion-product-rating,
  .products-details-top-suggestion-discount {
    display: flex;
    align-items: center;
  }

  .product-details-pg-srvc-heading h5 {
    color: white;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 17px;
  }

  .services-images-slider-div-child {
    padding: 15px;
  }

  .product-offer-carousel .product_offer_img_1 {
    margin: 14px 0px;
    height: auto;
  }

  .services_details_right_dv {
    margin-top: 40px;
  }

  .products-status-switch {
    transform: scale(0.6);
  }
}

/* For 1024 Resolution */
@media only screen and (min-device-width : 575px) and (max-device-width : 1024px) {
  .products-details-top-suggestion-product-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 65px);
    /* Adjusted width considering image size and gap */
    flex-shrink: 1;
    box-sizing: border-box;
    overflow: hidden;
    /* Prevents overflow */
  }

  .product-details-pg-srvc-heading h5 {
    color: white;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 17px;
  }

  .services_details_right_dv {
    margin-top: 40px;
  }

  .services-images-slider-div-child {
    padding: 15px;
  }
}

.admin-controls-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  margin: 30px 0;
}

.admin-controls-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.control-section {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.section-title {
  color: var(--heading-color);
  font-size: 1rem;
  /* margin-bottom: 1rem; */
  font-weight: 600;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: white;
  border-radius: 6px;
}

.toggle-label {
  font-size: 0.9rem;
  color: var(--text-color);
}

.toggle-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-label {
  font-size: 0.8rem;
  color: #6c757d;
}

.status-label.active {
  color: var(--click-color);
  font-weight: 500;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radio-label {
  margin: 0;
  /* Remove any existing margin */
  display: flex;
  align-items: center;
}

.radio-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: var(--card-bg);
  border-radius: 6px;
  margin-bottom: 8px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  cursor: pointer;
}

.radio-text {
  font-size: 0.9rem;
  color: var(--text-color);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  background-color: var(--click-color);
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.copy-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.role-selection {
  margin-bottom: 15px;
}

.copy-form {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.original-badge,
.copy-badge {
  font-size: 0.7em;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 8px;
  vertical-align: middle;
}

.original-badge {
  background-color: #e3f2fd;
  color: #1976d2;
}

.copy-badge {
  background-color: #f5f5f5;
  color: #616161;
}

.view-product-link {
  display: flex;
  align-items: center;
  color: var(--click-color);
  opacity: 0.7;
  transition: opacity 0.3s;
}

.view-product-link:hover {
  opacity: 1;
}

.view-product-link .material-symbols-outlined {
  font-size: 20px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}