.launching_page_right_parent {
    height: 100vh;
    background-image: url(https://images.hdqwalls.com/download/city-4k-buildings-skyscraper-view-l3-1920x1080.jpg);
    background-repeat: no-repeat;
    overflow: hidden;

    .launching_page_right_pc {
        display: none;
    }

    .launching_page_left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;

        img {
            height: 200px;
        }

        div {
            p {
                color: var(--logo-primary-color);
                font-size: 2rem;
            }

            h1 {
                color: var(--white-color);
                font-size: 3rem;
                font-weight: 700;

                span {
                    color: var(--click-color);
                }
            }

            button{
                color: var(--white-color);
                border: 2px solid var(--white-color);
            }
        }
    }

    .launching_page_right {
        img {
            width: 300px;
        }
    }

    @media (max-width: 576px) {
        .launching_page_left {
            margin-top: -200px;

            img {
                height: 100px;
            }

            div{
                margin-top: 0px;
                p{
                    font-size: 1.5rem;
                }
                h1{
                    font-size: 2rem;
                }
            }
        }

        .launching_page_right_pc {
            display: block;
            img {
                width: 200px;
            }
        }

        .launching_page_right {
            img {
                display: none;
            }
        }
    }
}
