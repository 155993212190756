 .our_partners_parent {
     background-color: white;
 }

 .partners_images_div img {
     filter: grayscale(100%);
     transition: filter 0.3s ease;
     cursor: pointer;
 }

 .partners_images_div img:hover {
     filter: grayscale(0%);
 }

 /* Tablet (portrait) and above */
 @media only screen and (max-width: 600px) {
     .our_partners_parent {}

 }