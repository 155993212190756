.corporateTwo-terms-body {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: var(--black-color);

  div {
    color: black;
  }

  p {
    ol {
      li {
        margin: 20px 0;
        font-size: 1.25rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-terms-body {
    padding: 0px 10px;

    p {
        ol {
          li {
            font-size: 1rem;
          }
        }
      }
  }
}
