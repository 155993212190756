.corporateTwo-home-callus {
  padding: 50px;
  min-height: 50vh;
  background: url("https://d2d22nphq0yz8t.cloudfront.net/88e6cc4b-eaa1-4053-af65-563d88ba8b26/https://media.croma.com/image/upload/v1679040543/Croma%20Assets/CMS/Vidhi/17-08-2022/body-image-washing-machine-fabrics_gyihhx.png/mxw_1440,f_auto");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(var(--click-color), rgba(0, 0, 0, 0.9));
    z-index: 5;
  }

  .corporateTwo-home-callus-info {
    flex: 1;
    z-index: 10;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease-out, opacity 0.5s ease-out;

    &.animate-container {
      opacity: 1;
      transform: translateX(0);
    }

    .corporateTwo-home-callus-action {
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: #dfbcea;
      padding: 5px 10px;
      border-radius: 30px;
      cursor: pointer;
      color: var(--click-color);

      a {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          position: relative;
          background-color: lightgray;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--click-color);
          cursor: pointer;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background: var(--click-color);
            border-radius: 50%;
            transform: translate(-50%, -50%) scale(1.5);
            animation: ripple-animation 1.5s infinite;
            pointer-events: none;
          }
        }
      }
    }

    h2 {
      font-size: 28px;
      font-weight: 600;
    }

    button {
      width: 200px;
      height: 50px;
      font-size: 1rem;
      font-weight: 600;
      border: none;
      color: var(--click-color);
      background-color: var(--logo-primary-color);
    }
  }

  .corporateTwo-home-callus-vehicle {
    flex: 1;
    z-index: 10;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease-out, opacity 0.5s ease-out;

    &.animate-image {
      opacity: 1;
      transform: translateX(0);
    }

    img {
      width: 100%;
      height: 400px;
    }
  }

  // Media queries for responsiveness
  @media (max-width: 1440px) {
    padding: 40px;

    .corporateTwo-home-callus-info {
      h2 {
        font-size: 26px;
      }

      button {
        width: 180px;
        height: 45px;
        font-size: 0.9rem;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 30px;

    .corporateTwo-home-callus-info {
      gap: 20px;

      h2 {
        font-size: 24px;
      }

      button {
        width: 160px;
        height: 40px;
        font-size: 0.85rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 25px;

    .corporateTwo-home-callus-info {
      gap: 15px;

      h2 {
        font-size: 22px;
      }

      button {
        width: 140px;
        height: 35px;
        font-size: 0.8rem;
      }
    }

    .corporateTwo-home-callus-vehicle {
      img {
        height: 200px;
        width: 300px;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 20px;

    .corporateTwo-home-callus-info {
      order: 2;
      align-items: center;
      text-align: center;
      gap: 20px;

      h2 {
        font-size: 20px;
        font-weight: 400;
      }

      button {
        width: 120px;
        height: 30px;
        font-size: 0.75rem;
      }
    }

    .corporateTwo-home-callus-vehicle {
      order: 1;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 70%;
        height: 70%;
      }
    }
  }
}
