.business-three-why-choose-us-container-parent {
  background-color: var(--white-color);
  padding: 50px 0;
}

.business-three-why-choose-us-container {
  display: flex;
  justify-content: end;
  position: relative;
  padding: 0 20px;
  /* Adjusted padding to fit better on smaller screens */
}

.business-three-why-choose-us-left-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 50%;
  /* Adjusted to ensure proper overlap */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 30%;
  /* Adjust width for responsiveness */
  max-width: 400px;
  /* Ensure it doesn't grow too large */

  .business-three-why-choose-us-slip {
    padding: 10px;
    cursor: pointer;
    transition: width 1s, transform 0.3s, box-shadow 0.3s;
    width: 90%;
    /* Make slips take full width of the parent */
    max-width: 400px;
    /* Maintain a max width */
    background-color: #f0f0f0;
    color: black;
    font-weight: 700;
    text-align: start;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 25px;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &.business-three-why-choose-us-active {
      color: white;
      width: 150%;
      /* Ensure active slip takes full width */
      max-width: 600px;
      /* Maintain a max width */
      background-color: var(--click-color);
      border-radius: 25px;

      span {
        font-size: 30px;
        color: var(--click-color);
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    span {
      font-size: 30px;
      color: white;
      background-color: var(--click-color);
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .business-three-why-choose-us-tab-text {
      font-weight: 700;
    }
  }
}

.business-three-why-choose-us-card {
  border: 1px solid #ddd;
  border-radius: 30px;
  overflow: hidden;
  height: 500px;
  /* Adjust height to be responsive */
  width: 70%;
  /* Adjust width to be responsive */
  position: relative;
  z-index: 1;
  padding: 1em;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 16px;
  transition: transform 0.3s, box-shadow 0.3s;

  .business-three-why-choose-us-card-content {
    position: relative;
    transition: transform 1s;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.business-three-why-choose-us-animate {
      transform: translateY(-150%);
    }

    &:hover {
      transform: scale(1.02);
    }

    .business-three-why-choose-us-tab-content {
      width: 100%;
      height: auto;
      /* Ensure height adapts */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        max-width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 20px;
        transition: transform 0.3s, box-shadow 0.3s;

        &:hover {
          transform: scale(1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
      }

      .business-three-why-choose-us-tab-description {
        margin-top: 10px;
        font-size: 16px;
        text-align: center;
        color: #333;
        padding: 10px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s, background-color 0.3s;
      }
    }
  }
}

/* Media Queries */
@media (min-width: 992px) and (max-width: 1200px) {
  .business-three-why-choose-us-container {
    padding: 0 40px;
    /* Adjust padding for larger screens */
  }

  .business-three-why-choose-us-left-panel {
    width: 35%;
    /* Adjust width for larger screens */
  }

  .business-three-why-choose-us-card {
    width: 75%;
    /* Adjust width for larger screens */
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .business-three-why-choose-us-left-panel {
    width: 40%;
  }

  .business-three-why-choose-us-card {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .business-three-why-choose-us-container {
    margin-top: 220px;
  }

  .business-three-why-choose-us-left-panel {
    width: 100%;
    right: 0;
    top: -75px;
    rotate: 90deg;
    transform: none;
  }

  .business-three-why-choose-us-card {
    width: 100%;
    height: auto;
  }

  .business-three-why-choose-us-left-panel .business-three-why-choose-us-slip.business-three-why-choose-us-active {
    color: white;
    width: 110%;
    /* Ensure active slip takes full width */
    // max-width: 300px;
    /* Maintain a max width */
    background-color: var(--click-color);
    border-radius: 25px;

    span {
      font-size: 30px;
      color: var(--click-color);
      background-color: white;
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}