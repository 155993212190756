.business-three-about-us-heading {
    text-align: center;

    h1 {
        font-weight: 700;
    }
}

.business-three-about-top-selector {
    width: 50%;
    margin: auto;
    background-color: var(--white-color);
    padding: 10px 30px;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;

    .business-three-service-tab {
        position: relative;
        cursor: pointer;
        padding: 10px 20px;
        transition: color 0.5s, border-bottom 0.5s;
        font-weight: bold;
        color: #4b4b4b;
        display: flex;
        align-items: center;

        span {
            font-size: 1.5rem;
            transition: color 0.5s;
        }

        &.active {
            color: var(--click-color);
            font-weight: bold;
            border-bottom: 2px solid var(--click-color);

            span {
                color: var(--click-color);
            }
        }
    }
}

.business-three-about-us {

    .business-three-about-us-content {
        display: flex;
        justify-content: center;
    }
}

// the why section
.about-the-why-section {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-direction: column;

    div {
      
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h3 {
                font-weight: 700;

                span {
                    color: var(--click-color);
                }
            }
p{
    font-size: 20px;
}
            img{
                width: 100%;
                border-radius: 20px;
            }
        }
    }
}

.business-three-service-selected-service-content-inside{
    flex-direction: row-reverse;
}

.business-three-about-us-reverse-sec{
    flex-direction: row-reverse;
    display: flex;
}

@media (max-width: 768px) {
    .business-three-about-top-selector {
        width: 100%;
        margin: auto;
        padding: 10px 10px;
    }
}