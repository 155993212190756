@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.TemplateEditSidePopup-slide-in {
  position: fixed;
  top: 13%;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-out forwards;
  overflow: hidden;
  height: 100px; // Initial height
  width: 100px; // Initial width
  padding: 7px 5px 0px 5px;
  transition: width 0.5s ease, height 0.5s ease;
  cursor: pointer;
  border-radius: 20px 0px 0px 20px;

  img {
    width: 80px;
  }
}

.TemplateEditSidePopup-expanded {
  width: 40%;
  height: 85vh;
  padding: 20px 0px;
  transition: width 0.5s ease, height 0.5s ease;

  .side-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-color);
    padding: 0px 20px 20px 20px;
    font-weight: bold;
    font-size: 1.2em;

    p {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #fff;
        font-size: 1.2em;
        margin-right: 10px;
      }
    }
    span {
      color: var(--black-color);
      font-size: 1.2em;
    }
  }

  .AccordionHeader-span-text-template {
    p {
      color: var(--black-color);
    }
    span {
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }
}

.side-popup-header-below{
  display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-color);
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    font-size: 1.2em;
    bottom: 0;
    position: absolute;
    background-color: var(--grey-light);
    width: 100%;

    p {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #fff;
        font-size: 1.2em;
        margin-right: 10px;
      }
    }
    span {
      color: var(--black-color);
      font-size: 1.2em;
    }
}
.side-popup-tooltip {
  position: fixed;
  top: 13%;
  right: 0;
  background-color: var(--white-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--black-color);
  font-weight: 700;
  padding: 10px;
  width: 220px;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
