$transition-duration: 0.3s;
$primary-color: #000000;
$hover-color: #1c2f3c;
.back_button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  color: $primary-color;
  font-weight: 500;
  cursor: pointer;
  transition: all $transition-duration ease-in-out;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    opacity: 0.1;
    transform: translateX(-100%);
    transition: transform $transition-duration ease-in-out;
  }
  &:hover {
    color: $hover-color;
    &::before {
      transform: translateX(0);
    }
    .back-button__icon {
      transform: translateX(-4px);
    }
  }
  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }
  &:active {
    transform: scale(0.98);
  }
}
