.checkout-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 20px;

  .new-progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: #e0e0e0;
      z-index: 1;
    }

    .progress {
      position: absolute;
      top: 15px;
      left: 0;
      height: 4px;
      background-color: #4caf50;
      z-index: 2;
      transition: width 0.3s ease;
    }

    .step-indicator {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 3;

      .step-number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #e0e0e0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .step-name {
        font-size: 12px;
        color: #666;
      }

      &.active-step {
        .step-number {
          background-color: #4caf50;
        }

        .step-name {
          color: #4caf50;
          font-weight: bold;
        }
      }
    }
  }

  .step-content {
    display: flex;
    gap: 30px;

    .personal-info {
      flex: 2;

      h2,
      h3 {
        margin-bottom: 20px;
      }

      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 15px;
      }

      .form-group {
        flex: 1;
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }
      }
    }

    .checkout-cart-summary {
      flex: 1;
      background-color: #f8f9fa;
      border-radius: 8px;
      padding: 20px;
      align-self: flex-start;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .checkout-cart-summary-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
        }

        .edit-cart-btn {
          background-color: #007bff;
          color: #fff;
          padding: 5px 10px;
          text-decoration: none;
          border-radius: 4px;
          transition: background-color 0.3s ease;
        }
      }

      .checkout-cart-item {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border: 1px solid #d3d3d3;
        border-radius: 8px;
        padding: 10px;

        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }

        .checkout-cart-item-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 5px;

          div {
            display: flex;
            gap: 10px;
          }

          h4 {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    &.payment-step {
      flex-direction: column;

      .payment-tabs {
        display: flex;
        margin-bottom: 20px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .payment-tab {
          flex: 1;
          padding: 15px;
          font-size: 16px;
          border: none;
          background-color: #f0f0f0;
          cursor: pointer;
          transition: all 0.3s ease;

          &.active {
            background-color: #4caf50;
            color: white;
          }

          &:hover:not(.active) {
            background-color: #e0e0e0;
          }
        }
      }

      .billing-info {
        background-color: #f8f9fa;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        flex: 2;

        .billing-info-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          h2 {
            margin-bottom: 15px;
          }

          .fill-shipping-btn {
            background-color: #007bff;
            color: white;
            border: none;
            padding: 10px 15px;
            border-radius: 4px;
            cursor: pointer;
            margin-bottom: 20px;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #0056b3;
            }
          }
        }

        h3 {
          margin-bottom: 20px;
        }

        .form-row {
          display: flex;
          gap: 20px;
          margin-bottom: 15px;
        }

        .form-group {
          flex: 1;
          margin-bottom: 15px;

          label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            letter-spacing: 0.5px;
          }

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }
        }
      }

      .online-payment-info {
        background-color: #f8f9fa;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        text-align: center;

        h3 {
          margin-bottom: 15px;
          color: #4caf50;
        }

        p {
          font-size: 16px;
          color: #666;
        }
      }
    }

    &.order-review {
      display: flex;
      gap: 30px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .order-details {
        flex: 2;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        h2 {
          margin-bottom: 20px;
          color: #333;
        }

        .address-section {
          margin-bottom: 20px;
          padding: 15px;
          background-color: #f8f9fa;
          border-radius: 6px;

          h3 {
            margin-bottom: 10px;
            color: #4caf50;
          }

          p {
            margin: 5px 0;
            color: #666;
          }
        }

        .cart-items {
          h3 {
            margin-bottom: 15px;
            color: #4caf50;
          }

          .cart-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 10px;
            background-color: #f8f9fa;
            border-radius: 6px;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 4px;
              margin-right: 15px;
            }

            .item-details {
              h4 {
                margin-bottom: 5px;
                color: #333;
              }

              p {
                margin: 3px 0;
                color: #666;
              }
            }
          }
        }
      }

      .order-summary {
        flex: 1;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        align-self: flex-start;
        position: sticky;
        top: 20px;

        h3 {
          margin-bottom: 20px;
          color: #333;
        }

        .summary-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          color: #666;

          &.total {
            font-weight: bold;
            font-size: 1.2em;
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid #e0e0e0;
            color: #333;
          }
        }

        .place-order-btn {
          width: 100%;
          padding: 15px;
          margin-top: 20px;
          background-color: #4caf50;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1.1em;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #45a049;
          }
        }
      }
    }

    &.order-confirmation {
      background-color: #ffffff;
      border-radius: 16px;
      padding: 40px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .confirmation-header {
        text-align: center;
        margin-bottom: 40px;
        position: relative;

        .check-icon {
          font-size: 80px;
          color: #4caf50;
          margin-bottom: 20px;
          animation: scaleIn 3s ease-out infinite;
        }

        h2 {
          font-size: 32px;
          color: #2c3e50;
          margin-bottom: 10px;
          font-weight: 600;
        }

        p {
          font-size: 18px;
          color: #7f8c8d;
          background-color: #ecf0f1;
          display: inline-block;
          padding: 8px 16px;
          border-radius: 20px;
        }
      }

      .order-details {
        margin-bottom: 40px;

        h3 {
          font-size: 24px;
          color: #2c3e50;
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 2px solid #ecf0f1;
          position: relative;
        }

        .details-flex {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          .detail-item {
            flex: 1 1 calc(50% - 15px);
            min-width: 200px;
            background-color: #f9f9f9;
            border-radius: 12px;
            padding: 24px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;

            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
            }

            h4 {
              font-size: 18px;
              color: #34495e;
              margin-bottom: 12px;
              font-weight: 600;
            }

            p {
              font-size: 16px;
              color: #7f8c8d;
              margin: 8px 0;
              line-height: 1.5;
            }
          }
        }
      }

      .confirmation-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        .print-receipt-btn,
        .continue-shopping-btn {
          padding: 14px 28px;
          font-size: 16px;
          font-weight: 600;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          gap: 10px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }

        .print-receipt-btn {
          background-color: #ecf0f1;
          color: #34495e;
          border: none;

          &:hover {
            background-color: #bdc3c7;
          }
        }

        .continue-shopping-btn {
          background-color: #4caf50;
          color: white;
          text-decoration: none;

          &:hover {
            background-color: #45a049;
            transform: translateY(-2px);
          }
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .back-btn {
      background-color: #6c757d;
      color: #fff;

      &:hover:not(:disabled) {
        background-color: #5a6268;
      }
    }

    .next-btn {
      background-color: #28a745;
      color: #fff;

      &:hover:not(:disabled) {
        background-color: #218838;
      }
    }
  }
}

@media (max-width: 768px) {
  .checkout-container {
    .progress-bar {
      margin-bottom: 80px;

      .step-indicator {
        width: 24px;
        height: 24px;

        .step-number {
          font-size: 12px;
        }

        .step-name {
          font-size: 10px !important;
        }
      }
    }

    .step-content {
      flex-direction: column;

      .personal-info {
        .form-row {
          flex-direction: column;
          gap: 15px;
        }
      }

      .cart-summary {
        position: static;
        margin-top: 20px;
      }
    }
  }
}

@keyframes scaleIn {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
