.Education-One-Testimonials{
      padding-bottom:50px;
    .Education-One-Testimonials-img{
      background-repeat:no-repeat;
      background-size:cover;
    }
    .Education-One-Testimonials-section_header{
    
        padding-bottom:45px;
        .Education-One-Testimonials-title{
            font-size:35px;
            color:#264D8C;
            text-align:center;
            
        }
        p{
            text-align:center;
            font-size:25px;
            color:#264D8C;
        }
    }
    .Education-One-Testimonials-buttonclass{
        text-align:center;
        
        .Education-One-Testimonials-button{
            text-align:center;
            border-radius:8px;
            padding:10px;
            font-size:18px;
            
            color:#2b989f;
            background:none;
            text-transform:uppercase;
            font-weight:bold;
            border:none;
            cursor:pointer;
            position:relative;
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0; /* Position the line just beneath the button */
                transform: translateX(-50%);
                width: 0; /* Initially no line is visible */
                height: 2px; /* Height of the line */
                background-color: #007BFF; /* Initial color of the line */
                transition: width 0.3s ease, background-color 0.3s ease;
              }
            
              &:hover::after {
                width: 100%; /* Expand the line to full width on hover */
                background-color: #007BFF; /* Change line color on hover if needed */
              }
            
              &:hover {
                color: #007BFF; /* Optional: Change text color on hover */
              }
            }
            
        }

  
      .Education-One-Testimonials-event-container {
        .Education-One-Testimonials-event-card {
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          width: 100%;
    }
  }
  
  
          .Education-One-Testimonials-image-container {
            position: relative;
  
            .Education-One-Testimonials-event-image {
              width: 100%;
              height: auto;
              aspect-ratio: 3 / 1.5;
              object-fit: cover;
              border-radius: 8px 8px 0 0;
            
            

            }
  
            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color:white;
              text-align:center;
        
              padding: 5px 10px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
             background-color:#264D8C;
                
                cursor:pointer;
            }
        }
      }