.education_two {
  .et-blog {
    background: #f2f2f2;

    .et_blog_card {
      display: grid;
      grid-template-columns: repeat(1, 1fr); // Default to one card per row (0 to 320px)
      gap: 25px;
      margin-top: 50px;

      .blog_card_single {
        background: white;
        border-radius: 10px;
        padding: 20px;

        img {
          width: 100%;
          border-radius: 10px;
          aspect-ratio: 3/2;
        }

        .content_area {
          .other_info {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 15px;
            padding-left: 0px;

            .oi_single {
              color: #4d5756;
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                width: 23px;
              }

              .text {
                font-size: 17px;
                font-family: regular;
              }
            }
          }

          .title {
            font-family: regular;
            font-size: 18px;
            line-height: 1.5;
            color: var(--heading-color);
            font-weight: 600;
            text-transform: capitalize;
            letter-spacing: 0.5px;
            padding-bottom: 16px;
          }
        }
      }
    }

    
  // Media query for screens 321px to 380px (small mobile devices)
  @media (min-width: 321px) and (max-width: 380px) {
    .et_blog_card {
      grid-template-columns: repeat(1, 1fr); // One card per row
    }

    .blog_card_single {
      padding: 15px; // Adjust padding for small screens
      .title {
        font-size: 16px; // Adjust font size for smaller screens
      }
      .content_area {
        .other_info {
          gap: 30px; // Reduce the gap between elements
          .oi_single .text {
            font-size: 14px; // Smaller text for additional info
          }
        }
      }
    }
  }

  // Media query for screens 321px to 767px (small to medium mobile devices)
  @media (min-width: 321px) and (max-width: 767px) {
    .et_blog_card {
      grid-template-columns: repeat(1, 1fr); // Still one card per row
    }

    .blog_card_single {
       // Adjust padding
      .title {
        font-size: 17px; // Slightly larger font
      }
      .content_area {
        .other_info {
           // Adjust gap for medium-sized screens
          .oi_single .text {
            font-size: 15px; // Medium text size for additional info
          }
        }
      }
    }
  }

  // Media query for screens 768px to 999px (tablet to smaller desktops)
  @media (min-width: 768px) and (max-width: 999px) {
    .et_blog_card {
      grid-template-columns: repeat(2, 1fr); // Two cards per row
    }

    .blog_card_single {
      padding: 20px;
      .title {
        font-size: 18px;
      }
      .content_area {
        .other_info {
          display: block; // Remove flex for screens below 1000px
          gap: 45px; 
          .oi_single .text {
            font-size: 16px;
          }
        }
      }
    }
  }

  // Media query for screens 1000px and above
  @media (min-width: 1000px) {
    .et_blog_card {
      grid-template-columns: repeat(3, 1fr) !important; // Three cards per row
    }

    .blog_card_single {
      padding: 25px;
      .title {
        font-size: 18px; // Font size for larger screens
      }
      .content_area {
        .other_info {
          display: flex; // Flex layout for larger screens
          gap: 65px;
          .oi_single .text {
            font-size: 17px;
          }
        }
      }
    }
  }


  }
}