.order-summary-page {
    margin: 0 auto;
    padding: 40px;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #fff;

    .thank-you-message {
        text-align: center;
        margin-bottom: 40px;
        position: relative;

        .material-symbols-outlined {
            font-size: 80px;
            color: #4CAF50;
            margin-bottom: 20px;
            animation: scaleIn 3s ease-out infinite;
        }

        h2 {
            font-size: 32px;
            color: #2c3e50;
            margin-bottom: 10px;
            font-weight: 600;
        }

        p {
            font-size: 18px;
            color: #7f8c8d;
            background-color: #ecf0f1;
            display: inline-block;
            padding: 8px 16px;
            border-radius: 20px;
        }
    }

    .order-details {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 40px;

        >div {
            flex: 1 1 calc(50% - 15px);
            min-width: 200px;
            background-color: #f9f9f9;
            border-radius: 12px;
            padding: 24px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;

            h4 {
                font-size: 18px;
                color: #34495e;
                margin-bottom: 12px;
                font-weight: 600;
            }

            p {
                font-size: 18px;
                color: #7f8c8d;
                line-height: 1.5;
            }

            .order-address-type {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .order-address {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .order-items {
        margin-bottom: 40px;

        h2 {
            font-size: 24px;
            color: #2c3e50;
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 2px solid #ecf0f1;
        }

        .order-items-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .order-item {
                display: flex;
                align-items: center;
                background-color: #f9f9f9;
                border-radius: 12px;
                overflow: hidden;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
                transition: all 0.3s ease;
                padding: 0 20px;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }

                .order-item-details {
                    flex-grow: 1;
                    padding: 20px;

                    h3 {
                        font-size: 18px;
                        color: #34495e;
                        margin-bottom: 10px;
                        font-weight: 600;
                    }

                    p {
                        font-size: 16px;
                        color: #7f8c8d;
                        margin: 5px 0;
                    }
                }
            }
        }
    }

    .view-orders-button {
        display: inline-block;
        padding: 14px 28px;
        font-size: 16px;
        font-weight: 600;
        background-color: var(--click-color);
        color: white;
        text-decoration: none;
        border-radius: 8px;
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:hover {
            transform: translateY(-2px);
        }
    }

    .loading,
    .error {
        text-align: center;
        font-size: 18px;
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    }

    .error {
        color: #d32f2f;
    }
}

@keyframes scaleIn {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

@media (max-width: 768px) {
    .order-summary-page {
        padding: 20px;

        .details-flex {
            flex-direction: column;
        }
    }
}