.education_two{
    .et_discover{
        background-color:#f1ebeb;

        .et_dis_relative{
         position:relative;}
    
        .et_discover-inner__shape-1{
        
          position:absolute;
          left:111px;
            animation:updown 0.8s infinte alternate;
        }
        .et_discover-inner__shape-2{
        
            position:absolute;
           left:350px;
          }
    

        
        .et_discover_header{
            text-align:center;
            .et_dis_title{
                font-family:regular;
                font-size:14px;
                line-height:32px;
                height:32;
                font-weight:400;
                margin-bottom:25px;
                display:inline-block;
                text-transform:uppercase;
                border-radius:5px;
                background-color:var(--logo-secondary-color);
                color:green;
                text-align:center;

                padding:0 22px;

            }

            .realtive{
                position:realtive;

            }
            .title-shape{
                position:absolute;
                bottom:56px;
                left:-32px;
            }
            h2.et_dis_content{
                
                font-family:regular;
                font-size:42px;
                font-weight:700;
                text-transform:capitalize;
                

            }

        }
        .et_cards_inner{
            display:flex;
            background-color:grey;
        }
    }
}