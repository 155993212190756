.home_hero_banners_parent {
    padding: 0px 0px 0px 0px;
    /* border: 1px solid #dcdcdc; */
}

.home_baneer_img {
    aspect-ratio: 3/1.20;
    /* height: 220px; */
    object-fit: cover;
    width: 100%;
    /* position: relative; */
}

.active_inactive_home_banner {
    background-color: white;
    border: 1px solid #dcdcdc;
    width: 100%;
    padding: 2px 0px 6px 14px;
    margin-top: -5px;
}

.products-status-switch {
    cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
    display: none;
}

span.swiper-pagination-bullet {
    background: var(--dark-blue);
    width: 12px;
    height: 12px;
}

.s_home_services .offers-section-title {
    padding: 0px;
    border-radius: 0px;
    background: none;
    padding-top: 30px;
}

@media only screen and (max-width: 767px) {
    .home_baneer_img {
        aspect-ratio: unset;
        height: 220px;
        object-fit: cover;
        width: 100%;
    }
}

@media (min-width: 575px) and (max-width: 991px) {
    .home_baneer_img {
        aspect-ratio: 3/1.5;
        /* height: 220px; */
        object-fit: cover;
        width: 100%;
    }
}