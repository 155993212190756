/* Document Card Styles */
.pd-document-card {
  border: 1px solid #b0b0b0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 5px;
  gap: 40px;
  box-shadow: 0 0 2px rgb(195, 195, 195);
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }

  .document-file {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    gap: 40px;
    .document-file-data {
      display: flex;
      gap: 20px;
      .document-file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .material-symbols-outlined {
          font-size: 25px;
          color: #404040;
        }
      }

      .document-file-name {
        display: flex;
        flex-direction: column;
        gap: 4px;
        min-width: 0;

        h3 {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          font-size: 12px;
          color: #808080;
          font-weight: 200;
        }
      }
    }
  }

  .uploaded-status {
    font-size: 10px;
    border: 1px solid;
    padding: 4px 10px;
    border-radius: 20px;
    white-space: nowrap;
    font-weight: 500;

    &.pending-uploaded {
      border-color: #facc15;
      color: #b45309;
      background-color: #fef3c7;
    }

    &.success-uploaded {
      border-color: #16a34a;
      color: #065f46;
      background-color: #d1fae5;
    }

    &.error-uploaded {
      border-color: #dc2626;
      color: #991b1b;
      background-color: #fee2e2;
    }
  }

  .document-option {
    display: flex;
    gap: 10px;

    .document-preview,
    .document-delete {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 8px;
      border-radius: 5px;
      transition: background-color 0.2s ease, transform 0.2s ease;

      &:hover {
        background-color: #ececec;
      }

      &:active {
        transform: scale(0.95);
      }

      span {
        font-size: 22px;
      }
    }

    .document-delete span {
      color: #dc2626;
    }
  }

  .document-upload {
    padding: 4px 10px;
    font-size: 14px;
    border: 0.5px solid #b0b0b0;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;

    &:hover {
      background-color: #ececec;
    }

    &:active {
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  @media screen and (max-width: 640px) {
    .pd-document-card {
      align-items: stretch;
      gap: 16px;
      padding: 12px;
    }

    .document-file {
      width: 100%;
      gap: 0px;
      .document-file-icon {
        align-self: flex-center;
        .material-symbols-outlined {
          font-size: 20px;
          color: #404040;
        }
      }
      .document-file-data {
        gap: 10px;
        .document-file-icon {
          .material-symbols-outlined {
            font-size: 18px;
          }
        }
        .document-file-name {
          margin-right: 10px;
          h3 {
            font-size: 10px;
            overflow: visible;
          }
          span {
            font-size: 8px;
            white-space: nowrap;
          }
        }
      }
    }
    .uploaded-status {
      font-size: 8px;
      padding: 2px 6px;
    }

    .document-option {
      justify-content: flex-end;
      // gap: 2px;
      .document-preview,
      .document-delete {
        .material-symbols-outlined {
          font-size: 18px;
        }
      }
    }
    .document-upload {
      font-size: 10px;
    }
  }
}

.upload-modal {
  .modal-content {
    padding: 20px;

    .upload-modal-header {
      display: flex;
      justify-content: center;
      align-items: self-start;
      border: none;

      div {
        width: 100%;
        text-align: center;

        h3 {
          font-size: 18px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          font-weight: 300;
          color: #888888;
        }
      }
    }

    .upload-modal-body {
      border: 2px dashed #0091ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      flex-direction: column;
      padding: 80px;
      gap: 30px;

      .upload-icon {
        padding: 8px;
        border-radius: 50%;
        background-color: #dce9fe;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-symbols-outlined {
          font-size: 30px;
          color: #386bdd;
          text-align: center;
        }
      }

      .upload-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .upload-text-1 {
          font-size: 14px;
          letter-spacing: 1px;
          color: #ccd0d6;
          font-weight: 500;
          text-align: center;
        }

        .upload-text-2 {
          font-size: 12px;
          color: #b9b9b9;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }

    .upload-progress {
      text-align: center;
      font-size: 16px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      gap: 10px;

      p {
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 5px;

        .material-symbols-outlined {
          font-size: 20px;
          padding-top: 4px;
        }
      }

      .progress-bar {
        width: 100%;
        height: 8px;
        background: #ddd;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 5px;

        .progress-fill {
          height: 100%;
          background: #007bff;
          transition: width 0.3s ease-in-out;
        }
      }
    }

    .upload-close-btn {
      width: 100%;
      padding: 10px 15px;
      background-color: #49c8ff;
      color: #f6f6f6;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      text-align: center;
    }
  }
  .image_container_inner {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 400px;
      border-radius: 6px;
      object-fit: contain;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 6px;
    }

    .document-preview {
      width: 100%;
      aspect-ratio: 3/2;
    }
  }

  /* Responsive Design */
  // @media screen and (max-width: 1024px) {
  //   .upload-modal {
  //     .modal-content {
  //       padding: 15px;
  //     }

  //     .upload-modal-body {
  //       padding: 60px;
  //       gap: 20px;

  //       .upload-icon {
  //         width: 45px;
  //         height: 45px;
  //         font-size: 28px;
  //       }
  //     }
  //   }
  // }

  @media screen and (max-width: 768px) {
    .upload-modal {
      .modal-content {
        padding: 10px;
        .upload-modal-header {
          div {
            h3 {
              font-size: 14px;
            }

            p {
              font-size: 10px;
            }
          }
        }
      }

      .upload-modal-body {
        padding: 40px;
        gap: 15px;

        .upload-icon {
          width: 40px;
          height: 40px;
        }

        .upload-info {
          .upload-text-1 {
            font-size: 12px;
          }

          .upload-text-2 {
            font-size: 10px;
          }
        }
      }
    }

    .image_container_inner {
      padding: 5px;

      img {
        max-height: 300px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .upload-modal {
      .modal-content {
        padding: 5px;
      }

      .upload-modal-header {
        div {
          h3 {
            font-size: 14px;
          }

          p {
            font-size: 10px;
          }
        }
      }

      .upload-modal-body {
        padding: 30px;
        gap: 10px;

        .upload-icon {
          width: 35px;
          height: 35px;
        }

        .upload-info {
          .upload-text-1 {
            font-size: 10px;
          }

          .upload-text-2 {
            font-size: 9px;
          }
        }
      }

      .upload-close-btn {
        font-size: 14px;
        padding: 8px;
      }
    }

    .image_container_inner {
      img {
        max-height: 250px;
      }
    }
  }
}
