.corporateTwo-order-stats-container {
    padding: 1rem;

    .stats-card {
        background: white;
        border-radius: 15px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        padding: 2rem;
        transition: transform 0.3s ease;
        position: relative;
        overflow: hidden;


        // Background decorative icons
        .background-icons {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 1;

            .bg-stats-icon {
                position: absolute;
                font-size: 8rem;
                opacity: 0.3;
                transition: transform 0.3s ease;
                color: var(--click-color);
                font-variation-settings:
                    'FILL' 1,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
                width: 8rem;
                height: 8rem;

                &.icon-left {
                    bottom: -2rem;
                    left: -2rem;
                    transform: rotate(-15deg);
                    fill: currentColor;
                }

                &.icon-right {
                    top: -2rem;
                    right: -2rem;
                    transform: rotate(15deg);
                }
            }
        }

        .stats-header {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1.5rem;
            position: relative;
            z-index: 2;

            .header-icon {
                font-size: 2.5rem;
                color: var(--click-color);
                font-variation-settings:
                    'FILL' 1,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
            }

            h2 {
                color: #333;
                font-size: 1.5rem;
                font-weight: 600;
                margin: 0;
            }
        }

        .stats-content {
            text-align: center;
            position: relative;
            z-index: 2;

            .stats-number {
                h3 {
                    font-size: 3.5rem;
                    color: var(--click-color);
                    margin: 1rem 0 0.5rem;
                    font-weight: 700;
                }

                p {
                    color: #666;
                    margin: 0;
                    font-size: 1.1rem;
                }
            }

            

            .stats-button {
                background: var(--click-color);
                color: white;
                border: none;
                padding: 0.8rem 2rem;
                border-radius: 25px;
                font-size: 1rem;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                margin: 10px auto;

                .material-symbols-outlined {
                    font-size: 1.2rem;
                    font-variation-settings:
                        'FILL' 1,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 48;
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

// Add responsive design
@media (max-width: 768px) {
    .corporateTwo-order-stats-container {
        padding: 1rem;

        .stats-card {
            padding: 1.5rem;

            .background-icons {
                .bg-stats-icon {
                    font-size: 8rem;
                }
            }

            .stats-header {
                .header-icon {
                    font-size: 2rem;
                }

                h2 {
                    font-size: 1.2rem;
                }
            }

            .stats-content {
                .stats-number {
                    h3 {
                        font-size: 2.5rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }

                .stats-icons .material-symbols-outlined {
                    font-size: 1.8rem;
                }
            }
        }
    }
}