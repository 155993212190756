.top-selling-products-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(206, 201, 201);

  .left-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    position: relative;
    background-color: var(--click-color);
    color: white;
    padding: 6px 4px;

    .rotated-text {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .top-selling-products {
    width: 100%; // Adjust as needed
    overflow: hidden; // Prevent horizontal scrolling

    .owl-carousel-wrapper {
      .owl-stage {
        display: flex;
        align-items: center;
      }

      .top-selling-product-item {
        width: 90%;
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: white;

        .top-selling-product-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          margin-right: 16px;
          border-radius: 4px;
        }

        .top-selling-product-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .top-selling-product-name {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
          }

          .top-selling-product-rating {
            font-size: 14px;
            color: #888;
            margin: 4px 0 0 0;
          }
        }
      }
    }
  }
}
