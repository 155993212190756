.smart-wash-home-page{
    position: relative;

    .schedule-pickup-btn{
        position: fixed;
        right: 0;
        bottom: 50%;
        z-index: 10000;
        ;

        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding: 10px 15px;
            width: 120px;
            background-color: var(--click-color);
            color: white;
            border: none;
            border-radius: 10px 0 0 10px;
            cursor: pointer;
            font-size: 16px;

            .material-symbols-outlined {
                margin-right: 8px;
            }
        }
    }

    .schedule-pickup-btn-mobile-view{
        display: none;
    }
}

@media (max-width:990px){
    .smart-wash-home-page{
        .schedule-pickup-btn{
            display: none;
        }
    
        .schedule-pickup-btn-mobile-view{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px auto 10px auto;
    
            button{
                padding: 10px 20px;
                background-color: var(--logo-primary-color);
                color: var(--click-color);
                border-radius: 30px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                min-width: 230px;
                font-size: 1.5rem;
                border: none;
                outline: none;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
            }
        }
    }
    
}