.create_ticket_top_header_pg {
    padding: 20px;
    background-color: #f9f9f9;
    
    .create_ticket_right_main_form {
      background-size: auto;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .create_ticket_rmf_inner {
        background-color: rgba(255, 255, 255, 0.95);
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        max-width: 600px;
        width: 100%;
        
        .create_ticket_form-w {
          .create_ticket_title {
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            color: #333;
            margin-bottom: 20px;
          }
  
          .create_ticket_fl_form_field {
            margin-bottom: 15px;
  
            label {
              font-size: 16px;
              font-weight: 500;
              color: #555;
              display: block;
              margin-bottom: 5px;
            }
  
            select {
              width: 100%;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 4px;
              font-size: 14px;
              color: #333;
              background-color: #fff;
              transition: border-color 0.3s;
  
              &:focus {
                border-color: #007bff;
                outline: none;
              }
            }
          }
  
          .create_ticket_form_field {
            margin-bottom: 15px;
  
            .create_ticket_field_inner {
              position: relative;
  
              textarea {
                width: 100%;
                padding: 15px;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 14px;
                color: #333;
                resize: none;
                height: 120px;
                transition: border-color 0.3s;
  
                &:focus {
                  border-color: #007bff;
                  outline: none;
                }
              }
  
              .create_ticket_field_icon {
                position: absolute;
                top: 10px;
                right: 10px;
                color: #bbb;
                font-size: 20px;
              }
            }
          }
  
          .create_ticket_submit_btn {
            text-align: center;
            margin-top: 20px;
          }
  
        }
      }
    }
  }
  