// // Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.pd-emp_doc {
  padding: 15px 0 0 8px;
  gap: 30px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: $breakpoint-sm) {
    padding: 10px 0 0 0;
    width: 100%;
  }
  .pd-document {
    display: grid;
    align-items: center;
    // grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
}
