.business-three-download-app {
  display: flex;
  height: 200vh;
  position: relative;
  background-color: rgb(225, 240, 239);

  .business-three-data-left,
  .business-three-data-right {
    width: 50%;
  }

  .business-three-data-left {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .business-three-heading {
    z-index: 1;
    height: 40vh;
    background-color: rgb(225, 240, 239);
    display: flex;
    justify-content: center;
    position: sticky;
    /* Change to sticky */
    top: 45px;
    /* Adjust as needed */
    width: 100%;
    /* Adjust width as needed */
    padding: 50px;
    transition: top 0.3s ease;

    /* Smooth transition */
    h4 {
      font-size: 3rem;
      font-weight: 700;
      font-family: system-ui;
      color: #4b4b4b;

      span {
        color: var(--heading-color);
      }
    }
  }

  .business-three-subheading-area {
    z-index: 0;
    position: relative;
    padding: 50px;

    p {
      font-size: 1.5rem;

      &::after {
        content: "";
        display: block;
        width: 15%;
        /* Adjust the width as needed */
        border-bottom: 4px solid var(--click-color);
        /* Line below the <p> */
        margin-top: 0.5rem;
        /* Adjust the space between text and line as needed */
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    img {
      margin-top: 50px;
      width: 70%;
      height: auto;
    }
  }

  .business-three-subheading-area-2 {
    z-index: 0;
    position: relative;
    margin-top: 5rem;
    padding: 50px;

    p {
      font-size: 1.5rem;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 15%;
        /* Adjust the width as needed */
        border-bottom: 4px solid var(--click-color);
        /* Line below the <p> */
        margin-top: 0.5rem;
        /* Adjust the space between text and line as needed */
      }
    }

    h6 {
      font-size: 1.3rem;
    }

    img {
      margin-top: 40px;
      width: 70%;
      height: auto;
    }
  }

  .business-three-subheading-area-3 {
    z-index: 0;
    position: relative;
    margin-top: 5rem;
    padding: 50px;

    p {
      font-size: 1.5rem;

      &::after {
        content: "";
        display: block;
        width: 15%;
        /* Adjust the width as needed */
        border-bottom: 4px solid var(--click-color);
        /* Line below the <p> */
        margin-top: 0.5rem;
        /* Adjust the space between text and line as needed */
      }
    }

    img {
      margin-top: 40px;
      width: 70%;
      height: auto;
    }
  }

  .business-three-data-right {
    // background-color: lightcoral;
    display: flex;
    justify-content: center;
    position: sticky;
    /* Change to sticky */
    top: 70px;
    /* Adjust as needed */
    right: 0;
    width: 50%;
    /* Adjust width as needed */
    height: 100vh;
    /* Adjust height as needed */
    overflow: hidden;
    /* Hide overflow */
  }

  .business-three-data-right {
    :nth-of-type(1) {
      position: absolute;
      /* Ensure positioning */
      margin-top: 40px;
      transition: opacity 0.3s ease;
      border: 10px solid black;
      width: auto;
      height: 80%;
      /* Adjust to fit the container */
      // object-fit: cover;
      border: 10px solid black;
      border-radius: 20px;
      position: absolute;
      /* Ensure positioning */
      margin-top: 40px;
      transition: opacity 0.3s ease;
    }

    :nth-of-type(2) {
      position: absolute;
      /* Ensure positioning */
      transition: opacity 0.3s ease;
      border-radius: 5px;
      width: 95%;
      height: auto;
      /* Adjust to fit the container */
      object-fit: cover;
      border: 10px solid black;
      border-radius: 20px;
      position: absolute;
      /* Ensure positioning */
      margin-top: 20%;
      transition: opacity 0.3s ease;
    }

    :nth-of-type(3) {
      position: absolute;
      /* Ensure positioning */
      transition: opacity 0.3s ease;
      border-radius: 5px;
      width: 60%;
      height: auto;
      /* Adjust to fit the container */
      object-fit: cover;
      border: 10px solid black;
      border-radius: 20px;
      position: absolute;
      /* Ensure positioning */
      margin-top: 5%;
      transition: opacity 0.3s ease;
    }
  }

  .business-three-image {
    opacity: 0;
    /* Default state is hidden */
    transform: perspective(1000px) rotateY(0deg) scale(0.8);
    /* Start with 3D perspective, no rotation, and scaled down */
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
    /* Smooth transitions for opacity and 3D transformation */

    &.show {
      opacity: 1;
      /* Fully visible */
      transform: perspective(1000px) rotateY(0deg) scale(1);
      /* Full size with no 3D rotation */
      animation: rotate3D 1s forwards;
      /* Apply 3D rotation animation */
    }

    &.hide {
      opacity: 0;
      /* Fully transparent */
      transform: perspective(1000px) rotateY(90deg) scale(0.8);
      /* Start with rotated 90 degrees in Y-axis */
    }
  }

  @media (max-width: 767px) {
    height: auto;


    .business-three-heading {
      display: none;
    }

    .business-three-data-left {
      width: 100%;
    }

    .business-three-subheading-area,
    .business-three-subheading-area-2,
    .business-three-subheading-area-3 {
      img {
        margin-top: 20px;
        width: 100%;
      }

    }

    .business-three-data-right {
      display: none;
    }

    .business-three-subheading-area,
    .business-three-subheading-area-2,
    .business-three-subheading-area-3 {
      padding: 20px;
    }

    .business-three-subheading-area-2 {
      margin-top: 1rem;
    }

    .business-three-subheading-area-3 {
      margin-top: 1rem;
    }
  }

  @media (min-width: 1000px) and (max-width: 1300px) {
    .business-three-subheading-area-2 {
      margin-top: -3rem;
    }

    .business-three-subheading-area-3 {
      margin-top: -3rem;
    }
  }

  @keyframes rotate3D {
    0% {
      transform: perspective(1000px) rotateY(90deg) scale(0.8);
      /* Start with rotated and scaled down */
    }

    100% {
      transform: perspective(1000px) rotateY(0deg) scale(1);
      /* End with no rotation and full size */
    }
  }



}