.ecom_two{
    .Footer-events{
        background:#1c1c3f;
        padding-bottom:20px;
        padding-top:10px;
            .footer_header{
                display:flex;}
                h2.title_footer{
                    color:white;
                    font-size:20px;
                    margin-bottom:0;
                    padding-top:0;
                }
                p.para_card_para{
                    color:white;
                    font-size:40px;
                    margin-top:0;
                    padding-top:0;
                }
        }
        .footer_define{
            margin-top:40px;
            margin_bottom:15px;
            color:white;
            font-size:14px;
        }
            .second_inner{
            .title{
                margin-top:20px;
                color:white;
                font-weight:bold;
                font-size:20px;
            }
            p.def{
                color:white;
            }
        }
        .btn_footer{

            margin-top:35px;
            button.btn_item {
                /* border-radius: 30px; */
                margin: 16px;
                border: none;
                background: none;
                color: white;
                /* font-size: 25px; */
                font-weight: bold;
            }
            
            
        }
    }
    @media (max-width: 320px) {
        .footer_header {
          h2.title_footer {
            font-size: 16px; /* Smaller font size for small screens */
          }
  
          p.left {
            font-size: 24px;
            color:white; /* Smaller font size for small screens */
          }
        }
  
        .footer_define {
          font-size: 12px; /* Smaller font size for small screens */
        }
  
        .second_inner {
          .title {
            font-size: 18px; /* Smaller font size for small screens */
          }
        }
  
        .btn_footer {
          button.btn_item {
            font-size: 14px; /* Smaller font size for small screens */
            padding: 8px 16px; /* Adjust padding for small screens */
          }
        }
      }
  
      @media (min-width: 321px) and (max-width: 567px) {
        .footer_header {
          h2.title_footer {
            font-size: 18px; /* Slightly smaller font size */
          }
  
          p.left {
            font-size: 30px; /* Slightly smaller font size */
          }
        }
  
        .footer_define {
          font-size: 13px; /* Slightly larger font size */
        }
  
        .second_inner {
          .title {
            font-size: 19px; /* Slightly smaller font size */
          }
        }
  
        .btn_footer {
          button.btn_item {
            font-size: 10px; /* Adjust font size for medium screens */
            padding: 2px 2px; /* Adjust padding for medium screens */
          }
        }
      }
  
      @media (min-width: 568px) {
        .footer_header {
          flex-direction: row; /* Align items horizontally on larger screens */
          justify-content: space-between; /* Space out items */
          text-align: left; /* Align text to the left for larger screens */
  
          h2.title_footer {
            font-size: 20px; /* Default font size */
          }
  
          p.left {
            font-size: 40px; /* Default font size */
          }
        }
  
        .footer_define {
          font-size: 14px; /* Default font size */
        }
  
        .second_inner {
          .title {
            font-size: 20px; /* Default font size */
          }
        }
  
        .btn_footer {
          button.btn_item {
            font-size: 10px; /* Default font size */
            padding: 10px 20px; /* Default padding */
          }
        }
      }
    
  
