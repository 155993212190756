.happyerp-sales_pg {
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  .happyerp-active {
    background: var(--theme-blue);
    color: #fff;
  }
  .happyerp-add_button {
    transition: 0.8s;
    background: var(--theme-orange);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: var(--white-color);
    cursor: pointer;
    position: fixed;
    bottom: 105px;
    right: 45px;
    z-index: 15;

    span {
      font-size: 32px;
    }
  }
  .happyerp-first_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
  }
  .happyerp-second_row {
    display: flex;
    justify-content: left;
    .happyerp-left {
      align-items: center;
      display: flex;
      gap: 15px;
    }
    .happyerp-center_ba {
      align-items: center;
      margin-right: 0;
      // width: 60%;
      padding: 5px 10px;
      display: flex;
    }
    .happyerp-right {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .happyerp-form_field {
      margin: 0;
      .happyerp-field_box.happyerp-search_bar {
        width: 230px;
        position: relative;
        input {
          padding-right: 40px;
        }
        .happyerp-field_icon {
          align-items: center;
          display: flex;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 35px;
          .material-symbols-outlined {
            color: var(--theme-grey);
            font-size: 22px;
            line-height: 0px;
          }
        }
      }
    }
  }
}
.happyerp-add_order_pg {
  display: flex;
  position: relative;
  .happyerp-form_area {
    width: 69%;
    margin-right: auto;
  }
  .happyerp-main_area_left {
    width: 72%;
    margin-right: auto;
    margin-top: -130px;
    margin-left: -25px;
    padding-left: 0;
  }
  .happyerp-order_summary {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;

    width: 30%;
    position: fixed;
    top: 82px;
    right: 0;
    height: calc(100vh - 82px);
  }
}
/* For Webkit-based browsers (Chrome, Edge, Safari) */
.happyerp-order_summary::-webkit-scrollbar {
  width: 1px; /* Scrollbar width */
}

.happyerp-order_summary::-webkit-scrollbar-thumb {
  background: var(--theme-lightmidnight-blue); /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for thumb */
}

.happyerp-order_summary::-webkit-scrollbar-thumb:hover {
  background: var(--theme-lightmidnight-blue); /* Thumb color on hover */
}

.happyerp-order_summary::-webkit-scrollbar-track {
  background: var(--theme-pg-bg); /* Scrollbar track color */
}
