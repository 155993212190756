.holi-theme {
    position: relative;

    .holi-theme-lantern-gif-left {
        position: fixed;
        top: 0px;
        right: 0px;
        z-index: 100;
        width: 300px;
        transform: scaleX(-1);
    }

    .holi-theme-lantern-gif-right {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100;
        width: 300px;
    }

    .holi-theme-h1-wish {
        position: fixed;
        top: 80px;
        left: 50%;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        width: 100vw;
        z-index: 10001;
        text-align: center;
        font-family: poppins; /* Ensure the font is loaded */
        font-size: 3.5rem;
        color: white; /* Bright yellow for visibility */
        text-shadow: 
            2px 2px 10px rgba(255, 165, 0, 0.9), /* Orange shadow */
            4px 4px 20px rgba(255, 140, 0, 0.8); /* Darker orange shadow */
        animation: flicker 2s infinite alternate ease-in-out;
        pointer-events: none;
        /* Add a background color with a gradient for extra flair */
        background: linear-gradient(45deg, rgba(255, 0, 0, 0.5), rgba(0, 255, 0, 0.5), rgba(0, 0, 255, 0.5));
        padding: 20px; /* Add padding for spacing */
        border-radius: 15px; /* Rounded corners for a softer look */
    }
    
    /* Animation for flickering effect */
    @keyframes flicker {
        0% { opacity: 1; }
        50% { opacity: 0.8; }
        100% { opacity: 1; }
    }
    

    .holi-theme-fire-crackers-gif {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        /* Prevent interaction */
        overflow: hidden;

        img {
            width: 50%;
            height: auto;
        }
    }

    .holi-theme-deepak-gif {
        width: 150px;
        position: fixed;
        bottom: 40px;
        left: 20px;
        z-index: 99999;
        cursor: pointer;
    }

    .holi-theme-dark-overlay-white {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999; /* Ensure this is below the radial gradient overlay */
        pointer-events: none; /* Prevent interaction */
        background: white; /* White background with some transparency */
    }
    
    .holi-theme-dark-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10000; /* Ensure this is above the white background */
        pointer-events: none;
        background: 
            radial-gradient(circle at 10% 80%, rgba(350, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 30%),
            radial-gradient(circle at 90% 20%, rgba(0, 255, 0, 0.7) 0%, rgba(0, 0, 0, 0) 40%),
            radial-gradient(circle at 30% 40%, rgba(0, 0, 255, 0.7) 0%, rgba(0, 0, 0, 0) 40%),
            radial-gradient(circle at 70% 70%, rgba(255, 255, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%),
            radial-gradient(circle at 50% 50%, rgba(255, 105, 180, 0.8) 0%, rgba(0, 0, 0, 0) 30%);
    }
    


    @media (max-width: 575px) {
        .holi-theme-lantern-gif-left {
            width: 150px;
            margin-top: 20px;
        }

        .holi-theme-lantern-gif-right {
            width: 150px;
            margin-top: 20px;
        }

        .holi-theme-h1-wish {
            top: 80px;
            width: 100vw;
            font-size: 2rem;
        }

        .holi-theme-deepak-gif {
            width: 130px;
            bottom: 50px;
            left: 0px;
        }

        .holi-theme-fire-crackers-gif {
            img {
                position: absolute;
                width: 100%;
                height: auto;
            }
        }
    }
}