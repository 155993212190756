.ecom_two {
  .cards {
    display: flex;
    gap: 20px; // Reduced gap for smaller cards
    margin-top: 20px;

    .cards_inside {
      border-radius: 4px;
      background: white;
      padding: 8px 20px; // Reduced padding for smaller cards
      width: 200px; // Set a smaller width for the card
      border: 2px solid #3498db;
      color: black;
      cursor: pointer;
      transition: border-color 0.3s ease, background-color 0.3s ease;
      h4.Cards_head{
        font-size:18px;
      }
      p.card_para{
        font-weight:bold;
      }

      &:hover {
        border-color: #e74c3c;
        background-color: #2980b9;
      }
    }
  }
}

  @media (max-width: 1199px) {
    .cards_inside {
      
      padding: 6px 16px;
      width: 180px; // Smaller width for medium screens
    }
  }

  @media (max-width: 991px) {
    .cards_inside {
      padding: 4px 12px;
      width: 160px; // Smaller width for smaller screens
    }
  }

  @media (max-width: 767px) {
    .cards_inside {
      padding: 4px 10px;
      width: 140px; // Smaller width for tablet screens
    }
  }

  @media (max-width: 575px) {
    .cards_inside {
      padding: 4px 8px;
      width: 120px; // Smaller width for mobile screens
    }
  }

  @media (max-width: 360px) {
    .cards_inside {
      padding: 4px 6px;
      width: 100px; // Smaller width for smaller mobile screens
    }
  }
  @media (max-width: 320px) {

    .cards_inside {
      padding: 0px 0px;
       // Smaller width for smaller mobile screens
      
    
    h4.Cards_head{
      font-size:4px;
      margin:0px;
    }
    p.card_para{
      font-size:9px;
      font-weight:bold;
      margin:0px;
      
    }
  }
}
  
