.EducationOneTutorjobs-page {
  padding-bottom: 50px;
  
  .head-education{
    padding-bottom:20px;
    h2{
      text-align:center;
      font-size:35px;
      text-transform:upperCase;
      color:#264d8c;
    }
  }
  .EducationOneTutorjobsHeader-section {
    display: flex;

    .EducationOneTutorjobs-right {
      width: 50%;

      img {
      width:90%;
      }
    }

    .EducationOneTutorjobs-left {
      width: 50%;

      .side-text {
        padding:15px;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for smooth sliding and shadow */

        &:hover {
          transform: translateX(10px); /* Slide the card to the right */
          box-shadow: 0px 5px 15px rgba(0, 0, 0, 2); /* Add a subtle shadow effect */
        }

        h3 {
          font-size: 20px;
          padding-top: 10px;
        }

        .EducationOneTutorjobs-date {
          padding-bottom: 8px;
        }

        .EducationOneTutorjobs-category {
          background-color: #264d8c;
          color: white;
          padding: 10px;
        }
      }
     
    }
  }
  .eduonebtn {
    text-align: center;
    padding-top: 20px;
  
    button {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #264d8c;
      color: white;
      border: none;
      border-radius: 5px;
      transition: transform 0.3s ease, background-color 0.3s ease;
  
      &:hover {
        transform: scale(1.1); /* Enlarge the button slightly */
        background-color: #78cde8; /* Change to a darker shade of the original color */
      }
    }
  }
  
}
