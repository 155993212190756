.happyerp-edit-page {
  // border: 2px solid;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  gap: 1rem;
  background-color: white;
  width: 100%;
  .happyerp-edit-page-left-content {
    flex: 1;
    .happyerp-edit-back {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      margin-bottom: 10px;
      .material-symbols-outlined {
        font-size: 20px;
      }
    }
    .happyerp-order-detail {
      border: 1px solid #e4e4e7;
      border-radius: 8px;
      padding: 1rem 1.5rem;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      .happyerp-order-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        h3 {
          font-size: 24px;
          font-weight: 700;
        }
        .happyerp-edit-btn {
          border: 1px solid #e4e4e7;
          border-radius: 8px;
          background-color: #fff;
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;
          .material-symbols-outlined {
            font-size: 20px;
            margin-right: 0.75rem;
          }
          &:hover {
            background-color: #f4f4f5;
          }
        }
      }
      .happyerp-order-info {
        .happyerp-order-content {
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          row-gap: 1rem;
        }
      }
    }
    .happyerp-product-details {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .happyerp-product-details-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 24px;
          font-weight: 700;
        }
        .happyerp-add-product {
          display: flex;
          gap: 0.5rem;
          .happyerp-add-product-btn {
            border: 1px solid #e4e4e7;
            border-radius: 8px;
            background-color: #fff;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            .material-symbols-outlined {
              font-size: 20px;
              margin-right: 0.75rem;
            }
          }
        }
      }
      .happyerp-product-content {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
        .happyerp-product-item {
          border: 1px solid #e4e4e7;
          border-radius: 8px;
          .happyerp-product-item-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            background-color: #dbeafe;
            border-bottom: 1px solid #e4e4e7;
            margin-bottom: 0.75rem;
            h5 {
              font-size: 18px;
              font-weight: 900;
            }
            .happyerp-product-edit {
              display: flex;
              gap: 20px;
              button {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                background-color: transparent;
                border-radius: 6px;
                padding: 6px;
                .material-symbols-outlined {
                  font-size: 20px;
                  cursor: pointer;
                }
                &:hover {
                  background-color: #f4f4f5;
                }
              }
            }
          }
          .happyerp-product-item-detail {
            display: flex;
            flex-direction: column;
            padding: 16px;
            .happyerp-product-item-description {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              .happyerp-item-value {
                font-size: 12px;
                font-weight: 300;
                background-color: #eff6ff;
                padding: 2px 8px;
                border-radius: 10px;
                text-align: center;
                display: inline-flex;
              }
            }
            .happyerp-product-item-color {
              .happyerp-product-item-color-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                flex-wrap: wrap;
                padding-top: 10px;
                column-gap: 20px;
                row-gap: 10px;

                .happyerp-product-item-color-text {
                  font-size: 16px;
                  font-weight: 300;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  span {
                    height: 1rem;
                    width: 1rem;
                    border-radius: 100%;
                    background-color: yellow;
                    margin-right: 10px;
                    border: 1px solid #e4e4e4;
                  }
                }
              }
            }
            .happyerp-product-item-quantity {
              .happyerp-product-item-quantity-content {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                padding-top: 15px;
                column-gap: 20px;
                row-gap: 10px;
              }
            }
          }
        }
      }
    }
  }
  .happyerp-edit-page-right-content {
    max-width: 40%;
    width: 40%;
    .happyerp-right-edit-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      padding: 1rem 1.5rem;
      background-color: #f9fafb;
      border-radius: 8px;
      border: 0.5px solid #e4e4e7;
      max-height: 100vh;
      overflow-y: scroll;
      .happyerp-right-edit-form-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 1.25rem;
          font-weight: bold;
        }
        .happyerp-right-edit-btn {
          border: none;
          border-radius: 8px;
          background-color: transparent;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .material-symbols-outlined {
            font-size: 20px;
          }
          &:hover {
            background-color: #f4f4f5;
          }
        }
      }
      .happyerp-right-edit-form-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .happyerp-right-edit-form-element {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          input {
            padding: 0.5rem 1rem;
            background-color: white;
            border: 1.5px solid #e4e4e4;
            border-radius: 6px;
            width: 100%;
          }
          .happyerp-right-edit-form-element-color {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            background-color: white;
            padding: 0.5rem 1rem;
            border-radius: 8px;
            .happyerp-right-edit-form-element-checkbox {
              display: flex;
              gap: 10px;
              border: 1px solid #e4e4e4;
              border-radius: 6px;
              cursor: pointer;
              background-color: transparent;
              input[type="checkbox"] {
                display: none;
                &:checked + label {
                  background-color: #e4e4e4;
                  border-radius: 6px;
                }
              }
              label {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                padding: 0.5rem 1rem;
                border-radius: 6px;
                span {
                  height: 15px;
                  width: 15px;
                  border-radius: 50%;
                  display: inline-block;
                }
              }
            }
          }
          .happyerp-right-edit-form-element-size {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 10px;
            .happyerp-size-div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border: 1px solid #e4e4e4;
              padding: 0.85rem;
              margin-top: 0.5rem;
              border-radius: 8px;
              background-color: white;
              p {
                font-weight: 700;
              }
              .happyerp-size-quantity {
                display: flex;
                .happyerp-size-quantity-btn {
                  border: 1px solid #e4e4e4;
                  border-radius: 2px;
                  width: 2.25rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.125rem;
                  line-height: 1.75rem;
                  padding: 0rem 0.5rem;
                }
                input {
                  width: 100%;
                  text-align: center;
                  font-weight: 400;
                  border-radius: 0px;
                  padding: 4px;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .happyerp-add-another-btn {
          width: 100%;
          button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid #e4e4e4;
            padding: 0.5rem;
            background-color: #ffffff;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 800;
            .material-symbols-outlined {
              font-size: 20px;
            }
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
      .happyerp-right-edit-form-multiple-add {
        background-color: white;
        margin-top: -10px;
        padding: 1rem 2rem;
        border-radius: 8px;
        border: 1px solid #e4e4e4;
        .happyerp-right-multiple-add-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 1rem;
            font-weight: bold;
          }
          .happyerp-right-multiple-add-heading-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            padding: 0px;
            width: 2rem;
            height: 2rem;
            border: none;
            border-radius: 6px;
            .material-symbols-outlined {
              font-size: 18px;
              color: red;
            }
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .happyerp-text-label {
    font-size: 14px;
    color: #71717b;
    font-weight: bold;
  }
  .happyerp-text-value {
    font-size: 1rem;
    font-weight: 500;
  }
  .happyerp-separator {
    border: 0.5px solid #e4e4e4;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .happyerp-right-edit-form-element-btn {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    button {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      border-radius: 8px;
      background-color: transparent;
      border: none;
    }
    .happyerp-edit-cancel-btn {
      border: 1px solid #e4e4e4;
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .happyerp-edit-save-btn {
      background-color: var(--theme-teal);
      color: white;
    }
  }
}
