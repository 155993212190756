.leave-history-parent-div {
  width: 100%;
  margin: auto;
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 10px;
  padding: 10px;

  .leave-history-header {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .leave-history-tabs {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 2px solid #ddd;

    .leave-history-tab {
      flex: 1;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: gray;
      font-size: 13px;

      &.leave-history-active {
        color: black;
        font-size: 13px;
        border-bottom: 2px solid black;
      }
    }
  }

  .leave-history-search {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .leave-history-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;

    @media (min-width: 600px) and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .leave-history-card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .leave-history-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leave-history-initial {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        // color: white;
      }

      .leave-history-name {
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
      }

      .leave-history-type {
        font-size: 14px;
        color: #007bff;
        background: #eaf4ff;
        padding: 3px 10px;
        border-radius: 5px;
        margin-left: 10px;
      }

      .leave-history-applied {
        font-size: 12px;
        color: gray;
      }
    }

    .leave-history-details {
      margin-top: 10px;
      font-size: 14px;

      p {
        margin: 5px 0;
        text-transform: capitalize;
      }
    }

    .leave-history-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      button {
        flex: 1;
        padding: 10px;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        margin: 0 5px;
      }

      .leave-history-approve {
        background: #d4f8d4;
        color: green;
      }

      .leave-history-reject {
        background: #f8d4d4;
        color: red;
      }
    }
  }
}
