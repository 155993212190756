.corporateTwo-home-blogs {
  min-height: 100vh;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;

  .corporateTwo-home-blogs-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    h1 {
      font-size: 16px;
      font-weight: 600;
      color: var(--subheading-color);
    }

    h2 {
      font-size: 36px;
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .corporateTwo-home-blogs-container {
    width: 80vw;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .corporateTwo-home-blog {
      height: 500px;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .corporateTwo-home-blog-image-container {
        height: 60%;
        width: 100%;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
          z-index: 5;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .corporateTwo-home-blog-info {
        height: 40%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background-color: white;

        h4{
          font-size: 1rem;
          color: var(--click-color);
          font-weight: 700;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 700;
          text-align: center;
        }

        .corporateTwo-home-blog-tags {
          display: flex;
          align-items: center;
          gap: 10px;

          .corporateTwo-home-blog-tag {
              border: 2px solid var(--click-color);
              padding: 5px;
              height: 30px;
              min-width: 100px;
              border-radius: 20px;
              background-color: rgb(224, 196, 237);
              font-size: 1rem;
              color: var(--click-color);
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
          }
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  .corporateTwo-home-blogs {
    .corporateTwo-home-blogs-container {
      width: 90vw;

      .corporateTwo-home-blog{
        .corporateTwo-home-blog-info {
          h2{
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .corporateTwo-home-blogs {
    .corporateTwo-home-blogs-container {
      flex-direction: column;
      align-items: center;

      .corporateTwo-home-blog {
        height: 300px;
        width: 100%;
        flex-direction: row;
        align-items: center;

        .corporateTwo-home-blog-image-container {
          height: 100%;
          width: 50%;
        }

        .corporateTwo-home-blog-info {
          height: 100%;
          width: 50%;
          border: 2px solid lightgray;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-home-blogs {
    .corporateTwo-home-blogs-heading{
      text-align: center;
      h1{
        font-size: 16px;
      }
      h2{
        font-size: 24px;
      }
    }

    .corporateTwo-home-blogs-container {
      padding: 20px;
      height: 100%;

      .corporateTwo-home-blog {
        flex-direction: column;
        align-items: center;

        .corporateTwo-home-blog-image-container {
          height: 60%;
          width: 100%;
        }

        .corporateTwo-home-blog-info {
          width: 100%;
          border-top: 2px solid lightgray;

          h4{
            font-size: 12px;
          }

          .corporateTwo-home-blog-tags{
            .corporateTwo-home-blog-tag{
              font-size: 0.75rem;
              padding: 5px 10px;
              height: 30px;
              min-width: 70px;
              min-width: 70px;
            }
          }
        }
      }
    }
  }
}