.business-three-nav-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    position: sticky;
    z-index: 9999;
    width: 100%;

    .business-three-nav-menu-column {
        flex: 2;

        nav {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .business_three_nav_active_menu {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                cursor: pointer;

                span {
                    font-size: 17px;
                    font-weight: 500;
                    background-color: var(--black-color);
                    padding: 5px;
                    color: var(--white-color);
                    border-radius: 50%;
                }

                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--black-color);
                }
            }

            .business_three_nav_inactive_menu {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                span {
                    font-size: 17px;
                    font-weight: 500;
                    color: var(--black-color);
                }

                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--black-color);
                }
            }
        }
    }

    .business-three-nav-icons-column {
        flex: 0.5;
        display: flex;
        justify-content: space-around;
        gap: 10px;
        align-items: center;
        padding: 15px;
        border-left: 2px solid #dfdfdf;

        .business-three-nav-chat-icon,
        .business-three-nav-notification-icon {
            font-size: 20px;
            cursor: pointer;
        }
    }

    .business-three-nav-profile-column {
        flex: 1.5;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        border-left: 2px solid #dfdfdf;

        .business-three-nav-profile-info {
            display: flex;
            align-items: center;

            .business-three-nav-profile_icon_div {
                display: flex;
                align-items: center;

                .business-three-nav-profile_icon {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    margin-right: 10px;
                    border: 3px solid var(--click-color);
                    padding: 3px;
                }

                h6 {
                    font-weight: 700;
                    color: var(--black-color);
                }
            }

            @keyframes ba_jump {
                0% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(-5px);
                }
            }
        }

        .business-three-nav-create-story-button {
            padding: 8px 20px;
            background-color: var(--click-color);
            color: var(--pure-white-color);
            font-weight: bold;
            border-radius: 20px;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;

            span {
                margin-left: 10px;
            }

            &:hover {
                background-color: var(--pure-white-color);
                color: var(--click-color);
                border: 2px solid var(--click-color);
            }
        }
    }

    @media only screen and (max-width: 993px) {
        .business-three-nav-menu-column {
            display: none;
        }

        .business-three-nav-icons-column {
            display: none;
        }

        .business-three-nav-profile-column {
            display: none;
        }
    }

}