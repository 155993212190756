.education_two {
  @font-face {
    font-family: regular;
    src: url(../../../../../public/assets/font/helvetica/Helvetica.ttf);
  }
  .regular {
    font-family: regular;
  }
  @font-face {
    font-family: semibold;
    src: url(../../../../../public/assets/font/helvetica/Helvetica-Bold.ttf);
  }
  .semibold {
    font-family: bold;
  }
  @font-face {
    font-family: extrabold;
    src: url(../../../../../public/assets/font/helvetica/Helvetica-Oblique.ttf);
  }
  .extrabold {
    font-family: extrabold;
  }
  @font-face {
    font-family: light;
    src: url(../../../../../public/assets/font/helvetica/helvetica-light-587ebe5a59211.ttf);
  }
  .light {
    font-family: light;
  }
}
