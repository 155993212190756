.business-three-services-top-selector {
  background-color: var(--white-color);
  padding: 10px 30px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;

  .business-three-service-tab {
    position: relative;
    cursor: pointer;
    padding: 10px 20px;
    transition: color 0.5s, border-bottom 0.5s;
    font-weight: bold;
    color: #4b4b4b;
    display: flex;
    align-items: center;

    span {
      font-size: 1.5rem;
      transition: color 0.5s;
    }

    &.active {
      color: var(--click-color);
      font-weight: bold;
      border-bottom: 2px solid var(--click-color);

      span {
        color: var(--click-color);
      }
    }
  }
}

.business-three-service-selected-service {
  background-color: var(--white-color);
  padding: 15px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
  position: relative;
  overflow: hidden;

  .business-three-service-selected-service-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      div {
        padding: 2rem;
      }
    }

    &.slide-in {
      animation: slideIn 0.5s forwards;
    }

    &.slide-out {
      animation: slideOut 0.5s forwards;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.business-three-service-selected-service-img {
  height: 400px;
  width: 100%;
  border-radius: 15px;
}

.business-three-service-selected-service-content .align-items-center {
  height: 100%;
  width: 100%;

  .business-three-service-selected-service-content.col-12.col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      h4 {
        font-size: 1.8rem;
        font-weight: 700;
        font-family: sans-serif;
        color: var(--black-color);
      }

      p {
        font-size: 1.1rem;
        line-height: 28px;
        color: var(--black-color);
      }
    }
  }
}

.business-three-dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .business-three-dot {
    width: 10px;
    height: 10px;
    background-color: #d3d3d3;
    border-radius: 50%;
    margin: 0 5px;
    transition: all 0.3s ease-in-out;

    &.active {
      width: 50px;
      border-radius: 7px;
      background-color: var(--click-color);
    }
  }
}

.slide-in {
  opacity: 0;
  animation: slideIn 0.5s forwards;
}

.slide-out {
  opacity: 1;
  animation: slideOut 0.5s forwards;
}


@media only screen and (max-width: 767px),
(max-width: 1024px) {
  .business-three-services-top-selector {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    padding: 10px 0px;
  }

  .business-three-services-top-selector::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .business-three-service-tab {
    flex: 0 0 auto;
    white-space: nowrap;
  }

  .business-three-services-top-selector .business-three-service-tab.active {
    border-bottom: none;
  }

  .business-three-service-selected-service-content {
    div {
      div {
        padding: 1rem !important;
      }
    }
  }
}



@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}