.business-three-profile-edit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 999;
}

.business-three-profile-edit-overlay.business-three-profile-overlay-open {
  opacity: 1;
}

.business-three-profile-edit-overlay.business-three-profile-overlay-close {
  opacity: 0;
  pointer-events: none;
}

.business-three-profile-edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0) !important;
  width: 80%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 30px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.business-three-profile-edit-popup.business-three-profile-open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) !important;
  animation: zoomIn 0.3s forwards;
}

.business-three-profile-edit-popup.business-three-profile-close {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0) !important;
  animation: zoomOut 0.3s forwards;
}

@keyframes zoomIn {
  0% {
    transform: translate(-50%, -50%) scale(0) !important;
  }

  100% {
    transform: translate(-50%, -50%) scale(1) !important;
  }
}

@keyframes zoomOut {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

.business-three-profile-edit-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  h2 {
    font-size: 22px;
    color: #333;
    margin: 0;
  }

  .business-three-profile-edit-popup-close {
    cursor: pointer;
    font-size: 24px;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--buttonfg-color);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: color 0.2s ease;

    &:hover {
      color: #555;
    }
  }
}

.business-three-profile-edit-popup-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.2s ease;

    &:focus {
      border-color: var(--logo-primary-color);
      outline: none;
    }
  }
}

.business-three-profile-edit-popup-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  button {
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:last-of-type {
      &:disabled {
        background-color: #adb5bd;
        cursor: not-allowed;
      }
    }
  }
}

// change img code start
.business-three-profile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;

  &-open {
    display: block;
  }
}

.business-three-profile-image-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;

  &-open {
    display: block;
  }
}

.business-three-profile-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .business-three-profile-popup-close {
    cursor: pointer;
  }
}

.business-three-profile-popup-body {
  .business-three-profile-image-preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      border: 5px double var(--logo-primary-color);
    }

    .business-three-profile-delete-icon {
      position: absolute;
      left: 100px;
      top: 76%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--buttonfg-color);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: var(
        --icon-color
      ); // Ensure this color contrasts well with the background
    }
  }

  .business-three-profile-button-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    .business-three-profile-icon-group {
      display: none !important;
    }
  }
}

.business-three-profile-popup-footer {
  margin-top: 15px;

  .business-three-profile-line-container {
    display: none;
  }

  .business-three-profile-dummy-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}

.business-three-profile {
  .business-three-profile-image {
    .business-three-profile-img-camera {
      position: relative;

      .business-three-profile-img-camera-img {
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -35%);
      }

      .business-three-profile-img-camera-occation-img {
        width: 150px;
        height: 150px;
      }

      .business-three-profile-img-holi-occation-img {
        width: 150px;
        height: 150px;
        margin-top: 32px;
      }

      .business-three-profile-camera-icon {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .business-three-profile-logout-span {
      background-color: var(--buttonfg-color);
      width: 100px;
      height: 100px;
      padding: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      span {
        font-size: 60px;
      }
    }

    .business-three-profile-login-butn {
      border: none;
      color: white;
      border-radius: 10px;
      background-color: var(--click-color);
    }

    .business-three-profile-edit-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }
}

// end img change code

.business-three-profile {
  display: flex;
  padding: 20px;

  .business-three-profile-sidebar {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    justify-content: space-between;
    /* Space between children */
    width: 30%;
    background-color: var(--card-bg);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0px 20px 20px 20px;
    margin-right: 20px;
    color: var(--black-color);

    .business-three-profile-image {
      text-align: center;
      margin-bottom: 20px;
      position: relative;

      .business-three-profile-img-camera {
        position: relative;
        display: inline-block;

        .business-three-profile-img-camera-img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          border: 5px double var(--logo-primary-color);
        }

        .business-three-profile-camera-icon {
          position: absolute;
          left: 100px; // Adjust this value based on your design needs
          top: 77%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--buttonfg-color);
          height: 30px;
          width: 30px;
          border-radius: 50%;
          color: var(
            --icon-color
          ); // Ensure this color contrasts well with the background
        }
      }

      .business-three-profile-edit-icon {
        position: absolute;
        right: 0;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--buttonfg-color);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: var(
          --icon-color
        ); // Ensure this color contrasts well with the background
      }
    }

    .business-three-profile-info {
      text-align: center;

      h2 {
        font-size: 24px;
        color: var(--black-color);
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: var(--black-color);
      }
    }

    .business-three-profile-details {
      margin-top: 20px;
      text-align: left;

      p {
        font-size: 17px;
        margin-bottom: 10px;
        color: var(--black-color);
      }
    }

    .business-three-profile-tags {
      text-align: center;
      margin-top: auto;
      /* Pushes the element to the bottom */

      .business-three-profile-button-container {
        gap: 10px;
        /* Optional: adds space between buttons */

        button {
          flex: 1;
          color: var(--subheading-color);
          border-color: var(--subheading-color);
          /* Makes buttons grow equally */
        }
      }
    }
  }

  .business-three-profile-main {
    width: 70%;

    .business-three-profile-more-expand-div {
      margin: 0px 0px 20px 0px;

      .business-three-profile-more-div-big-icon-div {
        border-radius: 8px;
        background: var(--card-bg);
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin: 0 auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .business-three-profile-more-div-big-icon-div-inner {
          width: 33.3% !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px;

          h1 {
            font-size: 0.9rem;
            font-weight: bolder;
            padding-top: 10px;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: var(--buttonfg-color);

            span {
              font-size: 2rem;
              transition: 0.5s;
              font-variation-settings: "FILL" 0;
              color: var(--black-color);
            }

            span:hover {
              font-variation-settings: "FILL" 1;
              color: var(--white-color);
            }
          }

          div:hover {
            background: var(--click-color);
            color: var(--white-color);
          }
        }
      }
    }

    .business-three-profile-menus-list {
      background-color: var(--card-bg);
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin-bottom: 20px;

      h3 {
        font-size: 18px;
        color: var(--black-color);
        margin-bottom: 20px;
      }

      .business-three-profile-logout-login-pc {
        display: none;
      }

      .business-three-profile-menu {
        width: 100%;
        margin-bottom: 16px;

        .business-three-profile-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: var(--light-blue-bg);
          border-radius: 8px;

          .business-three-profile-icon {
            display: flex;
            align-items: center;

            .business-three-profile-icon-bg {
              background-color: #eaf7f7;
              padding: 10px;
              border-radius: 50%;
              margin-right: 10px;
              display: flex;

              svg {
                color: var(--logo-primary-color);
              }
            }

            h6 {
              font-size: 16px;
              color: var(--black-color);
              margin: 0;
            }
          }

          span {
            color: #999;
          }
        }
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .business-three-profile-popup-footer {
    .business-three-profile-line-container {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 20px 0;

      .business-three-profile-line {
        flex: 1;
        height: 1px;
        background-color: #e0e0e0;
        /* Adjust the color as needed */
      }

      .business-three-profile-or-text {
        margin: 0 15px;
        font-size: 16px;
        color: #333;
      }
    }

    .business-three-profile-dummy-images {
      img {
        width: 70px;
        height: 70px;
      }
    }
  }

  .business-three-profile-popup-body {
    .business-three-profile-image-preview {
      .business-three-profile-delete-icon {
        position: absolute;
        left: 90px;
        top: 78%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--buttonfg-color);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: var(
          --icon-color
        ); // Ensure this color contrasts well with the background
      }
    }

    .business-three-profile-button-group {
      .business-three-profile-icon-group {
        display: flex !important;
      }
      .pc_profile_browse_btn {
        display: none !important;
      }
    }
  }

  .business-three-profile {
    flex-direction: column;

    .business-three-profile-sidebar {
      width: 100%;
      margin-right: 0;
      height: auto;
      /* Auto height for mobile */
      flex-direction: column;

      .business-three-profile-tags {
        display: none;
      }
    }

    .business-three-profile-main {
      width: 100%;

      .business-three-profile-more-expand-div {
        margin: 20px 0px 20px 0px;
      }

      .business-three-profile-menus-list {
        .business-three-profile-menu {
          width: 100%;
          margin-right: 0;
        }

        .business-three-profile-logout-login-pc {
          display: block;
        }
      }
    }
  }
}
