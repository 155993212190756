.EducationOneContact-section {
  padding-top: 250px;
  padding-bottom: 50px;

  .EducationOneContact-inner {
    .EducationOneContact-section_header {
      display: flex;
      align-items: center;
      width: 100%; // Ensure full width

      .EducationOneContact-right {
        width: 50%;

        .image-container {
          position: relative;

          .main-image {
            border-radius: 50%;
            overflow: hidden;
            animation: scaleImage 1.2s ease-in-out forwards;

            img {
              width: 85%;
              object-fit: cover;
              transition: transform 0.5s ease;

              &:hover {
                transform: scale(1.1);
              }
            }
          }

          .overlay-image {
            position: absolute;
            bottom: -30px;
            right: -10px;
            border-radius: 50%;
            border: 5px solid #264D8C;
            animation: rotateOverlay 10s infinite linear; // Continuous rotation

            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .EducationOneContact-left {
        width: 50%;
        max-width: 600px;
        padding-left: 70px;

        .text-content {
          opacity: 1; // Always fully visible

          h2, h1, p, .statistics {
            margin: 0; // Remove default margins
          }

          h2 {
            color: #264D8C;
            font-size: 20px;
            margin-bottom: 10px;
          }

          h1 {
            font-size: 36px;
            color: #333;
            font-weight: 400;
            margin-bottom: 20px;
          }

          p {
            font-size: 18px;
            line-height: 1.6;
            margin-bottom: 3px;
          }

          .statistics {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            margin-top: 15px;
          }

          .learn-more-btn {
            background-color: #264D8C;
            color: #fff;
            padding: 12px 25px;
            font-size: 16px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #1d3b6e;
              animation: bounceButton 0.5s ease-in-out; // Bounce on hover
            }
          }
        }
      }
    }
  }
}

// Animations
@keyframes scaleImage {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateOverlay {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceText {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}





@keyframes bounceButton {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

