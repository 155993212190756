$primary-color: #574b90;
$accent-color: #f78fb3;
$background-gradient: linear-gradient(45deg, #26aed9, #264d8c);
$text-color: #333;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(-180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}

.EducationOneBannerTwo_banner-section {

  padding-bottom: 50px;
  background: $background-gradient;
  

  .EducationOneBannerTwo_content-wrapper {
    display: flex;

    .EducationOneBannerTwo_left {
      width: 50%;

      .text-content {
        padding-top: 180px;



        .log_head{
          padding-bottom:20px;
          font-size:30px;
        }
        .sub-heading {
          padding-bottom: 12px;
          font-size: 34px;
          font-weight: bold;
          opacity: 0;
          transform: translateX(-100%);
          transition: none;
        }

        h1 {
          padding-bottom: 10px;
          font-size: 56px;
          font-weight: bold;
          opacity: 0;
          transform: translateY(100%);
          transition: none;
        }

        .description {
          font-size: 20px;
          padding-bottom: 50px;
          opacity: 0;
          transition: none;
        }

        .cta-button {
          padding: 0.8rem 2rem;
          background-color: #254C89;
          color: #fff;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          opacity: 0;
          transition: none;

          &:hover {
            background-color: darken($primary-color, 10%);
          }
        }
      }
    }

    .EducationOneBannerTwo_right {
      width: 50%;
      position: relative;

      .image-container {
        .main_image {
          position: absolute;
          top: 10%;
          left: 22%;
          width: 72%;
          border-radius:50%;
          opacity: 0;
          transform: scale(0.5);
          transition: none;
        }

        .hero-image {
          position: absolute;
          top: 3%;
          left: 15%;
          width: 85%;
          opacity: 0;
          transform: rotate(-180deg);
          transition: none;
        }

        .badge {
          position: absolute;
          top: 20%;
          left: 4%;
          background-color: #f4bb2a;
          color: #fff;
          padding: 1rem;
          width: 120px;  // Adjust width
  height: 120px; // Adjust height
  border-radius: 50%; 
        
          text-align: center;
          transform: rotate(-15deg);
          opacity: 0;
          transition: none;

          .badge-number {
            position:absolute;
            top:22%;
            left:21%;
            padding-bottom:8px;
            
            font-size: 30px;
            font-weight: bold;
          }

          .badge-text {
            font-size: 15px;
            position:absolute;
            top:48%;
            left:16%;

            
          }
        }
      }
    }
  }
}

// Ensuring the final state is retained after the animation
.start-animation .sub-heading {
  animation: fadeIn 1s ease-out, slideInFromLeft 1.2s ease-out;
  opacity: 1;
  animation-fill-mode: forwards; // Retain final state after animation
}

.start-animation h1 {
  animation: fadeIn 1s ease-out 0.8s, slideInFromBottom 1s ease-out 0.5s;
  opacity: 1;
  animation-fill-mode: forwards;
}

.start-animation .description {
  animation: fadeIn 1.2s ease-out 0.8s;
  opacity: 1;
  animation-fill-mode: forwards;
}

.start-animation .cta-button {
  animation: fadeIn 1.5s ease-out 1s;
  opacity: 1;
  animation-fill-mode: forwards;
}

.start-animation .main_image {
  animation: scaleUp 1s ease-out;
  opacity: 1;
  transform: scale(1);
  animation-fill-mode: forwards;
}

.start-animation .hero-image {
  animation: rotateIn 1.2s ease-out;
  opacity: 1;
  transform: rotate(0deg);
  animation-fill-mode: forwards;
}

.start-animation .badge {
  animation: fadeIn 1.5s ease-out, rotateIn 1.2s ease-out;
  opacity: 1;
  animation-fill-mode: forwards;
}
