.EducationOneStudent{
  padding-bottom:50px;
}
.EducationOneStudent_header {
    padding: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .EducationOneStudent-right {
    .EducationOneStudent-contain {
      font-size: 35px;
      text-transform: uppercase;
      color: #264d8c;
    }
  }
  
  .EducationOneStudent-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Two columns
    grid-template-rows: auto auto; // Two rows
    gap: 40px; // Adjust the gap between cards
    max-width: 1200px; // Optional: limit container width
    margin: 0 auto; // Center the container
    perspective: 1000px; // Adds depth to the 3D effect
  
    .EducationOneStudent-card {
      background-color: white;
      padding-bottom: 10px;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      transition: transform 0.6s ease, box-shadow 0.6s ease, filter 0.6s ease;
      transform-style: preserve-3d;
      position: relative;
      overflow: hidden;
  
      &:hover {
        transform: rotateY(20deg) rotateX(10deg) scale(1.05);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
        filter: brightness(1.1) contrast(1.2);
      }
  
      &::before {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), transparent);
        transform: rotate(30deg);
        transition: transform 0.6s ease;
      }
  
      &:hover::before {
        transform: rotate(60deg);
      }
  
      .edu_para_card {
        background-color: #264d8c;
        color: white;
        font-weight: 500;
        padding: 10px;
      }
  
      img {
        width: 400px;
        height: 200px;
        
        padding-bottom: 5px;
       
        transition: transform 0.6s ease;
      }
  
      &:hover img {
        transform: scale(1.1) rotate(5deg);
      }
    }
  }
