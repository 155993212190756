.education_two{
  .et_Testimonials_bg_img {
    border-left: 100px solid var(--click-color); // Left border
    border-bottom: 50px solid var(--click-color); // Bottom border
  .et_Testimonials_inner_content{
    display:flex;
  .et_Testimonials_inner_left{
    width:30%;
  }
  .et_Testimonials_inner_right{
    width: 70%;
    .et_Testimonials_inner_right_content{
      display:flex;
      .et_Testimonials_cards{
  
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        background-color: white;
        
          padding:40px;
          width:100%;
        border-bottom: 2px solid var(--click-color);
        border-left: 4px solid var(--click-color); // Left border

        transform: scale(1);
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
        }
        .et_Testimonials_cards_para{
          padding-bottom: 20px;
          font-size: 16px;
          line-height: 1.6;
        }
        h2.et_Testimonials_cards_name{
          padding-bottom: 4px;
          font-weight: bold;
          font-size: 18px;
        }
        .some_text{
          color: #22225d;
          font-weight: 500;
        }
        .img_abso1, .img_abso {
          position: absolute;
          top:180px;
          left:285px;
          img {
            width: 60px;
            border: 4px solid var(--click-color);
            transform: scale(1);
            transition: transform 0.4s ease-in-out;
          }
        
        .img_abso1 {
          
        
        }

        .img_abso {
        
        }
      }

      }

    }
  }
}
}
}

