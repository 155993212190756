.faq_services_heading_p {
    color: var(--white-color);
}

.aboutus_video {
    border-radius: 20px;
    width: 60rem;

}

.about_team_sect {
    background-color: var(--click-color);
    width: 100%;
}

.about_team_sect .about_team_sect_upper_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    letter-spacing: 1px;
    text-align: center;
}

.about_team_sect .about_team_sect_upper_child h3 {
    font-weight: 700;
}

.about_video_sect_upper_child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.about_video_sect_upper_child h3 {
    font-weight: 700;
    color: var(--black-color);
}