.corporateTwo-about {
  min-height: 100vh;
  padding: 50px 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  .corporateTwo-about-main-info {
    display: flex;
    justify-content: space-around;

    .corporateTwo-about-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        font-size: 16px;
        font-weight: 600;
        color: var(--subheading-color);
      }

      h2 {
        font-size: 36px;
        font-weight: 600;
        color: var(--black-color);
      }

      p {
        font-size: 1rem;
        color: var(--black-color);
      }

      .corporateTwo-about-left-info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .corporateTwo-about-left-info-features {
          ul {
            display: flex;
            flex-direction: column;
            gap: 5px;

            li {
              list-style-type: none;
              display: flex;
              align-items: center;
              gap: 10px;
              color: var(--black-color);

              span {
                font-size: 1.15rem;
                color: var(--black-color);
              }
            }
          }
        }

        .corporateTwo-about-left-activity {
          display: flex;
          align-items: center;
          gap: 30px;

          button {
            width: 200px;
            height: 50px;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            background-color: var(--click-color);
            color: white;
          }

          .corporateTwo-about-left-calling-info {
            display: flex;
            align-items: center;
            column-gap: 50px;
            background-color: #dfbcea;
            padding: 5px 10px;
            border-radius: 30px;
            cursor: pointer;

            a {
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                position: relative;
                background-color: lightgray;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--click-color);
                cursor: pointer;

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 100%;
                  height: 100%;
                  background: var(--click-color);
                  border-radius: 50%;
                  transform: translate(-50%, -50%) scale(1.5);
                  animation: ripple-animation 1.5s infinite;
                  pointer-events: none;
                }
              }

              .corporateTwo-about-left-calling-detail {
                p {
                  font-size: 1rem;
                  color: var(--click-color);
                }
                h4 {
                  font-weight: 600;
                  letter-spacing: 1px;
                  color: var(--black-color);
                }
              }
            }
          }
        }
      }
    }

    .corporateTwo-about-right {
      position: relative;
      margin-top: 30px;

      .corporateTwo-about-right-img {
        height: 500px;
        width: 500px;
        background-color: black;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Faboutimg.webp?alt=media&token=09a4ff74-6672-4438-9ccc-c119ef55bdd9");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        box-shadow: 7px 7px 1px var(--logo-primary-color);

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        }
      }

      .corporateTwo-about-right-yoe {
        position: absolute;
        top: -30px;
        right: -40px;
        background-color: var(--click-color);
        color: var(--logo-primary-color);
        height: 150px;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px;

        span {
          font-size: 3rem;
          font-weight: 700;
        }

        div {
          text-align: center;

          .corporateTwo-about-right-yoe-info {
            h5 {
              font-size: 1rem;
              color: var(--subheading-color) !important;
            }
          }
        }
      }
    }
  }
}

@keyframes ripple-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  70% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

@media (max-width: 1440px) {
  .corporateTwo-about {
    padding: 50px 70px;

    .corporateTwo-about-main-info {
      .corporateTwo-about-left {
        h2 {
          font-size: 36px;
        }

        p {
          font-size: 1rem;
        }
      }

      .corporateTwo-about-right {
        .corporateTwo-about-right-img {
          height: 500px;
          width: 500px;
        }

        .corporateTwo-about-right-yoe {
          height: 150px;
          width: 150px;

          span {
            font-size: 3rem;
          }

          div h5 {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .corporateTwo-about {
    padding: 40px 50px;

    .corporateTwo-about-main-info {
      .corporateTwo-about-left {
        width: 48%;

        h2 {
          font-size: 32px;
        }

        .corporateTwo-about-left-activity {
          gap: 30px;

          button {
            width: 200px;
            height: 50px;
            font-size: 1rem;
          }
        }
      }

      .corporateTwo-about-right {
        .corporateTwo-about-right-img {
          height: 450px;
          width: 450px;
        }

        .corporateTwo-about-right-yoe {
          height: 120px;
          width: 120px;
          right: 30px;

          span {
            font-size: 2.8rem;
          }
        }
      }
    }
  }
}

@media (max-width: 940px) {
  .corporateTwo-about {
    padding: 30px 20px;

    .corporateTwo-about-main-info {
      flex-direction: column;
      gap: 30px;

      .corporateTwo-about-left {
        width: 100%;
        order: 1;
        gap: 20px;

        h2 {
          font-size: 28px;
        }

        .corporateTwo-about-left-activity {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }
      }

      .corporateTwo-about-right {
        order: 2;
        width: 100%;
        margin-top: 30px;

        .corporateTwo-about-right-img {
          height: 350px;
          width: 100%;
        }

        .corporateTwo-about-right-yoe {
          top: -20px;
          right: 0;
          height: 120px;
          width: 120px;

          span {
            font-size: 2.5rem;
          }

          div h5 {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .corporateTwo-about {
    padding: 50px 15px;
    margin-bottom: 40px;

    .corporateTwo-about-main-info {
      .corporateTwo-about-left {
        h1 {
          font-size: 16px;
        }

        h2 {
          font-size: 24px;
        }

        p {
          font-size: 0.9rem;
        }

        .corporateTwo-about-left-info {
          gap: 15px;

          .corporateTwo-about-left-info-features {
            ul li span {
              font-size: 1rem;
            }
          }

          .corporateTwo-about-left-activity {
            button {
              width: 180px;
              height: 45px;
              font-size: 0.9rem;
            }

            .corporateTwo-about-left-calling-info {
              gap: 15px;

              span {
                height: 45px;
                width: 45px;
              }

              .corporateTwo-about-left-calling-detail h4 {
                font-size: 0.9rem;
              }
            }
          }
        }
      }

      .corporateTwo-about-right {
        .corporateTwo-about-right-img {
          height: 300px;
        }

        .corporateTwo-about-right-yoe {
          top: auto;
          bottom: -50px;
          right: 50%;
          transform: translateX(50%);
          height: 100px;
          width: 100px;

          span {
            font-size: 2.2rem;
          }

          div h5 {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
