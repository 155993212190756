.ecom_two {
    .Magzine-events {
      margin-bottom:50px;
      .section_header{
        .right{
          display:flex;
          button.btn_inr{
            margin-left:1030px;
          }
        }
      }
      .magzine-container { 
   
        .event-card {
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          .image-container {
            position: relative;
            .event-image {
              width: 100%;
              height: auto;
              aspect-ratio: 2 / 2.5;
              object-fit: cover;
              border-radius: 8px 8px 0px 0px;
            }
            p {
              position: absolute;
              bottom: 0px;
              left: 0px;
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
              color: white;
              padding: 5px 10px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
            }
            .badge {
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: black;
              color: white;
              padding: 5px 10px;
              border-radius: 5px;
            }
          }
          

            }
          }
        }
     
      }
    
  
  