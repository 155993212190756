.corporateTwo-about-pg-header{
    height: 40vh;
    padding: 20px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Faboutimg.webp?alt=media&token=09a4ff74-6672-4438-9ccc-c119ef55bdd9");
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    position: relative;

    &::after{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
        z-index: 5;
    }

    h1{
        font-size: 3rem;
        font-weight: 400;
        z-index: 10;
    }
}

@media (max-width:480px){
    .corporateTwo-policy-header{
        align-items: center;

        h1{
            font-size: 2rem;
            text-align: center;
        }
    }
}