/* Add overlay styling */
.calendar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  // align-items: center;
}

/* Calendar container styling */
.accommodation-property-calendar {
  z-index: 1000;
  margin-top: 150px;
}


.rdrDefinedRangesWrapper {
  height: 381px;
  margin-top: 150px;
}


.accommodation-property-list-checked-date {
  display: flex;
  justify-content: center;
  background-color: #0d1b2a;
  padding: 0px 0px 10px 0px;
  margin: 0 auto;
  overflow: hidden;

  .accommodation-property-list-checked-date-date {
    margin-top: 15px;

    input {
      background-color: #1b263b;
      color: white;
      // border: none;
      border-radius: 5px;
      padding: 10px 15px;
      font-size: 16px;
      // outline: none;
      cursor: pointer;

      &::placeholder {
        color: #a1a5b0;
      }
    }
  }

  .MuiTextField-root {
    background-color: #1b263b;
    border-radius: 5px;

    input {
      color: white;
    }

    label {
      color: #a1a5b0;
    }
  }
}

.accommodation-property-list-both-calender {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .guest-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    // align-items: center;
    z-index: 1001; // Ensure it sits above everything else
  }

  .guest-popup-content {
    background-color: white;
    padding: 20px;
    width: 500px;
    height: 220px;
    margin-top: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .guest-counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      font-weight: 700;

      button {
        background-color: #3a86ff;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        margin: 0 5px;

        &:hover {
          background-color: #2563eb;
        }
      }
    }
  }
}

.accommodation-property-list-page {
  display: flex;
  gap: 20px;

  .accommodation-property-list-filter-section {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .accommodation-property-list-filter-category {
      margin-bottom: 20px;

      h4 {
        font-size: 1.125rem;
        margin-bottom: 10px;
        font-weight: 700;
      }

      div {
        margin-bottom: 8px;
      }

      input {
        margin-right: 10px;
      }

      label {
        font-size: 0.875rem;

        span {
          color: #888;
        }
      }
    }
  }

  .accommodation-property-list-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 20px;
  }

  .accommodation-property-list-item {
    display: flex;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    // overflow: hidden;

    .accommodation-property-list-image {
      flex: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .accommodation-property-list-info {
      flex: 2;
      padding: 20px;

      .accommodation-property-list-title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .accommodation-property-list-location {
        font-size: 1rem;
        color: gray;
        margin-bottom: 10px;
      }

      .accommodation-property-list-features {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;

        span {
          background-color: #eafaf1;
          color: #31b57e;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 0.875rem;
        }
      }

      .accommodation-property-list-price {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .accommodation-property-list-buttons {
        display: flex;
        gap: 10px;
      }

      .accommodation-property-list-viewing-status {
        margin-top: 20px;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

}


@media only screen and (max-width: 575px) {
  .accommodation-property-list-phone-view-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--page-bg);
    position: fixed;
    width: 100%;
    z-index: 2999999;
    top: 58px;
    padding: 20px 10px 10px 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 10px 7px);
    border-top: 2px solid rgb(203, 203, 203);
  }

  .accommodation-property-list-checked-date {
    margin-top: 75px;
  }

  .accommodation-property-list-page {
    display: table-column;

    .accommodation-property-list-filter-section {
      margin-top: 80px;
    }

    .accommodation-property-list-item {
      display: grid;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      // overflow: hidden;
    }

    .accommodation-property-list-container {
      margin-top: 80px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .accommodation-property-list-phone-view-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--page-bg);
    position: fixed;
    width: 100%;
    z-index: 2999999;
    top: 58px;
    padding: 20px 10px 10px 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 10px 7px);
    border-top: 2px solid rgb(203, 203, 203);
  }

  .accommodation-property-list-checked-date {
    margin-top: 75px;
  }

  .accommodation-property-list-page {
    display: table-column;

    .accommodation-property-list-filter-section {
      margin-top: 80px;
    }

    .accommodation-property-list-item {
      display: grid;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .accommodation-property-list-container {
      margin-top: 80px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .accommodation-property-list-phone-view-detail {
    display: none;
  }
}