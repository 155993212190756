.offer_banner_parent {
    /* background-color: white; */

    /* border-radius: 10px 10px 10px 10px; */
    /* box-shadow: 0 0.3125rem 0.3125rem 0 #523f690d; */
}

.offer_img_carousel {
    border-radius: 10px;
    /* height: 180px; */
}

.offers-section-title {
    padding: 40px 0px 50px 0px;
    color: var(--pink);
    margin: 30px 50px 0px 50px;
    border-radius: 10px 10px 0px 0px;
    background: white;
}

.offers-section-title p {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    margin-left: 20px;
}

.offers-section-title p:before {
    position: absolute;
    content: "";
    left: 30;
    bottom: 0;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
}

.team_prnt_div {}

/* Tablet (portrait) and above */
@media only screen and (max-width: 600px) {
    .offer_banner_parent {}

    .offers-section-title {
        padding: 10px 0px 20px 0px;
        color: var(--pink);
        margin: 0px 15px 0px 15px;
    }

    .offers-section-title p {
        font-size: 18px;
        margin-left: 10px;
    }

    .team_prnt_div {}
}

/* Desktop and above */
@media only screen and (min-width: 992px) {}

/* Large desktop and above */
@media only screen and (min-width: 1200px) {}