.pvcy_polcy_head_div {
    background-color: var(--click-color);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 0px 0px 50px 50px;
}

.pvcy_polcy_head_div h2 {
    font-weight: 700;
}

.pvcy_polcy_head_div p {
    /* font-weight: 700; */
}

.pvcy_polcy_head_ul_div {
    padding: 30px 100px 50px 100px;
    color: var(--black-color);
}

.pvcy_polcy_head_ul_div li p {
    color: var(--grey-dark);
}

@media (max-width:575px) {
    .pvcy_polcy_head_ul_div {
        padding: 10px 10px 50px 10px;
    }
}