.app-store-one-heading{
    color: var(--black-color);
    font-family: "Nunito", Sans-serif;
    font-size: 28px;
    font-weight: 800;
}
.app-store-one-sub-heading{
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    color: var(--grey-dark);
}