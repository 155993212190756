/* why choose us Section */
.international_why_choose_parent_div {
  background-color: var(--card-bg);
}

.fb_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ip_middle_circle {
  background-image: linear-gradient(135deg, var(--click-color), #f0f0f0);
  width: 60%;
  height: 50rem;
  border-radius: 0% 50% 50% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 3px 30px -7px rgba(0, 0, 0, 0.5);
}

.ip_middle_div_text {
  width: 55%;
  margin-left: 40px;
  color: white;
}

.ip_why_choose_circle {
  background-image: linear-gradient(135deg, #a38fe0, #8367cb, #6747c6);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 25%;
  left: -25%;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  color: white;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5);
}

.ip_whychoose_four_div {
  position: absolute;
}

.ip_last_circles-1 {
  background-image: linear-gradient(135deg, #e1e1e1, #adadad);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10%;
  top: 0%;
}

.ip_last_circles-2 {
  background-image: linear-gradient(135deg, #a48fdc, #6b4cc3);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 9%;
  top: 10%;
}

.ip_last_circles-3 {
  background-image: linear-gradient(135deg, #0fc7d9, #0eb4c6, #0d98a9);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -4%;
  top: 42%;
}

.ip_last_circles-4 {
  background-image: linear-gradient(135deg, #e878a7, #be53a4);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10%;
  top: 78%;
}

.ip_airplane_icon {
  width: 60px;
}

.ip_hand_icon {
  width: 70px;
}

.ip_time_icon {
  width: 90px;
}

.ip_securty_icon {
  width: 60px;
}

.ip_whychoose_details {
  top: -8%;
  position: absolute;
  margin-left: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.ip_whychoose_second_details {
  position: absolute;
  left: 100%;
  /* Position it to the right of the .ip_last_circles-3 container */
  transform: translateX(10px);
  /* Add some space between the circle and the text */
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* Stack the text content vertically */
  /* align-items: flex-start; */
  /* Align the text content to the start (left) */
  font-weight: 700;
}

.ip_whychoose_third_details {
  position: absolute;
  left: 100%;
  /* Position it to the right of the .ip_last_circles-3 container */
  transform: translateX(10px);
  /* Add some space between the circle and the text */
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* Stack the text content vertically */
  /* align-items: flex-start; */
  /* Align the text content to the start (left) */
  font-weight: 700;
}

.ip_whychoose_fourth_details {
  position: absolute;
  left: 100%;
  /* Position it to the right of the .ip_last_circles-3 container */
  transform: translateX(10px);
  /* Add some space between the circle and the text */
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* Stack the text content vertically */
  /* align-items: flex-start; */
  /* Align the text content to the start (left) */
  font-weight: 700;
}

.ip_whychoose_phone_div_parent {
  display: none;
}

.ip_whychoose_upper_bg_color {
  background-color: var(--click-color);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

@media only screen and (max-width: 575px) {
  /* why choose us Section */
  .international_why_choose_parent_div {
    display: none;
  }

  .interntional_left_top_div div h1 {
    color: var(--black-color);
    font-size: 22px;
    margin-top: 0px;
  }

  .ip_whychoose_phone_div_parent {
    display: block;
  }
}

@media (min-width: 575px) and (max-width: 1200px) {
  /* why choose us Section */
  .international_why_choose_parent_div {
    display: none;
  }

  .ip_whychoose_phone_div_parent {
    display: block;
  }
}
