section.footer_before {
    padding: 100px 0px;
    text-align: center;
    z-index: 1;
    background-size: cover;
    background-attachment: fixed;
    color: var(--white-color);
    /* margin-bottom: -145px; */
    padding-bottom: 175px;

}

section.footer_before:after {
    content: '';
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1;
}

.fb_inner h6 {
    margin: 30px 0px !important;
    font-weight: 400;
}

.fb_inner .btn_group {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.fb_inner .btn_group button.theme_btn.btn_fill {
    border: none;
    background: var(--pink);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
}

.footer_upper_row {
    padding: 0px 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_upper_boxes {
    align-items: center;
    justify-content: center;
    color: var(--black-color);
    border-radius: 15px;
    height: 100px;
    background-color: var(--card-bg);
}

.footer_uuper_icon span {
    font-size: 25px;
}

/* .footer_upper_boxes div h3 {
    font-size: 18px;
} */

@media (max-width: 767px) {
    section.footer_before {
        padding: 35px 0px;
        padding-bottom: 120px;
    }

    .fb_inner h6 {
        margin: 25px 0px !important;
        font-size: 14px;
    }

    .fb_inner h3 {
        font-size: 22px;
    }

    .fb_inner .btn_group {
        display: grid;
        gap: 15px;
    }

    .footer_upper_row {
        padding: 0px 0px;
    }
}