.corporateTwo-home-faq {
  min-height: 90vh;
  padding: 50px 80px 0 50px;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  .corporateTwo-home-faq-left {
    position: relative;
    height: 100vh;
    z-index: 5;
    width: 70%;

    .corporateTwo-home-faq-left-lady {
      height: 100%;
      width: 100%;

      opacity: 0;
      transform: translateX(-100%);
      transition: transform 1s ease-out, opacity 0.5s ease-out;

      &.animate-image {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .corporateTwo-home-faq-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 10px;
    z-index: 30;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease-out, opacity 0.5s ease-out;
    overflow: hidden;
    width: 90%;

    &.animate-faq {
      opacity: 1;
      transform: translateX(0);
    }

    .corporateTwo-home-faq-heading {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-size: 16px;
        font-weight: 600;
        color: var(--subheading-color);
      }

      h2 {
        font-size: 36px;
        font-weight: 600;
        color: var(--black-color);
      }
    }

    .corporateTwo-home-faq-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%;

      .corporateTwo-home-faq-card {
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: background-color 0.5s;
        position: relative;
        background-color: white;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);

        .corporateTwo-home-faq-card-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 70px;
          padding: 15px;
          font-weight: bold;
          transition: color 0.5s;

          h4 {
            font-size: 1.25rem;
          }

          &.active {
            color: #007bff;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 5px;
              background-color: teal;
              transition: height 0.5s ease all;
            }
          }

          span {
            transition: transform 0.5s;
            font-size: 15px;
            background-color: var(--click-color);
            color: white;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.active span {
            transform: rotate(180deg);
          }
        }

        .corporateTwo-home-faq-card-body {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
          padding: 0 15px;
          opacity: 0;
          position: relative;

          h5 {
            font-size: 1.25rem;
            margin: 15px 0;
          }

          &.visible {
            max-height: 200px;
            opacity: 1;
            padding: 15px;
          }
        }
      }
    }
  }

  // Responsive styles
  @media (max-width: 1440px) {
    .corporateTwo-home-faq {
      padding: 40px 60px 0 60px;
      gap: 25px;

      .corporateTwo-home-faq-right {
        width: 70%;
        .corporateTwo-home-faq-heading {
          h2 {
            font-size: 32px;
          }
        }

        .corporateTwo-home-faq-container {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    padding: 30px 40px 0 40px;
    gap: 20px;

    .corporateTwo-home-faq-left {
      height: 100vh;
      // width: 30%;
    }

    .corporateTwo-home-faq-right {
      width: 70%;
      .corporateTwo-home-faq-heading {
        h1 {
          font-size: 14px;
        }

        h2 {
          font-size: 28px;
        }
      }

      .corporateTwo-home-faq-container {
        .corporateTwo-home-faq-card {
          .corporateTwo-home-faq-card-title {
            h4 {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 993px) {
    flex-direction: column-reverse;
    padding: 30px 20px 0 20px;

    .corporateTwo-home-faq-left {
      height: 100%;
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .corporateTwo-home-faq-right {
      width: 100%;
      .corporateTwo-home-faq-heading {
        h2 {
          font-size: 24px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    padding: 20px 15px 0 15px;

    .corporateTwo-home-faq-left {
      margin-top: 20px;

      .corporateTwo-home-faq-left-lady {
        width: 100%;
        object-fit: contain;
      }

      .corporateTwo-home-faq-left-machine {
        display: none;
      }
    }

    .corporateTwo-home-faq-right {
      .corporateTwo-home-faq-heading {
        h1 {
          font-size: 12px;
        }

        h2 {
          font-size: 20px;
        }
      }

      .corporateTwo-home-faq-container {
        .corporateTwo-home-faq-card {
          .corporateTwo-home-faq-card-title {
            height: 60px;
            padding: 10px;

            h4 {
              font-size: 1rem;
            }

            span {
              font-size: 12px;
              height: 18px;
              width: 18px;
            }
          }

          .corporateTwo-home-faq-card-body {
            h5 {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
