.online-payment-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;

    .payment-summary {
        background: white;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 500px;

        h2 {
            margin-bottom: 1.5rem;
            text-align: center;
            color: #333;
        }

        .order-details {
            margin-bottom: 2rem;
            
            p {
                margin: 0.5rem 0;
                font-size: 1.1rem;
                display: flex;
                justify-content: space-between;
                color: #666;
            }
        }

        .payment-button, .back-button {
            width: 100%;
            padding: 1rem;
            margin: 0.5rem 0;
            border: none;
            border-radius: 4px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }
        }

        .payment-button {
            background-color: #348DCB;
            color: white;

            &:hover:not(:disabled) {
                background-color: darken(#348DCB, 10%);
            }
        }

        .back-button {
            background-color: #f5f5f5;
            color: #333;

            &:hover:not(:disabled) {
                background-color: darken(#f5f5f5, 5%);
            }
        }
    }
}
