.navbar-default-parent {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  padding: 0px 0px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  border-bottom: 1px solid greylight;
}

.nav_logo_text {
  font-size: 25px;
  line-height: 26px;
  font-weight: 700;
  color: var(--black-color);
}

.navbar-logo-only-with-title{
  width: 75px;
  margin-right: 10px;
  position: relative;
  z-index: 999;
}
.navbar-logo-only-without-title{
  width: auto;
  max-height: 80px;
}
.navbar-logo-only-admin {
  position: relative;
  cursor: pointer;
  z-index: 999;
}


.navbar-logo-only-customer {
  transition: transform 0.3s, filter 0.3s;
}

.navbar-logo-only-admin {
  transition: transform 0.3s, filter 0.3s;
}
.navbar-logo-container {
  z-index: 9999;
}

.navbar-logo-only-admin:hover::after {
  content: '+';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: white; */
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}


.navbar-default-parent>.container-fluid {
  align-items: inherit !important;
}

.navbar-default-parent ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.navbar-default-parent a {
  color: #333;
  text-decoration: none;

}

.navbar-hover-menu{
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-hover-menu span{
  font-size: 1.8rem;
  color: var(--black-color);
  cursor: pointer;
  margin-left: 10px;
}

.navbar-notification-div {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-notification-div span {
  font-size: 1.8rem;
  color: var(--black-color);
  cursor: pointer;
  margin-left: 10px;
}

.navbar-notification-div div {
  position: absolute;
  top: 2px;
  right: 3px;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  pointer-events: none;
}

.navbar-laptop-menu-links-div {
  display: flex;
  /* align-items: center; */
}

.navbar-laptop-menu-links-div .navbar-laptop-menu-links {
  display: flex;
  align-items: center;
  padding: 0 20px;
}


.navbar-laptop-menu-links-div .navbar-laptop-menu-links .navbar-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 7px 15px;
  border-radius: 50px;
  position: relative;
}


.navbar-laptop-menu-links-div .navbar-laptop-menu-links div span {
  color: var(--black-color);
  transition: 0.5s;
  font-variation-settings:
    'FILL' 0;
}

.default_nav_active_menu {
  background: var(--click-color);
  border-bottom: 2px solid var(--gold) !important;
}

.default_nav_active_menu .default_nav_active_menu_icons,
.default_nav_active_menu h1,
.default_nav_active_menu .default_nav_active_menu_bottm_direction {
  color:var(--white-color) !important;
}

.navbar-laptop-menu-links-div .navbar-laptop-menu-links div h1 {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin: 0;
  color: var(--black-color);
  padding-left: 5px;
  transition: 0.5s;
}

.navbar-laptop-menu-icons-div {
  display: flex;
  align-items: center;
}

.navbar-user-icon {
  position: relative;
  top: 1px;
  width: 35px;
  height: 35px;
  background: var(--lightblue-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
  overflow: hidden;
}

.navbar-user-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.4rem;
}

.np_inner {
  display: flex;
  gap: 6px;
  align-items: center;
}

.np_inner img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.np_inner h5 {
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 6px;
  position: relative;
  top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 122px;
}

.navbar_profile {
  display: flex;
  align-items: center;
  background: var(--click-color);
  padding: 3px 3px 3px 14px;
  border-radius: 50px;
}

/* .navbar_profile.default_nav_active_menu {
  background: var(--dark-blue);
} */

.navbar-user-icon img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  padding: 1px;
}

.navbar-laptop-menu-icons-div button {
  margin-left: 20px;
  font-size: 0.8rem;
  height: 30px;
  padding: 0 10px;
  border-radius: 30px;
  font-weight: 700;
}

.navbar-laptop-menu-icons-div .navbar-laptop-menu-icons-div-hamburger-icon {
  padding-left: 20px;
}

.navbar-laptop-menu-icons-div .navbar-laptop-menu-icons-div-hamburger-icon span {
  position: relative;
  top: 3px;
  color: var(--lightblue-color);
  cursor: pointer;
}


.menu_single.profile {
  border-radius: 50px;
  background-color: var(--click-color);
  padding: 3px 3px 3px 14px;
  transition: 0.3s;
  color: var(--white-color);
}

.menu_single.profile .user_img img {
  width: 35px;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.menu_single.profile span {
  font-size: 14px;
  max-width: 100px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  margin-right: 6px;
  width: inherit;
  height: inherit;
  color: var(--pure-white-color);
}

.menu_single span {
  width: 35px;
  height: 35px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--p-theme-grey);
  border-radius: 6px;
  transition: 0.3s;
  margin-right: 6px;
}

.menu_single.login span {
  background: #c8c1d9;
  color: var(--click-color);
  transition: 0.3s;
}
@keyframes btn_arrow_animation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.ba_animation {
  animation: btn_arrow_animation 1s linear infinite;
}

.menu_single.login {
  color: var(--click-color);
  font-weight: 700;
  display: flex;
  align-items: center;
}

.navbar-cart-quantity{
  position: relative;
}

.navbar-cart-quantity .cart-quantity-counter{
  position: absolute;
  top: -25px;
  right: -17px;
  background-color: var(--logo-primary-color);
  color: #4f0a87 !important;
  height: 20px;
  width: 20px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-icon-nav-menu{
  margin: 0 10px !important;
}

.profile-menu-container {
  position: relative;
}

.profile-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0px;
  background: var(--page-bg);
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 8px 0;
  min-width: 200px;
  z-index: 1000;
}

.navbar-booking-dropdown-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: var(--black-color);
  text-decoration: none;
  transition: background-color 0.2s;
}

.navbar-booking-dropdown-item:hover {
  background-color: var(--click-color);
  color: white;
}

.navbar-booking-dropdown-item .material-symbols-outlined {
  margin-right: 12px;
  font-size: 20px;
}

.navbar-booking-dropdown-item span {
  font-size: 14px;
  /* color: var(--black-color); */
}

.navbar-booking-dropdown-item:hover span {
  color: white;
}

.schedule-pickup-nav-button{
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--click-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  gap: 10px;
  font-size: 12px;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  20% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  60% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}

.vibrate {
  animation: vibrate 0.3s linear;
}

/* SUBMENU END */
.navbar-sub-menu-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0px;
  background: var(--page-bg);
  box-shadow: 0 20px 20px 20px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 8px 0;
  min-width: 250px;
  z-index: 1000;
}

.navbar-sub-menu-dropdown-name {
  padding: 12px 16px;
  /* color: var(--black-color); */
  text-decoration: none;
  transition: background-color 0.3s;
  text-transform: capitalize;
}

.navbar-sub-menu-dropdown-name:hover {
  background-color: var(--click-color);
  color: white;

  div{
    span{
      color: white !important;
    }
  }
}

/* SUBMENU START */

.navbar-two-extra-menu{
  display: flex;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 767px) {
  .navbar-logo-only-without-title {
    width: auto;
    height: 70px;
  }

  .nav_logo_text {
    font-size: 18px;
    color: var(--black-color);
  }

  .navbar-default-parent {
    background-color: var(--white-color);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.navbar-vendor-name {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  margin: 0 10px;
  border: 1px solid var(--border-color);
}

.vendor-preview {
  display: flex;
  align-items: center;
  gap: 12px;
}

.vendor-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.vendor-info span {
  font-size: 1.5rem;
  color: var(--click-color);
}

.vendor-info h5 {
  font-size: 13px;
  margin: 0;
  color: var(--black-color);
  font-weight: 500;
}

.vendor-info small {
  font-size: 11px;
  color: var(--grey-color);
  display: block;
  margin-top: 2px;
}

.vendor-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.vendor-modal {
  background: var(--page-bg);
  border-radius: 20px;
  width: 95%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.vendor-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendor-modal-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--black-color);
  margin: 0;
}

.close-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--border-color);
  transition: all 0.2s ease;
}

.close-button:hover {
  background: var(--click-color);
  color: white;
  transform: rotate(90deg);
}

.vendor-list {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.vendor-card {
  background: var(--white-color);
  border-radius: 16px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.vendor-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.vendor-card.selected {
  border-color: var(--click-color);
  color: var(--click-color);
  background-color: white;
}

.vendor-card img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
  border: 2px solid var(--border-color);
  padding: 2px;
  background: white;
}

.vendor-card-info {
  text-align: center;
  width: 100%;
}

.vendor-card-info h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px;
  color: var(--black-color);
}

.vendor-card-info p {
  font-size: 12px;
  margin: 0;
}

.check-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  color: var(--click-color);
  background: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Breakpoints */
@media screen and (max-width: 768px) {
  .vendor-modal {
    max-width: 100%;
    border-radius: 16px;
  }

  .vendor-modal-header {
    padding: 16px 20px;
  }

  .vendor-modal-header h2 {
    font-size: 16px;
  }

  .vendor-list {
    padding: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .vendor-card {
    padding: 16px;
  }

  .vendor-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
  }

  .vendor-card-info h3 {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .vendor-card-info p {
    font-size: 11px;
  }
}

@media screen and (max-width: 480px) {
  .vendor-modal {
    border-radius: 12px;
  }

  .vendor-list {
    padding: 12px;
    gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }

  .vendor-card {
    padding: 12px;
  }

  .vendor-card img {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }

  .vendor-card-info h3 {
    font-size: 12px;
  }

  .vendor-card-info p {
    font-size: 10px;
  }

  .check-icon {
    font-size: 16px;
    top: 8px;
    right: 8px;
  }
}