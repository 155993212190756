.accommodation-property-details {
  // display: flex;
  // gap: 20px;
  padding: 20px;
  background-color: var(--white-color);
  border-radius: 20px;

  .accommodation-left {
    // flex: 2;

    .image-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 10px;

      .grid-item {
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .large {
        grid-row: span 2;
      }
    }

    .property-title {
      font-size: 24px;
    }

    .property-features {
      margin-top: 10px;
      display: flex;
      gap: 15px;
      font-size: 14px;
      color: #ababab;
      font-weight: 700;
    }

    .property-more-features {
      margin-top: 10px;
      display: flex;
      font-size: 14px;
      color: #707070;
      background-color: #ebebeb;
      padding: 3px 10px;
      border-radius: 10px;
      font-weight: 700;
    }
  }

  .accommodation-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .location-map {
      width: 100%;
      height: 300px;
      border: 0;
      border-radius: 20px;
    }

    .payment-card {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

      h6 {
        margin-bottom: 10px;
        font-weight: 700;
      }

      .payment-details {
        margin-bottom: 20px;

        .detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          span:first-child {
            font-weight: bold;
          }

          &.total {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .accommodation-property-details {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .accommodation-property-details {
    margin-top: 100px;
  }
}
