.career_img_banner img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.career-section {
    background-color: var(--card-bg);
    border-radius: 16px;
    box-shadow: 1px 1px 10px -5px;
    padding: 50px;
}

@media only screen and (max-width: 576px) {
    .career_img_banner img {
        height: 200px;
    }

    .career-section {
        padding: 15px;
    }
}