.security-one-video-section-parent {
    padding: 2rem;

    @media (max-width: 768px) {
        .row{
            display: flex;
            flex-direction: column-reverse;

            .security-one-video-section-heading{
                margin-top: 20px;
            }
        }
    }

    .security-one-video-section-content {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
    }

    .security-one-video-section-heading {
        font-size: 2rem;
        font-weight: bold;
        color: var(--heading-color);
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

        .material-symbols-outlined {
            font-size: 40px;
        }
    }

    .security-one-video-section-description {
        font-size: 1rem;
        color: #5a5e78;
        margin-bottom: 2rem;
        max-width: 600px;
    }


    .security-one-video-section-video {
        max-width: 100%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        iframe {
            border-radius: 10px;
            border: 10px solid black;
            width: 100%; // Full width of the container
            aspect-ratio: 16 / 9; // Maintain 16:9 aspect ratio for video
            object-fit: cover; // Ensure the thumbnail fits within the iframe
        }
    }
}