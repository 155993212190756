.corporateTwo-home-testimonials {
  min-height: 100vh;
  padding: 50px 20px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Ftestimonialbanner.avif?alt=media&token=fd8a831b-4102-4b1b-a68f-de3b3c31107e");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8));
    z-index: 1;
  }

  .testimonials-content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .corporateTwo-home-testimonials-heading {
    text-align: center;
    color: white;

    h1 {
      font-size: 16px;
      font-weight: 600;
    }

    h2 {
      font-size: 36px;
      font-weight: 600;
      word-spacing: 3px;
    }
  }

  .corporateTwo-home-testimonials-container {
    width: 100%;
    max-width: 800px;
    height: 400px;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
  }

  .corporateTwo-home-testimonial-card {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: absolute;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    transform: translateX(100%);

    &.active {
      opacity: 1;
      transform: translateX(0);
    }

    &.prev {
      transform: translateX(-100%);
    }

    &.next {
      transform: translateX(100%);
    }

    .quote-icon {
      font-size: 3rem;
      color: var(--click-color); 
      position: absolute;
      top: 10px;
      z-index: 0;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: -50px;
    }

    .corporateTwo-home-testimonial-userinfo {
      text-align: center;

      img{
        border: none;
      }

      .review {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.5;
        color: #333;
        margin-bottom: 15px;
      }

      .user {
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--click-color);
      }
    }
  }

  .corporateTwo-home-testimonials-controls {
    display: flex;
    gap: 20px;

    button {
      background-color: transparent;
      border: 2px solid white;
      color: white;
      font-size: 1.5rem;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: white;
        color: #333;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
      }
    }
  }
}

// Media Queries
@media (max-width: 768px) {
  .corporateTwo-home-testimonials {
    padding: 40px 15px;

    .corporateTwo-home-testimonials-heading {
      h1 {
        font-size: 1rem;
      }

      h2 {
        font-size: 2.2rem;
      }
    }

    .corporateTwo-home-testimonials-container {
      height: 400px;
    }

    .corporateTwo-home-testimonial-card {
      padding: 20px;

      img {
        width: 80px;
        height: 80px;
      }

      .corporateTwo-home-testimonial-userinfo {
        .review {
          font-size: 1rem;
        }

        .user {
          font-size: 1.1rem;
        }
      }
    }

    .corporateTwo-home-testimonials-controls {
      button {
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .corporateTwo-home-testimonials {
    padding: 30px 10px;

    .corporateTwo-home-testimonials-heading {
      h1 {
        font-size: 0.9rem;
      }

      h2 {
        font-size: 1.8rem;
      }
    }

    .corporateTwo-home-testimonials-container {
      height: 400px;
    }

    .corporateTwo-home-testimonial-card {
      padding: 15px;

      img {
        width: 70px;
        height: 70px;
      }

      .corporateTwo-home-testimonial-userinfo {
        .review {
          font-size: 1rem;
        }

        .user {
          font-size: 1.25rem;
        }
      }
    }

    .corporateTwo-home-testimonials-controls {
      button {
        width: 35px;
        height: 35px;
        font-size: 1rem;
      }
    }
  }
}