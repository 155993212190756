.business-two-for-you {
  background-color: var(--white-color);
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 0; /* Ensure these are behind the content */
    opacity: 0.7;
  }

  &::before {
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background: linear-gradient(
      45deg,
      rgb(255, 118, 198) 0%,
      rgb(100, 172, 254) 100%
    );
    border-bottom-right-radius: 80%;
  }

  &::after {
    bottom: 0;
    right: 0;
    width: 300px;
    height: 300px;
    background: linear-gradient(
      135deg,
      rgb(255, 126, 201) 0%,
      rgb(111, 178, 255) 100%
    );
    border-top-left-radius: 80%;
  }

  .business-two-for-you-card-img-container {
    position: relative;
    border: 2px solid rgb(186, 185, 185);
  }

  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    text-align: center;
    padding: 5px 30px;
  }

  .business-two-for-you-card-img-left {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }

  .business-two-for-you-right-cards {
    z-index: 1;
    .business-two-for-you-card {
      display: flex;
      flex-direction: row;
      box-shadow: 0px 0px 13px -6px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.7);
      }

      .business-two-for-you-card-img-right {
        width: 100px;
        height: auto;
        object-fit: cover;
        border-right: 1px solid #e0e0e0;
        transition: transform 0.3s ease-in-out;
        border-radius: 5px;
      }

      .business-two-for-you-card-body {
        flex: 1;
        padding: 1rem;
        transition: transform 0.3s ease;

        .business-two-for-you-card-rating {
          font-size: 1.2rem;
          margin: 0.2rem 0;
        }

        .business-two-for-you-card-price,
        .business-two-for-you-card-discount {
          margin: 0.2rem 0;
        }

        p {
          position: relative;
          overflow: hidden;
        }

        p::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: var(--click-color);
          transform: translateX(-100%);
          transition: transform 0.3s ease;
        }

        p:hover::after {
          transform: translateX(0);
        }
      }
    }

    .business-two-for-you-card:hover .business-two-for-you-card-img-right {
      transform: scale(1.2);
    }
  }

  svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 50px;
  }
  svg text {
    animation: stroke 4s infinite alternate;
    // stroke-width: 2;
    font-size: 40px;
  }
  @keyframes stroke {
    0% {
      fill: transparent;
      stroke: rgba(54, 95, 160, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
    }
    80% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
      stroke-width: 2;
    }
    100% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
  // Media query for smaller screens (phone view)
  @media screen and (max-width: 1100px) {
    .wrapper {
      svg text {
        font-size: 30px; // Set the font size to 30px for smaller screens
      }
    }
  }
}
