
.ab_whatsapp_icon img {
    width: 30px !important;
    height: 30px !important;
}


@media only screen and (max-width: 991px) {

    .ab_whatsapp_icon img {
        width: 30px !important;
        height: 30px !important;
    }
}