.app-store-one-hero-container {
  padding: 2rem;

  .app-store-one-hero-content {
    .app-store-one-hero-title {
      color: var(--black-color);

      .app-store-one-hero-title-highlight {
        color: var(--logo-primary-color);
        // background: #e7e7ff;
        padding: 0 0.2rem;
        border-radius: 0.2rem;
        height: 40px;
      }
    }

    .app-store-one-hero-description {
      margin: 1rem 0;
    }

    .app-store-one-hero-buttons {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .app-store-one-hero-btn {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 0.3rem;
        cursor: pointer;
        transition: background 0.3s;

        &-primary {
          background: var(--click-color);
          color: #fff;
          border: none;

          &:hover {
            background: #5555e6;
          }
        }

        &-secondary {
          background: #fff;
          color: var(--click-color);
          border: 2px solid var(--click-color);

          &:hover {
            background: #f0f0ff;
          }
        }
      }
    }

    .app-store-one-hero-note {
      font-size: 0.9rem;
      color: var(--click-color);
      margin-top: 1rem;
    }

    .app-store-one-hero-works-with {
      margin-top: 2rem;

      span {
        font-size: 1rem;
        color: #4a4a4a;
      }

      .app-store-one-hero-icons {
        display: flex;
        gap: 0.5rem;
        margin-top: 0.5rem;

        .app-store-one-hero-trusted-by-image {
          width: auto;
          height: 3rem;
          animation: fadeIn 1s ease-in-out;
        }
      }
    }
  }
  .app-store-one-hero-visuals {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem; /* Rounded corners */
    overflow: hidden; /* Prevent pseudo-element overflow */
  }
  
  .app-store-one-hero-visuals::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem; /* Matches the parent border radius */
    padding: 2px; /* Border width */
    background: linear-gradient(90deg, transparent, var(--click-color), transparent);
    background-size: 400% 400%; /* Large size for smooth movement */
    animation: moveBorder 5s linear infinite;
    z-index: 1;
    box-sizing: border-box; /* Ensures the pseudo-element respects padding */
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
    -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: xor;
  }
  
  @keyframes moveBorder {
    0% {
      background-position: 0% 0%; /* Start at the top-left corner */
    }
    25% {
      background-position: 100% 0%; /* Move to the top-right corner */
    }
    50% {
      background-position: 100% 100%; /* Move to the bottom-right corner */
    }
    75% {
      background-position: 0% 100%; /* Move to the bottom-left corner */
    }
    100% {
      background-position: 0% 0%; /* Return to the top-left corner */
    }
  }
  
  .app-store-one-hero-visuals img {
    max-width: 100%;
    height: auto;
    border-radius: 1rem; /* Ensures the image matches the border radius */
    z-index: 2; /* Places image above the glowing border */
  }


  @media (max-width: 576px) {
    flex-direction: column-reverse;

    .app-store-one-hero-content {
      margin-top: 3rem;
      .app-store-one-hero-title {
        .app-store-one-hero-title-highlight {
          display: block;
          /* Moves currentText to a new line */
          margin-top: 10px;
          /* Optional: Adds spacing between rows */
        }
      }
    }

    .app-store-one-hero-visuals {
      img {
        margin-bottom: 30px;
      }
    }
  }

}