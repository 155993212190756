.aboutus {
  position: relative;
  height: 100%;
  display: flex;
  .aboutus_inner {
    // z-index: 999;
    .aboutus_bg {
      .aboutus_bg_img {
        width: 100%;
        img {
          width: 100%;
        }
        margin-bottom: 20px;
      }
      .aboutus_content {
        .aboutus_intro_section {
          padding: 15px 25px;
          display: grid;
          .card_section {
            display: grid;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            .card_section_header {
              display: grid;
              padding: 10px;
              margin-bottom: 10px;
              gap: 5px;
              .aboutus_intro_header {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin: 22px 0px;
              }
              .aboutus_explore_more {
                display: flex;
                margin-top: 20px;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                .aboutus_explore_more_header {
                  display: flex;
                  flex-direction: column;
                }
              }
            }

            .cards {
              flex-wrap: wrap;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 20px;
              justify-content: center;
              height: 250px;
              margin-bottom: 22px;

              .card {
                background-color: gainsboro;
                border: 0.5px solid #e5e7eb;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                transition: transform 0.5s, background-color 0.5s;
                cursor: pointer;
                padding: 20px;
                text-align: left;
                width: 100%;
                max-width: 300px;
                position: relative;

                /* Card Header Styling */
                .card_header {
                  position: absolute;
                  top: -20px; /* Adjust position relative to the card */
                  left: 20px; /* Adjust alignment inside the card */
                  background-color: #ffffff;
                  border-radius: 50%;
                  padding: 8px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                  border: 0.5px solid lightgray;

                  img {
                    // width: 32px; /* Small icon size */
                    // height: 32px;
                    object-fit: contain;
                  }
                }
                .card_content {
                  margin-top: 20px;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .card_title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #26617c;
                  }
                  .card_description {
                    padding: 7px 0;
                    font-size: 14px;
                    color: #4b5563;
                  }
                  .card_link {
                    font-size: 14px;
                    color: #0ea5e9;
                    font-weight: 500;
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;
                  }
                }
                &:hover {
                  background-color: var(--theme-lightmidnight-blue);
                  border-color: white;
                  color: white;
                  .card_title {
                    color: var(--theme-orange);
                  }
                  .card_description {
                    color: white;
                  }
                  .card_link {
                    color: white;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        .aboutus_explore_more_btn {
          position: relative;
          //   margin: 22px 0;
          display: grid;
          align-items: center;
          justify-content: space-between;
          .exp_bg {
            display: flex;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; /* Ensures the image covers the space without distortion */
              display: block;
              opacity: 0.8;
              filter: brightness(50%);
            }
          }

          .em_text_overlay {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);
            z-index: 2;
            text-align: center;
            color: #ffffff;

            padding: 10px;
            border-radius: 8px;
            .exp_btns {
              margin-top: 20px;
              display: flex;
              gap: 10px;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
