.security-one-latest-visitor-home-container {
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-top: 100px;
}

.security-one-latest-visitor-home-heading {
  font-size: 24px;
  font-weight: bold;
}

.filters {
  display: flex;
  gap: 15px;
  justify-content: end;

  .search-input,
  .date-picker {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    width: 100%;
  }
}

.security-one-latest-visitor-home-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.security-one-latest-visitor-home-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.security-one-latest-visitor-home-avatar div img {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid var(--grey-dark);
}

.security-one-latest-visitor-home-name {
  font-size: 18px;
  margin: 0px 0px 5px 15px;
  font-weight: bold;
}

.security-one-latest-visitor-home-date {
  font-size: 13px;
  margin: 0px 0px 5px 15px;
  color: var(--grey-dark);
}

.security-one-latest-visitor-home-city {
  font-size: 13px;
  color: var(--grey-dark);
  display: flex;
  justify-content: end;
}

.security-one-latest-visitor-home-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .security-one-latest-visitor-home-tag {
    background: #e0e7ff;
    color: #1d4ed8;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
  }
}
