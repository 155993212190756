.app-store-one-faq-container-parent {
    position: relative;
    padding: 50px 0px;
    background: url("https://www.expresshealthcaremd.com/wp-content/uploads/2023/06/hm-faq-desktop.jpg")
      no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
  
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit; /* Inherit the background from the parent */
      opacity: 1; /* Adjust the opacity as needed */
      z-index: -1; /* Ensure the background image stays behind the content */
    }
  }
  
  .app-store-one-faq-container {
    background: rgba(
      249,
      249,
      249,
      0.9
    ); /* Slightly transparent to let background image show through */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden; /* To ensure the background image stays within bounds */
  
    .app-store-one-faq-questions {
      cursor: pointer;
  
      .app-store-one-faq-question {
        padding: 15px;
        margin: 10px 0;
        background: #fff;
        border-radius: 5px;
        transition: background 0.3s, transform 0.3s;
        border: 1px solid rgb(201, 201, 201);
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        &:hover {
          background: #e1e1e1;
          transform: translateX(10px);
        }
  
        &.active {
          background: #d1e7ff;
        }
      }
    }
  
    .app-store-one-faq-answer {
      .app-store-one-faq-answer-content {
        background: var(--grey-light);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-right: 0.15em solid orange; /* Simulate caret */
        white-space: pre-wrap; /* Preserve whitespace and line breaks */
        position: relative; /* For positioning the cursor */
        overflow: hidden; /* To hide the cursor when not needed */
      }
    }
  
   
  } 
  
  @media (max-width: 767px) {
    .app-store-one-faq-container-parent {
      position: relative;
      padding: 50px 0px;
      background: none;
      background-size: cover;
      background-attachment: fixed;
    
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: inherit; /* Inherit the background from the parent */
        opacity: 1; /* Adjust the opacity as needed */
        z-index: 0; /* Ensure the background image stays behind the content */
      }
    }
  
    
    .app-store-one-faq-questions,
    .app-store-one-faq-answer {
      padding: 15px;
    }
  }
  