.corporateTwo-terms-header{
    height: 40vh;
    padding: 20px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url("./../../../../../../public/assets/img/t&cheader.png");
    background-repeat: no-repeat;
    background-position: center;

    &::after{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
        z-index: 5;
    }

    h1{
        font-size: 3rem;
        font-weight: 400;
        z-index: 10;
    }
}

@media (max-width:576px){
    .corporateTwo-terms-header{
        padding: 30px 10px;

        h1{
            font-size: 2rem;
            text-align: center;
        }
    }
}