.ecom_two {
  .banner-events {
    // margin-bottom: 50px;

    .event-container {
      background: #1e1e51;
      padding-top: 40px;
      padding-left: 0;
      padding-right: 0;

      .owl-nav {
        display: none !important;
      }

      .owl-item {
        transition: transform 0.3s ease, opacity 0.3s ease;

        .event-card {
          position: relative;

          .image-container {
            position: relative;

            .event-image {
              width: 100%;
              height: auto;
              aspect-ratio: 3/1.5;

              object-fit: contain;
              border-radius: 8px 8px 0px 0px;
              cursor: pointer;
              transition: transform 0.3s ease, opacity 0.1s ease,
                border 0.3s ease; /* Add transition here */
            }
          }

          .banner_button {
            position: relative;

            button.bnr_btn {
              background: blue;
              position: absolute;
              bottom: 38px;
              right: 10px;
              padding: 10px;
              border-radius: 5px;
              cursor: pointer;
              transition: padding 0.3s ease, bottom 0.3s ease;
            }

            button.bnr_btn1 {
              background: pink;
              position: absolute;
              bottom: 10px;
              right: 10px;
              padding: 10px;
              border-radius: 5px;
              cursor: pointer;
              transition: padding 0.3s ease, bottom 0.3s ease;
            }
          }
        }
      }

      .owl-item.active.center .event-image {
        transform: scale(1.2);
        opacity: 1;
        border: 3px solid red;
      }

      // Make other images dull
      .owl-item:not(.active.center) .event-image {
        transform: scale(0.8);
        opacity: 0.5;
      }
    }
  }

  // Responsive design
  @media (max-width: 767px) {
    .event-container {
      padding-top: 0px !important;
    }
  }
}

@media (max-width: 320px) {
  .banner-events .event-container {
    .owl-item {
      transition: none; // Disable transition on small screens

      .event-card .image-container .event-image {
        transform: none; // Remove scaling
        opacity: 1; // Keep full opacity
      }
    }
  }
}
