.address-page {
    padding: 2rem;
    min-height: 100vh;
    background-color: #f5f5f5;
  
    .address-container {
      max-width: 1200px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
      padding: 2rem;
  
      // Address List Styles
      .address-list-container {
        .address-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2rem;
  
          h1 {
            font-size: 1.8rem;
            color: #333;
          }
  
          button {
            padding: 0.8rem 1.5rem;
            background-color: var(--click-color);
            color: white;
            border: none;
            border-radius: 6px;
            cursor: pointer;
          }
        }
  
        .address-cards {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;
  
          .address-card {
            flex: 1;
            min-width: 300px;
            max-width: calc(33.333% - 1rem);
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            padding: 1.5rem;
            position: relative;
            transition: transform 0.2s, box-shadow 0.2s;
  
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            }
  
            &.default-address {
              border: 2px solid var(--click-color);
            }
  
            .address-card-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1rem;
  
              div {
                display: flex;
                align-items: center;
                gap: 0.5rem;
              }
  
              .default-label {
                background-color: var(--click-color);
                color: white;
                padding: 0.3rem 0.8rem;
                border-radius: 4px;
                font-size: 0.8rem;
              }
  
              .set-as-default {
                color: var(--click-color);
                cursor: pointer;
                font-size: 0.9rem;
  
                &:hover {
                  text-decoration: underline;
                }
              }
            }
  
            .address-card-actions {
              display: flex;
              gap: 0.8rem;
              margin-top: 1rem;
  
              .address-action-btn {
                padding: 0.5rem;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.2s;
  
                &.address-edit-btn {
                  background-color: #f5f5f5;
                  &:hover { background-color: #e0e0e0; }
                }
  
                &.address-delete-btn {
                  background-color: #ffebee;
                  color: #f44336;
                  &:hover { background-color: #ffcdd2; }
                }
              }
            }
          }
        }
      }
  
      // Address Form Styles
      .address-form-container {
        .form-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 2rem;
  
          .back-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            
            &:hover {
              background-color: #f5f5f5;
              border-radius: 50%;
            }
          }
        }
  
        .address-type-tabs {
          display: flex;
          gap: 1rem;
          margin-bottom: 2rem;
  
          .address-type-tab {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.8rem 1.5rem;
            border: 1px solid #e0e0e0;
            border-radius: 6px;
            background: none;
            cursor: pointer;
            transition: all 0.2s;
  
            &.active {
              background-color: var(--click-color);
              color: white;
              border-color: var(--click-color);
            }
  
            &:hover:not(.active) {
              background-color: #f5f5f5;
            }
          }
        }
  
        form {
          display: flex;
          flex-wrap: wrap;
          gap: 2rem;
  
          .form-column {
            flex: 1;
            min-width: 300px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
  
            label {
              font-weight: 500;
              color: #555;
            }
  
            input {
              padding: 0.8rem;
              border: 1px solid #e0e0e0;
              border-radius: 6px;
              font-size: 1rem;
  
              &:focus {
                outline: none;
                border-color: var(--click-color);
              }
            }
          }
  
          .form-actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;
  
            .button-group {
              display: flex;
              gap: 1rem;
  
              button {
                padding: 0.8rem 1.5rem;
                border-radius: 6px;
                cursor: pointer;
                transition: background-color 0.2s;
  
                &[type="submit"] {
                  background-color: var(--click-color);
                  color: white;
                  border: none;
                }
  
                &[type="button"] {
                  background-color: #f5f5f5;
                  border: 1px solid #e0e0e0;
  
                  &:hover {
                    background-color: #e0e0e0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Media Queries
  @media (max-width: 1440px) {
    .address-page {
      padding: 1.5rem;
      
      .address-container {
        max-width: 1100px;
  
        .address-cards {
          .address-card {
            max-width: calc(33.333% - 1rem);
          }
        }
  
        .address-header {
          h1 {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  
  @media (max-width: 1140px) {
    .address-page {
      .address-container {
        max-width: 900px;
        padding: 1.5rem;
  
        .address-cards {
          .address-card {
            max-width: calc(50% - 1rem);
            padding: 1.25rem;
  
            .address-card-header {
              h3 {
                font-size: 1.1rem;
              }
            }
          }
        }
  
        .address-form-container {
          .form-header {
            h2 {
              font-size: 1.4rem;
            }
          }
  
          form {
            gap: 1.5rem;
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .address-page {
      padding: 0.75rem;

      .address-container {
        padding: 1rem;
        margin: 0.5rem;

        .address-header {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.75rem;
          margin-bottom: 1.25rem;

          h1 {
            font-size: 1.2rem;
            margin: 0;
          }

          button {
            width: 100%;
            padding: 0.6rem 1rem;
            font-size: 0.9rem;
            border-radius: 4px;
          }
        }

        .address-cards {
          gap: 0.75rem;

          .address-card {
            max-width: 100%;
            padding: 1rem;

            .address-card-header {
              margin-bottom: 0.6rem;

              h3 {
                font-size: 0.95rem;
              }

              .default-label {
                padding: 0.2rem 0.5rem;
                font-size: 0.7rem;
              }

              .set-as-default {
                font-size: 0.8rem;
              }
            }

            .address-card-body {
              p {
                font-size: 0.85rem;
                line-height: 1.4;
              }
            }

            .address-card-actions {
              margin-top: 0.75rem;
              
              .address-action-btn {
                padding: 0.35rem;
                
                .material-symbols-outlined {
                  font-size: 1.1rem;
                }
              }
            }
          }
        }

        // Form specific styles for mobile
        .address-form-container {
          .form-header {
            margin-bottom: 1rem;
            
            h2 {
              font-size: 1.1rem;
            }

            .back-button {
              padding: 0.35rem;
              margin-right: 0.25rem;

              .material-symbols-outlined {
                font-size: 1.2rem;
              }
            }
          }

          .address-type-tabs {
            gap: 0.5rem;
            margin-bottom: 1.25rem;

            .address-type-tab {
              padding: 0.5rem 0.75rem;
              font-size: 0.8rem;
              border-radius: 4px;

              .material-symbols-outlined {
                font-size: 1rem;
              }
            }
          }

          form {
            gap: 0.75rem;

            .form-column {
              min-width: 100%;

              label {
                font-size: 0.85rem;
                margin-bottom: 0.25rem;
                color: #666;
              }

              input {
                padding: 0.6rem;
                font-size: 0.9rem;
                height: 40px; // Fixed height for better touch targets
              }
            }

            .form-actions {
              margin-top: 1rem;
              
              label {
                font-size: 0.85rem;
                
                input[type="checkbox"] {
                  margin-right: 0.5rem;
                  height: 16px;
                  width: 16px;
                }
              }

              .button-group {
                width: 100%;
                margin-top: 0.75rem;
                
                button {
                  height: 40px;
                  font-size: 0.85rem;
                  padding: 0 1rem;

                  &[type="submit"] {
                    flex: 2;
                  }

                  &[type="button"] {
                    flex: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .address-page {
      padding: 0.5rem;
      background-color: #fafafa;

      .address-container {
        margin: 0.25rem;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        .address-type-tabs {
          .address-type-tab {
            min-width: auto;
            flex: 1;
          }
        }

        .form-actions {
          .button-group {
            button {
              height: 38px;
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 350px) {
    .address-page {
      padding: 0;

      .address-container {
        margin: 0;
        border-radius: 0;
        padding: 0.75rem;

        .address-header {
          h1 {
            font-size: 1.1rem;
          }
        }

        .address-card {
          padding: 0.75rem;
          margin-bottom: 0.5rem;

          .address-card-header {
            gap: 0.35rem;

            h3 {
              font-size: 0.9rem;
            }
          }

          .address-card-body {
            p {
              font-size: 0.8rem;
              line-height: 1.3;
            }
          }
        }

        .address-form-container {
          .form-header {
            h2 {
              font-size: 1rem;
            }
          }

          .address-type-tabs {
            .address-type-tab {
              padding: 0.4rem 0.6rem;
              font-size: 0.75rem;
            }
          }

          form {
            .form-column {
              label {
                font-size: 0.8rem;
              }

              input {
                padding: 0.5rem;
                font-size: 0.85rem;
                height: 36px;
              }
            }

            .form-actions {
              .button-group {
                button {
                  height: 36px;
                  font-size: 0.8rem;
                  padding: 0 0.75rem;
                }
              }
            }
          }
        }
      }
    }
  }