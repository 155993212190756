.wishlist-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
        text-align: center;
        margin-bottom: 2rem;
        color: #333;
    }

    .empty-wishlist-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .empty-wishlist {
            text-align: center;
            font-size: 1.2rem;
            color: #666;
        }

        button{
            padding: 10px 20px;
            border-radius: 20px;
            background-color: var(--click-color);
            color: white;
            border: none;
            outline: none;
            cursor:pointer;
        }
    }

    

    .wishlist-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 2rem;

        .wishlist-item {
            border: 1px solid #eee;
            border-radius: 8px;
            overflow: hidden;
            transition: transform 0.2s;
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            &:hover {
                transform: translateY(-5px);
            }

            .item-image {
                height: 200px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .item-details {
                padding: 1rem;

                h3 {
                    margin: 0 0 0.5rem;
                    font-size: 1.2rem;
                }

                .description {
                    color: #666;
                    font-size: 0.9rem;
                    margin-bottom: 1rem;
                }

                .price {
                    font-weight: bold;
                    color: #2c2c2c;
                    font-size: 1.1rem;
                    margin-bottom: 1rem;
                }

                .item-actions {
                    display: flex;
                    gap: 1rem;

                    button {
                        flex: 1;
                        padding: 0.5rem;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.2s;

                        &.add-to-cart {
                            background-color: #4CAF50;
                            color: white;

                            &:hover {
                                background-color: #45a049;
                            }
                        }

                        &.remove {
                            background-color: #f44336;
                            color: white;

                            &:hover {
                                background-color: #da190b;
                            }
                        }
                    }
                }
            }
        }
    }
}