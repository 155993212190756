.corporate-two-features-container {
    -webkit-user-select: none;
    /* Disable text selection on WebKit browsers */
    -moz-user-select: none;
    /* Disable text selection on Firefox */
    -ms-user-select: none;
    /* Disable text selection on older IE versions */
    user-select: none;

    .corporate-two-features-features-header-parent {
        display: flex;
        align-items: center;
        justify-content: center;

        .corporate-two-features-features-header {
            text-align: center;

            h1 {
                font-size: 36px;
                font-weight: 600;
                color: var(--black-color);
            }

            p {
                font-size: 16px;
                font-weight: 600;
                color: var(--subheading-color);
            }
        }
    }

    .corporate-two-features-features-content {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        /* Space between cards */
        .corporate-two-features-feature-card {
            display: flex;
            flex-direction: column;
            /* Align content vertically */
            justify-content: center;
            /* Distribute space evenly */
            align-items: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            /* Optional: Add shadow for design */
            border-radius: 8px;
            /* Optional: Rounded corners */
            background-color: var(--card-bg);
            /* Optional: Background color */
            padding: 16px;
            /* Padding inside the card */
            width: 100%;
            /* Responsive width */
            max-width: 300px;
            /* Set a maximum width */
            height: auto;
            /* Make height responsive */
            min-height: 200px;
            /* Ensure a minimum consistent height */

            .card-slip {
                position: absolute;
                top: 0;
                left: 0;
                width: 50px; /* Adjust based on your design */
                height: 20px; /* Adjust based on your design */
                background-color: var(--logo-primary-color); /* Light gray background */
                color: white; /* Text color */
                font-size: 15px; /* Font size */
                font-weight: 700;
                text-align: center;
                line-height: 20px; /* Match the height for vertical centering */
                border-bottom-right-radius: 5px; /* Rounded corner */
                z-index: 1;
              }
              
            h3 {
                font-size: 1.1rem;
                font-weight: 700;
            }

            .corporate-two-features-drag-icon {}

            .corporate-two-features-image-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100px;
                /* Consistent image height */
                overflow: hidden;
                border-radius: 8px;
                /* Optional */
                background-color: #f4f4f4;

                /* Placeholder background */
                img {
                    max-width: 100%;
                    max-height: 90%;
                    object-fit: cover;
                }
            }
        }
    }
}