.Education-One-Work-page {
  padding-bottom: 50px;

  .Education-One-Work-left {
  
    h2.Education-One-Work-title {
      color: #264d8c;
      font-size: 35px;
      text-transform: uppercase;
    }
  }
  .Education-One-Work-right{
   
  .Education-One-Work-images {
    display: flex;

    align-items: center;
    

    .Education-One-Work-image-item {
      position: relative;
      display: flex;
      width:25%;
      flex-direction: column;
      align-items: center;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;


      &:nth-child(1) {
        animation-name: bounce-down;
      }

      &:nth-child(2) {
        animation-name: bounce-up;
      }

      &:nth-child(3) {
        animation-name: bounce-down;
      }

      &:nth-child(4) {
        animation-name: bounce-up;
      }

      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        border: 5px solid transparent; /* Default border color */
        border: none;
        clip-path: circle(50%);
        cursor: pointer;

        object-fit: cover; // Ensures the image covers the container without distortion
        display: block;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        transition: border 0.3s ease, background-color 0.3s ease;
      }
      img:hover {
        transform: scale(1.1); /* Zoom effect on hover */
        border: 5px solid #264d8c; /* Highlighted border color */
        background-color: #e0e0e0; /* Background color on hover */
      }

      img:active {
        transform: scale(1.2); /* Zoom effect on click */
      }

      .education-card {
        text-align: center;
        margin-top: 15px;
        
        .education-work-contain {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .education-work-para {
          font-size: 16px;
          color: #555;
        }
      }
    }
  }
}
}
@keyframes bounce-up {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

@keyframes bounce-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
}
