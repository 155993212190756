.app-store-one-counter-parent {
  text-align: center;
  padding: 40px 20px;
  position: relative;
  background-image: url('https://comealong.spsm.se/Assets/img/Numbers.png?anchor=center&mode=crop&width=954&height=334');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  // Black overlay
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80); // Black overlay with 50% opacity
    z-index: 1;
  }

  .app-store-one-counter-counter-stats {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    position: relative;
    z-index: 2; // Ensure stats appear above the overlay

    .app-store-one-counter-stat-item {
      position: relative;
      background: #fff;
      border: 2px solid #e3e6ea;
      border-radius: 8px;
      padding: 20px 15px;
      width: 250px;
      text-align: center;
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        background: rgba(173, 216, 230, 0.4); // Light blue
        border-radius: 50%;
        transition: width 2s, height 2s;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      &.active::after {
        width: 300%;
        height: 300%;
      }

      .app-store-one-counter-icon {
        font-size: 20px;
        color: var(--logo-primary-color);
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        width: 50px;
        height: 50px;
        border: 1px solid var(--logo-primary-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        span {
          font-size: 24px;
        }
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        margin: 20px 0 5px;
        color: var(--black-color);
      }

      p {
        font-size: 14px;
        color: #6c757d;
      }
    }
  }
}
