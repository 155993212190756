.sidebar_nav_logo_only img {
    width: 75px;
}

.sidebar_nav_logo_only button {
    padding: 0px;
    margin: 0px;
}

.sidebar_nav_logo_only button span {
    font-size: 30px;
    color: var(--click-color);
}

@media only screen and (max-width: 767px) {
    .sidebar_nav_logo_only img {
        width: 65px;
    }
}