.ecom_two {background:white;
  
  .section_header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 22px;
    margin-top:35px;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      gap: 10px;
    }   

    .btn_inr {
      margin-left: auto;
    }
    
    
  
    h2.title {
      
      font-size: 28px;
      color: #202226;
      line-height: 28px;
      font-weight: 700;
    }
  

    h6.sub_title{
      font-size: 14px;
      line-height: 21px;
      color: #7a7c82;
      font-weight: 500;
      margin-top: 4px;
    }
    img.icon{
      height: 30px;
      width: 30px;
    }
  }
  .my_owl_carousel {
    position: relative;
    .owl-nav {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: space-between;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: 0px;
      button{
        background: var(--click-color);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
        cursor: pointer;
        &.owl-prev{
          position: relative;
          left: -22px;
        }
        &.owl-next{
          position: relative;
          right: -22px;
        }
        span{
          font-size: 50px;
          color: white;
          line-height: 0px;
          position: relative;
          top: -2px;
        }
        
      }
  }
  }
}
// Small screens
@media (max-width: 767px) {
  .ecom_two {
    .section_header {
      gap: 8px;
      margin-bottom: 18px;
      margin-top: 25px;

      h2.title {
        font-size: 24px; 
        line-height: 24px;
      }

      h6.sub_title {
        font-size: 12px;
        line-height: 18px;
      }

      img.icon {
        height: 25px;
        width: 25px;
      }
    }
  }
}


@media (max-width: 320px) {
  .ecom_two {
    .section_header {
      gap: 8px;
      margin-bottom: 12px;
      margin-top: 20px;

      h2.title {
        font-size: 15px; 
        line-height: 18px;
      }

      h6.sub_title {
        font-size: 8px; 
        line-height: 14px;
      }


    }
  }
}
