.order-details-page-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  .order-details-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;

    h2 {
      color: #333;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid #f0f0f0;
      font-size: 1.8rem;
    }

    h3 {
      color: #444;
      margin: 1.5rem 0 1rem;
      font-size: 1.4rem;
    }

    .order-info {
      .info-row {
        display: flex;
        margin-bottom: 0.8rem;
        align-items: center;
        flex-wrap: wrap;

        .label {
          font-weight: 600;
          min-width: 150px;
          color: #666;
        }

        .value {
          color: #333;
          word-break: break-word;
        }
      }
    }

    .customer-info {
      margin: 1.5rem 0;
      padding: 1rem;
      background: #f8f8f8;
      border-radius: 6px;
    }

    .products-list {
      margin-top: 1.5rem;

      .product-item {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem;
        border-bottom: 1px solid #eee;
        flex-wrap: wrap;
        gap: 0.5rem;

        &:last-child {
          border-bottom: none;
        }

        .product-name {
          flex: 1;
          min-width: 200px;
        }

        .product-quantity {
          margin: 0 1rem;
          color: #666;
        }

        .product-price {
          font-weight: 600;
          min-width: 80px;
          text-align: right;
        }
      }
    }

    .shipping-info {
      p {
        line-height: 1.6;
        word-wrap: break-word;
      }
    }
  }

  .loading {
    text-align: center;
    padding: 2rem;
    color: #666;
  }

  .error {
    text-align: center;
    padding: 2rem;
    color: #dc3545;
  }

  .order-barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .barcode-image {
      max-width: 40%;
      height: auto;
    }

    .barcode-number {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      font-family: monospace;
    }
  }
}

// Media Queries
@media screen and (max-width: 768px) {
  .order-details-page-container {
    padding: 1rem;

    .order-details-card {
      padding: 1.5rem;

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1.2rem;
      }

      .order-info {
        .info-row {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.3rem;
          margin-bottom: 1.2rem;

          .label {
            min-width: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .order-details-page-container {
    padding: 0.5rem;

    .order-details-card {
      padding: 1rem;
      border-radius: 4px;

      h2 {
        font-size: 1.3rem;
      }

      .products-list {
        .product-item {
          flex-direction: column;
          gap: 0.3rem;

          .product-name {
            min-width: unset;
          }

          .product-quantity {
            margin: 0;
          }
        }
      }

      .order-barcode {
        padding: 10px;

        .barcode-number {
          font-size: 12px;
        }
      }
    }
  }
}
