/* Base styles */
.ucs_body {
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.userlist .edit_bg {
  width: 20px;
  height: 20px;
  top: -12px;
  right: 0px;
}

.ucs_body .ucsb_left {
  padding: 15px 0px 15px 15px;
  z-index: 2;
}

.ucs_body .ucsb_left:after {
  content: "";
  width: 120%;
  height: 135%;
  position: absolute;
  top: -12px;
  z-index: -1;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  left: 0;
}

.customer .ucs_body .ucsb_left:after {
  background: var(--click-color);
}

.dealer .ucs_body .ucsb_left:after {
  background: var(--pink);
}

.admin .ucs_body .ucsb_left:after {
  background: var(--peach-color);
}

.superAdmin .ucs_body .ucsb_left:after {
  background: red;
}

.ucs_body .ucsb_right {
  padding: 6px 15px 25px 0px;
  width: 100%;
}

.ucs_header h6 {
  color: #979595;
  font-size: 12px;
}

.userlist-popup-parent-div {
  display: flex;
  flex-direction: column;
}

.ucs_header h5 {
  font-size: 15px;
  padding-top: 2px;
  color: var(--black-color);
  opacity: 0.9;
}

.userlist .img_container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

h4.u_status {
  background: var(--dark-green);
  border-radius: 10px 0px 0px 10px;
  color: var(--white-color);
}

.inactive h4.u_status {
  background: #8b8b8b;
  color: var(--black-color);
  opacity: 0.5;
}

.userlist .img_container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.ucs_body h4 {
  font-size: 16px;
  padding-top: 3px;
  color: grey;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ucs_body h4.u_name {
  font-size: 22px;
  font-weight: 800;
  color: var(--dark-blue);
  text-transform: capitalize;
  margin-bottom: -5px;
  margin-top: 6px;
}

.ucs_footer {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  bottom: -24px;
  display: flex;
}

.user_card {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 45px;
  margin-bottom: 100px;
}

.user_card_single {
  width: 32%;
}

.ucs_footer h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  text-transform: capitalize;
}

h4.u_role.relative {
  border-radius: 0px 10px 10px 0px;
  color: white;
}

.admin .u_role.relative {
  background: var(--peach-color);
  border-radius: 0px 10px 10px 0px;
}

.customer .u_role.relative {
  background: var(--click-color);
  border-radius: 0px 10px 10px 0px;
}

.front_desk .u_role.relative {
  background: var(--pink);
  border-radius: 0px 10px 10px 0px;
}

.ucs_header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.pc_search_y_year {
}

@media only screen and (max-width: 1199px) {
  .user_card_single {
    width: 48%;
  }
}

@media only screen and (max-width: 767px) {
  .user_card_single {
    width: 100%;
  }

  .ucs_body h4 {
    font-size: 14px;
  }

  .ucs_body .ucsb_right {
    padding: 8px 8px 18px 0px;
  }

  .ucs_body .ucsb_left {
    padding: 8px 0px 8px 8px;
  }

  .userlist .img_container {
    width: 75px;
    height: 75px;
  }

  .ucs_footer h4 {
    font-size: 12px;
    padding: 8px 12px;
  }

  .ucs_footer {
    bottom: -16px;
  }

  .ucs_body h4.u_name {
    font-size: 18px;
  }

  .user_card {
    grid-row-gap: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .user_list_filter_search {
    margin-top: 0px;
    padding: 20px;
  }

  .user_numbers-stats .user_numbers-admin {
    font-size: 10px;
  }

  .user_numbers-stats .user_numbers-total {
    font-size: 10px;
  }

  .user_numbers-stats .user_numbers-customers {
    font-size: 10px;
  }

  .user_numbers-stats .user_numbers-active {
    font-size: 10px;
  }

  .user_numbers-stats .user_numbers-inactive {
    font-size: 10px;
  }
}

/* Styles for the combined card */
.search_and_stats_card {
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.user_numbers-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.user_numbers-stats p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #333;
}

.user_numbers-stats .user_numbers-active {
  color: var(--dark-green);
}

.user_numbers-stats .user_numbers-inactive {
  color: #8b8b8b;
}

.user_numbers-stats .user_numbers-total {
  color: var(--dark-blue);
}

.user_numbers-stats .user_numbers-customers {
  color: var(--click-color);
}

.user_numbers-stats .user_numbers-admin {
  color: var(--peach-color);
}

/* Filter UI Styles */
.user_list_filter_search {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
  padding: 30px;
}

.search_bar input {
  width: 100%;
  padding: 8px 12px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.user_list_filter_dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user_list_filter_dropdown select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.icon-container {
  cursor: pointer;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  cursor: pointer;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.overlay-stop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
