.business-three-hero-hero-section {
  display: flex;
  justify-content: space-between;

  .business-three-hero-hero-left {
    width: 63%;
    position: relative;
    margin-top: 10px;

    .business-three-hero-owl-theme .owl-nav [class*="owl-"] {
      display: none;
    }

    .business-three-hero-carousel-item {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .business-three-hero-image-wrapper {
        flex: 1;
        display: contents;

        img {
          width: 66%;
          height: 580px;
          border-radius: 8px;
        }
      }

      .business-three-hero-carousel-text {
        flex: 1;
        color: black;
        padding: 20px 0px;
        position: absolute;

        h1 {
          font-size: 3.6rem;
          width: 70%;
          font-weight: 700;
          font-family: system-ui;
          color: #4b4b4b;
        }

        p {
          width: 50%;
          font-size: 1.2rem;
          color: var(--black-color);
        }
      }
    }

    .business-three-hero-search-bar {
      position: absolute;
      border: 1px solid rgb(195, 195, 195);
      top: 75%;
      z-index: 1;
      width: 80%;
      display: flex;
      align-items: center;
      background: white;
      border-radius: 8px;
      padding: 10px;
      justify-content: space-between;

      input {
        border: none;
        padding: 10px;
        font-size: 1rem;
      }

      button {
        border: none;
        background: var(--click-color);
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 1rem;
      }
    }
  }

  .business-three-hero-hero-right {
    width: 35%;

    .business-three-hero-hero-card {
      position: relative;
      display: flex;
      padding: 10px 0px;
      border-radius: 8px;
      overflow: hidden;
      /* Ensures overlay does not spill out of the card */
      transition: color 0.3s;
      /* Smooth transition for text color */

      img {
        width: 100%;
        height: 180px;
        border-radius: 8px;
        transition: opacity 0.3s;
        /* Smooth transition for image opacity */
      }

      div {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        position: absolute;
        margin: 25px 30px;
        transition: color 0.3s;
        /* Smooth transition for text color */

        h3 {
          font-size: 1.2rem;
          color: black;
          font-weight: 700;
          line-height: 30px;
        }

        span {
          background-color: white;
          border-radius: 50%;
          width: 33px;
          height: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--same-black-color);
        }
      }

      &:hover {
        img {
          opacity: 0.7;
          /* Optional: Adjust image opacity to enhance overlay effect */
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg,
              var(--click-color) 11.46%,
              rgba(255, 255, 255, 0) 67.58%);
          z-index: 1;
          /* Ensure the overlay is above the image */
        }

        div {
          color: white;
          /* Text color change on hover */

          h3 {
            z-index: 1;
            color: white;
            /* Text color change on hover */
          }

          span {
            z-index: 1;
            color: black;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        /* Adjust as needed */
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(144, 238, 144, 0.4);
        /* Adjust color to match your overlay */
        z-index: 2;
        /* Ensure the arrow is above the overlay */
      }
    }
  }
}

.home-three-hero-banner-below-parent {
  border: 1px solid rgb(215, 215, 215);
  padding: 30px 0px;

  .material-symbols-outlined {
    color: var(--click-color);
    font-size: 33px;
    margin: 0px 10px;
    font-weight: 400;
  }

  span {
    color: var(--black-color);
    font-weight: bold;
  }
}



@media only screen and (max-width: 767px) {
  .business-three-hero-owl-theme {
    display: none !important;
  }

  .business-three-hero-hero-section {

    .business-three-hero-hero-left{
      display: none;
    }
  

  .business-three-hero-hero-right {
      width: 100%;
      margin-top: -35px;
    }
  }

  .home-three-hero-banner-below-parent {  
    .material-symbols-outlined {
      color: var(--click-color);
      font-size: 20px;
      margin: 0px 10px;
      font-weight: 400;
    }
  
    span {
      color: var(--black-color);
      font-weight: bold;
    }
    h5{
      font-size: 1rem;
    }
  }
}