.accommodation-booking-dashboard-container {
    display: flex;
    background-color: var(--logo-primary-color);

    .accommodation-booking-dashboard-sidebar {
        width: 250px;
        background-color: var(--logo-primary-color);
        color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .accommodation-booking-dashboard-logo {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
        }

        .accommodation-booking-dashboard-nav ul {
            list-style: none;
            padding: 0;
            margin-top: 50px;

            li {
                padding: 5px 10px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                margin-top: 10px;

                &.active {
                    background-color: var(--click-color);
                    border-radius: 5px;
                }
            }
        }
    }

    .accommodation-booking-dashboard-content {
        flex: 1 1;
        margin: 20px;
        background: #f9f9f9;
        border-radius: 20px;
        padding: 20px;

        .accommodation-booking-dashboard-top-nav {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            ul {
                display: flex;
                list-style: none;

                li {
                    margin-right: 20px;
                    cursor: pointer;

                    &.active {
                        font-weight: bold;
                        border-bottom: 2px solid #2c1d67;
                    }
                }
            }

            .accommodation-booking-dashboard-search {
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 36%;
            }
        }

        .accommodation-booking-dashboard-listings {
            display: flex;

            .accommodation-booking-dashboard-list {
                flex: 2;
                margin-right: 20px;

                .accommodation-booking-dashboard-listing-card {
                    display: flex;
                    padding: 10px;
                    background: #fff;
                    border-radius: 15px;
                    margin-bottom: 10px;
                    align-items: center;
                    box-shadow: 0px 1px 45px -30px;
                    cursor: pointer;

                    &.active {
                        border: 2px solid var(--logo-primary-color);
                    }

                    img {
                        width: 120px;
                        height: 80px;
                        margin-right: 10px;
                        border-radius: 5px;
                    }

                    .accommodation-booking-dashboard-listing-info {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        p {
                            color: var(--grey-dark);
                            font-size: 13px;
                            display: flex;
                            align-items: center;
                            span{
                                font-size: 17px;   
                            }
                        }

                        h6 {
                            margin: 0;
                            font-weight: 600;
                        }

                        >div:nth-child(2) {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                    }
                }
            }

            .accommodation-booking-dashboard-details {
                flex: 1;
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0px 1px 45px -30px;
                text-align: center;
            
                .accommodation-booking-dashboard-details-image {
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                    margin-bottom: 15px;
                }
            
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
            
                .accommodation-booking-dashboard-details-location {
                    color: #6c757d;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    margin-bottom: 10px;
                }
            
                .accommodation-booking-dashboard-details-meta {
                    color: #6c757d;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    margin-bottom: 15px;
                }
            
                h4 {
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            
                .accommodation-booking-dashboard-amenities {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 20px 0;
                    font-size: 14px;
            
                    li {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #6c757d;
                        margin-bottom: 8px;
            
                        span {
                            color: #2c1d67;
                        }
                    }
                }
            
                .accommodation-booking-dashboard-gallery {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: center;
            
                    .accommodation-booking-dashboard-gallery-item {
                        width: calc(33.333% - 10px);
                        height: auto;
                        border-radius: 5px;
                    }
                }
            }
            
        }
    }
}

@media (max-width: 768px) {
    .accommodation-booking-dashboard-container {
        flex-direction: column;

        .accommodation-booking-dashboard-sidebar {
            width: 100%;
        }

        .accommodation-booking-dashboard-content {
            .accommodation-booking-dashboard-listings {
                flex-direction: column;

                .accommodation-booking-dashboard-list {
                    margin-right: 0;
                }
            }
        }
    }
}