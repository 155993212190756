.ecom_two {
  .experience {
    margin-bottom: 40px;

    .experience-images {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }

    .image-item {
      width: 15%;
    }

    .image-item img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
}

// Responsive design for different breakpoints
@media (max-width: 1199px) {
  .experience {
    margin-bottom: 35px;

    .experience-images {
      gap: 8px;

      .image-item {
        width: 18%;
      }
    }
  }
}

@media (max-width: 991px) {
  .experience {
    margin-bottom: 30px;

    .experience-images {
      gap: 8px;

      .image-item {
        width: 20%;
      }
    }
  }
}

@media (max-width: 767px) {
  .ecom_two {

    
    .experience {
            margin-bottom: 25px;
  
      .experience-images {
        display: block;
        white-space: nowrap;
        overflow: auto;
      }
  
      .image-item {
        width: 40%;
      display: inline-block;
      margin-right: 15px;
      white-space: normal;
      } 
      
      

  
    }
  }
 
}


