.default_template_team {
    &_team_prnt_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    &_image_wrapper {
        position: relative;
        margin: 0px 10px;

        .default_template_team_offer_img_carousel {
            width: 100%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .default_template_team_edit_icon {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.3s ease;

            &:hover {
                background-color: var(--click-color);
                transform: scale(1.1);
                color: white;
            }
        }
    }

    &_popup_content {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        max-width: 90%;
        width: 400px;
        text-align: center;

        img {
            width: 100%;
            margin-bottom: 15px;
            border: 2px solid #ddd;
            border-radius: 5px;
        }

        input[type="text"] {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 14px;
        }

        .products-status-switch {
            margin: 10px 0;
        }
    }

    &_popup_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    &_popup {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        width: 400px;
        max-width: 90%;
        text-align: center;

        h3 {
            margin-bottom: 20px;
            font-size: 18px;
        }
    }

    &_file_input {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }

    &_selected_img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        border: 2px solid #ddd;
        border-radius: 5px;
    }

    &_page_url_input {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
    }

    &_offer_img_carousel {
        width: 100%;
        height: auto;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
}