.gallery_parent_div {
    margin-top: 0px;
    margin-bottom:60px;
  }

  .section-title h2{
    color: var(--subheading-color);
  }
  
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .fullscreen-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .show_gallery_data {
    background: linear-gradient(to right, var(--click-color), var(--dark-blue));
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.4s ease, background 0.4s ease;
    overflow: hidden;
    position: relative;
  }
  
  .show_gallery_data_images {
    cursor: pointer;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.4s ease, opacity 0.3s ease;
    z-index: 1;
  }
  
  /* Hover effect with overlay */
  /* .show_gallery_data:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, var(--click-color), var(--dark-blue));
  } */
  
  /* .show_gallery_data:hover .show_gallery_data_images {
    transform: scale(1.1);
    opacity: 0.9;
  } */
  
  /* Gradient overlay for hover effect */
  /* .show_gallery_data::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7));
    transition: opacity 0.4s ease;
    opacity: 0;
    z-index: 2;
  } */
  
  .show_gallery_data:hover::before {
    opacity: 1;
  }
  
  /* Text captions for hover */
  .gallery-hover-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    z-index: 3;
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
  
  .show_gallery_data:hover .gallery-hover-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  .gallery-fullscreen-icon {
    position: absolute;
    color: white;
    font-size: 25px;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
    background-color: #00000063;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
  
  @media only screen and (max-width: 600px) {
    .gallery-fullscreen-icon {
      font-size: 20px;
      bottom: 5px;
      right: 10px;
      width: 30px;
      height: 30px;
    }
  
    .show_gallery_data_images {
      height: 150px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .show_gallery_data_images {
      height: 200px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .show_gallery_data_images {
      height: 250px;
    }
  }
  