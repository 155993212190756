.enquiries-data-all-contacts {
    padding: 20px;

    .enquiries-data-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            color: var(--heading-color);
        }

        .enquiries-data-header-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            flex-wrap: wrap;
            /* Allow items to wrap on small screens */
        }

        .button-wrapper {
            flex: 0 1 auto;
            /* Button stays on top */
        }

        .search-wrapper {
            flex: 1 0 100%;
            /* Search takes full width in smaller screens */
            margin-top: 10px;
            /* Space above search bar */
        }

        .search-bar {
            padding: 8px 20px;
            width: 100%;
            /* Takes full width in mobile */
            border-radius: 5px;
            border: 1px solid #ccc;
            outline: none;
        }
    }


    /* Table layout for larger screens */
    .enquiries-data-contacts-table {
        width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                background-color: #f7f7f7;

                th {
                    text-align: left;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: bold;
                    color: var(--sub-heading-color);
                    border: 1px solid var(--grey-dark);
                }
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #ececec;
                }

                &:hover {
                    background-color: #eef7ff;
                }


                .enquiries-data-contacts-message {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /* Show only 3 lines */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                td {
                    padding: 10px;
                    border: 1px solid var(--grey-dark);
                    font-size: 14px;

                    .enquiries-data-rating {
                        color: #ff9800;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .enquiries-data-error {
        color: red;
        margin-top: 10px;
    }

    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        backdrop-filter: blur(5px);
        /* Adds blur effect for the background */
    }

    .popup-content {
        background: white;
        padding: 20px;
        border-radius: 12px;
        max-width: 500px;
        width: 100%;
        position: relative;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        transform: translateY(-30px);
        /* Start position for the popup */
        margin: 0px 10px;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .popup-overlay.active .popup-content {
        transform: translateY(0);
        /* Slide the popup in */
        opacity: 1;
    }

    .close-popup {
        background: var(--grey-light);
        height: 20px;
        width: 20px;
        font-weight: bold;
        padding: 13px;
        cursor: pointer;
        border-radius: 50%;
        border: none;
        color: var(--click-color);
        transition: color 0.3s ease;
    }

    .popup-content h3 {
        font-size: 22px;
        font-weight: bold;
        color: var(--grey-dark);
    }

    .popup-content p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 12px;
        color: #555;
    }

    .popup-content strong {
        color: #333;
    }

    .popup-content p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        padding: 12px;
        background-color: #f4f7fc;
        border-radius: 8px;
    }

    .popup-content p strong {
        color: #333;
    }

    .popup-content p span {
        color: #777;
    }

}

@media (min-width: 600px) {
    .enquiries-data-all-contacts {
        .enquiries-data-contacts-grid {
            display: none;
            /* Grid for mobile only */
        }

        .enquiries-data-header {
            display: flex;
            /* Row layout for larger screens */
            justify-content: space-between;
            align-items: center;

            .enquiries-data-header-info {
                display: flex;
                align-items: center;
                gap: 15px;
                flex-wrap: nowrap;
                /* Ensure no wrapping */
            }

            .search-wrapper {
                flex: 1;
                margin-top: 0;
                /* Remove extra spacing */
            }
        }

        .search-bar {
            width: auto;
            /* Adjust search bar width on desktop */
            max-width: 350px;
            /* Optional: Set max width for better alignment */
        }

        .button-wrapper {
            /* Align the button alongside the search bar */
            flex: 0 0 auto;
        }
    }
}

/* Mobile view (phones) */
@media (max-width: 600px) {
    .enquiries-data-all-contacts {

        .enquiries-data-header {
            .enquiries-data-header-info {
                display: flex;
                /* Stack elements vertically in mobile */
            }

            .search-wrapper {
                margin-top: 10px;
                /* Add spacing for search bar */
            }
        }

        /* Hide the table in mobile view */
        .enquiries-data-contacts-table {
            display: none;
        }

        /* Show the grid layout for mobile devices */
        .enquiries-data-contacts-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            /* One column layout */
            gap: 10px;
        }

        /* Card style for each contact */
        .enquiries-data-contact-card {
            border: 1px solid #ddd;
            padding: 16px;
            background-color: #f9f9f9;
            border-radius: 8px;
            /* Rounded corners for a modern look */
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            /* Light shadow for depth */
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            /* Smooth animation */
            cursor: pointer;
            /* Pointer cursor to indicate clickability */
            overflow: hidden;
            /* Prevent content overflow */
            margin-bottom: 16px;
            /* Space between cards */

            .enquiries-data-contacts-message {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /* Show only 3 lines */
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        /* Hover effect for the card */
        .enquiries-data-contact-card:hover {
            transform: translateY(-5px);
            /* Slight lift effect */
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
            /* Darker shadow on hover */
        }

        .enquiries-data-contact-card h3 {
            font-size: 18px;
            margin-bottom: 12px;
            /* Spacing between title and other content */
            font-weight: 600;
            /* Bold for emphasis */
            color: #333;
            /* Dark color for readability */
        }

        .enquiries-data-contact-card p {
            font-size: 14px;
            margin-bottom: 8px;
            /* Margin to separate each line */
            color: #555;
            /* Lighter color for secondary text */
        }

        .enquiries-data-contact-card strong {
            color: var(--grey-dark);
            /* Blue color for labels */
        }

        /* Responsive card text */
        .enquiries-data-contact-card p {
            font-size: 14px;
            display: flex;
            align-items: center;

            span {
                font-size: 14px;
            }
        }

        /* Style for No Contacts Found message */
        .enquiries-data-contact-card p.no-contacts {
            color: #ff4040;
            /* Red color for emphasis */
            font-size: 16px;
            text-align: center;
            /* Center align the no contacts message */
        }
    }
}