.education_two {
  .et_Teacher {
    position: relative;

    .ett_top_left_img {
      position: absolute;
      left: 607px;
      top: 182px;
    }

    .ett_left {
      margin-top: 100px;
      width: 40%;

      p {
        padding-right: 60px;
        font-family: regular;
        font-size: 18px; // Adjust this size for larger content
        font-weight: 700;
        line-height: 32px;
        padding-top: 15px;
        margin-bottom: 30px;
      }
    }

    // Adjust layout to display content on the left and images on the right
    .row {
      display: flex;
      flex-direction: column; // Stack vertically for mobile
      justify-content: space-between;
      align-items: flex-start; // Align items to the start
    }

    .ett_left {
      width: 100%; // Full width for mobile
      padding-right: 0; // No padding for mobile
    }

    .ett_right {
      width: 100%; // Full width for mobile
      display: flex;
      flex-wrap: wrap; // Allows the images to wrap
      justify-content: space-between; // Adjust spacing between cards

      .teacher-card {
        width: 100%; // Full width for one card in mobile
        margin-bottom: 20px;

        @media (min-width: 768px) and (max-width: 991px) {
          width: 48%; // Two cards per row for 768px to 991px
        }

        @media (min-width: 992px) {
          width: 45%; // Adjust width for larger screens
        }

        .image-container {
          position: relative;

          img {
            width: 100%;
            height: auto;
            border: 4px solid var(--click-color);
            transition: transform 0.3s ease;
          }

          img:hover {
            transform: scale(1.1);
          }

          .theme_btn {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            color: black;
            border: none;
            cursor: pointer;
            font-weight: bold;
            border-radius: 8px;
            padding: 8px 12px;
            white-space: nowrap;
            max-width: 100%;
            text-align: center;
          }
        }
      }
    }

    // Responsive adjustments
    @media (max-width: 767px) {
      .ett_left {
        order: 1; // Ensure content comes first
      }

      .ett_right {
        order: 2; // Teacher cards come after content
        width: 100%; // Ensure full width
      }
    }

    // New media query for screens from 320px to 600px
    @media (min-width: 320px) and (max-width: 600px) {
      .ett_left p {
        padding-right: 30px; // Reduced padding for smaller screens
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .row {
        flex-direction: column; // Stack content above teacher cards
      }

      .ett_right {
        display: flex; // Two cards per row
        flex-wrap: wrap;
        justify-content: space-between; // Adjust spacing
      }
    }

    @media (min-width: 992px) {
      .row {
        flex-direction: row; // Horizontal layout for larger screens
      }

      .ett_left {
        width: 40%; // Left content width
      }

      .ett_right {
        width: 60%; // Right teacher cards width
      }
    }
  }
}
