.corporateTwo-numbers-main {
  min-height: 100vh;
  padding: 70px;
  background: url("https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Ftestimonialbanner.avif?alt=media&token=fd8a831b-4102-4b1b-a68f-de3b3c31107e");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(#131312, 0.6), rgba(#1c082c, 0.9));
  }

  .corporateTwo-numbers-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20vh;

    .corporateTwo-numbers-left-container {
      position: relative;
      z-index: 10;
      box-shadow: 10px 10px 1px rgba(var(--logo-primary-color), 1);

      .corporateTwo-numbers-left-img-container {
        width: 600px;
        height: 500px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .corporateTwo-numbers-image-videoplay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 70px;
        width: 70px;
        background-color: var(--logo-primary-color);
        color: var(--click-color);
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          background: var(--click-color);
          transform: translate(-50%, -50%) scale(1.5);
          animation: ripple-animation 1.5s infinite;
          cursor: pointer;
        }
      }
    }

    .corporateTwo-numbers-right-container {
      position: relative;
      width: 40vw;
      height: 500px;
      z-index: 15;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      .corporateTwo-numbers-right-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        h1 {
          font-size: 16px;
          font-weight: 600;
        }

        h2 {
          font-size: 36px;
          font-weight: 600;
          color: var(--logo-primary-color);
        }

        p {
          width: 80%;
        }
      }

      .corporateTwo-numbers-special-sectors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        row-gap: 10px;

        .corporateTwo-numbers-sector {
          width: 50%;

          .corporateTwo-home-sector-icon {
            color: var(--logo-primary-color);
            font-size: 1.5rem;
            margin: 10px 0;
          }

          p {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }

      button {
        width: 200px;
        height: 50px;
        font-size: 1rem;
        font-weight: 800;
        border: none;
        color: var(--click-color);
        background-color: var(--logo-primary-color);
      }
    }
  }

  .corporateTwo-numbers-main-counter {
    background-color: var(--logo-primary-color);
    color: var(--click-color);
    padding: 20px 10px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;

    .corporateTwo-numbers-counter-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;

      .corporateTwo-numbers-counter-cm {
        text-align: center;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 0 10px;

        .corporateTwo-numbers-counter-icon {
          width: 40px;
          height: 40px;
        }

        div {
          display: flex;
          align-items: center;
          gap: 10px;

          h5 {
            span{
              font-weight: 600;
              font-size: 1.5rem;
              color: var(--click-color);
            }
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      max-width: 600px;
      width: 90%;

      .video-container {
        text-align: center;
        z-index: 5;
      }

      .modal-close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        background-color: var(--logo-primary-color);
        color: var(--click-color);
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      }
    }
  }
}

@keyframes ripple-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  70% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

@media (max-width: 1440px) {
  .corporateTwo-numbers-main {
    padding: 50px;

    .corporateTwo-numbers-main-container {
      .corporateTwo-numbers-left-container {
        .corporateTwo-numbers-left-img-container {
          width: 500px;
          height: 400px;
        }

        .corporateTwo-numbers-image-videoplay {
          height: 60px;
          width: 60px;
        }
      }

      .corporateTwo-numbers-right-container {
        width: 45vw;
        height: 400px;

        .corporateTwo-numbers-right-info {
          h2 {
            font-size: 32px;
          }

          p {
            width: 90%;
          }
        }

        .corporateTwo-numbers-special-sectors {
          .corporateTwo-numbers-sector {
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1140px) {
  .corporateTwo-numbers-main {
    padding: 40px;

    .corporateTwo-numbers-main-container {
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .corporateTwo-numbers-left-container {
        .corporateTwo-numbers-left-img-container {
          width: 500px;
          height: 380px;
        }

        .corporateTwo-numbers-image-videoplay {
          height: 70px;
          width: 70px;
        }
      }

      .corporateTwo-numbers-right-container {
        width: 90%;
        height: auto;

        .corporateTwo-numbers-right-info {
          h2 {
            font-size: 28px;
          }

          p {
            width: 100%;
          }
        }

        .corporateTwo-numbers-special-sectors {
          width: 100%;

          .corporateTwo-numbers-sector {
            width: 50%;

            p {
              font-size: 16px;
            }
          }
        }
      }
    }

    .corporateTwo-numbers-main-counter {
      .corporateTwo-numbers-counter-container {
        width: 90%;

        .corporateTwo-numbers-counter-cm {
          gap: 10px;

          .corporateTwo-numbers-counter-icon {
            height: 25px;
            width: 25px;
          }

          div {
            h5 {
              span{
                font-size: 1.5rem;
                font-weight: 600;
              }
            }

            span {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .modal-overlay {
      .modal-content {
        padding: 15px;

        .modal-close-button {
          top: 8px;
          right: 8px;
          font-size: 20px;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .corporateTwo-numbers-main {
    padding: 30px;

    .corporateTwo-numbers-main-container {
      gap: 50px;

      .corporateTwo-numbers-left-container {
        .corporateTwo-numbers-left-img-container {
          width: 100%;
          height: 100%;
        }

        .corporateTwo-numbers-image-videoplay {
          height: 40px;
          width: 40px;
        }
      }

      .corporateTwo-numbers-right-container {
        width: 100%;

        .corporateTwo-numbers-right-info {
          h1 {
            font-size: 14px;
          }

          h2 {
            font-size: 24px;
          }

          p {
            font-size: 14px;
          }
        }

        .corporateTwo-numbers-special-sectors {
          .corporateTwo-numbers-sector {
            .corporateTwo-home-sector-icon {
              font-size: 1.2rem;
              margin: 8px 0;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .corporateTwo-numbers-main-counter {
      .corporateTwo-numbers-counter-container {
        width: 90%;

        .corporateTwo-numbers-counter-cm {
          gap: 0;
          flex-direction: column;

          .corporateTwo-numbers-counter-icon {
            height: 25px;
            width: 25px;
          }

          div {
            h5 {
              span{
                font-size: 1.25rem;
                font-weight: 600;
              }
            }

            span {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .modal-overlay {
      .modal-content {
        padding: 10px;

        .modal-close-button {
          top: 5px;
          right: 5px;
          font-size: 18px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .corporateTwo-numbers-main {
    padding: 20px;

    .corporateTwo-numbers-main-container {
      gap: 40px;

      .corporateTwo-numbers-left-container {
        .corporateTwo-numbers-left-img-container {
          width: 100%;
          height: 100%;
        }

        .corporateTwo-numbers-image-videoplay {
          height: 30px;
          width: 30px;
        }
      }

      .corporateTwo-numbers-right-container {
        .corporateTwo-numbers-right-info {
          h1 {
            font-size: 16px;
          }

          h2 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }

        .corporateTwo-numbers-special-sectors {
          .corporateTwo-numbers-sector {
            width: 100%;

            .corporateTwo-home-sector-icon {
              font-size: 1.5rem;
              margin: 6px 0;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .corporateTwo-numbers-main-counter {
      .corporateTwo-numbers-counter-container {
        width: 100%;

        .corporateTwo-numbers-counter-cm {
          gap: 5px;
          flex-direction: column;
          justify-content: space-between;

          .corporateTwo-numbers-counter-icon {
            height: 20px;
            width: 20px;
          }

          div {
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;

            h5 {

              span{
                font-size: 1rem;
                font-weight: 600;
              }
            }

            span {
              font-size: 13px;
              font-weight: 600;
              line-height: 17px;
            }
          }
        }
      }
    }

    .modal-overlay {
      .modal-content {
        padding: 8px;

        .modal-close-button {
          top: 3px;
          right: 3px;
          font-size: 16px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .corporateTwo-numbers-main {
    .corporateTwo-numbers-main-counter {
      .corporateTwo-numbers-counter-container {
        width: 100%;

        .corporateTwo-numbers-counter-cm {
          gap: 5px;
          justify-content: space-between;

          .corporateTwo-numbers-counter-icon {
            height: 20px;
            width: 20px;
          }

          div {
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;

            h5 {

              span{
                font-size: 16px;
                font-weight: 600;
              }
            }

            span {
              font-size: 12px;
              line-height: 15px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
