.orders-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 2rem;
  }

  .orders-utilities {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;

      p {
        font-size: 0.9rem;
        color: #4a5568;
        margin-bottom: 0.5rem;
      }

      select {
        flex: 1;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 0.95rem;
        transition: border-color 0.2s;

        &:focus {
          outline: none;
          border-color: #4299e1;
        }
      }

      .order-item-status-tabs {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 10px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 0.95rem;
        background: white;
        cursor: pointer;

        span {
          padding: 5px 10px;
          border-radius: 6px;
          transition: all 0.2s;

          &:hover {
            background: #ebf8ff;
          }
        }

        .active-order-status-tab {
          background-color: var(--click-color);
          color: white;
        }
      }
    }

    &-filter {
      display: flex;
      gap: 2rem;
      align-items: flex-start;

      &-search {
        flex: 1.5;

        p {
          font-size: 0.9rem;
          color: #4a5568;
          margin-bottom: 0.5rem;
        }

        div {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        input {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 0.95rem;
          background: white;
          cursor: pointer;

          &:focus {
            outline: none;
            border-color: #4299e1;
          }
        }

        button {
          padding: 0.75rem 1.5rem;
          background: var(--click-color);
          color: white;
          border: none;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s;

          &:hover {
            background: #3182ce;
          }
        }
      }

      &-calendar {
        flex: 1;

        p {
          font-size: 0.9rem;
          color: #4a5568;
          margin-bottom: 0.5rem;
        }

        div {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        input {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 0.95rem;
          background: white;
          cursor: pointer;

          &:focus {
            outline: none;
            border-color: #4299e1;
          }
        }
      }
    }
  }

  .orders-list {
    h3 {
      font-size: 1.1rem;
      color: #4a5568;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }

    .order-item {
      background: white;
      border-radius: 12px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      margin-bottom: 1.5rem;
      overflow: hidden;
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-2px);
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        background: #f7fafc;
        border-bottom: 1px solid #edf2f7;

        .order-header-left {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          h5 {
            font-weight: 600;
          }

          .order-user-info {
            display: flex;
            gap: 1rem;

            span {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 0.9rem;
              color: #4a5568;
            }
          }
        }

        .order-item-header-info {
          display: flex;
          gap: 2rem;

          div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            span:first-child {
              font-size: 0.9rem;
              color: #718096;
            }

            span:last-child {
              font-size: 1.1rem;
              font-weight: 600;
              color: #2d3748;
            }
          }
        }
      }

      &-body {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &-info {
          display: flex;
          justify-content: space-between;
          gap: 1rem;

          &-status,
          &-payment,
          &-shipping-address {
            flex: 1;
            background: #f7fafc;
            padding: 1rem;
            border-radius: 8px;

            h5 {
              font-size: 0.9rem;
              color: #718096;
              margin-bottom: 0.5rem;
            }

            span {
              display: inline-block;
              padding: 0.5rem 1rem;
              background: #ebf8ff;
              color: #2b6cb0;
              border-radius: 6px;
              font-size: 0.9rem;
              font-weight: 500;
            }

            &-details {
              font-size: 0.95rem;
              color: #4a5568;
              line-height: 1.6;

              p {
                margin: 0.25rem 0;
              }
            }
          }
        }

        .products-accordion {
          border-top: 1px solid #edf2f7;
          padding-top: 1rem;

          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 1rem 0;

            h4 {
              font-size: 1.1rem;
              color: #2d3748;
              margin: 0;
            }

            .toggle-icon {
              font-size: 1.5rem;
              color: #4a5568;
              transition: transform 0.3s ease;

              &.open {
                transform: rotate(180deg);
              }
            }
          }

          &-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;

            &.open {
              max-height: 1000px;
            }

            .order-item-products {
              display: flex;
              flex-wrap: wrap;
              gap: 1rem;
              padding-top: 1rem;

              .order-item-product {
                flex: 1 1 calc(25% - 1rem);
                min-width: 150px;
                background: #f7fafc;
                border-radius: 8px;
                padding: 1rem;
                text-align: center;

                img {
                  width: 70px;
                  height: 70px;
                  object-fit: cover;
                  border-radius: 6px;
                }

                h4 {
                  font-size: 0.9rem;
                  color: #2d3748;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      &-footer {
        padding: 1.5rem;
        border-top: 1px solid #edf2f7;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .order-item-footer-shipping-address {
          display: flex;
          flex: 0.5;
          flex-direction: column;
          gap: 0.5rem;
        }

        .order-item-footer-buttons {
          display: flex;
          align-items: center;
          gap: 20px;

          a {
            color: #4299e1;
            text-decoration: none;
            font-weight: 500;
            transition: color 0.2s;

            &:hover {
              color: #3182ce;
            }
          }

          button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.25rem;
            background: #edf2f7;
            border: none;
            border-radius: 8px;
            color: #2d3748;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              background: #e2e8f0;
            }

            .material-symbols-outlined {
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }

  .order-process-status {
    margin-bottom: 20px;

    .main-status-tracker {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
    }

    .main-status-bar {
      position: absolute;
      top: 33%;
      left: 0;
      height: 4px;
      background-color: #e2e8f0; // Light gray background for the entire bar
      width: 100%; // Full width
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #4caf50; // Green color for completed portion
        width: calc(
          (var(--current-stage) - 1) / 3 * 100%
        ); // Adjust width based on current stage
        transition: width 0.3s ease;
      }
    }

    .main-status-stage {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      .main-status-dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #e2e8f0; // Light gray for incomplete stages
        margin-bottom: 8px;
        border: 2px solid #fff; // White border around dots
        z-index: 2;
      }

      span {
        font-size: 12px;
        text-align: center;
      }

      &.completed {
        .main-status-dot {
          background-color: #4caf50;
        }
      }

      &.current {
        .main-status-dot {
          background-color: #4caf50;
          box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.3);
        }

        span {
          font-weight: bold;
        }
      }
    }
  }

  .orders-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      margin: 0; // Remove margin since it's handled by the container
    }

    .search-container {
      position: relative;
      width: 300px;

      .search-input {
        width: 100%;
        padding: 0.75rem 1rem;
        padding-right: 2.5rem; // Space for the icon
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 0.95rem;
        transition: all 0.2s;

        &:focus {
          outline: none;
          border-color: var(--click-color);
          box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
        }
      }

      .material-symbols-outlined {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        color: #718096;
        pointer-events: none;
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1440px) {
  .orders-page {
    padding: 2rem;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }

    .orders-list {
      .order-item {
        padding: 10px;
        gap: 1.2rem;
        border-radius: 10px;

        &-header {
          border-radius: 10px;
          &-info {
            gap: 2rem;

            div {
              span {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .orders-page {
    padding: 1.5rem;

    .orders-utilities {
      flex-direction: column;
      gap: 1.5rem;

      &-item,
      &-filter {
        width: 100%;
      }
    }

    .order-item {
      padding: 1.2rem;

      &-header-info {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        div {
          flex: 1 1 150px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .orders-page {
    padding: 1rem;

    h1 {
      font-size: 2rem;
    }

    .order-item {
      &-header {
        flex-direction: column;
        gap: 1rem;

        &-info {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          div {
            flex: 1 1 calc(50% - 1rem);
            min-width: 140px;
          }
        }
      }

      .products-accordion {
        &-content {
          padding: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .orders-page {
    padding: 0.8rem;

    .orders-utilities {
      &-filter {
        flex-direction: column;
        gap: 1rem;

        &-search,
        &-calendar {
          width: 100%;
        }
      }
    }

    .order-item {
      &-status-tabs {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        span {
          flex: 1 1 auto;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          text-align: center;
        }
      }

      &-body-info {
        flex-direction: column;
        gap: 1rem;
      }

      .order-process-status {
        margin-bottom: 15px;

        .status-tracker {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;

          .status-bar {
            position: absolute;
            top: 33%;
            left: 0;
            height: 3px;
            width: 100%;
          }

          .status-stage {
            flex-direction: column;
            align-items: center;

            .status-dot {
              width: 12px;
              height: 12px;
              margin-bottom: 6px;
            }

            span {
              font-size: 10px;
              text-align: center;
              //   max-width: 60px;
              word-wrap: break-word;
            }

            &.current {
              .status-dot {
                box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.3);
              }
            }
          }
        }
      }

      &-footer {
        flex-direction: column;
        gap: 1rem;

        &-shipping-address {
          width: 100%;
        }

        &-buttons {
          width: 100%;
          flex-direction: column;
          gap: 0.8rem;

          a,
          button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .orders-page {
    padding: 0.5rem;

    h1 {
      font-size: 1.5rem;
    }

    .order-item {
      padding: 0.8rem;

      &-header-info {
        flex-direction: column;

        div {
          width: 100%;
          flex: 1 1 100%;
        }
      }

      &-product {
        flex-direction: column;
        align-items: center;

        img {
          width: 100%;
          max-width: 200px;
        }

        &-info {
          width: 100%;
          padding: 1rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

.order-item-status-tabs span {
  cursor: pointer;
}

.order-item-footer-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: #e0e0e0;
  }
}

.orders-utilities-filter-timeline {
  p {
    color: #4a5568;
    font-size: 0.9rem;
  }
  .timeline-dropdown {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 0.95rem;
    background: white;
    cursor: pointer;
    margin-top: 10px;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }

    option {
      padding: 10px;
    }
  }
}

// Add responsive styles
@media screen and (max-width: 768px) {
  .orders-page {
    .orders-header {
      flex-direction: column;
      gap: 1rem;
      align-items: stretch;

      .search-container {
        width: 100%;
      }
    }
  }
}

.assign-user-icon {
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;

  i {
    transition: all 0.3s ease;
  }

  &:hover {
    i {
      color: var(--click-color);
      transform: scale(1.1);
    }
  }

  // Style for assigned orders icon
  &[title="View Assignment Details"] {
    i {
      color: var(--click-color);
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.assignment-modal {
  background: white;
  border-radius: 5px;
  padding: 10px;
  width: 90%;
  max-width: 500px;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .assignment-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }

    .close-icon {
      cursor: pointer;
      &:hover {
        color: var(--click-color);
      }
    }
  }

  .search-container {
    position: relative;
    // margin-bottom: 20px;

    input {
      width: 100%;
      padding: 12px;
      padding-right: 40px;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      font-size: 1rem;

      &:focus {
        outline: none;
        border-color: var(--click-color);
      }
    }

    i {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #718096;
    }
  }

  .users-list {
    max-height: 200px;
    overflow-y: auto;
    margin: 20px 0 10px 0;

    .user-item {
      padding: 12px;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f7fafc;
      }

      &.selected {
        border-color: var(--click-color);
        background-color: #ebf8ff;
      }

      .user-info {
        display: flex;
        align-items: center;
        gap: 12px;

        h4 {
          margin: 0;
          font-size: 1rem;
        }

        p {
          margin: 4px 0 0;
          color: var(--click-color);
          font-size: 0.9rem;
        }
      }

      .check-icon {
        color: var(--click-color);
      }
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      padding: 8px 16px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      font-size: 0.9rem;

      &.cancel-btn {
        background-color: #e2e8f0;
        color: #4a5568;

        &:hover {
          background-color: #cbd5e0;
        }
      }

      &.save-btn {
        background-color: var(--click-color);
        color: white;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.order-timeline {
  .timeline-item {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    position: relative;

    &:not(:last-child):before {
      content: "";
      position: absolute;
      left: 15px;
      top: 30px;
      bottom: -24px;
      width: 2px;
      background-color: #e2e8f0;
    }

    .timeline-icon {
      width: 32px;
      height: 32px;
      background-color: var(--click-color);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: white;
        font-size: 18px;
      }
    }

    .timeline-content {
      flex: 1;

      h4 {
        margin: 0;
        font-size: 1rem;
        color: #2d3748;
      }

      p {
        margin: 4px 0;
        font-size: 0.9rem;
        color: #4a5568;
      }

      .timeline-date {
        font-size: 0.8rem;
        color: #718096;
      }
    }
  }
}

.orders-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .order-type-dropdown {
    .type-dropdown {
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid #ddd;
      background-color: white;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      outline: none;
      transition: all 0.3s ease;

      &:hover {
        border-color: var(--click-color);
      }

      &:focus {
        border-color: var(--click-color);
        box-shadow: 0 0 0 2px rgba(var(--click-color-rgb), 0.1);
      }
    }
  }
}

.modal-tabs {
  display: flex;
  gap: 10px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;

  .tab {
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 14px;
    transition: all 0.3s ease;
    color: #666;

    &:hover:not(.active) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.active {
      background-color: var(--click-color);
      color: white;
    }
  }
}

.modal-content-orders {
  flex: 1;
  overflow: hidden;
  padding: 20px;
  border: 2px solid #eee;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 10px;

  .order-timeline {
    height: calc(80vh - 220px);
    overflow-y: auto;
    padding: 10px 20px 20px 0;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;

      &:hover {
        background: #666;
      }
    }

    .timeline-event {
      display: flex;
      margin-bottom: 20px;
      position: relative;

      &:not(:last-child):before {
        content: "";
        position: absolute;
        left: 20px;
        top: 40px;
        bottom: -20px;
        width: 2px;
        background-color: #e0e0e0;
      }

      .timeline-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--click-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        flex-shrink: 0;
        z-index: 1;

        .material-symbols-outlined {
          color: white;
          font-size: 20px;
        }
      }

      .timeline-content {
        flex-grow: 1;
        background-color: #f5f5f5;
        padding: 15px;
        border-radius: 8px;

        .event-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;

          h4 {
            margin: 0;
            color: var(--click-color);
            font-size: 16px;
          }

          .event-time {
            font-size: 12px;
            color: #666;
          }
        }

        p {
          margin: 0;
          color: #666;
          font-size: 14px;
        }

        .event-creator {
          margin-top: 8px;
          font-size: 12px;
          color: #999;
        }
      }
    }
  }

  .empty-timeline {
    height: calc(80vh - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;

    .material-symbols-outlined {
      font-size: 48px;
      margin-bottom: 16px;
      color: #999;
    }

    p {
      margin: 0;
      font-size: 16px;
    }
  }

  .order-stages {
    // padding: 20px;

    .stepper {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 30px;
      position: relative;

      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 10px;
      //   left: 0;
      //   right: 0;
      //   height: 2px;
      //   background: #e2e8f0;
      //   z-index: 1;
      // }

      .stepper-item {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        .step-counter {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #fff;
          border: 2px solid #e2e8f0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 6px;
          font-weight: 600;
          transition: all 0.3s ease;
        }

        .step-name {
          font-size: 12px;
          color: #64748b;
          text-align: center;
        }

        .step-timestamp {
          font-size: 12px;
          color: #94a3b8;
          margin-top: 4px;
        }

        &.completed {
          .step-counter {
            background: var(--click-color);
            border-color: var(--click-color);
            color: white;
          }

          .step-name {
            color: var(--click-color);
          }
        }

        &.current {
          .step-counter {
            border-color: var(--click-color);
            color: var(--click-color);
          }
        }
      }
    }

    .stage-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      gap: 10px;

      button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 14px;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.prev-btn {
          background-color: #f1f3f5;
          color: #495057;

          &:not(:disabled):hover {
            background-color: #e9ecef;
          }
        }

        &.next-btn {
          background-color: var(--click-color);
          color: white;

          &:not(:disabled):hover {
            opacity: 0.9;
          }
        }
      }
    }

    .stage-confirmation {
      margin-top: 20px;
      text-align: center;

      p {
        margin-bottom: 15px;
        color: #64748b;
      }

      .confirmation-actions {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
          padding: 8px 16px;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          font-size: 14px;
          transition: all 0.3s ease;

          &.cancel-btn {
            background-color: #f1f3f5;
            color: #495057;

            &:hover {
              background-color: #e9ecef;
            }
          }

          &.save-btn {
            background-color: var(--click-color);
            color: white;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }

  .staff-assignment {
    //   padding: 5px 10px;

    .staff-role-tabs {
      display: flex;
      border-bottom: 2px solid #eee;
      margin-bottom: 20px;

      .role-tab {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #666;
        font-size: 12px;
        position: relative;
        background: transparent;

        .material-symbols-outlined {
          font-size: 20px;
        }

        &:hover:not(.active) {
          color: var(--click-color);
        }

        &.active {
          color: var(--click-color);

          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--click-color);
          }
        }
      }
    }

    .staff-selection-content {
      margin-top: 20px;
      min-height: 200px;
    }
  }

  .staff-selection-content {
    margin-top: 20px;

    .assigned-staff-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: #f8f9fa;
      border: 1px solid #e2e8f0;
      border-radius: 8px;

      .staff-info {
        display: flex;
        align-items: center;
        gap: 16px;

        .staff-avatar {
          width: 40px;
          height: 40px;
          background-color: #e2e8f0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .material-symbols-outlined {
            font-size: 24px;
            color: #64748b;
          }
        }

        .staff-details {
          h4 {
            margin: 0;
            font-size: 16px;
            color: #1a202c;
          }

          p {
            margin: 4px 0 0;
            font-size: 14px;
            color: #64748b;
          }
        }
      }

      .edit-staff {
        background: none;
        border: none;
        height: 30px;
        width: 30px;
        padding: 5px;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-symbols-outlined {
          color: #64748b;
          font-size: 20px;
        }

        &:hover {
          background-color: #e2e8f0;

          .material-symbols-outlined {
            color: var(--click-color);
          }
        }
      }
    }
  }

  .staff-selection-content {
    .search-section {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 15px;

      .search-container {
        flex: 1;
      }

      .cancel-edit-btn {
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        background-color: #f1f3f5;
        color: #495057;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.3s ease;

        &:hover {
          background-color: #e9ecef;
        }
      }
    }
  }

  .stepper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      &:not(:last-child):after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 10px;
        left: 50%;
        z-index: 2;
      }

      .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
        transition: all 0.3s ease;

        .material-symbols-outlined {
          font-size: 20px;
          color: white;
        }
      }

      .step-name {
        color: #666;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.3s ease;
      }

      &.completed {
        .step-counter {
          background-color: #4caf50;
          box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
        }

        .step-name {
          color: #4caf50;
        }

        &:after {
          border-bottom: 2px solid #4caf50;
        }
      }

      &.current {
        .step-counter {
          background-color: #2196f3;
          transform: scale(1.2);
          box-shadow: 0 0 10px rgba(33, 150, 243, 0.5);
        }

        .step-name {
          color: #2196f3;
          font-weight: 600;
          transform: scale(1.1);
        }

        &:before {
          content: "Current";
          position: absolute;
          top: -20px;
          font-size: 12px;
          color: #2196f3;
          font-weight: 600;
        }
      }
    }
  }

  .stage-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.prev-btn {
        background-color: #f0f0f0;
        color: #333;

        &:not(:disabled):hover {
          background-color: #e0e0e0;
        }
      }

      &.next-btn {
        background-color: #2196f3;
        color: white;

        &:not(:disabled):hover {
          background-color: #1976d2;
        }
      }
    }
  }

  .step-timestamp {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
  }
}

.order-barcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  
  .barcode-image {
    min-width: 100px;
    width: 100%;
    max-width: 150px;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
  
  .barcode-number {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
}

.barcode-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  
  .material-symbols-outlined {
    animation: spin 1s linear infinite;
  }
  
  span {
    color: #666;
    font-size: 14px;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

