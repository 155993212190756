.corporateTwo-aboutpg-info {
  min-height: 100vh;
  padding: 50px; // Balanced padding for desktop and mobile
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  h3 {
    font-size: 2rem;
    text-align: center;
  }

  p {
    margin: 20px 0;
    color: var(--black-color);
  }

  ul {
    list-style-type: disc;
  }
}

@media (max-width: 1024px) {
  .corporateTwo-aboutpg-info {
    padding: 40px;
    gap: 30px;
    font-size: 16px;

    p {
      margin: 18px 0;
    }
  }
}

// Small tablets and large mobile phones
@media (max-width: 768px) {
  .corporateTwo-aboutpg-info {
    padding: 30px;
    gap: 25px;
    font-size: 15px;

    p {
      margin: 16px 0;
    }
  }
}

// Mobile devices
@media (max-width: 480px) {
  .corporateTwo-aboutpg-info {
    padding: 20px;
    gap: 20px;
    font-size: 14px;

    h3{
        font-size: 1.5rem;
    }

    p {
      margin: 14px 0;
    }

    ul {
      padding-left: 20px; // Adjust left padding for better alignment on smaller screens
    }
  }
}

// Extra small mobile devices
@media (max-width: 360px) {
  .corporateTwo-aboutpg-info {
    padding: 15px;
    gap: 15px;
    font-size: 13px;

    h3{
        font-size: 1.25rem;
    }

    p {
      margin: 12px 0;
    }
  }
}
