

.settings-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 1.25rem;
    border-radius: 5px;
  
    .settings-title {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
    
    .toggle-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  
    .toggle-status {
      margin: 0;
      color: #6c757d;
      font-weight: normal;
    
      &.active {
        color: #149AA2;
        font-weight: 600;
      }
    
      &.inactive {
        color: #F05B83;
        font-weight: 600;
      }
    }
  }
  
  
  
  
  