.happyerp-order_edit {
  margin-top: 5px;
  width: 22px;
  height: 22px;
  display: flex;
  // align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  cursor: pointer;
  .happyerp-more_opt_card {
    background-color: none;
    background: transparent;
    .material-symbols-outlined {
      font-size: 22px;
      color: black;
    }
  }
}

.happyerp-more_opt_container {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 3px;
  width: 200px;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 8;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);

  li {
    border-top: 1px solid #c6c6c6;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px;
    color: var(--text-black);
    list-style-type: none;
    // text-align: center;
    align-items: center;
    padding: 3px 8px;

    // background-color: lightgray;
    // border: 1px solid lightgray;

    &:hover {
      background-color: #f5f5f5;
    }
    img {
      padding: 5px 5px;
      width: 32px;
    }
  }
  .happyerp-close_form {
    padding: 3px;
    width: 23px;
    height: 23px;
    span {
      font-size: 18px;
    }
  }
  .happyerp-moc_title {
    text-align: center;
    margin: 0;
    margin-bottom: 5px;

    font-size: 15px;
    font-family: "medium";
    color: black;

    padding-top: 0;
    padding: 5px;
  }
}
