.banner_relative {
  position: relative;
  text-align: center;

  /* Carousel Styling */
  .carousel-item img {
    height: 92vh;
    object-fit: cover;
    width: 100%;
  }

  /* Content Above Login Box */
  .banner-content {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
  }

  /* Login Box Styling */
  .login-box {
    position: absolute;
    top: 180%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-width: 90%;

    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      color: #333;
    }

    form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        padding: 10px;
        margin-right: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        width: 100%;
      }

      button {
        padding: 10px 20px;
        background-color: #264d8c;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        min-width: 100px;

        &:hover {
          background-color: #138496;
        }
      }
    }
  }

  /* Curve Styling */
  .banner_half_curve {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.intersection {
  position: relative;
  z-index: 2;

  

  .card-carousel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
     /* Space between cards */
    position: absolute;
    top: -150px; /* Adjust to overlap with the banner */
    left: 50%;
    transform: translateX(-50%);
    z-index: 3; /* Ensure it stays above the banner */
    width: 80%;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: white;
      
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 3;
      width: 100%; 
      height: 400px; /* Increased height */
      flex-grow: 1; /* Cards will grow to fill the available space equally */
      max-width: none; /* Removes the max-width limitation */
      .card-icon {
        width: 100%; /* Scale image to fit inside the card */
        height: auto; /* Maintain aspect ratio */
        margin-bottom: 10px;
      }

      .card-description {
        font-size: 1.1rem;
        text-align: center;
      }
      &:nth-child(odd) {
        flex-direction: column-reverse; /* Image below description for odd cards */
      }
    }
  }
}
