.support-section {
  .Support_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;

    .content-left-support {
      flex: 1;
      width:50%; 

      h2{
        color:#1d3c72;
        font-size:25px;
        margin-bottom:10px;
        margin-top:12px;
      }

      h3 {
        font-size: 50px;
        .highlight {
          color: #ff6600;
        }
      }

      p {
        margin: 20px 0;
        font-size:20px;
      }

      .stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Creates 3 columns with equal width
        
        grid-column-gap:30px;
        grid-row-gap:30px;
        margin-top:30px;
        max-width:420;
       

        .stat-item {
            align-items:flex-start;
            display:flex;
            gap:10px;


            .stat-item-left{
                img{
                    width:50px;
                    height:50px;
                }
            }
        
            .stat-item-right{
          .count-support h6 {
            font-size: 2rem;
            color: black;
          }

          .para_support p{ 
            font-size: 1rem;
            margin:0;
            text-decoration:none;
            color:black;
            font-size:bold;
          }
        }
        }
      }
    }

    .images-right-support {
      display: flex;
       gap:10px;
      width:50%; 

      .image-container-support {
        position: relative;
        img {
          width: 300px;
          object-fit:cover;
          height: 500px;
          transition: opacity 0.5s ease-in-out;
        }

        &:hover img:first-child {
          opacity: 0;
        }

        &:hover img:last-child {
          opacity: 1;
        }

        img:last-child {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }

      .image-container-support_two {
        position: relative;
        img {
          width: 300px;
          object-fit:cover;
          height: 500px;
          transition: opacity 0.5s ease-in-out;
        }

        &:hover img:first-child {
          opacity: 0;
        }

        &:hover img:last-child {
          opacity: 1;
        }

        img:last-child {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
}
