.education_two {
  .et_banner {
    min-height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    position: relative;

    .shape_bottom {
      img {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .et_banner_right {
      position: relative;
      min-height: 100vh;
      display: flex;
      align-items: end;

      .main_img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
      }

      .shape_top {
        img {
          position: absolute;
          top: 0;
          left: 0;
          margin: auto;
          right: 0;
          width: 100%;
        }
      }
    }

    .et_banner_left {
      height: 100%;
      display: flex;
      align-items: center;

      h2 {
        color: var(--heading-color);
        font-size: 48px;
        line-height: 1.2;
        font-family: bold;

        span {
          font-family: bold;
        }
      }

      .theme_description {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 25px;
        color: var(--heading-color);
      }

      .theme_des_inside {
        margin-bottom: 20px;
        font-size: 20px;
        color: var(--heading-color);
      }

      .btn_video_area {
        display: flex;
        align-items: center;
        gap: 30px;

        .video_area {
          display: flex;
          align-items: center;
          gap: 12px;

          .video_icon {
            background: var(--heading-color);
            border-radius: 50%;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            .material-symbols-outlined {
              color: white;
              font-size: 45px;
              font-variation-settings: "wght" 400, "FILL" 1;
            }
          }
        }
      }
    }

    /* Media queries */

    /* Max width: 991px */
    @media (max-width: 991px) {
      .et_banner_left {
        h2 {
          font-size: 40px;
        }
        p.theme_description {
          font-size: 22px;
        }
        .theme_des_inside {
          font-size: 18px;
        }
      }
      .main_img {
        height: auto;
      }
      .shape_bottom img {
        right: 10px;
      }
    }

    /* Max width: 767px */
    @media (max-width: 767px) {
      .shape_top img,
      .shape_bottom img {
        display: none; /* Hide all shape images */
      }

      .et_banner_left {
        h2 {
          font-size: 46px;
        }
        p.theme_description {
          font-size: 30px;
        }
        .btn_video_area {
          gap: 20px;
        }
      }
    }

    /* Max width: 567px */
    @media (max-width: 567px) {
      .et_banner_left h2 {
        font-size: 32px;
      }
      .theme_description {
        font-size: 18px;
      }
      .btn_video_area {
        gap: 15px;
      }

      .shape_bottom img {
        display: none; /* Hide shapes */
      }
    }

    /* Max width: 320px */
    @media (min-width: 320px) and (max-width: 767px) {
      min-height: 85vh;

      .shape_top img,
      .shape_bottom img {
        display: none; /* Hide all shape images */
      }

      .et_banner_right {
        position: relative;
        min-height: 50vh;
        display: flex;
        align-items: end;

        .main_img {
          width: 100%;
          height: auto;
          top: 0px;
          position: relative;
          display: none;
        }
      }

      .et_banner_left {
        h2 {
          font-size: 30px;
        }

        p.theme_description {
          font-size: 24px;
        }

        .btn_video_area {
          display: block;
          gap: 10px;

          .video_area {
            gap: 5px;
            margin-top: 15px;
            .video_icon {
              padding: 4px;

              .material-symbols-outlined {
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
}
