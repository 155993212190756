.education_two {
  .et_whyus {
    position: relative;

    .etw_top_left_img {
      position: absolute;
      left: 607px;
      top: 182px;
    }

    .etw_left {
      .theme_description {
        margin: 20px 0 30px 0;
      }

      .why_us_cards {
        display: grid;
        gap: 15px;

        .wuc_single {
          border-radius: 8px;
          padding: 30px 20px;
          background-color: #f8f6f1;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Adding shadow

          .title {
            font-size: 17px;
            font-family: regular;
            color: var(--heading-color);
            font-weight: 600;
            line-height: 32px;
            padding-bottom: 8px;
            text-transform: capitalize;
            letter-spacing: 0.8px;
            display: flex;
            align-items: center;
            gap: 5px;

            span {
              font-family: Material Symbols Outlined;
              color: var(--click-color);
              font-variation-settings: "FILL" 1, "wght" 400;
            }
          }

          .description {
            color: #4D5756;
            font-family: regular;
            font-size: 15px;
            line-height: 28px;
            font-weight: 400;
          }
        }
      }
    }

    .etw_right {
      padding-left: 40px;
      position: relative;

      .main_img {
        width: 100%;
        object-fit: cover;
        border: 10px solid white;
        z-index: 1;
        position: relative;
      }

      .etw_right_top_img,
      .etw_right_bottom_img,
      .etw_top_right_img {
        position: absolute;
        z-index: 0;
      }

      .etw_right_top_img {
        top: -20px;
        left: 15px;
      }

      .etw_right_bottom_img {
        bottom: -20px;
        right: -18px;
      }

      .etw_top_right_img {
        bottom: -20px;
        right: -82px;
        top: -10px;
        animation: updown 0.8s infinite alternate;
      }
    }
  }

  // Media Queries
  @media (max-width: 600px) {
    .etw_left .why_us_cards {
      margin-bottom: 30px;
      grid-template-columns: 1fr; // One card per row for screens 0 to 600px
    }

    .etw_top_left_img,
    .etw_right_bottom_img,
    .etw_top_right_img {
      display: none; // Remove specified images for small screens
    }
  }

  @media (min-width: 601px) {
    .etw_left .why_us_cards {
      grid-template-columns: repeat(2, 1fr); // Two cards per row for screens above 600px
    }
  }

  @media (max-width: 700px) {
    .etw_top_left_img,
    .etw_right_top_img,
    .etw_right_bottom_img,
    .etw_top_right_img {
      display: none; // Remove specified images for screens up to 700px
    }
  }

  @media (max-width: 857px) {
    .etw_top_right_img {
      display: none; // Remove specified images for screens up to 857px
    }
  }

  @media (max-width: 767px) {
    // Hide all images for screens between 320px and 767px
    .etw_top_left_img,
    .etw_right_top_img,
    .etw_right_bottom_img,
    .etw_top_right_img,
    .main_img {
      display: none;
    }

    .etw_left {
      padding-left: 0; // Adjust padding for left section
    }

    // Optional: Adjust margins/paddings of the cards
    .etw_left .why_us_cards {
      margin: 0 10px; // Add some margin for smaller screens
    }
  }
}
