.ht-testimonials-home_two_testimonials_parent_div {
  padding: 20px;
  position: relative;
  overflow: hidden; /* Ensure content does not overflow */

  /* Diagonal gradient background */
  background: linear-gradient(
    to top left,
    rgba(255, 0, 150, 0.5) 0%,
    rgba(0, 119, 255, 0.5) 50%,
    white 50%
  );

  .ht-testimonials-div {
    background-color: white;
    position: relative;
    height: 300px; // Increased height to accommodate all content
    border-radius: 40px 5px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center; // Centering the content
    flex-direction: column;
    // margin-bottom: 20px;
    margin: 10px;
    padding: 20px; // Added padding to give some space inside the card
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: Adding some shadow for better visual

    .ht-testimonials-ht_testimonials_profile_div {
      img {
        border-radius: 10px;
        width: 80px;
        margin-bottom: 10px; // Space between the image and the text
      }
    }

    .ht-testimonials-ht-text-content-div {
      text-align: center;
      color: var(--black-color);

      h4 {
        font-weight: 700;
        margin-bottom: 10px; // Space between the name and details
      }

      p {
        margin-bottom: 20px; // Space between the details and the footer
      }

      .testimonial-footer {
        width: 100%; // Make the footer take the full width of the card
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 50px;
  }
  svg text {
    animation: stroke 4s infinite alternate;
    // stroke-width: 2;
    font-size: 40px;
  }
  @keyframes stroke {
    0% {
      fill: transparent;
      stroke: rgba(54, 95, 160, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
    }
    80% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
      stroke-width: 2;
    }
    100% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
  // Media query for smaller screens (phone view)
  @media screen and (max-width: 1100px) {
    .wrapper {
      svg text {
        font-size: 30px; // Set the font size to 30px for smaller screens
      }
    }
  }
}
