.education_two {
    .et_aboutus {
        .eta_left {
            padding-right: 50px;
            height: 100%;
            text-align: right;
            position: relative;

            .main_img {
                height: 100%;
                width: 65%;
                margin-left: auto;
                object-fit: cover;
                border-radius: 5px;
            }

            .img_top {
                position: absolute;
                width: 50%;
                height: 50%;
                left: 0;
                top: -20px;
                object-fit: cover;
                border-radius: 5px;
            }

            .img_bottom {
                position: absolute;
                width: 50%;
                height: 50%;
                left: 0;
                bottom: -20px;
                object-fit: cover;
                border-radius: 5px;
            }

            .shape_img {
                position: absolute;
                width: 50%;
                height: 50%;
                right: 20%;
                bottom: -20px;
            }
        }

        .eta_right {
            .sect_header {
                .sect_title {
                    margin-bottom: 16px;
                }
            }

            .more_info {
                margin: 24px 0 30px 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;

                h5 {
                    font-size: 17px;
                    text-transform: uppercase;
                    color: var(--heading-color);
                    font-family: bold;
                    margin-bottom: 4px;
                }
            }
        }
    }

    // Responsive Styles
    @media (max-width: 767px) {
        .et_aboutus {
            .eta_left {
                padding-right: 15px;

                .main_img {
                    width: 100%;
                    
                }

                .img_top,
                .img_bottom,
                .shape_img {
                    display: none; // Hide top, bottom, and shape images on screens <= 767px
                }
            }

            .eta_right {
                .more_info {
                    grid-template-columns: 1fr; // Stack items on smaller screens
                    gap: 15px; // Reduce gap
                }
            }
        }
    }
}
