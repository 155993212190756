.ecom_two {
    .Cards_Events {
      background: #f9f7f7;
      margin-top:20px;
      padding: 20px; /* General padding for the container */
  
      .Cards_header_card {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping for smaller screens */
        gap: 30px; /* Space between cards */
        justify-content: center; /* Center cards horizontally */
  
        .left_card, .right_card {
          background: white;
          padding: 10px;
          box-sizing: border-box;
          flex: 1 1 100%; /* Default to one card per row on small screens */
  
          img.icon_card {
            width: 50px;
            
          }
  
          h2.title_card {
            font-size: 18px;
            color: black;
            font-weight: bold;
            margin: 10px 0;
          }
  
          p.card_para_card {
            font-size: 14px;
            color: black;
            font-weight: 700;
            margin-bottom: 10px;
          }
  
          text.card_text_card {
            font-size: 16px;
            color: black;
            font-weight: 700;
            margin-bottom: 15px;
          }
  
          form {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
  
            input {
              flex: 1;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 5px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
  
            button {
              padding: 10px;
              background: #007bff;
              color: #fff;
              border: none;
              border-radius: 5px;
              cursor: pointer;
  
              &:hover {
                background: #0056b3;
              }
            }
          }
        }
  
        // Responsive layout adjustments
        @media (max-width: 320px) {
          .left_card, .right_card {
            flex: 1 1 100%; /* Full width on very small screens */
            margin: 0; /* Remove margin for better fit */
          }
        }
  
        @media (min-width: 321px) and (max-width: 568px) {
          .left_card, .right_card {
            flex: 1 1 calc(50% - 20px); /* Two cards per row with spacing */
          }
        }
  
        @media (min-width: 569px) {
          .left_card, .right_card {
            flex: 1 1 calc(50% - 20px); /* Two cards per row with spacing */
          }
        }
      }
    }
  }
  