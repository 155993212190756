.app-store-one-our-apps-two-parent {

    .app-store-one-services-top-selector {
        background-color: var(--white-color);
        padding: 10px 30px;
        border-radius: 40px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        padding: 10px 10px;
        user-select: none;
        
        .app-store-one-categories-container{
            display: flex;
            overflow-x: auto;
            scroll-behavior: smooth;
            gap: 16px; 
            scrollbar-width: none;

            .app-store-one-service-tab {
                flex: 0 0 auto;
                white-space: nowrap;
                user-select: none; /* Prevent text selection on individual tabs */
            }
        }
        .app-store-one-arrow-left,
        .app-store-one-arrow-right {
            position: sticky; /* Fix the arrows to the screen */
            top: 50%;
            // stransform: translateY(-50%);
            background-color: #ebebeb; /* Optional background */
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px; /* Optional shadow */
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
            z-index: 10;
        }
        .app-store-one-arrow-left {
            left: 0px; /* Adjust distance from the left edge */
        }
    
        .app-store-one-arrow-right {
            right: 0px; /* Adjust distance from the right edge */
        }
    
        .app-store-one-arrow-left svg,
        .app-store-one-arrow-right svg {
            width: 24px;
            height: 24px;
        }

        .app-store-one-service-tab {
            position: relative;
            cursor: pointer;
            padding: 10px 15px;
            transition: color 0.5s, border-bottom 0.5s;
            font-weight: bold;
            color: #4b4b4b;
            display: flex;
            align-items: center;
    
            img {
                width: 25px;
                transition: color 0.5s;
            }
    
            &.active {
                color: var(--click-color);
                font-weight: bold;
                border-bottom: 2px solid var(--click-color);
            }
        }
    }
    
    .app-store-one-our-apps-two-cards {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(5, 1fr); 
  
      @media (max-width: 1024px) { // For tablets
        grid-template-columns: repeat(4, 1fr); // 4 cards in a row
      }
  
      @media (max-width: 768px) { // For phones
        grid-template-columns: repeat(2, 1fr); // 2 cards in a row
      }
  
      .app-store-one-our-apps-two-card {
        // width: 250px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(163deg, var(--logo-primary-color) 0%, var(--click-color) 100%);    
        transition: all .3s;
        background-color: white;
        overflow: visible;
        position: relative;
  
        .card-details {
          color: black;
          height: 100%;
          gap: .5em;
          display: grid;
          // place-content: center;
          background: var(--card-bg);
          border-radius: 10px;
          position: relative;
  
          img {
            min-width: 100% !important;
            height: 190px;
            max-width: 100%;
            border-radius: 10px 10 0px 0px;
          }
  
          .card-discount {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: red;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
          }
  
          // .card-like-btn {
          //   position: absolute;
          //   top: 10px;
          //   right: 10px;
          //   background: none;
          //   border: none;
          //   font-size: 20px;
          //   cursor: pointer;
          // }
  
          &:hover {
            transform: scale(0.98);
            border-radius: 10px;
  
            img {
              border-radius: 10px 10px 0px 0px;
            }
           }
        }
  
        &:hover {
          transform: translateY(-5px);
        }
  
        .card-content {
          padding: 10px;
  
          h3 {
            font-size: 16px;
            margin-bottom: 10px;
          }
  
          .card-price {
            display: flex;
            gap: 5px;
            font-size: 14px;
            color: red;
  
            del {
              color: #aaa;
            }
          }
  
          .card-rating {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        .card-button {
          transform: translate(-50%, 125%);
          width: 60%;
          border-radius: 1rem;
          cursor: pointer;
          border: none;
          background-color: var(--click-color);
          color: #fff;
          font-size: 1rem;
          padding: .5rem 1rem;
          position: absolute;
          left: 50%;
          bottom: 0;
          opacity: 0;
          text-align: center;
          transition: 0.3s ease-out;
         }
  
         &:hover .card-button {
          transform: translate(-50%, 50%);
          opacity: 1;
         }
  
         &:hover {
          box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
         }
      }
    }
  }

 
