.ecom_two {
  .featured-events {
    margin-bottom: 50px;

    .event-container {   
      .event-card {
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .image-container {
          position: relative;

          .event-image {
            width: 100%;
            height: auto;
            aspect-ratio: 2 / 2.5;
            object-fit: cover;
            border-radius: 8px 8px 0px 0px;
          }

          p {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: white;
            padding: 5px 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
          }

          .badge {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: red;
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }

        .event-details {
          padding: 10px;  
          display: grid;
          gap: 10px;

          .text {
            display: flex;
            align-items: center;
            gap: 10px;

            h6 {
              font-size: 13px;
              color: #45474d;
              font-weight: 600;
            }

            span {
              font-size: 20px;
              color: grey;
            }
          }
        }

        .buy-now {
          background: #ffeaee;
          color: black;
          font-weight: 700;
          margin: 10px;
          margin-top: 0; 
          border-radius: 8px;
          padding: 5px;
          display: flex;
          gap: 5px;
          justify-content: space-between;

          h6 {
            font-size: 16px;
            font-weight: 700;

            &.buy_now {
              text-transform: uppercase;
              padding-left: 8px;
              border-left: 1px solid red;
            }
          }
        }
      }
    }
  }

  // Responsive design for specific breakpoints
  @media (max-width: 1199px) {
    .featured-events {
      margin-bottom: 40px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 2.2 / 2.5;
            }

            p {
              font-size: 15px;
              padding: 5px 9px;
            }

            .badge {
              padding: 4px 9px;
            }
          }

          .event-details {
            padding: 9px;
            
            .text {
              h6 {
                font-size: 12px;
              }

              span {
                font-size: 19px;
              }
            }
          }

          .buy-now {
            margin: 9px;
            padding: 4px;
            
            h6 {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .featured-events {
      margin-bottom: 35px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 2.5 / 2.5;
            }

            p {
              font-size: 14px;
              padding: 4px 8px;
            }

            .badge {
              padding: 4px 8px;
            }
          }

          .event-details {
            padding: 8px;
            
            .text {
              h6 {
                font-size: 11px;
              }

              span {
                font-size: 18px;
              }
            }
          }

          .buy-now {
            margin: 8px;
            padding: 4px;
            
            h6 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .featured-events {
      margin-bottom: 30px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 3 / 2.5;
            }

            p {
              font-size: 13px;
              padding: 4px 7px;
            }

            .badge {
              padding: 4px 7px;
            }
          }

          .event-details {
            padding: 7px;
            
            .text {
              h6 {
                font-size: 11px;
              }

              span {
                font-size: 17px;
              }
            }
          }

          .buy-now {
            margin: 7px;
            padding: 4px;
            
            h6 {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .featured-events {
      margin-bottom: 25px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 4 / 2.5;
            }

            p {
              font-size: 12px;
              padding: 3px 6px;
            }

            .badge {
              padding: 3px 6px;
            }
          }

          .event-details {
            padding: 6px;
            
            .text {
              h6 {
                font-size: 10px;
              }

              span {
                font-size: 16px;
              }
            }
          }

          .buy-now {
            margin: 6px;
            padding: 3px;
            
            h6 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .featured-events {
      margin-bottom: 20px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 1 / 1.5;
            }

            p {
              font-size: 11px;
              padding: 3px 5px;
            }

            .badge {
              padding: 3px 5px;
            }
          }

          .event-details {
            padding: 5px;

            .text {
              h6 {
                font-size: 10px;
              }

              span {
                font-size: 15px;
              }
            }
          }

          .buy-now {
            margin: 5px;
            padding: 3px;

            h6 {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 320px) {
    .featured-events {
      margin-bottom: 15px;

      .event-container {
        .event-card {
          .image-container {
            .event-image {
              aspect-ratio: 1 / 2;
            }

            p {
              font-size: 10px;
              padding: 2px 4px;
            }

            .badge {
              padding: 2px 4px;
            }
          }

          .event-details {
            padding: 4px;

            .text {
              h6 {
                font-size: 9px;
              }

              span {
                font-size: 14px;
              }
            }
          }

          .buy-now {
            margin: 4px;
            padding: 2px;

            h6 {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
