@keyframes popupOpen {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popupClose {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


.business-three-home-banners-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  animation: popupOpen 1s forwards;

  &.close {
    animation: popupClose 0.5s backwards;
  }
}

.business-three-home-banners-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;

  h3 {
    margin-bottom: 20px;
  }

  input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .business-three-banner-btn-div{
    margin: 20px 0px 20px 0px;
  }
}

.business-three-home-banners-popup-two {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.business-three-home-banners-popup-content-two {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
width: 40%;
  input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px; // Optional: Adds space between the buttons

    label {
      width:100%; 
      padding: 3px 20px;
      border-radius: 5px;
      color: white;
      align-items: center;
      border: 2px solid transparent;
      display: flex;
      margin-bottom: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease, border-color 0.3s ease;

      input[type="radio"] {
        display: none;
      }

      &:has(input[type="radio"]:checked) {
        background-color: var(--click-color);
        border-color: var(--click-color);
      }

      &:not(:has(input[type="radio"]:checked)) {
        border-color: var(--click-color);
        background-color: none;
        color: black;
      }
    }
  }
}

.business-three-home-banners-parent {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;

  .business-three-all-banners-container {
    position: absolute;
    top: 10px;
    font-size: 50px;
    color: white;
    z-index: 20000;
    animation: fadeIn 1s forwards;
  
    &.hide {
      animation: fadeOut 1s forwards;
    }
  
    .row {
      div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
  
        img {
          width: 100%;
          border: 4px solid white;
          border-radius: 5px;
          cursor: pointer;
          animation: zoomIn 1s forwards;
          opacity: 0; /* Start hidden */
        }
      }
    }
  }
  
  .note-stack-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 30px;
    color: white;
    background-color: var(--click-color);
    border-radius: 50%;
    padding: 7px;
    z-index: 9;
  }
}

.business-three-home-banners-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s linear, visibility 1s linear;
  visibility: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.business-three-home-banner-without-text {

  img {
    width: 100%;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.business-three-home-banners-banner-content {
  position: absolute;
  bottom: 200px;
  left: 50px;
  color: white;
  max-width: 500px;

  h2 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 700;
  }

  p {
    font-size: 1rem;
    margin: 10px 0;
  }
}

.business-three-home-banners-image-upload-container {
  position: relative;
  margin-bottom: 20px;
}

.business-three-home-banners-selected-image {
  position: relative;

  img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    transition: opacity 0.3s ease;
  }

  .uploading {
    opacity: 0.3;
  }

  .uploading-gif-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999;

    .uploading-gif {
      width: auto;
      height: 150px;
    }
  }
}

.business-three-home-banners-edit-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: var(--click-color);
  border-radius: 50%;
  padding: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;

  input[type="file"] {
    display: none;
  }
}

.business-three-home-banners-edit-icon-placeholder {
  display: inline-block;
  cursor: pointer;
  color: #6c757d;

  input[type="file"] {
    display: none;
  }
}

.business-three-home-banners-image-text {
  position: absolute;
  top: 50%;
  width: fit-content;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  border-radius: 10px;
  padding-left: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));

  h2 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
  }

  p {
    font-size: 1rem;
    margin: 10px 0 0 0;
  }
}

.business-three-home-banners-shortcut-parent {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px 0;
  width: 100%;
  z-index: 11;
  bottom: 0;
  position: absolute;
  color: var(--white-color);

  .business-three-home-banners-shortcut-names {
    h6 {
      font-weight: 700;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .business-three-home-banners-shortcut-parent {
    display: none;
  }

  .business-three-home-banners-popup-content-two {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 95%;
}

.business-three-home-banners-banner-content {
  top: 40px;
  left: 30px;
  h2 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: 700;
  }
}

}

.business-three-home-banners-gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 998;
  display: none;
  /* Hidden by default */
  pointer-events: none;
  /* Ensure it doesn't interfere with user interactions */
}

.business-three-home-banners-gif.visible {
  display: block;
}


@media only screen and (max-width:767px){
  .business-three-home-banners-parent {
      position: relative;
      width: 100%;
      height: 35vh; 
      overflow: hidden;

    .business-three-all-banners-container {
      .row {
        div {
          margin-top: 10px;
        }
      }
    }
    
    .note-stack-icon {
      top: 15px;
      right: 15px;
      font-size: 25px;
      background-color: var(--click-color);
      border-radius: 50%;
      padding: 6px;
      z-index: 90000;
    }
  }
}