.business-team-three {
  &-team-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    padding: 100px 0;
  }

  &-team-heading {
    font-size: 3rem;
    color: #fff;
    margin-bottom: 20px;
  }

  &-team-description {
    font-size: 1rem;
    color: #ccc;
    margin-bottom: 20px;
    line-height: 1.5;
    padding-right: 30px;

    span {
      font-size: 2rem;
      font-weight: 700;
      color: var(--white-color);
    }
  }

  &-circle {
    position: relative;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    border: 3px solid var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-team-member {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s, border-color 0.3s;
    border: 3px solid var(--click-color);
    padding: 3px;
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      transition: transform 0.3s, filter 0.3s;

      &:hover {
        filter: grayscale(0%);
        transform: scale(1.1);
      }
    }

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(2) {
      top: 20%;
      left: 80%;
      transform: translate(0%, -70%);
    }

    &:nth-child(3) {
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(4) {
      top: 80%;
      left: 80%;
      transform: translate(0%, -30%);
    }

    &:nth-child(5) {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(6) {
      top: 80%;
      left: 20%;
      transform: translate(-110%, -30%);
    }

    &:nth-child(7) {
      top: 50%;
      left: 0%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(8) {
      top: 20%;
      left: 20%;
      transform: translate(-110%, -70%);
    }
  }

  &-center-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-member-info {
    color: var(--white-color);
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }

  &-small-box,
  &-biggest-box {
    position: absolute;
    border-radius: 5px;
  }

  &-small-box {
    width: 300px;
    height: 300px;
    z-index: 2;
    border-radius: 50%;
    border-top: 5px solid rgb(255, 23, 23);
    border-bottom: 5px solid rgb(255, 255, 255);
    border-right: none;
    animation: rotateBoxCounterClockwise 10s linear infinite;
  }

  &-biggest-box {
    width: 420px;
    height: 420px;
    z-index: 1;
    border-radius: 50%;
    border-right: 5px solid rgb(255, 255, 255);
    border-left: 5px solid rgb(72, 0, 255);
    animation: rotateBoxClockwise 5s linear infinite;
  }

  @media (max-width: 767px) {
    &-circle {
      margin-top: 70px;
      width: 300px;
      height: 300px;
    }
    &-small-box {
      width: 60%;
      height: 60%;
    }

    &-biggest-box {
      width: 80%;
      height: 80%;
    }
    &-center-member {
      img {
        width: 80px;
        height: 80px;
      }
    }
    &-team-member {
      width: 22%;
      height: 22%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1300px) {
    &-circle {
      margin-top: 70px;
      width: 400px;
      height: 400px;
    }
    &-small-box {
      width: 250px;
      height: 250px;
      z-index: 2;
      border-radius: 50%;
      border-top: 5px solid rgb(255, 23, 23);
      border-bottom: 5px solid rgb(255, 255, 255);
      border-right: none;
      animation: rotateBoxCounterClockwise 10s linear infinite;
    }

    &-biggest-box {
      width: 320px;
      height: 320px;
      z-index: 1;
      border-radius: 50%;
      border-right: 5px solid rgb(255, 255, 255);
      border-left: 5px solid rgb(72, 0, 255);
      animation: rotateBoxClockwise 5s linear infinite;
    }
  }
}

@keyframes rotateBoxClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateBoxCounterClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
