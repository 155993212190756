.ecom_two {
  .Browse_Events {
    .Events-images {
      text-align:center;
      display: flex;
      gap: 15px; // Adjust gap as needed
      justify-content: space-between;
      flex-wrap: nowrap; // Keep all images in a single row
      overflow-x: hidden; // Prevent horizontal scrolling on larger screens
    }

    .image-item {
      flex: 0 0 auto;
      width: calc((100% / 8) - 15px); // 8 images with gap
    }

    .image-item img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      border: 2px solid black;
      padding: 10px; // Adjust padding as needed
    }

    p.img_title{
      text-align:center;

    }
    text.img_para{
      text-align:center;
    }

    .ecom_btn_event_button {
      button {
        border-radius: 16px;
        padding: 8px;
        text-align: center;
        background: lightgrey;
        margin-top: 40px;
        border: none;
        color: black;
        cursor: pointer;
        transition: background 0.3s ease, transform 0.3s ease;

        &:hover {
          background: red;
          transform: scale(1.05);
        }
      }
    }
  }

  // Responsive design for different breakpoints
  @media (max-width: 1400px) {
    .Browse_Events {
      .Events-images {
        gap: 20px; // Adjusted gap
      }

      .image-item {
        width: calc((100% / 8) - 20px); // Adjusted for new gap
      }
    }
  }

  @media (max-width: 1199px) {
    .Browse_Events {
      .Events-images {
        gap: 25px; // Adjusted gap
      }

      .image-item {
        width: calc((100% / 8) - 25px); // Adjusted for new gap
      }

    }
  }

  @media (max-width: 991px) {
    .Browse_Events {
      .Events-images {
        gap: 20px;
      }

      .image-item {
        width: calc((100% / 8) - 20px); // Adjusted for new gap
      }
    }
  }

  @media (max-width: 767px) {
    .Browse_Events {
      .Events-images {
        gap: 15px;
      }

      .image-item {
        width: calc((100% / 8) - 15px); // Adjusted for new gap
      }
    }
  }

  // Enable slider for screens smaller than 575px where not all images can fit in one row
  @media (max-width: 575px) {
    .Browse_Events {
      .Events-images {
        gap: 12px;
        overflow-x: auto; // Enable horizontal scrolling
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch; // Smooth scrolling on mobile
      }

      .image-item {
        width: 48%;
        scroll-snap-align: center;
      }
    }
  }

  @media (max-width: 360px) {
    .Browse_Events {
      .Events-images {
        gap: 8px;
      }

      .image-item {
        width: 45%; // Adjust width to show 2-3 images at a time
      }
    }
  }

  @media (max-width: 320px) {
    .Browse_Events {
      .Events-images {
        gap: 5px;
      }

      .image-item {
        width: 33%; // Adjust width to show 2-3 images at a time
      }




    }
  }
  }
