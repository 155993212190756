.education_two {
    .et_category {
      .category_cards {
        display: grid;
        gap: 15px;
        margin-top: 50px;
        padding: 0;
  
        // For screens between 320px and 767px, show 2 cards per row
        @media (min-width: 320px) and (max-width: 767px) {
          grid-template-columns: repeat(2, 1fr); // 2 cards per row
        }
  
        // For larger screens, set different layouts
        @media (min-width: 768px) and (max-width: 991px) {
          grid-template-columns: repeat(2, 1fr); // 2 cards per row
        }
  
        @media (min-width: 992px) {
          grid-template-columns: repeat(4, 1fr); // 4 cards per row
        }
  
        .cc_single {
          background: #f2f2f2;
          border-radius: 10px;
          padding: 10px; // Reduced padding for smaller cards
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 200px; // Reduced height for smaller cards
          text-align: center;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  
          .ccs_inner {
            .icon {
              padding: 6px;
              width: fit-content;
              border-radius: 50%;
              border: 2px dashed var(--click-color);
              margin: auto;
  
              .icon_inner {
                border-radius: 50%;
                background: var(--click-color);
                padding: 10px;
                width: fit-content;
  
                img {
                  width: 30px; // Smaller image size
                  height: 30px;
                }
              }
            }
  
            h5 {
              font-size: 16px; // Smaller font size for smaller cards
              font-weight: 700;
              color: var(--heading-color);
              font-family: regular;
              letter-spacing: 0.5px;
              margin: 12px 0px 6px 0px;
            }
  
            h6 {
              color: #4d5756;
              font-size: 13px; // Smaller font size for the subheading
              font-family: regular;
              font-weight: 500;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
  