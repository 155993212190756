.happyerp {
  .ninedots {
    .oc_icon_position {
      position: fixed;
      bottom: 45px;
      right: 45px;
      z-index: 99;
    }
    .icon_div {
      transition: 0.8s;
      background: var(--theme-orange);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      color: var(--white-color);

      cursor: pointer;
      span {
        font-size: 32px;
      }
    }
    .close_div {
      transition: 0.8s;
      opacity: 0;
      visibility: hidden;
    }
    .open_div {
      transition: 0.8s;
      opacity: 1;
      visibility: visible;
    }
    &.option_show .open_div {
      transition: 0.8s;
      opacity: 0;
      visibility: hidden;
    }
    &.option_show .close_div {
      transition: 0.8s;
      opacity: 1;
      visibility: visible;
    }
    .icon_with_text {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;
    }
    .inner_options {
      transition: 1s;
      position: fixed;
      background: rgb(0 0 0 / 91%);
      width: 100%;
      height: 0%;
      z-index: 98;
      bottom: 0;
      display: flex;
      align-items: end;
      justify-content: end;
      opacity: 0;
      visibility: hidden;
    }
    &.option_show .inner_options {
      transition: 1s;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
    .io_menu {
      display: flex;
      flex-direction: column-reverse;
      padding-right: 45px;
      padding-bottom: 105px;
      gap: 15px;
    }
  }
}
