.happyerp-customized-modal {
  .modal-header {
    padding: 2px;
    border: none;
    margin-top: 0px;
    background-color: #cfe2ff;
    .happyerp-main_title {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
    }
    border-bottom: none;
    --bs-modal-header-border-color: transparent;
  }
  .modal-body {
    text-align: center;
    .user_inpt_field {
      width: 50% !important;
      margin: 5px;
      padding: 5px;
      border-radius: 3px;
    }
  }
  .modal-footer {
    justify-content: space-between !important;
    --bs-modal-footer-border-color: transparent; /* Set to transparent */
    border-top: none; /* Ensure the border is completely removed */
    padding: 2px;
    .happyerp-theme_btn {
      background-color: var(--theme-teal);
      border-radius: 0 !important;
      font-size: 1rem;
      color: white;
      &:hover {
        background-color: var(--theme-lightmidnight-blue);
      }
    }
    .happyerp-theme_btn1 {
      color: var(--theme-orange);
      background-color: white;
      border: 1px solid var(--theme-orange);
      border-radius: 0 !important;
      font-size: 1rem;
    }
  }
}
