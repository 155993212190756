.happyerp-card_detail_pg {
  .happyerp-order_summary_accordion {
    padding-bottom: 18px;

    .happyerp-accordion-button {
      padding: 0px;
      &::after {
        margin-right: 13px;
      }
    }
    .happyerp-text_heading_or {
      display: flex;
      align-items: center;
      .happyerp-text {
        padding: 15px 20px;
        border-right: 2px solid var(--theme-blue);
        &:last-child {
          border-right: none;
        }
        h6 {
          font-size: 14px;
          font-family: regular;
          color: var(--text-grey);
        }
        h5 {
          font-size: 15px;
          font-family: medium;
          color: var(--text-black);
        }
      }
    }
  }

  .happyerp-cf_single {
    h6 {
      font-size: 14px;
      font-family: light;
      color: var(--text-grey);
      margin-bottom: 1px;
    }
    .happyerp-badges_group {
      display: flex;
      gap: 18px;
    }
    h5 {
      font-size: 16px;
      font-family: regular;
      color: var(--text-black);
      &.happyerp-my_badge {
        margin-top: 6px;
        span {
          background: #edf4ff;
          display: inline-block;
          padding: 6px 14px;
          font-size: 16px;
          border-radius: 5px;
          text-transform: capitalize;
        }
        &.happyerp-color_badge {
          display: flex;
          align-items: center;
          gap: 5px;
          .happyerp-color_wrapper {
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
        }
      }
    }
    &.happyerp-with_icon {
      display: flex;
      //   align-items: center;
      flex-direction: column;
      gap: 4px;
      .happyerp-icon {
        display: flex;
        // align-items: center;
        // justify-content: center;
        span {
          color: var(--text-grey);
          font-size: 28px;
          font-variation-settings: "wght" 300;
        }
      }
    }
  }
  .happyerp-first_row {
    .happyerp-card_fields {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 22px;
    }
  }
  .happyerp-order_item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;

    &.col_2 {
      grid-template-columns: repeat(2, 1fr);
    }
    .happyerp-card_title {
      padding: 5px 15px;
      margin: -15px -15px 12px -15px;
      background: #cfe2ff;
      font-size: 16px;
      font-family: "medium";
    }
    .happyerp-item_single {
      padding: 15px;
      border-radius: 8px;
      .happyerp-cf_single {
        .happyerp-badges_group {
          gap: 12px;
          flex-wrap: wrap;
        }
        h6 {
          font-size: 12px;
        }
        h5 {
          &.happyerp-my_badge {
            &.happyerp-color_badge {
              gap: 3px;
              .happyerp-color_wrapper {
                width: 12px;
                height: 12px;
              }
            }
            span {
              padding: 4px 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.happyerp-card_divider {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  margin: 12px 0px;
}
.happyerp-order-summary-card {
  margin-bottom: 20px;
}

.happyerp-order-summary-card .happyerp-text_heading_or {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.happyerp-card_divider {
  height: 1px;
  background-color: var(--theme-grey);
  margin: 15px 0;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .happyerp-order-summary-card .happyerp-text_heading_or {
    gap: 15px;
  }

  .happyerp-order-summary-card .happyerp-text {
    min-width: 120px;
  }
}
