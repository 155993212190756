// START CARD DESIGN FOR LAPTOP VIEW
.today_attendance_data_parent {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  max-width: 87%;
  margin: auto;
  border: 1px solid var(--grey-dark);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  // z-index: 9999;

  &:hover .today_attendance_hover_open_card {
    opacity: 1;
    transform: translateY(0);
  }

  .today_attendance_data_date_left {
    background-color: var(--logo-primary-color);
    min-height: 170px;
    min-width: 10%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;

    h5 {
      color: var(--pure-white-color);
      font-weight: bold;
    }
  }

  //  STEPS START
  .today_attendance_data_steps {
    min-width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: relative;

    // Horizontal line that connects all steps
    &::before {
      content: "";
      position: absolute;
      top: 20px; // Aligns with the middle of the icons
      left: 18%;
      width: 65%;
      height: 4px;
      background-color: #ddd;
      z-index: 0;
    }

    .today_attendance_data_step {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      // z-index: 1;
      min-width: 33.33%;
      max-width: 33.33;

      .today_attendance_data_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        position: relative;
      }

      .today_attendance_data_text {
        margin-top: 5px;
        display: inline-block;

        p {
          font-size: 12px;
          font-weight: 800;
          margin: 2px 0;
        }
      }

      .today_attendance_data_status {
        font-size: 12px;
        max-width: 280px;
      }

      &.completed {
        .today_attendance_data_icon {
          background-color: var(--grey-light);
          border: 1px solid var(--grey-dark);
          color: white;

          img {
            max-width: 25px;
          }
        }

        .today_attendance_data_status {
          color: green;
          display: block;
          min-height: 50px;
        }
      }

      &.in-progress {
        .today_attendance_data_icon {
          background-color: var(--grey-light);
          border: 1px solid var(--grey-dark);
          color: white;

          img {
            max-width: 25px;
          }
        }

        .today_attendance_data_status {
          font-size: 15px;
          display: block;
          min-height: 50px;
        }

        .work-hr-below-four {
          color: red !important;
        }
        .work-hr-between-four-six {
          color: orange !important;
        }
        .work-hr-between-six-ten {
          color: green !important;
        }
        .work-hr-between-ten-above {
          color: red !important;
        }
      }

      &.pending {
        .today_attendance_data_icon {
          // background-color: var(--grey-dark);
          background-color: var(--grey-light);
          border: 1px solid var(--grey-dark);
          color: white;

          img {
            max-width: 25px;
          }
        }

        .today_attendance_data_status {
          color: var(--grey-dark);
          display: block;
          min-height: 50px;
        }
      }
    }
  }
  //  STEPS END

  .today_attendance_data_page_btn {
    background-color: var(--grey-light);
    min-height: 170px;
    min-width: 5%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;

    .today_attendance_data_page_status {
      display: none;
    }
  }
}
// END CARD DESIGN FOR LAPTOP VIEW
.today_attendance_hover_open_card {
  position: absolute;
  bottom: -0%; // Initially hidden below
  left: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-top: 1px solid var(--grey-dark);
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  z-index: 1;
  border-radius: 10px;

  p {
    font-size: 15px;
  }
}

// START CARD DESIGN FOR PHONE VIEW
@media only screen and (max-width: 575px) {
  .today_attendance_data_parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: 425px;
    border-radius: 20px;

    .today_attendance_data_date_left {
      min-height: 50px;
      min-width: 100%;
      border-radius: 10px;

      h5 {
        display: ruby;
      }
    }

    //  STEPS START
    .today_attendance_data_steps {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;
      width: 100%;
      position: relative;

      // Horizontal line that connects all steps
      &::before {
        content: "";
        position: absolute;
        top: 35px;
        left: 5.5%;
        width: 4px;
        height: 75%;
        background-color: #ddd;
        z-index: 0;
      }

      .today_attendance_data_step {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        position: relative;
        z-index: 1;

        .today_attendance_data_div {
          text-align: left;
          margin-left: 10px;
        }

        .today_attendance_data_icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          position: relative;
        }

        .today_attendance_data_text {
          margin-top: 5px;
          min-height: 100%;
          p {
            font-size: 13px;
            display: inline;
          }
        }

        .today_attendance_data_status {
          font-size: 12px;
        }

        &.completed {
          .today_attendance_data_status {
            display: block;
          }
        }

        &.in-progress {
          .today_attendance_data_status {
            display: block;
            min-height: 0px;
          }
        }

        &.pending {
          .today_attendance_data_status {
            display: block;
          }
        }
      }
    }
    //  STEPS END

    .today_attendance_data_page_btn {
      min-height: 50px;
      min-width: 100%;
      border-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;

      .today_attendance_data_page_status {
        display: block;

        p {
          font-size: 14px;
        }
      }
    }

    .today_attendance_hover_open_card {
      display: none;
    }
  }
}
// END CARD DESIGN FOR PHONE VIEW
