.home_pg {
    .today_attendance_div_for_phone {
        display: none;
    }

    .today_attendance_div_for_pc {
        display: block;
    }

    .home_service_mor_btn {
        margin: "30px 0px 45px 0px";
        margin-top: 10px;
    }

    .home_video_section_parent {
        background-color: var(--white-color);
    }

    .home_video {
        border-radius: 0px 50px 50px 0px;
    }

    .home_faq_more_btnn {
        width: 50%;
        display: flex;
        justify-content: start;
    }

    .home_faq_Button {
        background-color: var(--click-color);
        /* width: 20%; */
        height: 40px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
    }

    @media only screen and (max-width: 575px) {
        .today_attendance_div_for_phone {
            display: block;
        }

        .today_attendance_div_for_pc {
            display: none;
        }

        .home_video {
            border-radius: 20px 20px 20px 0px;
        }

        .home_faq_more_btnn {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .home_faq_Button {
            margin-left: 0%;
            margin-top: 10px;
        }
    }
}