.ht-testimonials-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it covers other content */
}

.ht-testimonials-popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  height: 90%;

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }

    button {
      margin: 5px 0;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    button[type="button"]:nth-child(1) {
      border: 2px solid var(--click-color);
    }

    button[type="button"]:nth-child(2) {
      color: white;
    }
  }
}
