.add-category-container {
  padding: 2rem;
  border-radius: 8px;

  .add-category-form-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .add-category-card {
    background-color: var(--white-color);
    padding: 0rem 2rem 2rem 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    width: 100%;
    margin: 0 auto;

    .add-category-form-grid {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr;
      }

      .add-category-form-row {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .add-category-form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          margin-bottom: 0.5rem;
          font-weight: 500;
        }

        .add-category-form-input {
          width: 100% !important;

          &:focus {
            border-color: var(--click-color);
          }
        }

        .add-category-preview-image {
          width: 80px;
          height: 80px;
          border: 3px dotted var(--logo-primary-color);
          border-radius: 50%;
          margin-right: 20px;
        }

        .add-category-icon-container {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--text-grey);
          border: 3px solid var(--logo-primary-color);          margin-right: 20px;

          span {
            color: var(--white-color);
          }
        }
      }
    }

    .d-flex {
      display: flex;
      justify-content: center;
    }
  }

  .add-category-actions{
    margin-top: 20px;
    // justify-content: right;
  }

  .add-category-categories-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; // Space between categories
  
    .add-category-category-card {
      background-color: var(--white-color);
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  
      .add-category-sub-category-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; // Space between subcategories
  
        .add-category-sub-category-item {
          flex: 1 1 calc(25% - 10px); // 4 items per row
          background-color: #f8f9fa; // Light background for subcategory items
          padding: 0.5rem;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          span {
            cursor: pointer; // Make the icon clickable
            color: var(--primary-color); // Your primary color
          }
        }
      }
    }
  
    @media (max-width: 768px) {
      .add-category-category-card {
        .add-category-sub-category-container {
          .add-category-sub-category-item {
            flex: 1 1 calc(50% - 10px); // 2 items per row on smaller screens
          }
        }
      }
    }
  
    @media (max-width: 480px) {
      .add-category-category-card {
        .add-category-sub-category-container {
          .add-category-sub-category-item {
            flex: 1 1 100%; // 1 item per row on extra small screens
          }
        }
      }
    }
  }
}