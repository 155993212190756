.BusinessTwoFaqs-parent {
  position: relative;
  overflow: hidden;
  padding: 50px 0;
  background-color: #f8f9fa;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
  }

  &:before {
    width: 300px;
    height: 300px;
    background: radial-gradient(
      circle,
      rgba(255, 0, 150, 1) 0%,
      rgba(0, 119, 255, 1) 100%
    );
    top: -100px;
    left: -100px;
  }

  &:after {
    width: 400px;
    height: 400px;
    background: radial-gradient(
      circle,
      rgba(0, 119, 255, 1) 0%,
      rgba(255, 0, 150, 1) 100%
    );
    bottom: -150px;
    right: -150px;
  }
}

.BusinessTwoFaqs-faq-row-div {
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.24);

  ul {
    .BusinessTwoFaqs-faq-heading {
      font-weight: 800;
    }
  }
  svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 50px;
  }
  svg text {
    animation: stroke 4s infinite alternate;
    // stroke-width: 2;
    font-size: 40px;
  }
  @keyframes stroke {
    0% {
      fill: transparent;
      stroke: rgba(54, 95, 160, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
    }
    80% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
      stroke-width: 2;
    }
    100% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
  // Media query for smaller screens (phone view)
  @media screen and (max-width: 1100px) {
    .wrapper {
      svg text {
        font-size: 30px; // Set the font size to 30px for smaller screens
      }
    }
  }
}

@media (max-width: 768px) {
  .BusinessTwoFaqs-faq-row-div {
    ul {
      .BusinessTwoFaqs-faq-heading {
        font-weight: 800;
        text-align: center;
        font-size: 20px;
      }
    }
  }

  .BusinessTwoFaqs-faq-row-div {
    display: flex;
    flex-direction: column-reverse;
    margin: 5px;
  }
}
