@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.home_two_service_parent_div {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .home_two_service_left_card {
    background-color: var(--card-bg);
    position: relative;
    border-radius: 15px;
    padding: 15px;
    margin-top: 20px;
    transition: background-color 0.5s; // Smooth transition for background color change

    &:hover {
      background-color: var(--click-color);
    }

    div {
      margin-top: 20px;
      img {
        width: 100px;
      }

      svg {
        font-family: "Russo One", sans-serif;
        width: 100%;
        height: 50px;
      }
      svg text {
        animation: stroke 3s infinite alternate;
        // stroke-width: 2;
        font-size: 40px;
      }
      @keyframes stroke {
        0% {
          fill: transparent;
          stroke: rgba(54, 95, 160, 1);
          stroke-dashoffset: 25%;
          stroke-dasharray: 0 50%;
          stroke-width: 2;
        }
        70% {
          fill: var(--click-color);
          stroke: rgba(54, 95, 160, 1);
        }
        80% {
          fill: var(--click-color);
          stroke: rgba(54, 95, 160, 1);
          stroke-width: 2;
        }
        100% {
          fill: var(--click-color);
          stroke: rgba(54, 95, 160, 0);
          stroke-dashoffset: -25%;
          stroke-dasharray: 50% 0;
          stroke-width: 0;
        }
      }
      // Media query for smaller screens (phone view)
      @media screen and (max-width: 1100px) {
        .wrapper {
          svg text {
            font-size: 30px; // Set the font size to 30px for smaller screens
          }
        }
      }
      p {
        font-size: 0.9rem;
      }
    }
    &:hover button {
      background-color: var(--white-color);
      color: var(--black-color);
    }
  }

  .home_two_service_Right_card {
    background-image: url(https://images.hdqwalls.com/download/city-4k-buildings-skyscraper-view-l3-1920x1080.jpg);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    border-radius: 15px;
    padding: 15px;
    margin-top: 20px;

    div {
      img {
        border-radius: 15px;
      }
      .home_two_service_Right_services_details {
        div {
          &:nth-child(1) {
            background-color: var(--dark-blue);
            border-radius: 10px;
            padding: 5px;
            h6 {
              span {
                color: var(--white-color);
              }
            }
          }
          &:nth-child(2) {
            margin-top: 20px;

            h5 {
              color: var(--white-color);
              font-weight: 700;
            }
          }
          &:nth-child(3) {
            margin-top: 20px;

            p {
              color: var(--white-color);
            }
          }
          &:nth-child(4) {
            margin-top: 20px;

            button {
              color: var(--white-color);
            }
          }
        }
      }
    }

    .status-slip {
      display: flex;
      justify-content: space-between;
      margin-top: 10px; /* Adjust margin as needed */
      .status-indicator {
        position: relative;
        width: 100%; /* Adjust width as needed */
        height: 4px; /* Adjust height as needed */
        background-color: var(--click-color); /* Set default background color */
      }
      .status-indicator.active::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background-color: var(--grey-light); /* Set active background color */
        animation: runBar 8s linear infinite; /* Adjust animation duration as needed */
      }
      @keyframes runBar {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
