// SECTION HEADING WITH ANIMATION START

.business-three-box-container {
  position: relative;
  overflow: hidden;
  height: auto;
  margin: auto;
  padding: 0px 0px;
  margin-bottom: 30px;
  align-items: center;
  display: flex;

  .business-three-heading-div {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-left: 5px solid var(--click-color);
    transition: opacity 2s ease, transform 2s ease;

    h3 {
      font-size: 2.3rem;
      font-weight: 700;
      font-family: sans-serif;
      color: #4b4b4b;
      position: relative;
      padding-left: 10px;
      opacity: 0;
      /* Initially hidden */
      transform: translateX(-100%);
      /* Initially off-screen */
      transition: opacity 1s ease, transform 1s ease;

      span {
        color: var(--heading-color);
      }
    }
  }

  .business-three-heading-div.animate h3 {
    opacity: 1;
    /* Make visible */
    transform: translateX(0);
    /* Slide in */
  }

  .business-three-template-view-more-icon {
    background-color: var(--click-color);
    color: var(--pure-white-color);
    width: 30px;
    height: 30px;
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes slideInLeftToRight {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(0);
      }
    }

    @keyframes slideInLeftToRight {
      from {
        transform: translateX(-5%);
      }

      to {
        transform: translateX(15%);
      }
    }

    .business-three-template-view-more-icon {
      background-color: var(--click-color);
      width: 30px;
      height: 30px;
      font-weight: 700;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      /* Ensure the sliding span is contained within the circle */
    }

    span {
      animation: slideInLeftToRight 0.5s linear infinite alternate;
      font-weight: 700;
    }
  }

  @media (max-width: 480px) {
      padding: 0px 15px;

      .business-three-heading-div {
        h3 {
          font-size: 20px;
        }
  
        
      }
    
  

   
  }

}

// SECTION HEADING WITH ANIMATION END