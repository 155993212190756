.accommodation-property-checkout-container {
    padding: 20px;
    border-radius: 10px;
  }

  .accommodation-property-checkout-booking-review{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    h5 {
        font-weight: 700;
      }


  }
  
  .accommodation-property-checkout-transport-info {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    
    h5 {
      color: #1a64db;
      font-weight: 700;
    }
  
    .accommodation-property-checkout-description {
      margin: 10px 0;
      color: #777;
    }
  
    .accommodation-property-checkout-transport-details {
      display: flex;
      justify-content: space-between;
      background-color: #fff5d8;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 15px;
  
      .accommodation-property-checkout-detail-item {
        display: flex;
        flex-direction: column;
  
        span {
          font-size: 12px;
          color: #666;
        }
  
        strong {
          font-size: 14px;
          color: #333;
        }
      }
    }
  
    .accommodation-property-checkout-location-info {
      p {
        margin: 5px 0;
        strong {
          color: #333;
        }
      }
    }
  
    .accommodation-property-checkout-details-link {
      color: #1a64db;
      text-decoration: none;
    }
  }
  
  .accommodation-property-checkout-customer-details-card {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  
    h5 {
      margin-bottom: 15px;
      color: #1a64db;
      font-weight: 700;
    }

    h6 {
        color: #1a64db;
        font-weight: 700;
      }
  
    .accommodation-property-checkout-form-group {
      margin-bottom: 15px;
  
      label {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
      }
  
      input, select {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }
  
    .accommodation-property-checkout-form-row {
      display: flex;
      justify-content: space-between;
  
      .accommodation-property-checkout-form-group {
        flex: 1;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  
    .accommodation-property-checkout-form-control {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }
  
  .accommodation-property-checkout-payment-section {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .coupon-code-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }
    
    h6 {
      color: #1a64db;
      font-weight: 700;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .coupon-code-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    
    .coupon-option {
      display: flex;
      align-items: center;
      border: 1px solid #eaeaea;
      border-radius: 8px;
      padding: 10px;
      background-color: #f9f9f9;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .coupon-option input {
      margin-right: 15px;
      accent-color: #1a64db;
      cursor: pointer;
    }
    
    .coupon-option label {
      flex-grow: 1;
      cursor: pointer;
    }
    
    .coupon-info {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 600;
    }
    
    .coupon-description {
      color: #777;
      margin-top: 5px;
      font-size: 0.9rem;
    }
    
    .coupon-option:hover {
      background-color: #e6f0ff;
    }

    
    .price-breakup-card {
        background-color: white;
        border: 1px solid #eaeaea;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        
        h3 {
          margin-bottom: 1rem;
        }
    
        .price-breakup-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
          span {
            font-size: 1rem;
          }
        }
    
        .limited-time-sale {
          background-color: #ffe58f;
          color: #d48806;
          padding: 5px 10px;
          display: inline-block;
          border-radius: 4px;
          margin-bottom: 1rem;
          font-size: 0.9rem;
        }
    
        .price-breakup-total {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 1.2rem;
          margin-top: 1.5rem;
          border-top: 1px solid #eaeaea;
          padding-top: 1rem;
        }
      }
  
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .accommodation-property-checkout-amount {
      font-size: 24px;
      color: #1a64db;
      margin-bottom: 10px;
    }
  
    .accommodation-property-checkout-payment-description {
      color: #777;
      margin-bottom: 15px;
    }
  
    .accommodation-property-checkout-form {
        background-color: white;
        border: 1px solid #eaeaea;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        .css-hip9hq-MuiPaper-root-MuiAppBar-root{
            background-color: white;
            color: black;
            font-weight: 700;
            letter-spacing: 1px;
            box-shadow: none;
            border: 1px solid rgb(222, 222, 222);
        }
      .accommodation-property-checkout-payment-methods {
        display: flex;
        margin-bottom: 15px;
  
        .accommodation-property-checkout-payment-method {
          background: #ddd;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
  
          &.active {
            background: var(--click-color);
            color: #fff;
          }
        }
      }
  
      // .accommodation-property-checkout-form-group {
      //   margin-bottom: 15px;
  
      //   label {
      //     display: block;
      //     font-size: 14px;
      //     margin-bottom: 5px;
      //   }
  
      //   input {
      //     width: 100%;
      //     padding: 10px;
      //     border-radius: 5px;
      //     border: 1px solid #ccc;
      //   }
      // }
  
      // .accommodation-property-checkout-form-row {
      //   display: flex;
  
      //   .accommodation-property-checkout-form-group {
      //     flex: 1;
      //     &:first-child {
      //       margin-right: 10px;
      //     }
      //   }
      // }
  
      .accommodation-property-checkout-confirm-btn {
        background-color: var(--logo-primary-color);
        color: #fff;
        padding: 15px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        font-size: 16px;
      }
    }
  }
  