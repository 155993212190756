.education_two {
  .et_joinsession {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .shape {
      position: absolute;
      z-index: 3;
      top: 0;
      right: 0;
    }

    .shape_one {
      z-index: 4;
    }

    .etj_inner {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: var(--heading-color);
      opacity: 85%;
    }

    .etj_left {
      h6 {
        color: var(--white-color);
        font-size: 23px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: regular;
        letter-spacing: 0.7px;
        margin-bottom: 30px;
      }

      h4 {
        color: var(--white-color);
        font-size: 50px;
        font-family: bold;
        line-height: 1.2;
        margin-bottom: 20px;
        letter-spacing: 0.7px;
      }
      .call-btn {
      
        background: linear-gradient(90deg, #ff7e5f, #feb47b); /* Gradient for a vibrant effect */
        color: white;
        padding: 10px 30px;
        border-radius: 50px; /* Makes the button pill-shaped */
        font-size: 1.2rem;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
        transition: background 0.3s ease, box-shadow 0.3s ease;
      }
      
      .call-btn:hover {
        background: linear-gradient(90deg, #feb47b, #ff7e5f); /* Reversed gradient on hover */
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
      }
      
      .call-btn .icon {
        margin-right: 10px; /* Space between icon and text */
      }
      
    }

    .etj_right {
      .video_area {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: column;

        .click_link {
          color: var(--white-color);
        }

        .video_icon {
          background: white;
          border-radius: 50%;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;

          .material-symbols-outlined {
            color: var(--click-color);
            font-size: 58px;
            font-variation-settings: "wght" 400, "FILL" 1;
          }
        }
      }
    }
  }

  // Responsive Styles
  @media (max-width: 767px) {
    .et_joinsession {
      .shape {
        display: none; // Hide shape image on screens <= 767px
      }

      .etj_left {
        h6 {
          font-size: 18px; // Adjusted font size
        }

        h4 {
          font-size: 32px; // Adjusted font size
          margin-bottom: 15px; // Reduced margin
        }
      }

      .etj_right {
        .video_area {
          flex-direction: column; // Stack elements vertically
          gap: 8px; // Reduced gap

          .video_icon {
            padding: 6px; // Reduced padding
            .material-symbols-outlined {
              font-size: 48px; // Adjusted icon size
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .et_joinsession {
      .etj_left {
        h6 {
          font-size: 16px; // Further reduced font size
        }

        h4 {
          font-size: 24px; // Smaller font size
        }
      }

      .etj_right {
        .video_area {
        
          .video_icon {
            padding: 4px; // Further reduced padding
            .material-symbols-outlined {
              font-size: 36px; // Smaller icon size
             
            }
          }
        }
      }
    }
  }
}
