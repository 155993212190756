/* BusinessTwoTeam.css */

.business-two-team-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f0f0f0;
  flex-wrap: wrap; /* Add this for better responsiveness */
}

.business-two-team-left {
  position: relative;
  overflow: hidden;
  background: white;
  color: white;
  padding: 30px;
  margin-right: 30px;
  border-radius: 10px;
  text-align: center;
  flex: 1; /* Make it flexible */
  transition: transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
  }

  &:before {
    width: 200px;
    height: 200px;
    background: radial-gradient(
      circle,
      rgba(255, 0, 150, 1) 0%,
      rgba(0, 119, 255, 1) 100%
    );
    top: -100px;
    left: 300px;
  }

  &:after {
    width: 300px;
    height: 300px;
    background: radial-gradient(
      circle,
      rgba(0, 119, 255, 1) 0%,
      rgba(255, 0, 150, 1) 100%
    );
    bottom: -150px;
    right: 200px;
  }
}

.business-two-team-right {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  flex: 3; /* Make it flexible */
}

.business-two-team-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.business-two-team-card img {
  width: 100%;
}

.business-two-team-right button {
  grid-column: span 2;
  background-color: #d71a1b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.business-two-team-svg {
  font-family: "Russo One", sans-serif;
  width: 100%;
  height: 50px;
}
.business-two-team-svg text {
  animation: stroke 4s infinite alternate;
  // stroke-width: 2;
  font-size: 40px;
}
@keyframes stroke {
  0% {
    fill: transparent;
    stroke: rgba(54, 95, 160, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: var(--click-color);
    stroke: rgba(54, 95, 160, 1);
  }
  80% {
    fill: var(--click-color);
    stroke: rgba(54, 95, 160, 1);
    stroke-width: 2;
  }
  100% {
    fill: var(--click-color);
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
// Media query for smaller screens (phone view)
@media screen and (max-width: 1100px) {
  .wrapper {
    svg text {
      font-size: 30px; // Set the font size to 30px for smaller screens
    }
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .business-two-team-card img {
    width: 100%;
    height: 200px;
  }
  .business-two-team-wrapper {
    flex-direction: column; /* Stack elements vertically */
  }

  .business-two-team-left {
    width: 100%;
    margin: 0px 0px 30px 0px;
  }

  .business-two-team-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .business-two-team-right button {
    grid-column: span 1; /* Button takes full width */
  }
}
