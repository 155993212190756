.holiday_coin_and_holiday_parent {
  min-width: 100%;
  .coin_and_holiday_parent {
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .holiday_parent_pgattendance {
      display: flex;
      align-items: center;
      width: 55%;

      /* Default (PC View) */
      .holiday_content_pgattendance {
        display: flex;

        .holiday_text_pgattendance {
          display: flex;
          flex-direction: column; /* Column layout for PC */
        }
        /* Hide marquee by default */
        .holiday_marquee_pgattendance {
          display: none;
        }
      }
    }

    .coins_parent_pgattendance {
      .attendance_page_total_coin_parent {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }

      .attendance_page_total_coin_box {
        display: flex;
        align-items: center;
        background: linear-gradient(180deg, #ffcc00, #ff9900);
        border-radius: 50px;
        padding: 7px 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        width: fit-content;
      }

      .attendance_page_coin_info {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .attendance_page_coin_icon {
        font-size: 24px;

        img {
          width: 40px;
        }
      }

      .attendance_page_coin_text {
        display: flex;
        flex-direction: column;
      }

      .attendance_page_bonus_text {
        font-size: 12px;
        font-weight: bold;
        color: green;
      }

      .attendance_page_coin_value {
        font-size: 15px;
        font-weight: bold;
        color: #fff;
      }

      .attendance_page_claim_button {
        background: #fff;
        color: #000;
        border: none;
        padding: 5px 15px;
        border-radius: 20px;
        font-weight: bold;
        margin-left: 10px;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .holiday_coin_and_holiday_parent {
    .coin_and_holiday_parent {
      border-radius: 8px;
      padding: 10px;
      flex-direction: column;

      .holiday_parent_pgattendance {
        width: 100%;
        .holiday_content_pgattendance {
          position: relative;
          overflow: hidden;
          white-space: nowrap;

          .holiday_text_pgattendance {
            display: none; /* Hide column view */
          }

          .holiday_marquee_pgattendance {
            display: inline-block;
          }
        }
      }
    }
  }
}
