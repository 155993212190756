.happyerp-pg_header {
  .happyerp-first_row {
    display: flex;
    align-items: center;
    gap: 22px;
    justify-content: space-between;
    .happyerp-right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
  .happyerp-second_row {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    .happyerp-left {
      display: flex;
      gap: 15px;
      align-items: center;
    }
    .happyerp-right {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }
  .happyerp-datefilter {
    input {
      padding: 5px 10px;
      border: none;
      margin: 0px;
      border: 1px solid var(--theme-blue);
      max-width: 135px;
      font-size: 14px;
      font-family: regular;
    }
    input:focus-visible {
      outline: none !important;
      border-radius: 0px;
      border-color: var(--theme-midnight-blue);
    }
    span {
      font-size: 22px;
      position: absolute;
      top: 49%;
      transform: translateY(-50%);
      right: 5px;
      color: var(--theme-blue);
      line-height: 0px;
    }
  }
  .happyerp-button_filter {
    display: flex;
    align-items: center;
    border: 1px solid var(--theme-blue);
    background: var(--white-color);
    .happyerp-bf_single {
      font-size: 14px;
      font-family: "regular";
      padding: 5px 15px;
      cursor: pointer;
      color: var(--text-grey);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .happyerp-bf_single.active {
      background: var(--theme-blue);
      color: white;
    }
  }
}

.happyerp-dashboard {
  .happyerp-dashboard_inner {
    .happyerp-congrats {
      display: flex;
      align-items: center;
      .happyerp-left {
        width: 70%;
        padding: 22px;
      }
      .happyerp-right {
        width: 30%;
        img {
          width: 100%;
          width: 100%;
          margin-bottom: -5px;
          margin-top: -28px;
        }
      }
    }
    .happyerp-cg_1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 22px;
      grid-row-gap: 22px;
      .happyerp-cd1_inner {
        display: flex;
        align-items: center;
        gap: 10px;
        .happyerp-svg_icon {
          width: fit-content;
          img {
            position: absolute;
            top: 50%;
            right: 0;
            width: 32px;
            left: 4px;
            margin: auto;
            transform: translateY(-50%);
          }
        }
        .happyerp-content {
          h6 {
            font-size: 14px;
            font-family: "regular";
            color: var(--text-grey);
          }
          h5 {
            font-size: 24px;
            font-family: "semibold";
            color: var(--text-black);
            margin-top: 5px;
          }
        }
      }
      .happyerp-trending {
        position: absolute;
        bottom: 5px;
        right: 5px;
        .happyerp-inner {
          display: flex;
          font-size: 12px;
          font-family: "medium";
          gap: 4px;
          align-items: center;
          span {
            font-size: 19px;
            line-height: 0px;
          }
          &.happyerp-up {
            color: var(--theme-green);
          }
          &.happyerp-down {
            color: var(--theme-crimson-red);
          }
        }
        p {
          font-size: 10px;
          color: var(--text-grey);
          margin-top: -4px;
        }
      }
    }
    .happyerp-two_graph {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 22px;
    }
  }
}
/* Adjust the width of the bars */
.happyerp-recharts-bar-rectangle {
  width: 10px; /* Change to your desired width */
}

/* Remove shadow when hovering over bars */
.happyerp-recharts-bar-rectangle:hover {
  box-shadow: none !important;
}
