.my-grid-layout {
    display: flex;
    flex-direction: column;
    // height: 100%;
    user-select: none; /* Prevent text selection */
    -webkit-user-drag: none; /* Prevent default browser drag */
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  
    .top-bar {
      display: flex;
      padding: 10px;
      background: #f0f0f0;
      border-bottom: 1px solid #ccc;
    }
    
    .main-content {
      display: flex;
      flex: 1;
  
      .sidebar {
        width: 200px;
        background: #f8f8f8;
        border-right: 1px solid #ccc;
        padding: 10px;
  
        button {
          display: block;
          margin-bottom: 10px;
          width: 100%;
        }
      }
  
      .grid-layout {
        flex: 1;
        padding: 10px;
      }

      .my-grid-layout-main-div-layout {
        border: 1px solid #ddd;
        background: white;

        .my-grid-layout-main-div-layout-hover {
            background-color: #ddd;
            height: 100%;
            width: 100%;
            &:hover {
                border: 2px solid var(--click-color);
            }
        }
        .my-grid-layout-main-div-layout-hover-none{
            background-color: transparent;
            height: 100%;
            width: 100%; 
        }
    }

      .editor-panel {
        width: 300px;
        background: #f8f8f8;
        border-left: 1px solid #ccc;
        padding: 10px;
      }
    }
  }
  