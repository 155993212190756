.customer-panel-layout {
    display: flex;
    min-height: 100vh;

    .customer-panel-layout-content {
        flex: 1;
        padding: 2rem;
        background-color: #f5f5f5;
    }
}
