.business-two-about-us {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 10px;
  position: relative;
  overflow: hidden;

  .business-two-about-us-video {
    z-index: 999;
    video {
      width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .business-two-about-us-text {
    z-index: 1;
    background-color: white;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);

    .business-two-about-us-counters {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    .business-two-about-us-counter {
      border-radius: 8px;
      text-align: left;
      padding: 20px;
      flex: 1 1 calc(50% - 15px);
      color: #fff; /* Text color for contrast */
      display: flex;
      align-items: center;

      &-content {
        display: flex;
        align-items: center;
      }

      .business-two-about-us-counter-icon {
        font-size: 2rem;
        margin-right: 15px;
        display: flex;
        span {
          font-size: 40px;
          color: white;
        }
      }

      .business-two-about-us-counter-number {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .business-two-about-us-counter-label {
        font-size: 1rem;
        color: #fff;
      }
    }

    .bg-color-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      background: radial-gradient(
        circle,
        rgba(255, 0, 150, 1) 0%,
        rgba(0, 119, 255, 1) 100%
      );
    }

    .bg-color-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      background: radial-gradient(
        circle,
        rgba(255, 0, 150, 1) 0%,
        rgba(0, 119, 255, 1) 100%
      );
    }

    .bg-color-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      background: radial-gradient(
        circle,
        rgba(255, 0, 150, 1) 0%,
        rgba(0, 119, 255, 1) 100%
      );
    }

    .bg-color-4 {
      display: flex;
      align-items: center;
      justify-content: center;
      background: radial-gradient(
        circle,
        rgba(255, 0, 150, 1) 0%,
        rgba(0, 119, 255, 1) 100%
      );
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("https://wallpaperaccess.com/full/1137443.jpg");
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.6;
  }
  svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 50px;
  }
  svg text {
    animation: stroke 3s infinite alternate;
    // stroke-width: 2;
    font-size: 40px;
  }
  @keyframes stroke {
    0% {
      fill: transparent;
      stroke: rgba(54, 95, 160, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
    }
    80% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 1);
      stroke-width: 2;
    }
    100% {
      fill: var(--click-color);
      stroke: rgba(54, 95, 160, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
  // Media query for smaller screens (phone view)
  @media screen and (max-width: 1100px) {
    .wrapper {
      svg text {
        font-size: 30px; // Set the font size to 30px for smaller screens
      }
    }
  }
}

@media (max-width: 576px) {
  .business-two-about-us-text {
    margin-top: 40px;
  }
}
