// Variables
$border-radius: 8px;
$card-padding: 13px;
$card-margin: 10px;
$card-width: 100px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$border-color: #ccc;
$background-color: var(--card-bg);
$heading-font: "Roboto", sans-serif; // Example font family

.category-component-parent-section {
  position: relative;
  // padding: 20px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://static.vecteezy.com/system/resources/previews/022/850/373/non_2x/horizontal-shot-of-hills-in-different-shades-of-blue-and-the-cloudy-evening-sky-generative-ai-photo.jpeg"); // Replace with the path to your image
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    opacity: 0.3; // Adjust the opacity to make the background image less prominent
    // z-index: -1;
  }

  .category-component-category-section {
    .category-component-category-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; // Adjust the gap between cards
    }

    .category-component-document-card {
      background-color: $background-color;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: $card-padding;
      width: $card-width;
      box-shadow: $box-shadow;
      text-align: center;
      position: relative;
      transition: transform 0.3s; // Add smooth transition effect
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:hover {
        transform: scale(1.05); // Zoom in the card on hover
      }

      h3 {
        color: var(--black-color);
        font-family: $heading-font;
        font-size: 90%; // Example font size
        text-transform: capitalize; // Capitalize the first letter of each word
      }

      .category-component-image-icon {
        margin-top: 5px;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
  }
}
