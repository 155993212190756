//

.add-new-product-btn-container {
  position: fixed;
  bottom: 150px;
  right: 32px;
  z-index: 1000;

  button {
    padding: 10px 20px;
    border-radius: 50%;
    border: none;
    background-color: var(--click-color);
    color: white;
    font-size: 25px;
    cursor: pointer;
    font-weight: 700;
  }
}

.ProductsFilterAndSearch {

  .ProductsFilterAndSearch-filter-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    // background-color: #00000080;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    gap: 16px;

    .ProductsFilterAndSearch-search-bar {
      flex: 1;

      input[type="text"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
          border-color: var(--logo-secondary-color);
        }
      }
    }

    .ProductsFilterAndSearch-category-dropdown {
      flex: 1;
      position: relative;

      .dropdown-container {
        position: relative;
        border-radius: 50px;

        .ProductsFilterAndSearch-dropdown-header {
          cursor: pointer;
          padding: 10px;
          border: 1px solid #ccc;
          background-color: white;
          border-radius: 50px;
          font-size: 14px;
          transition: border-color 0.3s ease;

          &.open {
            border-color: var(--logo-secondary-color);
          }

          .ProductsFilterAndSearch-dropdown-header-text {
            display: block;
          }
        }

        .ProductsFilterAndSearch-dropdown-content {
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 1000; // Ensure dropdown is above other content
          background-color: #fff;
          border: 1px solid #ccc;
          border-top: none;
          border-radius: 0 0 5px 5px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

          
          .ProductsFilterAndSearch-dropdown-search-input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 14px;
            transition: border-color 0.3s ease;

            &:focus {
              outline: none;
              border-color: var(--logo-secondary-color);
            }
          }

          .ProductsFilterAndSearch-dropdown-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .ProductsFilterAndSearch-dropdown-item {
              cursor: pointer;
              padding: 10px;
              font-size: 14px;
              transition: background-color 0.3s ease;
              text-transform: capitalize;

              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }
      }
    }
  }

  .ProductsFilterAndSearch-subcategory-container {
    border-bottom: 2px solid #d7d7d7;
    overflow-x: auto; // Enable horizontal scrolling
    white-space: nowrap; // Prevent items from wrapping
    background-color: rgb(231, 231, 231);

    // Hide scrollbar for WebKit (Chrome, Safari, etc.)
    &::-webkit-scrollbar {
      display: none;
    }

    .ProductsFilterAndSearch-subcategory-list {
      display: flex;
      justify-content: space-around;
      gap: 15px; // Adjust gap between items as needed
    }

    .ProductsFilterAndSearch-subcategory-item {
      cursor: pointer;
      transition: background-color 0.3s;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 15px; // Adjust padding as needed
      white-space: nowrap; // Ensure item stays in one line

      &:hover {
        background-color: #e0e0e0;
      }

      .ProductsFilterAndSearch-arrow-icon {
        span {
          margin-left: 1px;
          font-size: 17px;
          align-items: center;
          display: flex;
        }
      }

      &.active {
        border-bottom: 3px solid var(--click-color);
        background-color: #b4afaf;
      }
    }
  }
}

.category-scroll-container {
  // margin: 20px 0;
  padding: 10px 20px;

  .category-scroll-header {
    display: flex;
    align-items: center;
    gap: 20px;

    .category-header-left {
      width: 30%;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
      }

      .refresh-button {
        padding: 5px;

        &:hover {
          color: var(--click-color);
        }
      }
    }

    .category-scroll-controls {
      display: flex;
      align-items: center;
      width: 70%;
      gap: 10px;
      position: relative;

      .scroll-button {
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 2;

        &:hover:not(:disabled) {
          background-color: #f5f5f5;
        }

        &:disabled {
          background-color: #f0f0f0;
          box-shadow: none;
        }
      }
    }
  }

  .category-chips-container {
    display: flex;
    overflow-x: auto;
    // scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    gap: 30px;
    padding: 10px 0;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .category-chip {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 16px;
      background: #f5f5f5;
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;
      min-width: fit-content;
      border: 1px solid #e0e0e0;
      // scroll-snap-align: start;

      &:hover {
        background: #e0e0e0;
      }

      &.active {
        background: var(--click-color);
        color: white;

        img {
          border: 2px solid #fff;
        }
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid transparent;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.corporateTwo-cards-container {
  .corporateTwo-card-2 {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    height: 450px;
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    overflow: hidden;
    z-index: 100;

    .card-2-clickable-area {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 50;
      cursor: pointer;
    }

    .card-2-image-container {
      height: 55%;
      background: #f8f8f8;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
      }

      .card-2-discount-info-text {
        position: absolute;
        left: 12px;
        top: 12px;
        background: var(--click-color);
        color: white;
        padding: 4px 12px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 600;
        z-index: 20;
      }

      .card-2-inactive-status,
      .card-2-active-status {
        position: absolute;
        left: 12px;
        top: 12px;
        padding: 6px 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 13px;
        font-weight: 600;
        z-index: 20;
      }

      .card-2-active-status {
        background: rgb(161, 242, 190);
        color: rgb(22, 163, 74);
        border: 2px solid rgb(22, 163, 74);
        border-radius: 20px;

        .card-2-active-flag {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgb(22, 163, 74);
        }
      }

      .card-2-inactive-status {
        background: rgb(245, 153, 153);
        color: rgb(220, 38, 38);
        border: 2px solid rgb(220, 38, 38);
        border-radius: 20px;

        .card-2-inactive-flag {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgb(220, 38, 38);
        }
      }

      .card-2-rating-info {
        position: absolute;
        bottom: 12px;
        right: 12px;
        background: white;
        padding: 4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 20;

        span {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .card-2-info-container {
      height: 45%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .corporatetwo-card-2-title-info {
        .corporateTwo-card-2-title {
          font-size: 16px;
          font-weight: 600;
          color: #1f2937;
          margin-bottom: 8px;
          line-height: 1.4;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .corporateTwo-card-2-short-desc {
          font-size: 14px;
          color: #6b7280;
          line-height: 1.4;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          max-height: 2.8em;
        }
      }

      .card-2-price-info {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 12px 0;

        .card-2-selling-price h3 {
          font-size: 20px;
          font-weight: 700;
          color: #1f2937;
        }

        .card-2-discount-info {
          display: flex;
          align-items: center;
          gap: 8px;

          p del {
            color: #9ca3af;
            font-size: 14px;
          }

          span {
            color: #dc2626;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .card-2-important-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 100;

        .card-2-cart-btn {
          // flex: 1;
          padding: 10px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          transition: all 0.2s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          background: rgba(var(--click-color-rgb), 0.1);
          color: var(--click-color);
          border: 1px solid var(--click-color);
          cursor: pointer;
        }

        .card-2-cart-btn.out-of-stock {
          background-color: #ccc;
          cursor: not-allowed;
          opacity: 0.7;

          &:hover {
            background-color: #ccc;
          }
        }

        .card-2-detail-btn {
          padding: 10px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          transition: all 0.2s;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          cursor: pointer;
        }

        .addwishlist-btn {
          background-color: #d4d4d4;
          color: #9b9b9b;
        }

        .removewishlist-btn {
          background-color: var(--click-color);
          color: white;
        }

        .corporateTwo-card-2-quantity {
          display: flex;
          align-items: center;
          border: 1px solid var(--click-color);
          border-radius: 6px;
          overflow: hidden;

          .corporateTwo-card-2-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(var(--click-color-rgb), 0.1);
            color: var(--click-color);
            border: none;
            cursor: pointer;
            z-index: 500;
          }

          input {
            width: 40px;
            height: 32px;
            text-align: center;
            border: none;
            font-weight: 600;
            color: var(--click-color);
            background: transparent;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .modal-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 12px;
    width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 10000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      color: #333;
    }

    .add-product-form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .product-buyer-type-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
          display: flex;
          align-items: center;
          gap: 5px;

          span {
            font-size: 16px;
            font-weight: 600;
          }

          span:last-child {
            font-size: 12px;
            font-weight: 500;
          }
        }

        .product-buyer-type-options {
          display: flex;
          gap: 10px;

          label {
            font-size: 14px;
            font-weight: 500;

            input {
              margin-right: 10px;
            }
          }
        }
      }

      .image-upload-container {
        display: flex;
        gap: 10px;

        .image-upload-box {
          width: 100px;
          height: 100px;
          border: 2px dashed #ccc;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          transition: all 0.3s ease;

          &:hover {
            border-color: var(--click-color);
          }

          .image-preview {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 8px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: cover;
            }

            .remove-image-btn {
              position: absolute;
              top: 5px;
              right: 5px;
              background: rgba(255, 0, 0, 0.7);
              color: white;
              border: none;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }

          .upload-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 100%;
            width: 100%;

            span {
              margin-top: 5px;
              font-size: 12px;
            }
          }

          input[type="file"] {
            display: none;
          }

          label {
            font-size: 24px;
            color: #ccc;
            transition: all 0.3s ease;
          }

          &:hover label {
            color: var(--click-color);
          }
        }
      }

      .form-input {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        font-size: 16px;
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
          border-color: var(--click-color);
        }
      }

      .dropdown-row {
        display: flex;
        gap: 20px;

        select {
          flex: 1;
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 10px;

        button {
          padding: 10px 20px;
          border: none;
          border-radius: 6px;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease;

          &.btn-cancel {
            background-color: #f0f0f0;
            color: #333;

            &:hover {
              background-color: #e0e0e0;
            }
          }

          &.btn-save {
            background-color: var(--click-color);
            color: white;

            &:hover {
              background-color: var(--click-color);
            }
          }
        }
      }
    }
  }
}

// Media Queries
@media (max-width: 1440px) {
  .ProductsFilterAndSearch {
    .ProductsFilterAndSearch-filter-card {
      padding: 12px;
      gap: 10px;

      input[type="text"] {
        padding: 8px;
        font-size: 13px;
      }

      .ProductsFilterAndSearch-dropdown-header {
        padding: 8px;
        font-size: 13px;
      }
    }
  }

  .corporateTwo-cards-container {
    .corporateTwo-card-2 {
      .card-2-info-container {
        padding: 12px;

        .corporatetwo-card-2-title-info {
          .corporateTwo-card-2-title {
            font-size: 15px;
            margin-bottom: 6px;
          }

          .corporateTwo-card-2-short-desc {
            font-size: 13px;
          }
        }

        .card-2-price-info {
          margin: 10px 0;
          gap: 10px;

          .card-2-selling-price h3 {
            font-size: 18px;
          }
        }

        .card-2-important-info {
          gap: 10px;

          .card-2-cart-btn,
          .card-2-detail-btn {
            padding: 8px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .add-new-product-btn-container {
    bottom: 150px;
    right: 37px;

    button {
      padding: 8px 16px;
      font-size: 22px;
    }
  }
}

@media (max-width: 1200px) {
  .ProductsFilterAndSearch {
    .ProductsFilterAndSearch-filter-card {
      padding: 10px;
      gap: 8px;
    }

    .ProductsFilterAndSearch-subcategory-container {
      .ProductsFilterAndSearch-subcategory-item {
        padding: 2px 12px;

        .ProductsFilterAndSearch-arrow-icon span {
          font-size: 15px;
        }
      }
    }
  }

  .corporateTwo-cards-container {
    .corporateTwo-card-2 {
      .card-2-info-container {
        padding: 12px;

        .corporatetwo-card-2-title-info {
          .corporateTwo-card-2-title {
            font-size: 15px;
            margin-bottom: 6px;
          }

          .corporateTwo-card-2-short-desc {
            font-size: 13px;
          }
        }

        .card-2-price-info {
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          .card-2-selling-price {
            h3 {
              font-size: 16px;
            }
          }
        }

        .card-2-important-info {
          gap: 10px;

          .card-2-cart-btn,
          .card-2-detail-btn {
            padding: 8px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ProductsFilterAndSearch {
    .ProductsFilterAndSearch-filter-card {
      padding: 8px;
      gap: 6px;

      input[type="text"] {
        padding: 6px;
        font-size: 12px;
      }
    }
  }

  .modal-overlay{
      .modal-content {
    width: 90%;
    padding: 15px 20px;

    h2 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .add-product-form {
      gap: 15px;

      .image-upload-container {
        flex-wrap: wrap;
        gap: 8px;

        .image-upload-box {
          width: 80px;
          height: 80px;
        }
      }

      .form-input {
        padding: 8px 12px;
        font-size: 14px;
      }

      .form-actions button {
        padding: 8px 15px;
        font-size: 14px;
      }
    }
  }
  }
}

@media (max-width: 576px) {
  .corporateTwo-cards-container {
    .corporateTwo-card-2 {
      height: 430px;

      .card-2-image-container {
        height: 50%;

        .card-2-discount-info-text {
          font-size: 11px;
          padding: 3px 8px;
        }

        .card-2-rating-info {
          padding: 3px 6px;

          span {
            font-size: 12px;
          }
        }
      }

      .card-2-info-container {
        height: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .corporatetwo-card-2-title-info {
          .corporateTwo-card-2-title {
            font-size: 14px;
            margin-bottom: 4px;
          }

          .corporateTwo-card-2-short-desc {
            font-size: 13px;
          }
        }

        .card-2-price-info {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 3px;
          margin-bottom: 10px 0 5px 0;

          .card-2-selling-price h3 {
            font-size: 16px;
          }

          .card-2-discount-info {
            p del {
              font-size: 12px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .corporateTwo-cards-container {
    .corporateTwo-card-2 {
      height: 380px;

      .card-2-image-container {
        // height: 50%;

        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
          aspect-ratio: 1;
          border-radius: 8px;
        }
      }

      .card-2-info-container {
        .corporatetwo-card-2-title-info {
          .corporateTwo-card-2-title {
            font-size: 13px;
            margin-bottom: 4px;
          }

          .corporateTwo-card-2-short-desc {
            font-size: 12px;
          }
        }

        .card-2-important-info {
          .card-2-cart-btn,
          .card-2-detail-btn {
            padding: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .modal-content {
    padding: 12px 15px;

    h2 {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .add-product-form {
      gap: 12px;

      .image-upload-container {
        .image-upload-box {
          width: 50px;
          height: 50px;

          label {
            font-size: 14px;
          }
        }
      }

      .form-input {
        padding: 6px 10px;
        font-size: 13px;
      }

      .form-actions button {
        padding: 6px 12px;
        font-size: 13px;
      }
    }
  }
}

.modal-overlay {
  .modal-content {
    // Update existing properties
    width: 90%;
    max-width: 600px;
    margin: 20px;
    padding: 20px;

    @media (max-width: 768px) {
      width: calc(100% - 32px); // Account for margins
      padding: 16px;
      margin: 16px;

      h2 {
        font-size: 18px;
        margin-bottom: 16px;
      }

      .add-product-form {
        gap: 12px;

        .form-input {
          padding: 8px 12px;
          font-size: 14px;

          &::placeholder {
            font-size: 13px;
          }
        }

        .dropdown-row {
          flex-direction: column;
          gap: 12px;
        }

        select {
          padding: 8px 12px;
          font-size: 14px;
        }

        .form-actions {
          gap: 10px;
          margin-top: 16px;

          button {
            padding: 10px 16px;
            font-size: 14px;
            min-width: 80px;
          }
        }
      }
    }

    @media (max-width: 480px) {
      padding: 12px;
      margin: 12px;

      h2 {
        font-size: 16px;
        margin-bottom: 12px;
      }

      .add-product-form {
        gap: 10px;

        .form-input {
          padding: 8px 10px;
          font-size: 13px;

          &::placeholder {
            font-size: 12px;
          }
        }

        select {
          padding: 8px 10px;
          font-size: 13px;
        }

        .form-actions {
          gap: 8px;
          margin-top: 12px;

          button {
            padding: 8px 14px;
            font-size: 13px;
            min-width: 70px;
          }
        }
      }
    }
  }
}
