    .app-store-one-more-menus-parent {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;

        .app-store-one-more-menus-circle {
            position: relative;
            width: 500px;
            height: 500px;
            border-radius: 50%;
            border: 2px dashed var(--logo-primary-color);
            background-color: #e3edf0; // Outer circle color

            display: flex;
            justify-content: start;
            align-items: center;

            .app-store-one-more-menus-center-icon {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: #aff6ff;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);

                svg {
                    color: #fff;
                }
            }

            .app-store-one-more-menus-item {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                text-align: center;

                p {
                    margin: 0;
                }
            }

            .app-store-one-more-menus-inner-circle {
                position: absolute;
                width: 300px;
                height: 300px;
                border-radius: 50%;
                border: 2px dashed var(--logo-primary-color);
                transform: translate(35%, 0%);
                background-color: #d7f5ff; // Inner circle color
                display: flex;
                justify-content: center;
                align-items: center;

                .app-store-one-more-menus-item {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    img {
                        width: 40px;
                        height: 40px;
                        fill: white; // Icon color
                    }
                }
            }

            .icon-container {
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                background-color: var(--click-color);
                height: 60px;
                width: 60px;
                color: var(--white-color);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .app-store-one-more-menus-item-1 {
                top: 20%;
                left: 10%;
                transform: translate(-50%, -50%);
            }

            .app-store-one-more-menus-item-2 {
                top: 7%;
                left: 70%;
                transform: translate(-50%, -50%);
            }

            .app-store-one-more-menus-item-3 {
                top: 60%;
                left: 99%;
                transform: translate(-50%, -50%);
            }

            .app-store-one-more-menus-item-4 {
                bottom: 0%;
                left: 60%;
                transform: translate(-50%, 50%);
            }

            .app-store-one-more-menus-item-5 {
                top: 80%;
                left: 10%;
                transform: translate(-50%, -50%);
            }
        }

        .app-store-one-more-menus-info {

            h2 {
                color: var(--logo-primary-color);
                margin-bottom: 10px;
            }

            h3 {
                font-weight: 700;
                margin-bottom: 10px;
            }

            li {
                strong {
                    font-size: 25px;
                }

                p {
                    font-size: 12px;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                gap: 20px;

            }
        }

        @media (max-width: 768px) {
            flex-direction: column; // Stack items vertically in mobile view
            align-items: center;

            .app-store-one-more-menus-circle {
                width: 300px; // Make the circle smaller for mobile view
                height: 300px;

                .app-store-one-more-menus-inner-circle {
                    width: 150px; // Make the circle smaller for mobile view
                    height: 150px;
                    transform: translate(50%, 0%);
                }

                .icon-container {
                    background-color: var(--click-color);
                    height: 40px;
                    width: 40px;
                }

                .app-store-one-more-menus-center-icon {
                    width: 50px;
                    height: 50px;
                }
            }

            .app-store-one-more-menus-center-icon {
                width: 80px; // Adjust center icon size for mobile
                height: 80px;
            }

            .app-store-one-more-menus-item {
                font-size: 12px; // Adjust font size further for mobile
            }
        }
    }