.happyerp-partners {
  background-color: var(--white-color);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  padding: 70px 20px;
  display: flex;
  gap: 55px;
  align-items: center;
  justify-content: center;
.happyerp-partner_single {
  img {
    max-width: 155px;
    height: auto;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    opacity: 0.6;
  }
}
.happyerp-partner_single:hover {
  img {  
      -webkit-transition: 0.6s;
      transition: 0.6s;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    opacity: 1;
  }
}
}
