.pg_login_signup_parent_div {
    .pg_login_signup_popup-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        position: relative;
        min-width: 100%;
        max-height: 290px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            height: 250px;
        }
    }

    .pg_login_signup_delete-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pg_login_signup_delete-icon:hover {
        background-color: var(--click-color);
        color: white;
    }

    .pg_login_signup_selected-image-preview {
        position: relative;
        display: flex;
justify-content: center;
        .pg_login_signup_default_img {
            height: 200px;
        }

        span{
            font-size: 200px; 
        }
    }

    // .pg_login_signup_selected-image-preview img {
    //     max-width: 100%;
    //     max-height: 50vh;
    //     border-radius: 8px;
    //     margin-bottom: 20px;
    // }

    .pg_login_signup_uploading-gif-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pg_login_signup_section-uploading-gif {
        width: 100%;
        /* Adjust the size for better visibility */
        height: 100%;
        object-fit: contain;
        /* Ensure the GIF scales properly */
        z-index: 9999;
    }

    .pg_login_signup_images-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    @media (max-width: 992px) {
        .pg_login_signup_images-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 576px) {
        .pg_login_signup_images-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}