/* css for font poppins start */
/* font face start */
@font-face {
  font-family: extralight;
  src: url(../../../../public/assets/font/poppins/Poppins-ExtraLight.ttf);
}
@font-face {
  font-family: light;
  src: url(../../../../public/assets/font/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: regular;
  src: url(../../../../public/assets/font/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: medium;
  src: url(../../../../public/assets/font/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: semibold;
  src: url(../../../../public/assets/font/poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: bold;
  src: url(../../../../public/assets/font/poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: extrabold;
  src: url(../../../../public/assets/font/poppins/Poppins-ExtraBold.ttf);
}

/* font face end */

/* font family classes start  */
.happyerp-extralight {
  font-family: extralight;
}

.happyerp-light {
  font-family: light;
}

.happyerp-regular {
  font-family: regular;
}

.happyerp-medium {
  font-family: medium;
}

.happyerp-semibold {
  font-family: semibold;
}

.happyerp-bold {
  font-family: bold;
}

.happyerp-extrabold {
  font-family: extrabold;
}

/* font family classes start  */
/* css for font poppins end*/

/* color variables start */
:root {
  --white-color: white;
  --theme-white: white;
  --text-black: #0d1820;
  --text-grey: #777777;
  --theme-orange: #f12711;
  --theme-blue: #0865f8;
  --theme-grey: #a9a9a9;
  --theme-midnight-blue: #0d1820;
  --theme-lightmidnight-blue: #1c2f3c;
  --theme-pg-bg: #edf2f6;
  --theme-bg-grey: #f5f5f5;
  --theme-lightblue-bg: #ebf3ff;
  --theme-green: #008000;
  --theme-crimson-red: #dc143c;
  --error-bg: #ffe3e9;
  /* #f5f5f9 */

  /* new colors may be remove */
  --theme-red: #ff0000;
  --theme-slate-grey: #708090;
  --theme-dark-blue: #003366;
  --theme-burgundy: #800020;
  --theme-teal: #008080;
  --theme-rich-purple: #800080;
}

/* color css start  */
.happyerp-text_black {
  color: var(--text-black);
}

.happyerp-text_grey {
  color: var(--text-grey);
}

.happyerp-text_orange {
  color: var(--theme-orange);
}

.happyerp-text_white {
  color: var(--white-color);
}
.happyerp-text_blue {
  color: var(--theme-blue);
}
.happyerp-text_teal {
  color: var(--theme-teal);
}
.happyerp-click_icon_color,
.happyerp-click_text {
  color: var(--theme-teal);
}
/* background colors */
.happyerp-bg_midnightblue {
  background: var(--theme-midnight-blue);
}
.happyerp-bg_orange {
  background: var(--theme-orange);
}
.happyerp-bg_teal {
  background: var(--theme-teal);
}
/* color variables end*/

/* common css  */
body {
  font-family: regular !important;
}

.happy_erp h1,
.happy_erp h2,
.happy_erp h3,
.happy_erp h4,
.happy_erp h5,
.happy_erp h6 {
  margin: 0;
}

.happy_erp p,
.happy_erp ul,
.happy_erp li,
.happy_erp a {
  margin: 0;
  text-decoration: none;
}

.happyerp-relative {
  position: relative;
}

.happyerp-br50 {
  border-radius: 50%;
}
/*theme btn css start  */
.happyerp-theme_btn {
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  /* background-color: var(--theme-orange); */
  /* background: linear-gradient(to right, var(--theme-orange), var(--theme-blue));  */
  background-color: var(--theme-teal);
  color: var(--white-color);
  font-size: 16px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid transparent;
}
.happyerp-theme_btn.happyerp-inline_btn {
  padding: 6px 12px;
}
.happyerp-theme_btn.happyerp-btn_midnight_blue {
  background: var(--theme-lightmidnight-blue);
}
.happyerp-theme_btn.happyerp-btn_white {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background: var(--white-color);
  color: var(--text-black);
}
.happyerp-editable_text {
  font-size: 14px;
  display: flex;
  color: var(--theme-teal);
  cursor: pointer;
}
.happyerp-editable_text .material-symbols-outlined {
  font-size: 14px;
  font-variation-settings: "wght" 300;
}

.happyerp-theme_btn.happyerp-btn_white:hover {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background: transparent;
  border-color: var(--white-color);
  color: var(--white-color);
}
.happyerp-theme_btn.happyerp-btn_border {
  background: transparent;
  color: var(--theme-orange);
  border: 1px solid;
}
.happyerp-theme_btn.happyerp-btn_border.happyerp-green_border {
  background: transparent;
  color: var(--theme-teal);
  border: 1px solid;
}

.happyerp-theme_btn.happyerp-btn_white_border:hover {
  border-color: transparent;
  background: var(--white-color);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: var(--text-black);
}

.happyerp-theme_btn.happyerp-btn_white_border {
  border-color: var(--white-color);
  background: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.happyerp-theme_btn:hover {
  background-color: var(--theme-lightmidnight-blue);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: white;
}

.happyerp-theme_btn.happyerp-with_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.happyerp-theme_btn.happyerp-with_icon .happyerp-btn_icon {
  font-size: 20px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.happyerp-theme_btn.happyerp-with_icon:hover .happyerp-btn_icon {
  transform: translateX(10px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
/*theme btn css end */

.happyerp-sect_padding {
  padding: 100px 0px;
}
.happyerp-pg_margin {
  padding: 22px 22px;
}
.happyerp-pg_bg {
  background: var(--theme-pg-bg);
}
.happyerp-page_height {
  position: relative;
  /* min-height: calc(100vh - 166px); */
  min-height: 100vh;
  padding-bottom: 140px;
  padding-top: 113px;
}

.happyerp-card_shadow {
  /* box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09); */
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14); */
  /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);    */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.happyerp-card_padding {
  padding: 22px;
}
.happyerp-card_bg {
  background: var(--white-color);
}
.happyerp-card_bottom_border {
  border-bottom: 1px solid var(--theme-blue);
}
.happyerp-click_text {
  font-size: 14px;
  color: var(--theme-teal);
  cursor: pointer;
}
.happyerp-ct_border {
  text-decoration: underline !important;
}
.happyerp-small_title_with_line {
  font-size: 16px;
  color: var(--theme-orange);
  text-transform: uppercase;
  position: relative;
  margin-left: 37px !important;
  font-family: semibold;
}

.happyerp-small_title_with_line.happyerp-text_white {
  color: var(--white-color);
}

.happyerp-small_title_with_line:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 30px;
  background-color: var(--theme-orange);
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}

.happyerp-small_title_with_line.happyerp-text_white:after {
  background-color: var(--white-color);
}

/* text size css start  */
.happyerp-df45 {
  font-size: 45px;
}

.happyerp-df36 {
  font-size: 36px;
}

.happyerp-df25 {
  font-size: 25px;
}

.happyerp-df20 {
  font-size: 20px;
}

.happyerp-df15 {
  font-size: 15px;
}
.happyerp-df14 {
  font-size: 15px;
}
.happyerp-df16 {
  font-size: 16px;
}

.happyerp-lh26 {
  line-height: 26px;
}

/* vertical css  */
.happyerp-dvg22 {
  height: 22px;
}
.happyerp-dvg12 {
  height: 12px;
}

/* text size css end */
.happyerp-pointer {
  cursor: pointer;
}
.happyerp-back_with_text {
  display: flex;
  align-items: center;
  gap: 5px;
}
/* common css  */
