.blog-and-articles-blog_sect {
    .blog-and-section_heading_effect {
        color: var(--subheading-color);
    }

    .blog-and-section_sub-heading_effect {
        color: var(--black-color);
        font-size: 40px;
        font-weight: 700;
    }

    .blog-article-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); // 4 columns on PC
        gap: 20px; 
        margin-top: 50px;

        @media (max-width: 992px) { // Tablet view
            grid-template-columns: repeat(3, 1fr); // 3 columns on tablets
        }

        @media (max-width: 576px) { // Phone view
            grid-template-columns: 1fr; // 1 column on mobile
        }
    }

    .blog-article-card-container {
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 0; // Adjust if necessary

        // Card Image
        .blog-media-card-image {
            position: relative;
            img {
                width: 100%;
                background-size: cover;
                aspect-ratio: 3/2;
            }

            .blog-media-image-upload-icon-overlay {
                font-size: 50px;
                z-index: 9999;
                width: 100%;
                background-size: cover;
                aspect-ratio: 3/2;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                color: #fff;
                background-color: #88888889;
            }

            .blog-media-image-upload-icon {
                font-size: 50px;
                z-index: 9999;
                width: 100%;
                background-size: cover;
                aspect-ratio: 3/2;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        // Card Body
        .blog-media-card-body {
            display: flex;
            flex-direction: column;
            padding: 22px;
            position: relative;

            &:after,
            &:before {
                content: '';
                position: absolute;
                width: 100px;
                height: 100px;
                background: #e957334f;
                border-radius: 50%;
            }

            &:after {
                top: -12px;
                left: -62px;
            }

            &:before {
                right: -45px;
                bottom: -62px;
            }

            // Card Title
            h3 {
                font-size: 16px;
                margin: 8px 0 !important;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: #303030;
                font-weight: 700;
            }

            // Card Subtitle
            p {
                font-size: 14px;
                margin-bottom: 16px !important;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: grey;
            }
        }
    }
}
