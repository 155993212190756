.pd-cards {
  width: 100%;
  padding: 20px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: white;
  overflow: visible;

  .pd-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    overflow: visible;
    .info-heading {
      display: flex;
      justify-content: space-between;
      gap: 80px;
      width: 60%;
      padding-left: 5px;
      div {
        text-align: left;
        label {
          font-size: 12px;
          font-weight: blod;
          color: #868686;
        }
        p {
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
    .info-detail {
      display: flex;
      justify-content: space-between;
      // padding: 20px;
      // background-color: #f0f5ff;
      border-radius: 8px;
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background-color: #f0f5ff;
        padding: 15px;
        border-radius: 10px;

        h6 {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .current-role {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 15px;
        background-color: #f0f5ff;
        border-radius: 10px;
        // border: 2px solid;
        h6 {
          font-size: 14px;
          font-weight: bold;
        }
        p {
          font-size: 16px;
          color: #3f5e98;
        }
      }
      .current-mode {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 15px;
        background-color: #f0f5ff;
        border-radius: 10px;
        // border: 2px solid;
        .online-status {
          width: 10px;
          height: 10px;
          border-radius: 100%;
        }
        p {
          font-size: 16px;
          color: #00a300;
          padding: 0 0 0 4px;
        }
      }
    }
  }
  .pd-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 7px 18px;
    border-bottom: 0.5px solid #a2a2a2;
    h2 {
      font-size: 22px;
      color: #3f5e98;
    }
    .pd-edit-state {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      color: #0091ff;
      padding: 4px 15px 4px 15px;
      span {
        font-size: 18px;
      }
      &:hover {
        // border: 1px solid;
        background-color: #4e63cf;
        color: #fff;
        padding: 4px 15px 4px 15px;
        border-radius: 6px;
      }
    }
  }
  .pd-data {
    padding: 15px 0 0 8px;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    .pd-data-element {
      display: flex;
      align-items: center;
      gap: 10px;

      .pd-icon {
        // flex: 1;
        img,
        .material-symbols-outlined {
          height: 32px;
          width: auto;
        }
      }
      .pd-data-value {
        display: flex;
        flex-direction: column;
        gap: 2px;
        h6 {
          font-size: 12px;
          font-weight: bold;
          line-height: normal;
          margin-top: 0;
          text-transform: capitalize;
          color: #888;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
  }
}
