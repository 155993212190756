.EducationOneTestimonials-section {
    text-align: center;
    padding: 50px 0;
  
    .section-title {
      font-size: 36px;
      margin-bottom: 30px;
      font-weight: bold;
    }

    .section-title h2 {
        position: relative;
        display: inline-block;
      
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 3px;
          background-color: #ff3d57;
          left: 0;
          bottom: -5px;
          transition: width 0.4s ease;
        }
      
        &:hover::after {
          width: 100%; // Expand the underline on hover
        }
      }
      .section-title h2 {
        display: inline-block;
        transition: letter-spacing 0.3s ease;
      
        &:hover {
          letter-spacing: 4px; // Slightly spread out the letters on hover
        }
      }
      .section-title h2 {
        text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2); // Soft shadow
        
        &:hover {
          text-shadow: 4px 8px 12px rgba(0, 0, 0, 0.3); // Increase the shadow on hover
          transform: translateY(-2px); // Slight upward lift on hover
          transition: transform 0.3s ease, text-shadow 0.3s ease;
        }
      }
                  
    .owl-item.center .testimonial-item {
        animation: float 3s ease-in-out infinite;
      }
      
      @keyframes float {
        0%, 100% {
          transform: translateY(-30px); // Start from the default position
        }
        50% {
          transform: translateY(-35px); // Slight floating effect
        }
      }
      
    .owl-dots {
        display: block !important; // Ensure dots are displayed
        margin-top: 20px;
        
        .owl-dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #ccc;
          border-radius: 50%;
          margin: 0 5px;
          
          &.active {
            background-color: #ff3d57; // Color for the active dot
          }
        }
      }
      
    .testimonial-item {
      background-color: #fff;
      padding: 20px;
      margin: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      position: relative;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; // Align everything to the bottom
      min-height:250px;
      
      p {
        font-size: 16px;
        line-height: 1.6;
        color: #555;
        margin-bottom: 20px;
        max-height:600px;
      }
  
      .testimonial-author {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-bottom: 10px;
          position:relative;
        }
  
        h3 {
          font-size: 18px;
          margin: 5px 0;
          font-weight: 600;
          color: #555;
          position:relative;
        }
  
        span {
          font-size: 14px;
          color: #888;
          position:relative;
        }
      }
    }
  
    .owl-item {
      &.center .testimonial-item {
        background-color: #264D8C; // Red background for the middle card
        transform: translateY(-30px); // Move the middle card up
        transition: transform 0.3s ease-in-out;
  
        p {
          color: #fff; // White text for the paragraph
        }
  
        .testimonial-author {
            
          img {
            background-color: #fff; // Keep the image background white
            position: absolute; 
            bottom: -60px;
            left: 50%;
            transform: translateX(-50%); // Center the image horizontally
          }
  
          h3  {
            color: black; // Change name and role text color to white
            position: absolute; 
            bottom: -80px;
            left: 50%;
            font-size:20px;
            transform: translateX(-50%);
          }
          span{
            color: black; // Change name and role text color to white
            position: absolute; 
            bottom: -100px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }