.corporateTwo-home-services {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding: 50px 30px;
  position: relative;
  z-index: 50;

  .corporateTwo-home-services-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    h1 {
      font-size: 16px;
      font-weight: 600;
      color: var(--subheading-color);
    }

    h2 {
      font-size: 36px;
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .corporateTwo-home-services-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 80vw;
    transition: transform 0.5s ease;

    .service-slide {
      flex: 0 0 calc(32.333% - 20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      height: 600px;
      background-color: #f8f8f8;
      border: 1px solid lightgray;
      transition: transform 0.5s ease;
      position: relative;

      img {
        width: 100%;
        height: 60%;
        object-fit: cover;
      }

      .corporateTwo-home-service-info {
        text-align: center;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 20px;

        h2 {
          font-size: 1.5rem;
          font-weight: 700;
        }

        button {
          width: 200px;
          height: 50px;
          font-size: 1rem;
          font-weight: 600;
          border: none;
          background-color: var(--click-color);
          color: white;
        }

        p {
          width: 70%;
          max-height: 100px;
          text-align: center;
          color: rgb(147, 147, 147);
          font-weight: 600;
        }
      }
    }
  }

  .corporateTwo-home-services-controls {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      border-radius: 50%;
      transition: background-color 0.3s, transform 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(79, 10, 135, 0.1);
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95);
      }

      svg {
        color: var(--black-color);
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1440px) {
  .corporateTwo-home-services {
    padding: 40px 25px;
    gap: 25px;

    .corporateTwo-home-services-heading {
      h1 {
        font-size: 15px;
      }

      h2 {
        font-size: 32px;
      }
    }

    .corporateTwo-home-services-container {
      max-width: 85vw;
      gap: 25px;

      .service-slide {
        height: 550px;

        .corporateTwo-home-service-info {
          gap: 25px;
          padding: 18px;

          h2 {
            font-size: 1.4rem;
          }

          button {
            width: 180px;
            height: 45px;
            font-size: 0.95rem;
          }

          p {
            width: 75%;
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .corporateTwo-home-services {
    padding: 35px 20px;
    gap: 20px;

    .corporateTwo-home-services-heading {
      h1 {
        font-size: 14px;
      }

      h2 {
        font-size: 28px;
      }
    }

    .corporateTwo-home-services-container {
      max-width: 90vw;
      gap: 20px;

      .service-slide {
        height: 500px;

        img {
          height: 45%;
        }

        .corporateTwo-home-service-info {
          gap: 20px;
          padding: 15px;

          h2 {
            font-size: 1.3rem;
          }

          button {
            width: 160px;
            height: 40px;
            font-size: 0.9rem;
          }

          p {
            width: 80%;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .corporateTwo-home-services {
    min-height: 70vh;
    padding: 30px 15px;
    gap: 15px;

    .corporateTwo-home-services-heading {
      h1 {
        font-size: 13px;
      }

      h2 {
        font-size: 24px;
      }
    }

    .corporateTwo-home-services-container {
      max-width: 95vw;
      gap: 15px;

      .service-slide {
        flex: 0 0 calc(33.333% - 10px);
        height: 450px;

        img {
          height: 45%;
        }

        .corporateTwo-home-service-info {
          gap: 15px;
          padding: 12px;

          h2 {
            font-size: 1.1rem;
          }

          button {
            width: 140px;
            height: 35px;
            font-size: 0.85rem;
          }

          p {
            width: 85%;
            line-height: 20px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .corporateTwo-home-services {
    padding: 25px 10px;
    gap: 30px;

    .corporateTwo-home-services-heading {
      h1 {
        font-size: 12px;
      }

      h2 {
        font-size: 28px;
      }
    }

    .corporateTwo-home-services-container {
      max-width: 100vw;

      .service-slide {
        flex: 0 0 100%;
        height: 400px;

        .corporateTwo-home-service-info {
          gap: 10px;
          padding: 10px;

          h2 {
            font-size: 1.1rem;
          }

          button {
            width: 120px;
            height: 30px;
            font-size: 0.8rem;
          }

          p {
            width: 90%;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
