.add-notification-modal {
    .modal-content {
        max-width: 500px;
        width: 100%;
        max-height: 80vh; /* Limit height on mobile */
        overflow-y: auto; /* Enable vertical scrolling */
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    .modal-content {
      background: #fff;
      padding: 20px;
      width: 400px;
      border-radius: 8px;
      .notification-tabs {
        display: flex;
        margin-bottom: 20px;
        .tab-button {
          flex: 1;
          padding: 10px;
          background: #f0f0f0;
          border: none;
          border-radius: 5px;
          margin: 0 5px;
          &.active {
            background: var(--click-color);
            color: #fff;
          }
        }
      }
      .styled-input,
      .styled-textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
      .notification-date-picker {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        select{
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 2px solid #ccc;
            margin-bottom: 20px;
        }

        input {
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          border: 2px solid #ccc;
          margin-bottom: 20px;
        } 
      }
    }
  }
  