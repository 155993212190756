.ecom_two {
  .Artist-events {
    margin-bottom: 50px;

    .event-container_artist {
      display: flex;
      justify-content: center; /* Center the event cards horizontally */

      .event-card_artist {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center content within each card */

        .image-container_artist {
          width: 120px;
          height: 120px;
          border-radius: 50%; /* Make the image container circular */
          overflow: hidden;
          margin-bottom: 10px; /* Space between image and text */

          .event-image_artist {
            width: 100%;
            height: 100%;
            border-radius:175px;
            object-fit: cover; /* Ensure the image covers the entire container */
          }
          img.event-image_artist{
            border-radius:175px;
          }
        }

        .contain_artist_name {
          font-size: 30px;
          text-align: center; /* Center the artist name */
        }
      }
    }
  }
}


   

   
  