/* .bcard {
    margin-top: 20px;
    padding: 15px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;

    position: relative;
}

.bookinglist {
    background-color: #f1efef;
    padding: 20px 0px;
}

.bookinglist .bcard_title {
    position: absolute;
    left: 0;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 20px 0px 0px 0px;
    font-family: 'p_medium';
}

.bcard.bd_card .pay_status {
    font-size: 16px;
    font-weight: 900;
    color: var(--red-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-25deg);
    opacity: 0.5;
}

.bcard.bd_card {
    padding-right: 62px;
    margin-top: 40px;
}


.card_fields .b_date_time .material-symbols-outlined {
    font-size: 23px !important;
}

.card_fields h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 900;
}

.b_date_time {
    display: flex;
}

.b_date_time .date {
    padding-right: 6px;
}

.b_date_time .time {
    padding-left: 6px;
}

.b_date_time .field_value {
    font-size: 18px;
    color: var(--black-color);
    font-family: 'p_bold';
}

.de_icon {
    background: var(--white-color);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.de_icon span.material-symbols-outlined {
    color: var(--pink);
    font-size: 24px;
}

.pay_btn .btn_fill {
    padding: 6px 22px !important;
}

.field_name {
    color: #8f8f8f;
}


.delete_edit i {
    font-size: 24px;
    color: var(--dark-blue);
}

.delete_edit {
    display: grid;
    gap: 6px;
    position: absolute;
    bottom: 9px;
    right: 8px;
}

.category {
    position: absolute;
    top: -14px;
    right: 75px;
    padding: 3px 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    font-size: 15px;
    text-transform: lowercase;
    font-family: 'p_medium';
}

.category.self {
    background: var(--dark-blue);
    color: var(--white-color);
}

.category.self .c_inner:before {
    background: var(--dark-blue);
}

.category.other {
    background: var(--gold);
    color: var(--black-color);
}

.category.other .c_inner:before {
    background: var(--gold);
}

.c_inner {
    position: relative;
}

.c_inner:before {
    content: '';
    width: 12px;
    height: 21px;
    position: absolute;
    top: -1px;
    left: -18px;
    transform: rotate(24deg);
    z-index: -1;
}

.help_single img {
    width: 30px;
}

.help_support {
    padding: 12px;
    display: grid;
    gap: 15px;
    border-radius: 0px 20px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
}

.bookinglist .pay_amount .field_name .material-symbols-outlined,
.bookinglist .pay_amount .field_value {
    font-size: 24px !important;
    color: var(--black-color);
    font-weight: 600;
}

.pay_amount {
    display: flex;
    align-items: center;
    position: relative;
    top: 4px;
    margin-right: 6px;
}

.pay_btn_amount .bda_card {
    display: flex;
    align-items: center;
}

.pay_btn_amount {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bookinglist .field_single .edit_bg {
    top: -17px;
    right: -4px;
    width: 20px;
    height: 20px;
}

.pay_btn_amount {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    padding: 6px 12px;
    border-radius: 0px 0px 20px 20px;
}

.pay_btn {
    padding-top: 40px;
}

/* card details css */
.bookinglist {
    background-color: #f1efef;
    padding: 20px 0px;
}

.property_cards_parent {

    .property_single_card {
        padding: 12px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
        background: white;
        border-radius: 10px;
        margin-top: 20px;

        .top {
            display: flex;
            gap: 8px;

            .fav {
                position: absolute;
                right: 0;
            }

            .img_container {
                aspect-ratio: 3/2;
                border-radius: 6px;
                height: 100%;
                overflow: hidden;
                width: 25%;

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .left_side {
                width: 75%;

                h5.demand {
                    color: #303030;
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 5px !important;
                }

                .full_address {
                    grid-gap: 2px;
                    display: grid;
                    gap: 2px;

                    h6 {
                        color: #373737;
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .middle {
            .show_more_arrow {
                align-items: center;
                background: #e1e1e1;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                height: 100%;
                justify-content: center;
                padding: 2px;
                position: absolute;
                right: 0;
                top: 0;

                span {
                    color: #606060;
                    font-size: 18px;
                }
            }

            .middle_single {
                grid-gap: 8px;
                background: #f5f5f5;
                border-radius: 8px;
                display: grid;
                gap: 8px;
                grid-template-columns: repeat(3, 1fr);
                margin: 12px 35px 12px 0;
                padding: 8px;

                &.addtional_rooms {
                    display: block;

                    .ms_child {
                        .left {
                            h5 {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 8px;

                                span {
                                    font-size: 12px;
                                    margin-top: 2px;
                                }
                            }
                        }
                    }
                }

                .ms_child {
                    display: flex;
                    gap: 8px;

                    .icon_container {
                        height: 30px;
                        object-fit: contain;
                        opacity: 0.9;
                        width: 30px;
                    }

                    .left {
                        h6 {
                            color: #606060;
                            font-weight: 400;
                            margin-bottom: 0px;
                            font-size: 11px;
                            text-transform: uppercase;
                        }

                        h5 {
                            color: #303030;
                            font-weight: 500;
                            font-size: 13px;
                            margin-top: 2px !important;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        .booked_card_bottom_details {
            display: flex;
            justify-content: space-between;
        }

        .booked_card_amount_div {
            display: flex;
            align-items: center;
        }
    }
}

/* start booked sharing card parent */
.booked_sharing_card_parent {
    background-color: var(--grey-light);
    padding: 10px;
    border-radius: 10px;
    margin-top: 50px;
}

.booked_sharing_card_parent_detals {
    display: flex;
    justify-content: space-between;
}

.booked-details-close-icon {
    background-color: #c2bcbc;
    border-radius: 50%;
    padding: 1px;
    cursor: pointer;
}

.booked_sharing_card_parent_heading {
    font-weight: 700;
    margin: 0px 0px 10px 0px;
}

.booked_sharing_card_child {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.booked_shring_card_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.booked_shring_card_details p {
    color: var(--grey-dark);
    text-transform: capitalize;
}

.booked_shring_card_details h6 {
    color: var(--grey-dark);
    font-weight: 700;
}

.booked_sharing_card_child_popup {
    border-radius: 5px;
    height: 120px;
    width: 100px;
}

.booked_sharing_card_child_side_text h6 {
    font: bold 15px Sans-Serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--grey-dark);
    line-height: 24px;
    transform: rotate(90deg);
    margin-top: 15px;
}

.booked_sharing_card_qr_serction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.booked_sharing_card_qr_serction img {
    width: 200px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media only screen and (max-width: 767px) {

    /* .bcard {
        padding: 10px;
    }

    .card_fields .field_single:last-child {
        padding-right: 0px;
    }

    .field_name,
    .field_value {
        font-size: 14px;
    } */
    /* .booked_card_amount_div p {
        font-size: 14px;
        padding: 2px 4px;
    } */

    .booked_sharing_card_parent_heading {
        font-size: 15px;
    }

    .booked_shring_card_details p {
        font-size: 14px;
    }
}