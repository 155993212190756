.corporateTwo-home-process {
  min-height: 100vh;
  padding: 50px 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .corporateTwo-home-process-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .corporateTwo-home-process-left-heading {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-size: 16px;
        font-weight: 600;
        color: var(--subheading-color);
      }

      h2 {
        font-size: 36px;
        font-weight: 600;
        color: var(--black-color);
      }
    }

    .corporateTwo-home-process-left-info {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-size: 16px;
        font-weight: 400;
        color: var(--black-color);
      }
    }

    button {
      width: 200px;
      height: 50px;
      font-size: 1rem;
      font-weight: 600;
      border: none;
      background-color: var(--click-color);
      color: white;
    }
  }

  .corporateTwo-home-process-right {
    width: 40%;
    padding: 10px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    position: relative;

    img {
      width: 250px;
      height: 250px;
      z-index: 15;
      position: absolute;
      left: 0;
      top: 20%;
    }

    .corporateTwo-home-process-right-info {
      width: 450px;
      height: 450px;
      padding: 10px;
      border-radius: 50%;
      border: 8px solid rgb(236, 236, 236);
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
      transition: transform 0.5s ease, opacity 0.5s ease;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      &.animate {
        animation: slideInFromRight 0.5s ease;
      }

      h1 {
        position: absolute;
        top: 30%;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: 10rem;
        font-weight: 700;
        color: rgb(236, 236, 236);
        z-index: 5;
      }

      .corporate-home-process-right-content {
        z-index: 15;
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-size: 2.5rem;
          font-weight: 900;
          color: var(--subheading-color);
        }

        p {
          font-size: 16px;
          font-weight: 500;
          color: var(--black-color);
        }
      }
    }

    .corporateTwo-home-process-right-controller {
      width: 80%;

      .corporateTwo-home-process-right-control-steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .corporateTwo-home-process-step {
          border: 4px solid rgba(0, 0, 0, 0.134);
          background-color: white;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          z-index: 5;
          transition: color 0.5s ease, border-color 0.5s ease;

          &.active {
            border-color: var(--subheading-color);
            color: var(--subheading-color);
          }

          h2 {
            font-size: 1.5rem;
          }
        }

        .corporateTwo-home-process-progress {
          height: 5px;
          width: 100%;
          background-color: gray;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          overflow: hidden;

          .corporateTwo-home-process-progress-bar {
            height: 100%;
            background-color: var(--subheading-color);
            transition: width 0.1s linear;
          }
        }
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Media Queries */

/* Large Desktop View (1440px) */
@media (max-width: 1440px) {
  .corporateTwo-home-process {
    padding: 40px 60px;

    .corporateTwo-home-process-left,
    .corporateTwo-home-process-right {
      width: 45%; /* Adjusting width for 1440px screens */
    }

    .corporateTwo-home-process-right {
      .corporateTwo-home-process-right-info {
        width: 400px;
        height: 400px;

        h1 {
          font-size: 8rem;
        }

        .corporate-home-process-right-content{
          h3{
            font-size: 2.2rem;
            font-weight: 800;
          }
        }
      }

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}

/* Tablet/Laptop View (1024px) */
@media (max-width: 1024px) {
  .corporateTwo-home-process {
    padding: 40px 30px;

    .corporateTwo-home-process-left,
    .corporateTwo-home-process-right {
      width: 48%; /* Adjusting width for 1024px screens */
    }

    .corporateTwo-home-process-right {
      .corporateTwo-home-process-right-info {
        width: 380px;
        height: 380px;

        h1 {
          font-size: 7rem;
        }

        .corporate-home-process-right-content {
          h3{
            font-size: 2rem;
          }
        }
      }

      img {
        width: 180px;
        height: 180px;
      }
    }
  }
}

/* Tablet View (768px) */
@media (max-width: 768px) {
  .corporateTwo-home-process {
    padding: 30px 30px;
    flex-direction: column;

    .corporateTwo-home-process-left,
    .corporateTwo-home-process-right {
      width: 100%; /* Full width for smaller tablets */
      margin-bottom: 30px;
    }

    .corporateTwo-home-process-right {
      .corporateTwo-home-process-right-info {
        width: 350px;
        height: 350px;

        h1 {
          font-size: 6rem;
        }

        .corporate-home-process-right-content{

          h3{
            font-size: 1.8rem;
          }
        }
      }

      img {
        width: 190px;
        height: 190px;
        left: 70px;
      }
    }

    .corporateTwo-home-process-right-controller {
      width: 90%; /* Adjusting the controller width */
    }
  }
}

/* Mobile View (576px and below) */
@media (max-width: 576px) {
  .corporateTwo-home-process {
    flex-direction: column;
    padding: 20px 10px;

    .corporateTwo-home-process-left,
    .corporateTwo-home-process-right {
      width: 100%;
      margin-bottom: 30px;
    }

    .corporateTwo-home-process-left {
      align-items: center;
      text-align: center;

      .corporateTwo-home-process-left-heading h2 {
        font-size: 28px;
      }
    }

    .corporateTwo-home-process-right {
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .corporateTwo-home-process-right-info {
        width: 300px;
        height: 300px;
        box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 5rem;
          top: 40%;
        }

        .corporate-home-process-right-content{
          text-align: center;

          h3{
            font-size: 1.6rem;
          }
        }
      }

      img {
        width: 150px;
        height: 150px;
        top: 10px;
        left: 38%;
      }
    }

    .corporateTwo-home-process-right-controller {
      width: 100%; /* Full width for mobile view */
    }
  }
}

@media (max-width: 500px) {
  .corporateTwo-home-process {
    padding: 10px;

    .corporateTwo-home-process-left {
      padding: 0 10px;

      .corporateTwo-home-process-left-heading h2 {
        font-size: 24px;
      }

      .corporateTwo-home-process-left-info p {
        font-size: 14px;
      }

      a{
        // width: 100%;

        button {
          width: 200px;
          height: auto;
          padding: 10px;
          font-size: 14px;
        }
      }
    }

    .corporateTwo-home-process-right {
      flex-direction: column;
      align-items: center;
      gap: 30px;
      .corporateTwo-home-process-right-info {
        width: 250px;
        height: 250px;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 6rem;
        }

        .corporate-home-process-right-content{
            width: 80%;

          h3{
            text-align: center;
            font-size: 2rem;
            font-weight: 800;
          }

          p{
            display: none;
          }
        }
      }

      img {
        width: 150px;
        height: 150px;
        top: 40px;
        left: 30%;
      }

      .corporateTwo-home-process-right-controller{
        width: 100%;
      }
    }
  }
}