.business-three-partners-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .business-three-partners-marquee {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    width: 86%;
  }

  .business-three-partners-row {
    display: flex;
    animation: business-three-partners-scroll 10s linear infinite;

    .business-three-partners-image-wrapper {
      width: 210px;
      height: 120px;
      background-color: white;
      margin: 0px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }

  .business-three-partners-image {
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  @keyframes business-three-partners-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}
