* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.light {
  --white-color: #fff;
  --pure-white-color: #fff;
  --grey-light: #e5e4e2;
  --grey-dark: #6d6d6d;
  --black-color: #000;
  --pure-clickcolor: #a855f7;
  --pink: pink;
  --page-bg: #f5f5f5;
  --gold: #fec84d;
  --light-gold: #fff9c4;
  --light-blue: #aed6f1;
  --light-blue-bg: #f2faff;
  --rose: #eedadc;
  --light-rose-bg: #fff8f9;
  --red-color: #e10032;
  --lightblue-color: #54cccb;
  --dark-green: #013120;
  --bg-color: #f4ffff5e;
  --text-color: #444;
  --light-green: #dbe8e1;
  --material-blue: #2196f3;
  --green: #c8e6c9;
  --darkgrey-light: #666;
  --peach-color: #ffbf90;
  --blue-color: #1b4480;
  --green-color: 188, 236, 224;
  --yellow-color: #feeba0;
  --cream-color: #f4f2eb;
  --buttonfg-color: #f4f2eb;
  --buttonbg-color: #e95733;
  /* --lightblue-color: #4e5bf8; */
  --lightblue-color: #54cccb;
  --text-grey: grey;
  --text-light-grey: #888;
}

.dark {
  --page-bg: #1f2937;
  --card-bg: #2f3a4b;
  --text-grey: white;
  --text-light-grey: #9ca3a9;
  --pure-clickcolor: #a855f7;
  --white-color: black;
  --pure-white-color: white;
  --black-color: white;
  --buttonfg-color: #4b4b4b;
  --pink: pink;
  --red-color: #e10032;
  --grey-dark: #fec84d;
}

.App {
  background: var(--page-bg);
}

/* scroll css start  */
::-webkit-scrollbar {
  width: 14px;
  border-radius: 10px;
}

/* Track  */
::-webkit-scrollbar-track {
  background: var(--light-blue-bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--click-color);
}

/* scroll css start  */
/* base styles */
body {
  font-family: p_regular;
  overflow-y: scroll;
  margin: 0;
}

.d_mobile {
  display: none;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

ul {
  list-style-type: none;
}

.pg_padding {
  padding: 30px 0px;
}

textarea {
  min-height: 100px;
}

.select {
  width: 100%;
  z-index: 10;
}

.menu {
  /* .css-t3ipsp-control .css-inmdiq5-menu { */
  background: red;
  z-index: 999;
}

.formerror {
  /* padding: 5px 10px; */
  /* background: #fdc5c5; */
  /* display: inline-block; */
  /* border-radius: 5px; */
  color: #e10032;
  /* border: 1px solid #e10032; */
}

.error {
  color: var(--red-color);
  background: pink;
  border: 1px solid var(--red-color);
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.section-title p {
  font-size: 25px;
  font-weight: 600;
  color: var(--heading-color);
  margin-bottom: 0;
}

// .section-title {
//   margin-bottom: 40px;
// }

.section-title h2 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: relative;
  color: var(--subheading-color);
}

.section-title h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 80px;
  height: 5px;
  background-color: var(--dark-blue);
  border-radius: 5px;
}

.search_bar input:focus {
  outline: none !important;
  border: none !important;
}

.default-navbar-large {
  width: 100%;
  border-radius: 0 0 0 0;
}

.default-navbar-small {
  width: 0px;
  display: none;
  border-radius: 0 0 0 0;
}

/* popup  */
.pop-up-div {
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: 1s;
}

.pop-up-div.open {
  opacity: 1;
  pointer-events: all;
}

.pop-up-div div {
  position: relative;
  background: var(--gold);
  padding: 20px 10px;
  border-radius: 10px;
  max-width: 500px;
  /* width: 100%; */
  text-align: center;
}
.popup_confim_btn:hover {
  transform: translateY(-3px); /* Move button up slightly */
}
.popup_cancel_btn:hover {
  transform: translateY(-3px); /* Move button up slightly */
}
.pop-up-div div p {
  font-size: 1.1rem;
  color: var(--black-color);
}

.pop-up-div div p strong {
  color: var(--black-color);
}

.addon-service {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #bcece0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 180px;
  /* width:120px; */
  /* padding-top: 4%; */
  /* padding-bottom: 6%; */
}

.addon-service div .material-symbols-outlined {
  /* padding: 20px 0 0 0; */
  font-size: 2.5rem;
  color: #4cd7d0;
}

.addon-service-h5 {
  /* margin-bottom: 6%; */
  font-size: 0.7rem;
  color: #222;
  font-weight: 600;
  /* padding-top: 5px; */
}

.dashboardCardBoxoffer {
  /* padding: 10px; */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  height: 9rem;
  width: 100%;
  transition: 1s;
  overflow: hidden;
}

.dashboardCardBox.active {
  height: 19.5rem;
}

.btn_fill {
  background: var(--click-color);
  border: none;
  border-radius: 5px;
  color: var(--pure-white-color);
  padding: 8px 25px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: capitalize;
  letter-spacing: 0.7px;
  cursor: pointer;
}

.btn_border {
  border: 2px solid var(--click-color);
  background: none;
  border-radius: 5px;
  color: var(--click-color);
  padding: 6px 25px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 0.7px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.form_field input,
.form_field select {
  width: 100%;
  border: 1px solid rgba(3, 70, 135, 0.2196078431);
  color: var(--black-color);
  border-radius: 12px;
  padding: 10px;
  font-size: 16px;
  padding-left: 42px;
}

.form_field {
  margin-bottom: 14px;
  position: relative;
}

.form_field input::-moz-placeholder {
  color: var(--black-color);
  opacity: 0.5;
}

.form_field input::placeholder {
  color: var(--black-color);
  opacity: 0.5;
}

.field_icon {
  position: absolute;
  top: 10px;
  left: 12px;
}

input:focus-visible,
select:focus-visible {
  outline: none;
  border: 1px solid var(--dark-blue);
}

.field_icon.hs_pass {
  left: inherit;
  right: 12px;
}

.form_field.pass input {
  padding-right: 42px;
}

.tab_btn_group .btn_single .active .btn_border {
  background: var(--dark-blue);
  color: var(--white-color);
}

.tab-buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-bottom: 30px;
}

.tab-buttons button.active {
  font-weight: bold;
  color: var(--white-color);
  background: var(--dark-blue);
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

span.material-symbols-outlined.info.pointer {
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 18px;
  background: rgba(128, 128, 128, 0.1607843137);
  border-radius: 50%;
  color: black;
}

.field_name,
.field_value {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
  word-break: break-all;
}

.field_single {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 3px;
}

.card_fields {
  margin-top: 50px;
}

.card_fields .material-symbols-outlined {
  font-size: 20px !important;
}

.spacer {
  width: 100%;
  height: 5px;
  background: #d3e0f1;
  margin-top: 25px;
}

.relative {
  position: relative;
}

.edit_bg img {
  width: 100%;
}

.edit_bg {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--dark-blue);
  position: absolute;
  top: -20px;
  right: 25px;
}

.small_margin_div {
  margin-top: 30px;
}

.medium_margin_div {
  margin-top: 40px;
}

.large_margin_div {
  margin-top: 50px;
}

.small_padding_div {
  padding-top: 30px;
}

.medium_padding_div {
  padding-top: 40px;
}

.large_padding_div {
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  .d_mobile {
    display: block;
  }

  .d_desktop {
    display: none;
  }

  .section-title p {
    font-size: 20px;
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 0;
  }

  .section-title h2 {
    font-size: 18px;
    max-width: 290px;
  }

  .col-md-6.add {
    padding: 0px !important;
  }

  .pg_padding {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 850px) {
  .dashboardCardBoxoffer {
    height: auto;
  }

  .small_margin_div {
    margin-top: 20px;
  }

  .medium_margin_div {
    margin-top: 30px;
  }

  .large_margin_div {
    margin-top: 40px;
  }

  .small_padding_div {
    padding-top: 20px;
  }

  .medium_padding_div {
    padding-top: 30px;
  }

  .large_padding_div {
    padding-top: 40px;
  }
}

@media (max-width: 992px) {
  .default-navbar-large {
    width: 0%;
    display: none;
    border-radius: 0 0 0 0;
  }

  .default-navbar-small {
    width: 100%;
    display: block;
    border-radius: 0 0 0 0;
  }
}

/* pwa pop up div installtation code start */

.install-popup-div {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  height: 0;
  transition: 1.5s ease-in-out;
}

.install-popup-div.open {
  opacity: 1;
  pointer-events: all;
  height: 100vh;
  transform: translateY(0%);
  animation: popupInTransition 2s ease-in-out;
}

@keyframes popupInTransition {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.install-popup-div div {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 12px;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.install-popup-div div .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: var(--click-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}

.install-popup-div div img {
  width: 80%;
  margin-bottom: 20px;
}

.install-popup-div div h1 {
  position: relative;
  margin-top: 20px;
  font-size: 0.7rem;
  color: #444;
  text-align: justify;
}

.install-popup-div div button {
  margin-top: 15px;
  background: var(--click-color);
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 8px;
  font-size: 0.8rem;
  color: #fff;
}
