// // Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
.dependent-doc {
  @media (max-width: $breakpoint-md) {
    gap: 6px;
  }
}
