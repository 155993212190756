.security-one-new-visitor-phone-container {
    margin-top: 100px;

    .security-one-new-visitor-phone-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @media (max-width: 768px) {
            max-width: 100%;
            margin-bottom: 2rem;
            grid-template-columns: 1fr;
        }

        .security-one-new-visitor-phone-card {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            padding: 1rem;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s, background-color 0.3s;
            height: 133px;

            &:hover {
                transform: scale(1.05);
                background-color: var(--click-color);
                color: var(--white-color);
            }

            h4 {
                margin-left: 0.5rem;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }

    .security-one-new-visitor-phone-wrapper {
        text-align: center;
        background: #fff;
        padding: 2rem;
        border-radius: 15px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    .security-one-new-visitor-phone-heading {
        font-size: 1.8rem;
        font-weight: bold;
        color: #0b1543;
        margin-bottom: 1rem;
    }

    .security-one-new-visitor-phone-description {
        font-size: 1rem;
        color: #5a5e78;
        margin-bottom: 1.5rem;
    }

    .security-one-new-visitor-phone-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .security-one-new-visitor-phone-input {
            padding: 0.8rem 1rem;
            font-size: 1rem;
            border: 1px solid #ddd;
            border-radius: 5px;
            width: 100%;
            box-sizing: border-box;

            &:focus {
                border-color: #0b1543;
                outline: none;
            }
        }

        .security-one-new-visitor-phone-button {
            padding: 0.8rem 1rem;
            background-color: #0b1543;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #1d2951;
            }
        }
    }
}

@media (max-width: 768px) {
    .security-one-new-visitor-phone-wrapper {
        padding: 1.5rem;
    }

    .security-one-new-visitor-phone-heading {
        font-size: 1.5rem;
    }

    .security-one-new-visitor-phone-description {
        font-size: 0.9rem;
    }

    .security-one-new-visitor-phone-input,
    .security-one-new-visitor-phone-button {
        font-size: 0.9rem;
    }
}