.business_one_route_parent_div {
  .theme_btn {
    display: block;
    padding: 16px 32px;
    font-size: 18px;
    font-family: medium;
    background: var(--background-black);
    border-radius: 5px;
    text-decoration: none;
    color: var(--theme-white);
    width: fit-content;
  }
  .theme_btn.btn_center {
    margin-left: auto;
    margin-right: auto;
  }
  .motion_img {
    animation: floating 5s ease-in-out infinite; /* Adjust the animation duration and timing function as needed */
  }
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); /* Move up 5px */
    }
    100% {
      transform: translateY(0px); /* Move down 5px */
    }
  }
  .light_top_title {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 100px;
    line-height: 118px;
    opacity: 0.2;
    color: var(--theme-black);
    font-family: title_regular;
    text-align: center;
    display: none;
  }
  .line_title {
    font-size: 20px;
    font-family: semibold;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    color: var(--theme-red);
    text-transform: uppercase;
    span {
      width: 70px;
      height: 2px;
      background: var(--theme-red);
      margin-right: 12px;
    }
  }
  .main_title {
    font-size: 70px;
    font-family: title_regular;
    color: var(--theme-darkgrey);
    margin-bottom: 0px;
    line-height: 68px;
  }
  .main_desc {
    font-size: 17px;
    font-family: regular;
    line-height: 28px;
    color: var(--theme-lightgrey);
  }
}
