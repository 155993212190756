.ecom_two {
    .Health-events {
      margin-bottom:50px;
      .section_header{
        .right{
          display:flex;
          button.btn_inr{
            margin-left:880px;
          }
        }
      }
      .event-container {

        .event-card {
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          .image-container {
            position:relative;
        
            .event-image {
              width: 100%;
              height: 50%;
              aspect-ratio: 5 / 2.5;
              object-fit: cover;
              border-radius: 8px 8px 0px 0px;
            }
            p {
            
              bottom: 0px;
              left: 0px;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color:black;
              padding: 5px 10px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
            }
            .badge {
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: red;
              color: white;
              padding: 5px 10px;
              border-radius: 5px;
            }
          }
          .event-details {
            padding: 10px;  
            display: grid;
            gap: 10px;
            .text{
              display:flex;
              align-item:center;
              gap:10px;
              h6{
                font-size: 13px;
                color: #45474d;
                font-weight: 600;
              }
              span{
                font-size: 20px;
                color: grey;
              }
            }
          }
          .buy-now {
            background: #ffeaee;
            color: black;
            font-weight: 700px;
            margin: 10px;
            margin-top: 0px; 
            border-radius: 8px;
            padding: 5px;
            display: flex;
            gap: 5px;
            justify-content: space-between;
            h6{
              font-size: 16px;
              font-weight: 700;
              &.buy_now{
                text-transform: uppercase;
                padding-left: 8px;
                border-left: 1px solid red;
  
              }
            }
          }
        }
     
      }
    }
  }
  
  