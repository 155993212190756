.et_functional {
  
  }
  
  .theme{
    background-color:var(--click-color);
  }
  .bg_container {
    width: 100%;
    height: 200px; /* or specify the height */
    padding: 20px; /* Adjust padding as per your design */
  }
  