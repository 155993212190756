.floating_Speed_dial_icon {
  transition: 0.3s;
  position: fixed;
  right: 32px;
  bottom: 35px;
  z-index: 9999;
}
.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: var(--click-color) !important;
}
