.app-store-one-why-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .app-store-one-why-us-content {
    flex: 1;
    padding-right: 2rem;

    .app-store-one-why-us-title {
      color: var(--logo-primary-color);
      margin-bottom: 1rem;
    }

    .app-store-one-why-us-description {
      margin-bottom: 2rem;
    }

    .app-store-one-why-us-features {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        .app-store-one-why-us-icon {
          flex-shrink: 0;
          margin-right: 1rem;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        .app-store-one-why-us-text {
          h4 {
            font-family: "Nunito", Sans-serif;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--click-color);
            margin: 0 0 0.5rem 0;
          }
        }
      }
    }

    .app-store-one-why-us-button {
      background: var(--click-color);
      color: #fff;
      border: none;
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      margin-top: 1.5rem;
    }
  }

  .app-store-one-why-us-images {
    position: relative;
    flex: 0.6;
    /* Adjust this based on your layout needs */
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .app-store-one-why-us-image {
      width: 250px;
      /* Adjust size as needed */
      height: 250px;
      /* Keep it consistent */
      border-radius: 10px;
      object-fit: cover;
      position: absolute;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      }
    }

    .app-store-one-why-us-image-1 {
      top: -100px;
      left: -95px;
      z-index: 1;
    }

    .app-store-one-why-us-image-2 {
      top: -160px;
      /* Adjust vertical offset */
      left: 180px;
      /* Adjust horizontal offset */
      z-index: 2;
    }

    .app-store-one-why-us-image-3 {
      top: 20px;
      /* Adjust vertical offset */
      left: 80px;
      /* Adjust horizontal offset */
      z-index: 3;
    }
  }
}
@media (max-width: 1000px) {
  .app-store-one-why-us-container .app-store-one-why-us-images {
    display: none;
  }
}

@media (max-width: 1200px) {
  .app-store-one-why-us-container .app-store-one-why-us-images .app-store-one-why-us-image {
    width: 200px;
    height: 200px;
  }
}
