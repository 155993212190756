.erp-section {
  background-color: #264d8c;
  color: #ffffff;
  font-family: 'Arial', sans-serif;

  

    
       

     

  .erp-main {
    text-align: center;
    padding: 50px 20px;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 40px;
    }

    .erp-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      padding: 0 10px;

      .erp-card {
        background-color: #2d3136;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-10px);
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }

        .card-content {
          padding: 20px;
          h3 {
            font-size: 1.1rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}
