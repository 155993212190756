.form_page h4.title {
    text-align: center;
    color: var(--black-color);
}

.diffmethodlogin .btn_border {
    margin-top: 14px;
}

.form_page {
    padding: 0px 0px;
    display: flex;
    margin: 50px 0px 50px 0px;
    /* align-items: center; */
    /* min-height: 100vh;
}

.pglogo img {
    width: 172px;
    position: absolute;
    left: 25px;
    top: 7px;
}

.ot span,
.ot a {
    color: var(--click-color);
    text-decoration: none;
    margin-left: 5px;
    cursor: pointer;
}

.fp_inner {
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-parnt {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 25px;

}

.submit_btn {
    text-align: center;
    margin: 15px 0px;
}

.ot {
    font-size: 14px;
}

.ot a {
    color: var(--click-color);
    font-size: 16px;
    font-family: 'p_bold';
}

.ot input.checkbox {
    margin-right: 6px;
    position: relative;
    top: 2px;
}

.signup-login-google-inpt-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border: none;
    border-radius: 7px;
    width: 100%;
    font-weight: bold;

    img {
        width: 20px;
    }
}
.login-pge-horizontal-line-with-or-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    position: relative;

    .login-pge-horizontal-line {
        width: 100%;
        height: 1px; /* Thin line */
        background-color: grey;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .login-pge-horizontal-line-with-or {
            background: var(--page-bg); /* Matches background to avoid overlap */
            padding: 0 7px;
            font-weight: bold;
            position: absolute;
            color: var(--black-color);
        }
    }
}


.two_ot {
    display: flex;
    justify-content: space-between;
}

.or {
    text-align: center;
    color: var(--primary-color);
    opacity: 0.5;
    position: relative;
}

.or:after {
    content: '';
    background: var(--primary-color);
    width: 70%;
    height: 2px;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.5;
}

.or span {
    background: white;
    position: relative;
    z-index: 1;
    padding: 0px 10px;
}

.tab_btn_group {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 22px 0px;
}

.tab_btn_group .btn_single {
    width: 50%;
}

.tab-buttons button {
    width: 40%;
    padding: 0px;
    min-height: 45px;
    border: none;
    border: 3px solid var(--primary-color);
    background: none;
    border-radius: 10px;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.phone_num_btn_login {
    font-size: 14px;
}

.email_btn_login {
    font-size: 14px;
}

.resend_otp_timer {
    font-size: 14px;
    color: var(--grey-dark);
    padding: 0px 0px 10px 0px;
}

.login_call_field_icon {
    position: absolute;
    top: 13px;
    left: 12px;
}

.login_pge_logo_div img {
    width: 65%;
    height: auto;
}


/* GENDER SELECTION CODE START */
.login-gender-selection-slider {
    text-align: center;
    padding: 5px;
    border-radius: 8px;
    margin: 0 auto;
}

/* .login-gender-selection-slider h2 {
    font-size: 24px;
    margin-bottom: 20px;
  } */

.login-gender-options {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.login-gender-option {
    position: relative;
    /* Necessary for the tick position */
    border: 2px solid #e2e2e2;
    padding: 20px;
    border-radius: 8px;
    width: 120px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adds shadow */
}

.login-gender-option img {
    width: 60px;
    margin-bottom: 10px;
}

.login-gender-option p {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
}

.login-gender-option.login-gender-selected {
    border-color: var(--click-color);
    background-color: #e3e7ff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    /* Darker shadow for selected option */
}

.login-gender-tick {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 16px;
    color: var(--white-color);
    background-color: var(--click-color);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* GENDER SELECTION CODE END */


@media only screen and (max-width: 575px) {
    .login-adcarousel-img {
        display: none;
    }

    .pglogo img {
        width: 155px;
        left: 10px;
        top: 2px;
        z-index: 2;
    }

    .form-parnt {
        padding: 0px;
        margin: 0px;
        box-shadow: none;
        border-radius: 0px;
        padding-bottom: 25px;
    }

    .form-parnt h4.title {
        margin-top: 50px;
    }

    .form_page {
        height: 100vh;
        margin: 0px 0px 50px 0px;
    }

    .tab_btn_group {
        /* display: block; */
    }

    .tab_btn_group .btn_single {
        width: 100%;
    }

    .tab_btn_group .btn_single:last-child {
        /* margin-top: 14px; */
    }

    .phone_num_btn_login {
        font-size: 12px;
    }

    .email_btn_login {
        font-size: 12px;
    }

}

@media only screen and (min-device-width : 575px) and (max-device-width : 991px) {
    .form_page {
        padding-top: 50px;
        min-height: inherit;
        margin: 0px 0px 50px 0px;
    }

    .main_formarea {
        margin: 30px 0px 60px 0px;
    }

    .phone_num_btn_login {
        font-size: 13px;
    }

    .email_btn_login {
        font-size: 13px;
    }

}